// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: onprem/onprem.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  FabricTopology,
  fabricTopologyFromJSON,
  fabricTopologyToJSON,
  NodeRole,
  nodeRoleFromJSON,
  nodeRoleToJSON,
  NodeType,
  nodeTypeFromJSON,
  nodeTypeToJSON,
  OsType,
  osTypeFromJSON,
  osTypeToJSON,
} from "../models/fabric";
import {
  AdminState,
  adminStateFromJSON,
  adminStateToJSON,
  FabricType,
  fabricTypeFromJSON,
  fabricTypeToJSON,
} from "../models/global";
import {
  DhcpRelay,
  Loopback,
  NetworkPort,
  PortBreakout,
  PortChannel,
  StaticRoutes,
  SubInterface,
  Vlan,
  Vni,
  Vrf,
} from "../models/models";
import { BgpPeer, BgpPolicy, PerVlanStp, PrefixList } from "../models/routing";
import { Certificates, PortConnection, VlanStp } from "../models/types";
import { MgmtConfig } from "../statsd/statsd";
import {
  TaskConfig,
  WorkflowSet,
  WorkflowStatus,
  workflowStatusFromJSON,
  workflowStatusToJSON,
  WorkflowType,
  workflowTypeFromJSON,
  workflowTypeToJSON,
} from "./workflow";

export const protobufPackage = "onprem";

/** TaskFeedback encapsulates a task execution feedback message. */
export interface TaskFeedback {
  /** Status of command execution. */
  status: WorkflowStatus;
  /** The task name. */
  taskName: string;
  /** Scripts that got executed. */
  scripts: string;
  /** Output message. */
  output: string;
  /** Command execution start time. */
  startTime:
    | Date
    | undefined;
  /** Command execution duration. */
  duration: number;
}

/** WorkflowFeedback defines a workflow execution feedback message. */
export interface WorkflowFeedback {
  /** The node identifier (for APIs). */
  nodeId: string;
  /** The device or node name. */
  deviceName: string;
  /** The config fingerprint. */
  configFp: string;
  /** The workflow name. */
  workflowName: string;
  /** Error string if any. */
  error: string;
  /** Workflow execution start timestamp. */
  startTime:
    | Date
    | undefined;
  /** Workflow execution duration. */
  duration: number;
  /** Feedback objects. */
  feedbacks: TaskFeedback[];
  /** Workflow type. */
  workflowType: WorkflowType;
  /** The device identifier (for devices). */
  deviceId: string;
}

/** FabricConfig encapsulates configuration of all device in a fabric. */
export interface FabricConfig {
  /** The fabric identifier. */
  fabricId: string;
  /** The fabric name. */
  name: string;
  /** The fingerprint of config. */
  configFp: string;
  /** State of the object (enabled or disabled). */
  adminState: AdminState;
  /** Tortuga's current timestamp. */
  timestamp:
    | Date
    | undefined;
  /** Fabric topology (SPINE/LEAF or MESH). */
  topology: FabricTopology;
  /** Fabric labels. */
  labels: string[];
  /** Switch OS type (SONIC, NEXUS etc.) */
  osType: OsType;
  /** Fabric transaction identifier. */
  txnId: number;
  /** Static anycast gateway MAC address. */
  sagMac: string;
  /** Enable/disable unknown vlan detection feature. */
  detectUnknownVlans: boolean;
  description: string;
  /** Fabric type. */
  fabricType: FabricType;
  /** Enable full feedbacks. */
  fullFeedback: boolean;
  /** Commit comments. */
  comments: string;
  /** EVPN-MH MAC hold time in seconds. */
  evpnMhMacHt: number;
  /** EVPN-MH neighbor hold time in seconds. */
  evpnMhNeighHt: number;
  /** EVPN-MH start delay in seconds. */
  evpnMhStartDelay: number;
  /** EVPN-NH uplink redirect status (ON or OFF). */
  evpnMhRedirect: boolean;
  /** Configs for devices in the fabric. */
  devices: DeviceConfig[];
  /** Fabric port connections. */
  connections: PortConnection[];
  /** Org certificates. */
  certificates: Certificates[];
  /** The user who triggered fabric grind. */
  userId: string;
  /** Global tasks definitions. */
  tasks: TaskConfig[];
}

/** ManagementConfig encapsulates management config such as DNS, port config etc. */
export interface ManagementConfig {
  /** The fingerprint of config. */
  configFp: string;
  /** Device's management config. */
  mgmtConfig: MgmtConfig | undefined;
}

/** DeviceStp encapsulates spanning-tree configs of a device. */
export interface DeviceStp {
  /** Common STP config object. */
  common:
    | PerVlanStp
    | undefined;
  /** A set Vlan specific STP objects. */
  vlans: VlanStp[];
}

/**
 * DeviceConfig encapsulates configuration needed by an agent to process
 * workflows of a device.
 */
export interface DeviceConfig {
  /** The device identifier. */
  deviceId: string;
  /** The device name. */
  name: string;
  /** The fingerprint of config. */
  configFp: string;
  /** State of the object (enabled or disabled). */
  adminState: AdminState;
  /** Device labels. */
  labels: string[];
  /** Device description. */
  description: string;
  /** Semantic version of workflow engine. */
  version: string;
  /** Device type. */
  deviceType: NodeType;
  /** Blueprint node identifier. */
  nodeId: string;
  /** Device role (LEAF, SPINE etc.) */
  roles: NodeRole[];
  /** Device's NetworkPort configs. */
  ports: NetworkPort[];
  /** PortChannel configs. */
  portChannels: PortChannel[];
  /** Vrf objects. */
  vrfs: Vrf[];
  /** Vni objects. */
  vnis: Vni[];
  /** Router identifier. */
  routerId: string;
  /** Serial number (for thread-list). */
  serialNumber: string;
  /** Model name (for thread-list). */
  modelName: string;
  /** OS type (for thread-list). */
  osType: OsType;
  /** Loopback configs. */
  loopbacks: Loopback[];
  /** SubInterface objects. */
  subInterfaces: SubInterface[];
  /** DhcpRelay objects. */
  dhcpRelays: DhcpRelay[];
  /** Vlan objects. */
  vlans: Vlan[];
  /** Management config. */
  mgmtConfig:
    | ManagementConfig
    | undefined;
  /** BGP peer objects. */
  bgpPeers: BgpPeer[];
  /** BGP policy objects. */
  bgpPolicies: BgpPolicy[];
  /** Static-route configs. */
  staticRoutes: StaticRoutes[];
  /** Port breakout objects. */
  portBreakouts: PortBreakout[];
  /** Spanning-tree config object. */
  stp:
    | DeviceStp
    | undefined;
  /** Prefix lists. */
  prefixLists: PrefixList[];
  /** Workflow sets. */
  workflowSets: WorkflowSet[];
  /** Workflow engine version. */
  workflowVersion: string;
  /** Device specific task definitions. */
  tasks: TaskConfig[];
  /** Grind identifier specific to this node. */
  grindId: number;
  /** The position of device within the fabric partitioned by node role. */
  position: number;
  /** The sequence of device within the fabric. */
  sequence: number;
}

function createBaseTaskFeedback(): TaskFeedback {
  return { status: 0, taskName: "", scripts: "", output: "", startTime: undefined, duration: 0 };
}

export const TaskFeedback = {
  encode(message: TaskFeedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.taskName !== "") {
      writer.uint32(18).string(message.taskName);
    }
    if (message.scripts !== "") {
      writer.uint32(26).string(message.scripts);
    }
    if (message.output !== "") {
      writer.uint32(34).string(message.output);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(42).fork()).ldelim();
    }
    if (message.duration !== 0) {
      writer.uint32(48).int64(message.duration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskFeedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskFeedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.taskName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scripts = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.output = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.duration = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TaskFeedback {
    return {
      status: isSet(object.status) ? workflowStatusFromJSON(object.status) : 0,
      taskName: isSet(object.taskName) ? globalThis.String(object.taskName) : "",
      scripts: isSet(object.scripts) ? globalThis.String(object.scripts) : "",
      output: isSet(object.output) ? globalThis.String(object.output) : "",
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
    };
  },

  toJSON(message: TaskFeedback): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = workflowStatusToJSON(message.status);
    }
    if (message.taskName !== "") {
      obj.taskName = message.taskName;
    }
    if (message.scripts !== "") {
      obj.scripts = message.scripts;
    }
    if (message.output !== "") {
      obj.output = message.output;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TaskFeedback>, I>>(base?: I): TaskFeedback {
    return TaskFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TaskFeedback>, I>>(object: I): TaskFeedback {
    const message = createBaseTaskFeedback();
    message.status = object.status ?? 0;
    message.taskName = object.taskName ?? "";
    message.scripts = object.scripts ?? "";
    message.output = object.output ?? "";
    message.startTime = object.startTime ?? undefined;
    message.duration = object.duration ?? 0;
    return message;
  },
};

function createBaseWorkflowFeedback(): WorkflowFeedback {
  return {
    nodeId: "",
    deviceName: "",
    configFp: "",
    workflowName: "",
    error: "",
    startTime: undefined,
    duration: 0,
    feedbacks: [],
    workflowType: 0,
    deviceId: "",
  };
}

export const WorkflowFeedback = {
  encode(message: WorkflowFeedback, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.deviceName !== "") {
      writer.uint32(18).string(message.deviceName);
    }
    if (message.configFp !== "") {
      writer.uint32(26).string(message.configFp);
    }
    if (message.workflowName !== "") {
      writer.uint32(34).string(message.workflowName);
    }
    if (message.error !== "") {
      writer.uint32(42).string(message.error);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.duration !== 0) {
      writer.uint32(56).int64(message.duration);
    }
    for (const v of message.feedbacks) {
      TaskFeedback.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.workflowType !== 0) {
      writer.uint32(72).int32(message.workflowType);
    }
    if (message.deviceId !== "") {
      writer.uint32(82).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowFeedback {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowFeedback();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.workflowName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.duration = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.feedbacks.push(TaskFeedback.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.workflowType = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowFeedback {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      workflowName: isSet(object.workflowName) ? globalThis.String(object.workflowName) : "",
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      feedbacks: globalThis.Array.isArray(object?.feedbacks)
        ? object.feedbacks.map((e: any) => TaskFeedback.fromJSON(e))
        : [],
      workflowType: isSet(object.workflowType) ? workflowTypeFromJSON(object.workflowType) : 0,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: WorkflowFeedback): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceName !== "") {
      obj.deviceName = message.deviceName;
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.workflowName !== "") {
      obj.workflowName = message.workflowName;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.feedbacks?.length) {
      obj.feedbacks = message.feedbacks.map((e) => TaskFeedback.toJSON(e));
    }
    if (message.workflowType !== 0) {
      obj.workflowType = workflowTypeToJSON(message.workflowType);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowFeedback>, I>>(base?: I): WorkflowFeedback {
    return WorkflowFeedback.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowFeedback>, I>>(object: I): WorkflowFeedback {
    const message = createBaseWorkflowFeedback();
    message.nodeId = object.nodeId ?? "";
    message.deviceName = object.deviceName ?? "";
    message.configFp = object.configFp ?? "";
    message.workflowName = object.workflowName ?? "";
    message.error = object.error ?? "";
    message.startTime = object.startTime ?? undefined;
    message.duration = object.duration ?? 0;
    message.feedbacks = object.feedbacks?.map((e) => TaskFeedback.fromPartial(e)) || [];
    message.workflowType = object.workflowType ?? 0;
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseFabricConfig(): FabricConfig {
  return {
    fabricId: "",
    name: "",
    configFp: "",
    adminState: 0,
    timestamp: undefined,
    topology: 0,
    labels: [],
    osType: 0,
    txnId: 0,
    sagMac: "",
    detectUnknownVlans: false,
    description: "",
    fabricType: 0,
    fullFeedback: false,
    comments: "",
    evpnMhMacHt: 0,
    evpnMhNeighHt: 0,
    evpnMhStartDelay: 0,
    evpnMhRedirect: false,
    devices: [],
    connections: [],
    certificates: [],
    userId: "",
    tasks: [],
  };
}

export const FabricConfig = {
  encode(message: FabricConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.configFp !== "") {
      writer.uint32(26).string(message.configFp);
    }
    if (message.adminState !== 0) {
      writer.uint32(32).int32(message.adminState);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(42).fork()).ldelim();
    }
    if (message.topology !== 0) {
      writer.uint32(48).int32(message.topology);
    }
    for (const v of message.labels) {
      writer.uint32(58).string(v!);
    }
    if (message.osType !== 0) {
      writer.uint32(64).int32(message.osType);
    }
    if (message.txnId !== 0) {
      writer.uint32(80).int64(message.txnId);
    }
    if (message.sagMac !== "") {
      writer.uint32(90).string(message.sagMac);
    }
    if (message.detectUnknownVlans !== false) {
      writer.uint32(96).bool(message.detectUnknownVlans);
    }
    if (message.description !== "") {
      writer.uint32(106).string(message.description);
    }
    if (message.fabricType !== 0) {
      writer.uint32(112).int32(message.fabricType);
    }
    if (message.fullFeedback !== false) {
      writer.uint32(120).bool(message.fullFeedback);
    }
    if (message.comments !== "") {
      writer.uint32(130).string(message.comments);
    }
    if (message.evpnMhMacHt !== 0) {
      writer.uint32(136).uint32(message.evpnMhMacHt);
    }
    if (message.evpnMhNeighHt !== 0) {
      writer.uint32(144).uint32(message.evpnMhNeighHt);
    }
    if (message.evpnMhStartDelay !== 0) {
      writer.uint32(152).uint32(message.evpnMhStartDelay);
    }
    if (message.evpnMhRedirect !== false) {
      writer.uint32(160).bool(message.evpnMhRedirect);
    }
    for (const v of message.devices) {
      DeviceConfig.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    for (const v of message.certificates) {
      Certificates.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    if (message.userId !== "") {
      writer.uint32(306).string(message.userId);
    }
    for (const v of message.tasks) {
      TaskConfig.encode(v!, writer.uint32(322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.topology = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.txnId = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sagMac = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.detectUnknownVlans = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.description = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.fabricType = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.fullFeedback = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.evpnMhMacHt = reader.uint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.evpnMhNeighHt = reader.uint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.evpnMhStartDelay = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.evpnMhRedirect = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.devices.push(DeviceConfig.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.certificates.push(Certificates.decode(reader, reader.uint32()));
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.tasks.push(TaskConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConfig {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      topology: isSet(object.topology) ? fabricTopologyFromJSON(object.topology) : 0,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      txnId: isSet(object.txnId) ? globalThis.Number(object.txnId) : 0,
      sagMac: isSet(object.sagMac) ? globalThis.String(object.sagMac) : "",
      detectUnknownVlans: isSet(object.detectUnknownVlans) ? globalThis.Boolean(object.detectUnknownVlans) : false,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      fabricType: isSet(object.fabricType) ? fabricTypeFromJSON(object.fabricType) : 0,
      fullFeedback: isSet(object.fullFeedback) ? globalThis.Boolean(object.fullFeedback) : false,
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
      evpnMhMacHt: isSet(object.evpnMhMacHt) ? globalThis.Number(object.evpnMhMacHt) : 0,
      evpnMhNeighHt: isSet(object.evpnMhNeighHt) ? globalThis.Number(object.evpnMhNeighHt) : 0,
      evpnMhStartDelay: isSet(object.evpnMhStartDelay) ? globalThis.Number(object.evpnMhStartDelay) : 0,
      evpnMhRedirect: isSet(object.evpnMhRedirect) ? globalThis.Boolean(object.evpnMhRedirect) : false,
      devices: globalThis.Array.isArray(object?.devices)
        ? object.devices.map((e: any) => DeviceConfig.fromJSON(e))
        : [],
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
      certificates: globalThis.Array.isArray(object?.certificates)
        ? object.certificates.map((e: any) => Certificates.fromJSON(e))
        : [],
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      tasks: globalThis.Array.isArray(object?.tasks) ? object.tasks.map((e: any) => TaskConfig.fromJSON(e)) : [],
    };
  },

  toJSON(message: FabricConfig): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.topology !== 0) {
      obj.topology = fabricTopologyToJSON(message.topology);
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.txnId !== 0) {
      obj.txnId = Math.round(message.txnId);
    }
    if (message.sagMac !== "") {
      obj.sagMac = message.sagMac;
    }
    if (message.detectUnknownVlans !== false) {
      obj.detectUnknownVlans = message.detectUnknownVlans;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.fabricType !== 0) {
      obj.fabricType = fabricTypeToJSON(message.fabricType);
    }
    if (message.fullFeedback !== false) {
      obj.fullFeedback = message.fullFeedback;
    }
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    if (message.evpnMhMacHt !== 0) {
      obj.evpnMhMacHt = Math.round(message.evpnMhMacHt);
    }
    if (message.evpnMhNeighHt !== 0) {
      obj.evpnMhNeighHt = Math.round(message.evpnMhNeighHt);
    }
    if (message.evpnMhStartDelay !== 0) {
      obj.evpnMhStartDelay = Math.round(message.evpnMhStartDelay);
    }
    if (message.evpnMhRedirect !== false) {
      obj.evpnMhRedirect = message.evpnMhRedirect;
    }
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => DeviceConfig.toJSON(e));
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    if (message.certificates?.length) {
      obj.certificates = message.certificates.map((e) => Certificates.toJSON(e));
    }
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => TaskConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConfig>, I>>(base?: I): FabricConfig {
    return FabricConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConfig>, I>>(object: I): FabricConfig {
    const message = createBaseFabricConfig();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.configFp = object.configFp ?? "";
    message.adminState = object.adminState ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    message.topology = object.topology ?? 0;
    message.labels = object.labels?.map((e) => e) || [];
    message.osType = object.osType ?? 0;
    message.txnId = object.txnId ?? 0;
    message.sagMac = object.sagMac ?? "";
    message.detectUnknownVlans = object.detectUnknownVlans ?? false;
    message.description = object.description ?? "";
    message.fabricType = object.fabricType ?? 0;
    message.fullFeedback = object.fullFeedback ?? false;
    message.comments = object.comments ?? "";
    message.evpnMhMacHt = object.evpnMhMacHt ?? 0;
    message.evpnMhNeighHt = object.evpnMhNeighHt ?? 0;
    message.evpnMhStartDelay = object.evpnMhStartDelay ?? 0;
    message.evpnMhRedirect = object.evpnMhRedirect ?? false;
    message.devices = object.devices?.map((e) => DeviceConfig.fromPartial(e)) || [];
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    message.certificates = object.certificates?.map((e) => Certificates.fromPartial(e)) || [];
    message.userId = object.userId ?? "";
    message.tasks = object.tasks?.map((e) => TaskConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseManagementConfig(): ManagementConfig {
  return { configFp: "", mgmtConfig: undefined };
}

export const ManagementConfig = {
  encode(message: ManagementConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.configFp !== "") {
      writer.uint32(10).string(message.configFp);
    }
    if (message.mgmtConfig !== undefined) {
      MgmtConfig.encode(message.mgmtConfig, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagementConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagementConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mgmtConfig = MgmtConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagementConfig {
    return {
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      mgmtConfig: isSet(object.mgmtConfig) ? MgmtConfig.fromJSON(object.mgmtConfig) : undefined,
    };
  },

  toJSON(message: ManagementConfig): unknown {
    const obj: any = {};
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.mgmtConfig !== undefined) {
      obj.mgmtConfig = MgmtConfig.toJSON(message.mgmtConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagementConfig>, I>>(base?: I): ManagementConfig {
    return ManagementConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagementConfig>, I>>(object: I): ManagementConfig {
    const message = createBaseManagementConfig();
    message.configFp = object.configFp ?? "";
    message.mgmtConfig = (object.mgmtConfig !== undefined && object.mgmtConfig !== null)
      ? MgmtConfig.fromPartial(object.mgmtConfig)
      : undefined;
    return message;
  },
};

function createBaseDeviceStp(): DeviceStp {
  return { common: undefined, vlans: [] };
}

export const DeviceStp = {
  encode(message: DeviceStp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.common !== undefined) {
      PerVlanStp.encode(message.common, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.vlans) {
      VlanStp.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceStp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceStp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.common = PerVlanStp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vlans.push(VlanStp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceStp {
    return {
      common: isSet(object.common) ? PerVlanStp.fromJSON(object.common) : undefined,
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => VlanStp.fromJSON(e)) : [],
    };
  },

  toJSON(message: DeviceStp): unknown {
    const obj: any = {};
    if (message.common !== undefined) {
      obj.common = PerVlanStp.toJSON(message.common);
    }
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => VlanStp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceStp>, I>>(base?: I): DeviceStp {
    return DeviceStp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceStp>, I>>(object: I): DeviceStp {
    const message = createBaseDeviceStp();
    message.common = (object.common !== undefined && object.common !== null)
      ? PerVlanStp.fromPartial(object.common)
      : undefined;
    message.vlans = object.vlans?.map((e) => VlanStp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeviceConfig(): DeviceConfig {
  return {
    deviceId: "",
    name: "",
    configFp: "",
    adminState: 0,
    labels: [],
    description: "",
    version: "",
    deviceType: 0,
    nodeId: "",
    roles: [],
    ports: [],
    portChannels: [],
    vrfs: [],
    vnis: [],
    routerId: "",
    serialNumber: "",
    modelName: "",
    osType: 0,
    loopbacks: [],
    subInterfaces: [],
    dhcpRelays: [],
    vlans: [],
    mgmtConfig: undefined,
    bgpPeers: [],
    bgpPolicies: [],
    staticRoutes: [],
    portBreakouts: [],
    stp: undefined,
    prefixLists: [],
    workflowSets: [],
    workflowVersion: "",
    tasks: [],
    grindId: 0,
    position: 0,
    sequence: 0,
  };
}

export const DeviceConfig = {
  encode(message: DeviceConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.configFp !== "") {
      writer.uint32(26).string(message.configFp);
    }
    if (message.adminState !== 0) {
      writer.uint32(32).int32(message.adminState);
    }
    for (const v of message.labels) {
      writer.uint32(42).string(v!);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.version !== "") {
      writer.uint32(58).string(message.version);
    }
    if (message.deviceType !== 0) {
      writer.uint32(64).int32(message.deviceType);
    }
    if (message.nodeId !== "") {
      writer.uint32(74).string(message.nodeId);
    }
    writer.uint32(82).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.ports) {
      NetworkPort.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.portChannels) {
      PortChannel.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.vrfs) {
      Vrf.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.vnis) {
      Vni.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.routerId !== "") {
      writer.uint32(122).string(message.routerId);
    }
    if (message.serialNumber !== "") {
      writer.uint32(130).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(138).string(message.modelName);
    }
    if (message.osType !== 0) {
      writer.uint32(144).int32(message.osType);
    }
    for (const v of message.loopbacks) {
      Loopback.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.subInterfaces) {
      SubInterface.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.dhcpRelays) {
      DhcpRelay.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.vlans) {
      Vlan.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    if (message.mgmtConfig !== undefined) {
      ManagementConfig.encode(message.mgmtConfig, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.bgpPeers) {
      BgpPeer.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.bgpPolicies) {
      BgpPolicy.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.staticRoutes) {
      StaticRoutes.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    for (const v of message.portBreakouts) {
      PortBreakout.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    if (message.stp !== undefined) {
      DeviceStp.encode(message.stp, writer.uint32(226).fork()).ldelim();
    }
    for (const v of message.prefixLists) {
      PrefixList.encode(v!, writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.workflowSets) {
      WorkflowSet.encode(v!, writer.uint32(322).fork()).ldelim();
    }
    if (message.workflowVersion !== "") {
      writer.uint32(330).string(message.workflowVersion);
    }
    for (const v of message.tasks) {
      TaskConfig.encode(v!, writer.uint32(338).fork()).ldelim();
    }
    if (message.grindId !== 0) {
      writer.uint32(344).int32(message.grindId);
    }
    if (message.position !== 0) {
      writer.uint32(352).int32(message.position);
    }
    if (message.sequence !== 0) {
      writer.uint32(368).int32(message.sequence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.version = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 10:
          if (tag === 80) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ports.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.portChannels.push(PortChannel.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.vrfs.push(Vrf.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.vnis.push(Vni.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.routerId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.loopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.subInterfaces.push(SubInterface.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.dhcpRelays.push(DhcpRelay.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.vlans.push(Vlan.decode(reader, reader.uint32()));
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.mgmtConfig = ManagementConfig.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.bgpPeers.push(BgpPeer.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.bgpPolicies.push(BgpPolicy.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.staticRoutes.push(StaticRoutes.decode(reader, reader.uint32()));
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.portBreakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.stp = DeviceStp.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.prefixLists.push(PrefixList.decode(reader, reader.uint32()));
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.workflowSets.push(WorkflowSet.decode(reader, reader.uint32()));
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.workflowVersion = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.tasks.push(TaskConfig.decode(reader, reader.uint32()));
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.grindId = reader.int32();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.position = reader.int32();
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.sequence = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceConfig {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      deviceType: isSet(object.deviceType) ? nodeTypeFromJSON(object.deviceType) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => NetworkPort.fromJSON(e)) : [],
      portChannels: globalThis.Array.isArray(object?.portChannels)
        ? object.portChannels.map((e: any) => PortChannel.fromJSON(e))
        : [],
      vrfs: globalThis.Array.isArray(object?.vrfs) ? object.vrfs.map((e: any) => Vrf.fromJSON(e)) : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => Vni.fromJSON(e)) : [],
      routerId: isSet(object.routerId) ? globalThis.String(object.routerId) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      loopbacks: globalThis.Array.isArray(object?.loopbacks)
        ? object.loopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
      subInterfaces: globalThis.Array.isArray(object?.subInterfaces)
        ? object.subInterfaces.map((e: any) => SubInterface.fromJSON(e))
        : [],
      dhcpRelays: globalThis.Array.isArray(object?.dhcpRelays)
        ? object.dhcpRelays.map((e: any) => DhcpRelay.fromJSON(e))
        : [],
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => Vlan.fromJSON(e)) : [],
      mgmtConfig: isSet(object.mgmtConfig) ? ManagementConfig.fromJSON(object.mgmtConfig) : undefined,
      bgpPeers: globalThis.Array.isArray(object?.bgpPeers) ? object.bgpPeers.map((e: any) => BgpPeer.fromJSON(e)) : [],
      bgpPolicies: globalThis.Array.isArray(object?.bgpPolicies)
        ? object.bgpPolicies.map((e: any) => BgpPolicy.fromJSON(e))
        : [],
      staticRoutes: globalThis.Array.isArray(object?.staticRoutes)
        ? object.staticRoutes.map((e: any) => StaticRoutes.fromJSON(e))
        : [],
      portBreakouts: globalThis.Array.isArray(object?.portBreakouts)
        ? object.portBreakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
      stp: isSet(object.stp) ? DeviceStp.fromJSON(object.stp) : undefined,
      prefixLists: globalThis.Array.isArray(object?.prefixLists)
        ? object.prefixLists.map((e: any) => PrefixList.fromJSON(e))
        : [],
      workflowSets: globalThis.Array.isArray(object?.workflowSets)
        ? object.workflowSets.map((e: any) => WorkflowSet.fromJSON(e))
        : [],
      workflowVersion: isSet(object.workflowVersion) ? globalThis.String(object.workflowVersion) : "",
      tasks: globalThis.Array.isArray(object?.tasks)
        ? object.tasks.map((e: any) => TaskConfig.fromJSON(e))
        : [],
      grindId: isSet(object.grindId) ? globalThis.Number(object.grindId) : 0,
      position: isSet(object.position) ? globalThis.Number(object.position) : 0,
      sequence: isSet(object.sequence) ? globalThis.Number(object.sequence) : 0,
    };
  },

  toJSON(message: DeviceConfig): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.deviceType !== 0) {
      obj.deviceType = nodeTypeToJSON(message.deviceType);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => NetworkPort.toJSON(e));
    }
    if (message.portChannels?.length) {
      obj.portChannels = message.portChannels.map((e) => PortChannel.toJSON(e));
    }
    if (message.vrfs?.length) {
      obj.vrfs = message.vrfs.map((e) => Vrf.toJSON(e));
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Vni.toJSON(e));
    }
    if (message.routerId !== "") {
      obj.routerId = message.routerId;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.loopbacks?.length) {
      obj.loopbacks = message.loopbacks.map((e) => Loopback.toJSON(e));
    }
    if (message.subInterfaces?.length) {
      obj.subInterfaces = message.subInterfaces.map((e) => SubInterface.toJSON(e));
    }
    if (message.dhcpRelays?.length) {
      obj.dhcpRelays = message.dhcpRelays.map((e) => DhcpRelay.toJSON(e));
    }
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => Vlan.toJSON(e));
    }
    if (message.mgmtConfig !== undefined) {
      obj.mgmtConfig = ManagementConfig.toJSON(message.mgmtConfig);
    }
    if (message.bgpPeers?.length) {
      obj.bgpPeers = message.bgpPeers.map((e) => BgpPeer.toJSON(e));
    }
    if (message.bgpPolicies?.length) {
      obj.bgpPolicies = message.bgpPolicies.map((e) => BgpPolicy.toJSON(e));
    }
    if (message.staticRoutes?.length) {
      obj.staticRoutes = message.staticRoutes.map((e) => StaticRoutes.toJSON(e));
    }
    if (message.portBreakouts?.length) {
      obj.portBreakouts = message.portBreakouts.map((e) => PortBreakout.toJSON(e));
    }
    if (message.stp !== undefined) {
      obj.stp = DeviceStp.toJSON(message.stp);
    }
    if (message.prefixLists?.length) {
      obj.prefixLists = message.prefixLists.map((e) => PrefixList.toJSON(e));
    }
    if (message.workflowSets?.length) {
      obj.workflowSets = message.workflowSets.map((e) => WorkflowSet.toJSON(e));
    }
    if (message.workflowVersion !== "") {
      obj.workflowVersion = message.workflowVersion;
    }
    if (message.tasks?.length) {
      obj.tasks = message.tasks.map((e) => TaskConfig.toJSON(e));
    }
    if (message.grindId !== 0) {
      obj.grindId = Math.round(message.grindId);
    }
    if (message.position !== 0) {
      obj.position = Math.round(message.position);
    }
    if (message.sequence !== 0) {
      obj.sequence = Math.round(message.sequence);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceConfig>, I>>(base?: I): DeviceConfig {
    return DeviceConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceConfig>, I>>(object: I): DeviceConfig {
    const message = createBaseDeviceConfig();
    message.deviceId = object.deviceId ?? "";
    message.name = object.name ?? "";
    message.configFp = object.configFp ?? "";
    message.adminState = object.adminState ?? 0;
    message.labels = object.labels?.map((e) => e) || [];
    message.description = object.description ?? "";
    message.version = object.version ?? "";
    message.deviceType = object.deviceType ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.ports = object.ports?.map((e) => NetworkPort.fromPartial(e)) || [];
    message.portChannels = object.portChannels?.map((e) => PortChannel.fromPartial(e)) || [];
    message.vrfs = object.vrfs?.map((e) => Vrf.fromPartial(e)) || [];
    message.vnis = object.vnis?.map((e) => Vni.fromPartial(e)) || [];
    message.routerId = object.routerId ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.osType = object.osType ?? 0;
    message.loopbacks = object.loopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    message.subInterfaces = object.subInterfaces?.map((e) => SubInterface.fromPartial(e)) || [];
    message.dhcpRelays = object.dhcpRelays?.map((e) => DhcpRelay.fromPartial(e)) || [];
    message.vlans = object.vlans?.map((e) => Vlan.fromPartial(e)) || [];
    message.mgmtConfig = (object.mgmtConfig !== undefined && object.mgmtConfig !== null)
      ? ManagementConfig.fromPartial(object.mgmtConfig)
      : undefined;
    message.bgpPeers = object.bgpPeers?.map((e) => BgpPeer.fromPartial(e)) || [];
    message.bgpPolicies = object.bgpPolicies?.map((e) => BgpPolicy.fromPartial(e)) || [];
    message.staticRoutes = object.staticRoutes?.map((e) => StaticRoutes.fromPartial(e)) || [];
    message.portBreakouts = object.portBreakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    message.stp = (object.stp !== undefined && object.stp !== null) ? DeviceStp.fromPartial(object.stp) : undefined;
    message.prefixLists = object.prefixLists?.map((e) => PrefixList.fromPartial(e)) || [];
    message.workflowSets = object.workflowSets?.map((e) => WorkflowSet.fromPartial(e)) || [];
    message.workflowVersion = object.workflowVersion ?? "";
    message.tasks = object.tasks?.map((e) => TaskConfig.fromPartial(e)) || [];
    message.grindId = object.grindId ?? 0;
    message.position = object.position ?? 0;
    message.sequence = object.sequence ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
