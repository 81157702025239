// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: fred/fapi.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "fred";

export interface GetUserContextsRequest {
}

export interface UserContext {
  orgId: string;
  orgName: string;
  tenantId: string;
  tenantName: string;
  current: boolean;
}

export interface GetUserContextsResponse {
  email: string;
  contexts: UserContext[];
}

function createBaseGetUserContextsRequest(): GetUserContextsRequest {
  return {};
}

export const GetUserContextsRequest = {
  encode(_: GetUserContextsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserContextsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserContextsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserContextsRequest {
    return {};
  },

  toJSON(_: GetUserContextsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserContextsRequest>, I>>(base?: I): GetUserContextsRequest {
    return GetUserContextsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserContextsRequest>, I>>(_: I): GetUserContextsRequest {
    const message = createBaseGetUserContextsRequest();
    return message;
  },
};

function createBaseUserContext(): UserContext {
  return { orgId: "", orgName: "", tenantId: "", tenantName: "", current: false };
}

export const UserContext = {
  encode(message: UserContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    if (message.orgName !== "") {
      writer.uint32(18).string(message.orgName);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(34).string(message.tenantName);
    }
    if (message.current !== false) {
      writer.uint32(40).bool(message.current);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.current = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserContext {
    return {
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      current: isSet(object.current) ? globalThis.Boolean(object.current) : false,
    };
  },

  toJSON(message: UserContext): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.current !== false) {
      obj.current = message.current;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserContext>, I>>(base?: I): UserContext {
    return UserContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserContext>, I>>(object: I): UserContext {
    const message = createBaseUserContext();
    message.orgId = object.orgId ?? "";
    message.orgName = object.orgName ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.current = object.current ?? false;
    return message;
  },
};

function createBaseGetUserContextsResponse(): GetUserContextsResponse {
  return { email: "", contexts: [] };
}

export const GetUserContextsResponse = {
  encode(message: GetUserContextsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    for (const v of message.contexts) {
      UserContext.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserContextsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserContextsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contexts.push(UserContext.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserContextsResponse {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      contexts: globalThis.Array.isArray(object?.contexts)
        ? object.contexts.map((e: any) => UserContext.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetUserContextsResponse): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.contexts?.length) {
      obj.contexts = message.contexts.map((e) => UserContext.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserContextsResponse>, I>>(base?: I): GetUserContextsResponse {
    return GetUserContextsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserContextsResponse>, I>>(object: I): GetUserContextsResponse {
    const message = createBaseGetUserContextsResponse();
    message.email = object.email ?? "";
    message.contexts = object.contexts?.map((e) => UserContext.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
