// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: syncfollow/syncfollow_threadlist.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import {
  AggregatedDeviceClaim,
  ApiKey,
  ApiPath,
  ApiPathMethod,
  ApiServiceMapping,
  Application,
  AssertBgpPeerEstablished,
  AssertConfigBgpPeerEstablished,
  AssertConfigDeviceConnectedToCloud,
  AssertConfigDeviceConnectedToFabric,
  AssertConfigDeviceEnvFanUsageBelowThreshold,
  AssertConfigDeviceEnvPsuUsageBelowThreshold,
  AssertConfigDeviceEnvTempSensorUsageBelowThreshold,
  AssertConfigDeviceResourceUsageBelowThreshold,
  AssertConfigExpectedPortBreakoutExists,
  AssertConfigIpmJitterBelowThreshold,
  AssertConfigIpmLatencyBelowThreshold,
  AssertConfigIpmLossBelowThreshold,
  AssertConfigManagementPortConfigSame,
  AssertConfigPortChannelMemberUp,
  AssertConfigPortConnectionSpeedMatch,
  AssertConfigPortExpectedNeighbor,
  AssertConfigPortLinkDown,
  AssertConfigPortLinkUp,
  AssertConfigPortNotConnectedToFabric,
  AssertConfigPortNoUnknownVlanFound,
  AssertConfigPortPluggablePidSame,
  AssertConfigPortSpeedConsistent,
  AssertConfigPortVlanStpStateConsistent,
  AssertConfigStaticDefaultRouteExists,
  AssertConfigSubInterfaceDown,
  AssertConfigSubInterfaceUp,
  AssertConfigVlanHasTraffic,
  AssertDeviceConnectedToCloud,
  AssertDeviceConnectedToFabric,
  AssertDeviceEnvFanUsageBelowThreshold,
  AssertDeviceEnvPsuUsageBelowThreshold,
  AssertDeviceEnvTempSensorUsageBelowThreshold,
  AssertDeviceResourceUsageBelowThreshold,
  AssertExpectedPortBreakoutExists,
  AssertIpmJitterBelowThreshold,
  AssertIpmLatencyBelowThreshold,
  AssertIpmLossBelowThreshold,
  AssertManagementPortConfigSame,
  AssertPortChannelMemberUp,
  AssertPortConnectionSpeedMatch,
  AssertPortExpectedNeighbor,
  AssertPortLinkDown,
  AssertPortLinkUp,
  AssertPortNotConnectedToFabric,
  AssertPortNoUnknownVlanFound,
  AssertPortPluggablePidSame,
  AssertPortSpeedConsistent,
  AssertPortVlanStpStateConsistent,
  AssertStaticDefaultRouteExists,
  AssertSubInterfaceDown,
  AssertSubInterfaceUp,
  AssertVlanHasTraffic,
  AssignmentStatus,
  BearerToken,
  BgpNeighborFamily,
  BgpNeighborInfo,
  BgpNeighborSummary,
  Bmc,
  BmcConfig,
  Breakout,
  BulkUpliftJobRequest,
  BulkUpliftJobStatus,
  ClaimAndBindTask,
  DacQsfp,
  Device,
  DeviceClaim,
  DeviceConnection,
  EsiInfo,
  EsiVtepInfo,
  Fabric,
  FabricConfig,
  FabricConfigEvent,
  FabricConfigNotification,
  FabricConnectionTask,
  FabricDevice,
  FabricMemoryUsage,
  FabricTransaction,
  FabricUuid,
  Fan,
  FiberQsfp,
  IpmLivenessCounters,
  IpmProcJitterCounters,
  IpmProcLatencyCounters,
  IpmProcLossCounters,
  IpmRawLatencyCounters,
  IpmSessionId,
  IpNextHop,
  IpPrefix,
  IpRouteTable,
  KubernetesServiceInstance,
  L2Fdb,
  L2Mac,
  L3Table,
  LineCard,
  Mac,
  ManagementConfig,
  ManagementState,
  ManifestImageState,
  ManifestPartitionFileCacheStatus,
  ManifestRunningImageStatus,
  NegativeConnectionTask,
  Node,
  NodeBreakout,
  NodeConfig,
  NodeLineCard,
  NodePort,
  OrgServiceProto,
  OrgServiceRoute,
  OrgServiceSvc,
  OrgToCellPairMapping,
  OrgUuid,
  PlannedConfig,
  Port,
  PortChannelMemberState,
  PortChannelState,
  PortConfig,
  PortCounters,
  PortNeighbor,
  PortSetupTask,
  PortVlan,
  ProcTable,
  ProxyConnectionState,
  ProxyConnectionStats,
  ProxyStats,
  Psu,
  QsfpDomSensor,
  QsfpDomThreshold,
  QsfpInfo,
  RackAndPowerOnTask,
  RefPoint,
  RefPointStatus,
  RemedyAction,
  RemoteVtep,
  ResourceAssignment,
  RoutedInterfaceCounters,
  ServerTemperatureSensor,
  ServerVoltageSensor,
  ServiceInstanceHealth,
  SmartNicDpu,
  SpyglassBrokerEntry,
  SpyglassDevice,
  SpyglassGingerEntry,
  SpyglassSshSessionEntry,
  SpyglassTcpListener,
  SpyglassTcpSession,
  StpPortState,
  StpVlanPortState,
  StpVlanState,
  SubInterface,
  SystemStats,
  TempSensor,
  TenantMetadata,
  TenantUuid,
  TestIpAddress,
  TestIpPrefix,
  TestLeafOne,
  TestMacAddress,
  TestMidOne,
  TestPlaceHolder1Secret,
  TestRootOne,
  TestRootTwo,
  TypeMemoryUsage,
  UnderlayInfo,
  UnknownVlan,
  UnknownVlanPort,
  UnpackAndAssembleTask,
  UserEmail,
  VlanCounters,
  VlanState,
  VlanVniMap,
  VniPort,
  Vrf,
  Vtep,
} from "../schema/schema";
import { SchemaType, schemaTypeFromJSON, schemaTypeToJSON } from "../schema_path/schema_path";

export const protobufPackage = "syncfollow";

export interface DataSeries {
  /** The threadlist object name for this data */
  objectName: string;
  /** The threadlist object type for this data */
  objectType: SchemaType;
  /** The offset of this data point, can be used in the Request when requesting a resume */
  offset: number;
  /** The instance the offset is valid for */
  instance: number;
  fabric: Fabric[];
  device: Device[];
  fan: Fan[];
  psu: Psu[];
  tempSensor: TempSensor[];
  lineCard: LineCard[];
  port: Port[];
  dacQsfp: DacQsfp[];
  fiberQsfp: FiberQsfp[];
  breakout: Breakout[];
  portNeighbor: PortNeighbor[];
  vtep: Vtep[];
  vniPort: VniPort[];
  mac: Mac[];
  procTable: ProcTable[];
  portVlan: PortVlan[];
  vlanState: VlanState[];
  qsfpDomSensor: QsfpDomSensor[];
  portConfig: PortConfig[];
  nodeConfig: NodeConfig[];
  fabricConfig: FabricConfig[];
  fabricConfigEvent: FabricConfigEvent[];
  l2Fdb: L2Fdb[];
  portCounters: PortCounters[];
  remedyAction: RemedyAction[];
  qsfpInfo: QsfpInfo[];
  fabricDevice: FabricDevice[];
  assertConfigPortLinkUp: AssertConfigPortLinkUp[];
  assertPortLinkUp: AssertPortLinkUp[];
  assertConfigDeviceConnectedToCloud: AssertConfigDeviceConnectedToCloud[];
  assertDeviceConnectedToCloud: AssertDeviceConnectedToCloud[];
  assertConfigPortExpectedNeighbor: AssertConfigPortExpectedNeighbor[];
  assertPortExpectedNeighbor: AssertPortExpectedNeighbor[];
  assertConfigPortNotConnectedToFabric: AssertConfigPortNotConnectedToFabric[];
  assertPortNotConnectedToFabric: AssertPortNotConnectedToFabric[];
  assertConfigDeviceConnectedToFabric: AssertConfigDeviceConnectedToFabric[];
  assertDeviceConnectedToFabric: AssertDeviceConnectedToFabric[];
  assertConfigPortLinkDown: AssertConfigPortLinkDown[];
  assertPortLinkDown: AssertPortLinkDown[];
  assertConfigPortConnectionSpeedMatch: AssertConfigPortConnectionSpeedMatch[];
  assertPortConnectionSpeedMatch: AssertPortConnectionSpeedMatch[];
  assertConfigPortSpeedConsistent: AssertConfigPortSpeedConsistent[];
  assertPortSpeedConsistent: AssertPortSpeedConsistent[];
  underlayInfo: UnderlayInfo[];
  proxyStats: ProxyStats[];
  vlanCounters: VlanCounters[];
  testPlaceHolder1Secret: TestPlaceHolder1Secret[];
  assertConfigVlanHasTraffic: AssertConfigVlanHasTraffic[];
  assertVlanHasTraffic: AssertVlanHasTraffic[];
  remoteVtep: RemoteVtep[];
  qsfpDomThreshold: QsfpDomThreshold[];
  l3Table: L3Table[];
  vlanVniMap: VlanVniMap[];
  proxyConnectionState: ProxyConnectionState[];
  proxyConnectionStats: ProxyConnectionStats[];
  l2Mac: L2Mac[];
  bmc: Bmc[];
  smartNicDpu: SmartNicDpu[];
  serverVoltageSensor: ServerVoltageSensor[];
  serverTemperatureSensor: ServerTemperatureSensor[];
  ipRouteTable: IpRouteTable[];
  vrf: Vrf[];
  ipNextHop: IpNextHop[];
  ipPrefix: IpPrefix[];
  subInterface: SubInterface[];
  assertConfigStaticDefaultRouteExists: AssertConfigStaticDefaultRouteExists[];
  assertStaticDefaultRouteExists: AssertStaticDefaultRouteExists[];
  spyglassBrokerEntry: SpyglassBrokerEntry[];
  managementState: ManagementState[];
  assertConfigManagementPortConfigSame: AssertConfigManagementPortConfigSame[];
  assertManagementPortConfigSame: AssertManagementPortConfigSame[];
  fabricConfigNotification: FabricConfigNotification[];
  spyglassGingerEntry: SpyglassGingerEntry[];
  plannedConfig: PlannedConfig[];
  routedInterfaceCounters: RoutedInterfaceCounters[];
  ipmRawLatencyCounters: IpmRawLatencyCounters[];
  ipmProcLatencyCounters: IpmProcLatencyCounters[];
  ipmProcLossCounters: IpmProcLossCounters[];
  ipmLivenessCounters: IpmLivenessCounters[];
  ipmSessionId: IpmSessionId[];
  ipmProcJitterCounters: IpmProcJitterCounters[];
  systemStats: SystemStats[];
  testRootOne: TestRootOne[];
  testRootTwo: TestRootTwo[];
  testMidOne: TestMidOne[];
  testLeafOne: TestLeafOne[];
  testIpAddress: TestIpAddress[];
  testIpPrefix: TestIpPrefix[];
  testMacAddress: TestMacAddress[];
  assertConfigDeviceResourceUsageBelowThreshold: AssertConfigDeviceResourceUsageBelowThreshold[];
  assertDeviceResourceUsageBelowThreshold: AssertDeviceResourceUsageBelowThreshold[];
  fabricTransaction: FabricTransaction[];
  bgpNeighborSummary: BgpNeighborSummary[];
  bmcConfig: BmcConfig[];
  deviceConnection: DeviceConnection[];
  bgpNeighborFamily: BgpNeighborFamily[];
  assertConfigBgpPeerEstablished: AssertConfigBgpPeerEstablished[];
  assertBgpPeerEstablished: AssertBgpPeerEstablished[];
  spyglassSshSessionEntry: SpyglassSshSessionEntry[];
  portChannelMemberState: PortChannelMemberState[];
  portChannelState: PortChannelState[];
  assertConfigIpmLatencyBelowThreshold: AssertConfigIpmLatencyBelowThreshold[];
  assertIpmLatencyBelowThreshold: AssertIpmLatencyBelowThreshold[];
  assertConfigIpmLossBelowThreshold: AssertConfigIpmLossBelowThreshold[];
  assertIpmLossBelowThreshold: AssertIpmLossBelowThreshold[];
  spyglassDevice: SpyglassDevice[];
  spyglassTcpListener: SpyglassTcpListener[];
  spyglassTcpSession: SpyglassTcpSession[];
  unknownVlan: UnknownVlan[];
  unknownVlanPort: UnknownVlanPort[];
  assertConfigExpectedPortBreakoutExists: AssertConfigExpectedPortBreakoutExists[];
  assertExpectedPortBreakoutExists: AssertExpectedPortBreakoutExists[];
  assertConfigSubInterfaceUp: AssertConfigSubInterfaceUp[];
  assertSubInterfaceUp: AssertSubInterfaceUp[];
  assertSubInterfaceDown: AssertSubInterfaceDown[];
  assertConfigSubInterfaceDown: AssertConfigSubInterfaceDown[];
  deviceClaim: DeviceClaim[];
  aggregatedDeviceClaim: AggregatedDeviceClaim[];
  assertPortChannelMemberUp: AssertPortChannelMemberUp[];
  assertConfigPortChannelMemberUp: AssertConfigPortChannelMemberUp[];
  esiInfo: EsiInfo[];
  esiVtepInfo: EsiVtepInfo[];
  assertPortVlanStpStateConsistent: AssertPortVlanStpStateConsistent[];
  assertConfigPortVlanStpStateConsistent: AssertConfigPortVlanStpStateConsistent[];
  assertPortPluggablePidSame: AssertPortPluggablePidSame[];
  assertConfigPortPluggablePidSame: AssertConfigPortPluggablePidSame[];
  assertConfigIpmJitterBelowThreshold: AssertConfigIpmJitterBelowThreshold[];
  assertIpmJitterBelowThreshold: AssertIpmJitterBelowThreshold[];
  manifestImageState: ManifestImageState[];
  orgUuid: OrgUuid[];
  fabricUuid: FabricUuid[];
  orgServiceProto: OrgServiceProto[];
  orgServiceSvc: OrgServiceSvc[];
  orgServiceRoute: OrgServiceRoute[];
  apiPath: ApiPath[];
  apiPathMethod: ApiPathMethod[];
  apiServiceMapping: ApiServiceMapping[];
  orgToCellPairMapping: OrgToCellPairMapping[];
  tenantUuid: TenantUuid[];
  userEmail: UserEmail[];
  managementConfig: ManagementConfig[];
  serviceInstanceHealth: ServiceInstanceHealth[];
  assignmentStatus: AssignmentStatus[];
  kubernetesServiceInstance: KubernetesServiceInstance[];
  bearerToken: BearerToken[];
  apiKey: ApiKey[];
  fabricMemoryUsage: FabricMemoryUsage[];
  typeMemoryUsage: TypeMemoryUsage[];
  manifestRunningImageStatus: ManifestRunningImageStatus[];
  manifestPartitionFileCacheStatus: ManifestPartitionFileCacheStatus[];
  stpPortState: StpPortState[];
  stpVlanState: StpVlanState[];
  stpVlanPortState: StpVlanPortState[];
  assertDeviceEnvPsuUsageBelowThreshold: AssertDeviceEnvPsuUsageBelowThreshold[];
  assertDeviceEnvFanUsageBelowThreshold: AssertDeviceEnvFanUsageBelowThreshold[];
  assertDeviceEnvTempSensorUsageBelowThreshold: AssertDeviceEnvTempSensorUsageBelowThreshold[];
  assertConfigDeviceEnvPsuUsageBelowThreshold: AssertConfigDeviceEnvPsuUsageBelowThreshold[];
  assertConfigDeviceEnvFanUsageBelowThreshold: AssertConfigDeviceEnvFanUsageBelowThreshold[];
  assertConfigDeviceEnvTempSensorUsageBelowThreshold: AssertConfigDeviceEnvTempSensorUsageBelowThreshold[];
  unpackAndAssembleTask: UnpackAndAssembleTask[];
  rackAndPowerOnTask: RackAndPowerOnTask[];
  claimAndBindTask: ClaimAndBindTask[];
  portSetupTask: PortSetupTask[];
  fabricConnectionTask: FabricConnectionTask[];
  negativeConnectionTask: NegativeConnectionTask[];
  application: Application[];
  resourceAssignment: ResourceAssignment[];
  node: Node[];
  nodeLineCard: NodeLineCard[];
  nodePort: NodePort[];
  nodeBreakout: NodeBreakout[];
  tenantMetadata: TenantMetadata[];
  assertPortNoUnknownVlanFound: AssertPortNoUnknownVlanFound[];
  assertConfigPortNoUnknownVlanFound: AssertConfigPortNoUnknownVlanFound[];
  bulkUpliftJobRequest: BulkUpliftJobRequest[];
  bulkUpliftJobStatus: BulkUpliftJobStatus[];
  bgpNeighborInfo: BgpNeighborInfo[];
  refPoint: RefPoint[];
  refPointStatus: RefPointStatus[];
}

function createBaseDataSeries(): DataSeries {
  return {
    objectName: "",
    objectType: 0,
    offset: 0,
    instance: 0,
    fabric: [],
    device: [],
    fan: [],
    psu: [],
    tempSensor: [],
    lineCard: [],
    port: [],
    dacQsfp: [],
    fiberQsfp: [],
    breakout: [],
    portNeighbor: [],
    vtep: [],
    vniPort: [],
    mac: [],
    procTable: [],
    portVlan: [],
    vlanState: [],
    qsfpDomSensor: [],
    portConfig: [],
    nodeConfig: [],
    fabricConfig: [],
    fabricConfigEvent: [],
    l2Fdb: [],
    portCounters: [],
    remedyAction: [],
    qsfpInfo: [],
    fabricDevice: [],
    assertConfigPortLinkUp: [],
    assertPortLinkUp: [],
    assertConfigDeviceConnectedToCloud: [],
    assertDeviceConnectedToCloud: [],
    assertConfigPortExpectedNeighbor: [],
    assertPortExpectedNeighbor: [],
    assertConfigPortNotConnectedToFabric: [],
    assertPortNotConnectedToFabric: [],
    assertConfigDeviceConnectedToFabric: [],
    assertDeviceConnectedToFabric: [],
    assertConfigPortLinkDown: [],
    assertPortLinkDown: [],
    assertConfigPortConnectionSpeedMatch: [],
    assertPortConnectionSpeedMatch: [],
    assertConfigPortSpeedConsistent: [],
    assertPortSpeedConsistent: [],
    underlayInfo: [],
    proxyStats: [],
    vlanCounters: [],
    testPlaceHolder1Secret: [],
    assertConfigVlanHasTraffic: [],
    assertVlanHasTraffic: [],
    remoteVtep: [],
    qsfpDomThreshold: [],
    l3Table: [],
    vlanVniMap: [],
    proxyConnectionState: [],
    proxyConnectionStats: [],
    l2Mac: [],
    bmc: [],
    smartNicDpu: [],
    serverVoltageSensor: [],
    serverTemperatureSensor: [],
    ipRouteTable: [],
    vrf: [],
    ipNextHop: [],
    ipPrefix: [],
    subInterface: [],
    assertConfigStaticDefaultRouteExists: [],
    assertStaticDefaultRouteExists: [],
    spyglassBrokerEntry: [],
    managementState: [],
    assertConfigManagementPortConfigSame: [],
    assertManagementPortConfigSame: [],
    fabricConfigNotification: [],
    spyglassGingerEntry: [],
    plannedConfig: [],
    routedInterfaceCounters: [],
    ipmRawLatencyCounters: [],
    ipmProcLatencyCounters: [],
    ipmProcLossCounters: [],
    ipmLivenessCounters: [],
    ipmSessionId: [],
    ipmProcJitterCounters: [],
    systemStats: [],
    testRootOne: [],
    testRootTwo: [],
    testMidOne: [],
    testLeafOne: [],
    testIpAddress: [],
    testIpPrefix: [],
    testMacAddress: [],
    assertConfigDeviceResourceUsageBelowThreshold: [],
    assertDeviceResourceUsageBelowThreshold: [],
    fabricTransaction: [],
    bgpNeighborSummary: [],
    bmcConfig: [],
    deviceConnection: [],
    bgpNeighborFamily: [],
    assertConfigBgpPeerEstablished: [],
    assertBgpPeerEstablished: [],
    spyglassSshSessionEntry: [],
    portChannelMemberState: [],
    portChannelState: [],
    assertConfigIpmLatencyBelowThreshold: [],
    assertIpmLatencyBelowThreshold: [],
    assertConfigIpmLossBelowThreshold: [],
    assertIpmLossBelowThreshold: [],
    spyglassDevice: [],
    spyglassTcpListener: [],
    spyglassTcpSession: [],
    unknownVlan: [],
    unknownVlanPort: [],
    assertConfigExpectedPortBreakoutExists: [],
    assertExpectedPortBreakoutExists: [],
    assertConfigSubInterfaceUp: [],
    assertSubInterfaceUp: [],
    assertSubInterfaceDown: [],
    assertConfigSubInterfaceDown: [],
    deviceClaim: [],
    aggregatedDeviceClaim: [],
    assertPortChannelMemberUp: [],
    assertConfigPortChannelMemberUp: [],
    esiInfo: [],
    esiVtepInfo: [],
    assertPortVlanStpStateConsistent: [],
    assertConfigPortVlanStpStateConsistent: [],
    assertPortPluggablePidSame: [],
    assertConfigPortPluggablePidSame: [],
    assertConfigIpmJitterBelowThreshold: [],
    assertIpmJitterBelowThreshold: [],
    manifestImageState: [],
    orgUuid: [],
    fabricUuid: [],
    orgServiceProto: [],
    orgServiceSvc: [],
    orgServiceRoute: [],
    apiPath: [],
    apiPathMethod: [],
    apiServiceMapping: [],
    orgToCellPairMapping: [],
    tenantUuid: [],
    userEmail: [],
    managementConfig: [],
    serviceInstanceHealth: [],
    assignmentStatus: [],
    kubernetesServiceInstance: [],
    bearerToken: [],
    apiKey: [],
    fabricMemoryUsage: [],
    typeMemoryUsage: [],
    manifestRunningImageStatus: [],
    manifestPartitionFileCacheStatus: [],
    stpPortState: [],
    stpVlanState: [],
    stpVlanPortState: [],
    assertDeviceEnvPsuUsageBelowThreshold: [],
    assertDeviceEnvFanUsageBelowThreshold: [],
    assertDeviceEnvTempSensorUsageBelowThreshold: [],
    assertConfigDeviceEnvPsuUsageBelowThreshold: [],
    assertConfigDeviceEnvFanUsageBelowThreshold: [],
    assertConfigDeviceEnvTempSensorUsageBelowThreshold: [],
    unpackAndAssembleTask: [],
    rackAndPowerOnTask: [],
    claimAndBindTask: [],
    portSetupTask: [],
    fabricConnectionTask: [],
    negativeConnectionTask: [],
    application: [],
    resourceAssignment: [],
    node: [],
    nodeLineCard: [],
    nodePort: [],
    nodeBreakout: [],
    tenantMetadata: [],
    assertPortNoUnknownVlanFound: [],
    assertConfigPortNoUnknownVlanFound: [],
    bulkUpliftJobRequest: [],
    bulkUpliftJobStatus: [],
    bgpNeighborInfo: [],
    refPoint: [],
    refPointStatus: [],
  };
}

export const DataSeries = {
  encode(message: DataSeries, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectName !== "") {
      writer.uint32(18).string(message.objectName);
    }
    if (message.objectType !== 0) {
      writer.uint32(24).int32(message.objectType);
    }
    if (message.offset !== 0) {
      writer.uint32(32).uint64(message.offset);
    }
    if (message.instance !== 0) {
      writer.uint32(40).uint32(message.instance);
    }
    for (const v of message.fabric) {
      Fabric.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.device) {
      Device.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.fan) {
      Fan.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    for (const v of message.psu) {
      Psu.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    for (const v of message.tempSensor) {
      TempSensor.encode(v!, writer.uint32(226).fork()).ldelim();
    }
    for (const v of message.lineCard) {
      LineCard.encode(v!, writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.port) {
      Port.encode(v!, writer.uint32(242).fork()).ldelim();
    }
    for (const v of message.dacQsfp) {
      DacQsfp.encode(v!, writer.uint32(250).fork()).ldelim();
    }
    for (const v of message.fiberQsfp) {
      FiberQsfp.encode(v!, writer.uint32(258).fork()).ldelim();
    }
    for (const v of message.breakout) {
      Breakout.encode(v!, writer.uint32(266).fork()).ldelim();
    }
    for (const v of message.portNeighbor) {
      PortNeighbor.encode(v!, writer.uint32(274).fork()).ldelim();
    }
    for (const v of message.vtep) {
      Vtep.encode(v!, writer.uint32(282).fork()).ldelim();
    }
    for (const v of message.vniPort) {
      VniPort.encode(v!, writer.uint32(290).fork()).ldelim();
    }
    for (const v of message.mac) {
      Mac.encode(v!, writer.uint32(298).fork()).ldelim();
    }
    for (const v of message.procTable) {
      ProcTable.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    for (const v of message.portVlan) {
      PortVlan.encode(v!, writer.uint32(314).fork()).ldelim();
    }
    for (const v of message.vlanState) {
      VlanState.encode(v!, writer.uint32(322).fork()).ldelim();
    }
    for (const v of message.qsfpDomSensor) {
      QsfpDomSensor.encode(v!, writer.uint32(338).fork()).ldelim();
    }
    for (const v of message.portConfig) {
      PortConfig.encode(v!, writer.uint32(378).fork()).ldelim();
    }
    for (const v of message.nodeConfig) {
      NodeConfig.encode(v!, writer.uint32(386).fork()).ldelim();
    }
    for (const v of message.fabricConfig) {
      FabricConfig.encode(v!, writer.uint32(394).fork()).ldelim();
    }
    for (const v of message.fabricConfigEvent) {
      FabricConfigEvent.encode(v!, writer.uint32(402).fork()).ldelim();
    }
    for (const v of message.l2Fdb) {
      L2Fdb.encode(v!, writer.uint32(410).fork()).ldelim();
    }
    for (const v of message.portCounters) {
      PortCounters.encode(v!, writer.uint32(418).fork()).ldelim();
    }
    for (const v of message.remedyAction) {
      RemedyAction.encode(v!, writer.uint32(426).fork()).ldelim();
    }
    for (const v of message.qsfpInfo) {
      QsfpInfo.encode(v!, writer.uint32(434).fork()).ldelim();
    }
    for (const v of message.fabricDevice) {
      FabricDevice.encode(v!, writer.uint32(442).fork()).ldelim();
    }
    for (const v of message.assertConfigPortLinkUp) {
      AssertConfigPortLinkUp.encode(v!, writer.uint32(450).fork()).ldelim();
    }
    for (const v of message.assertPortLinkUp) {
      AssertPortLinkUp.encode(v!, writer.uint32(458).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceConnectedToCloud) {
      AssertConfigDeviceConnectedToCloud.encode(v!, writer.uint32(466).fork()).ldelim();
    }
    for (const v of message.assertDeviceConnectedToCloud) {
      AssertDeviceConnectedToCloud.encode(v!, writer.uint32(474).fork()).ldelim();
    }
    for (const v of message.assertConfigPortExpectedNeighbor) {
      AssertConfigPortExpectedNeighbor.encode(v!, writer.uint32(482).fork()).ldelim();
    }
    for (const v of message.assertPortExpectedNeighbor) {
      AssertPortExpectedNeighbor.encode(v!, writer.uint32(490).fork()).ldelim();
    }
    for (const v of message.assertConfigPortNotConnectedToFabric) {
      AssertConfigPortNotConnectedToFabric.encode(v!, writer.uint32(498).fork()).ldelim();
    }
    for (const v of message.assertPortNotConnectedToFabric) {
      AssertPortNotConnectedToFabric.encode(v!, writer.uint32(506).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceConnectedToFabric) {
      AssertConfigDeviceConnectedToFabric.encode(v!, writer.uint32(514).fork()).ldelim();
    }
    for (const v of message.assertDeviceConnectedToFabric) {
      AssertDeviceConnectedToFabric.encode(v!, writer.uint32(522).fork()).ldelim();
    }
    for (const v of message.assertConfigPortLinkDown) {
      AssertConfigPortLinkDown.encode(v!, writer.uint32(530).fork()).ldelim();
    }
    for (const v of message.assertPortLinkDown) {
      AssertPortLinkDown.encode(v!, writer.uint32(538).fork()).ldelim();
    }
    for (const v of message.assertConfigPortConnectionSpeedMatch) {
      AssertConfigPortConnectionSpeedMatch.encode(v!, writer.uint32(546).fork()).ldelim();
    }
    for (const v of message.assertPortConnectionSpeedMatch) {
      AssertPortConnectionSpeedMatch.encode(v!, writer.uint32(554).fork()).ldelim();
    }
    for (const v of message.assertConfigPortSpeedConsistent) {
      AssertConfigPortSpeedConsistent.encode(v!, writer.uint32(562).fork()).ldelim();
    }
    for (const v of message.assertPortSpeedConsistent) {
      AssertPortSpeedConsistent.encode(v!, writer.uint32(570).fork()).ldelim();
    }
    for (const v of message.underlayInfo) {
      UnderlayInfo.encode(v!, writer.uint32(586).fork()).ldelim();
    }
    for (const v of message.proxyStats) {
      ProxyStats.encode(v!, writer.uint32(594).fork()).ldelim();
    }
    for (const v of message.vlanCounters) {
      VlanCounters.encode(v!, writer.uint32(602).fork()).ldelim();
    }
    for (const v of message.testPlaceHolder1Secret) {
      TestPlaceHolder1Secret.encode(v!, writer.uint32(610).fork()).ldelim();
    }
    for (const v of message.assertConfigVlanHasTraffic) {
      AssertConfigVlanHasTraffic.encode(v!, writer.uint32(618).fork()).ldelim();
    }
    for (const v of message.assertVlanHasTraffic) {
      AssertVlanHasTraffic.encode(v!, writer.uint32(626).fork()).ldelim();
    }
    for (const v of message.remoteVtep) {
      RemoteVtep.encode(v!, writer.uint32(634).fork()).ldelim();
    }
    for (const v of message.qsfpDomThreshold) {
      QsfpDomThreshold.encode(v!, writer.uint32(642).fork()).ldelim();
    }
    for (const v of message.l3Table) {
      L3Table.encode(v!, writer.uint32(658).fork()).ldelim();
    }
    for (const v of message.vlanVniMap) {
      VlanVniMap.encode(v!, writer.uint32(666).fork()).ldelim();
    }
    for (const v of message.proxyConnectionState) {
      ProxyConnectionState.encode(v!, writer.uint32(674).fork()).ldelim();
    }
    for (const v of message.proxyConnectionStats) {
      ProxyConnectionStats.encode(v!, writer.uint32(682).fork()).ldelim();
    }
    for (const v of message.l2Mac) {
      L2Mac.encode(v!, writer.uint32(690).fork()).ldelim();
    }
    for (const v of message.bmc) {
      Bmc.encode(v!, writer.uint32(698).fork()).ldelim();
    }
    for (const v of message.smartNicDpu) {
      SmartNicDpu.encode(v!, writer.uint32(706).fork()).ldelim();
    }
    for (const v of message.serverVoltageSensor) {
      ServerVoltageSensor.encode(v!, writer.uint32(714).fork()).ldelim();
    }
    for (const v of message.serverTemperatureSensor) {
      ServerTemperatureSensor.encode(v!, writer.uint32(722).fork()).ldelim();
    }
    for (const v of message.ipRouteTable) {
      IpRouteTable.encode(v!, writer.uint32(730).fork()).ldelim();
    }
    for (const v of message.vrf) {
      Vrf.encode(v!, writer.uint32(738).fork()).ldelim();
    }
    for (const v of message.ipNextHop) {
      IpNextHop.encode(v!, writer.uint32(746).fork()).ldelim();
    }
    for (const v of message.ipPrefix) {
      IpPrefix.encode(v!, writer.uint32(754).fork()).ldelim();
    }
    for (const v of message.subInterface) {
      SubInterface.encode(v!, writer.uint32(762).fork()).ldelim();
    }
    for (const v of message.assertConfigStaticDefaultRouteExists) {
      AssertConfigStaticDefaultRouteExists.encode(v!, writer.uint32(770).fork()).ldelim();
    }
    for (const v of message.assertStaticDefaultRouteExists) {
      AssertStaticDefaultRouteExists.encode(v!, writer.uint32(778).fork()).ldelim();
    }
    for (const v of message.spyglassBrokerEntry) {
      SpyglassBrokerEntry.encode(v!, writer.uint32(786).fork()).ldelim();
    }
    for (const v of message.managementState) {
      ManagementState.encode(v!, writer.uint32(794).fork()).ldelim();
    }
    for (const v of message.assertConfigManagementPortConfigSame) {
      AssertConfigManagementPortConfigSame.encode(v!, writer.uint32(818).fork()).ldelim();
    }
    for (const v of message.assertManagementPortConfigSame) {
      AssertManagementPortConfigSame.encode(v!, writer.uint32(826).fork()).ldelim();
    }
    for (const v of message.fabricConfigNotification) {
      FabricConfigNotification.encode(v!, writer.uint32(834).fork()).ldelim();
    }
    for (const v of message.spyglassGingerEntry) {
      SpyglassGingerEntry.encode(v!, writer.uint32(842).fork()).ldelim();
    }
    for (const v of message.plannedConfig) {
      PlannedConfig.encode(v!, writer.uint32(850).fork()).ldelim();
    }
    for (const v of message.routedInterfaceCounters) {
      RoutedInterfaceCounters.encode(v!, writer.uint32(858).fork()).ldelim();
    }
    for (const v of message.ipmRawLatencyCounters) {
      IpmRawLatencyCounters.encode(v!, writer.uint32(866).fork()).ldelim();
    }
    for (const v of message.ipmProcLatencyCounters) {
      IpmProcLatencyCounters.encode(v!, writer.uint32(874).fork()).ldelim();
    }
    for (const v of message.ipmProcLossCounters) {
      IpmProcLossCounters.encode(v!, writer.uint32(882).fork()).ldelim();
    }
    for (const v of message.ipmLivenessCounters) {
      IpmLivenessCounters.encode(v!, writer.uint32(890).fork()).ldelim();
    }
    for (const v of message.ipmSessionId) {
      IpmSessionId.encode(v!, writer.uint32(898).fork()).ldelim();
    }
    for (const v of message.ipmProcJitterCounters) {
      IpmProcJitterCounters.encode(v!, writer.uint32(906).fork()).ldelim();
    }
    for (const v of message.systemStats) {
      SystemStats.encode(v!, writer.uint32(954).fork()).ldelim();
    }
    for (const v of message.testRootOne) {
      TestRootOne.encode(v!, writer.uint32(962).fork()).ldelim();
    }
    for (const v of message.testRootTwo) {
      TestRootTwo.encode(v!, writer.uint32(970).fork()).ldelim();
    }
    for (const v of message.testMidOne) {
      TestMidOne.encode(v!, writer.uint32(978).fork()).ldelim();
    }
    for (const v of message.testLeafOne) {
      TestLeafOne.encode(v!, writer.uint32(994).fork()).ldelim();
    }
    for (const v of message.testIpAddress) {
      TestIpAddress.encode(v!, writer.uint32(1010).fork()).ldelim();
    }
    for (const v of message.testIpPrefix) {
      TestIpPrefix.encode(v!, writer.uint32(1018).fork()).ldelim();
    }
    for (const v of message.testMacAddress) {
      TestMacAddress.encode(v!, writer.uint32(1026).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceResourceUsageBelowThreshold) {
      AssertConfigDeviceResourceUsageBelowThreshold.encode(v!, writer.uint32(1034).fork()).ldelim();
    }
    for (const v of message.assertDeviceResourceUsageBelowThreshold) {
      AssertDeviceResourceUsageBelowThreshold.encode(v!, writer.uint32(1042).fork()).ldelim();
    }
    for (const v of message.fabricTransaction) {
      FabricTransaction.encode(v!, writer.uint32(1050).fork()).ldelim();
    }
    for (const v of message.bgpNeighborSummary) {
      BgpNeighborSummary.encode(v!, writer.uint32(1066).fork()).ldelim();
    }
    for (const v of message.bmcConfig) {
      BmcConfig.encode(v!, writer.uint32(1074).fork()).ldelim();
    }
    for (const v of message.deviceConnection) {
      DeviceConnection.encode(v!, writer.uint32(1082).fork()).ldelim();
    }
    for (const v of message.bgpNeighborFamily) {
      BgpNeighborFamily.encode(v!, writer.uint32(1090).fork()).ldelim();
    }
    for (const v of message.assertConfigBgpPeerEstablished) {
      AssertConfigBgpPeerEstablished.encode(v!, writer.uint32(1098).fork()).ldelim();
    }
    for (const v of message.assertBgpPeerEstablished) {
      AssertBgpPeerEstablished.encode(v!, writer.uint32(1106).fork()).ldelim();
    }
    for (const v of message.spyglassSshSessionEntry) {
      SpyglassSshSessionEntry.encode(v!, writer.uint32(1114).fork()).ldelim();
    }
    for (const v of message.portChannelMemberState) {
      PortChannelMemberState.encode(v!, writer.uint32(1122).fork()).ldelim();
    }
    for (const v of message.portChannelState) {
      PortChannelState.encode(v!, writer.uint32(1130).fork()).ldelim();
    }
    for (const v of message.assertConfigIpmLatencyBelowThreshold) {
      AssertConfigIpmLatencyBelowThreshold.encode(v!, writer.uint32(1138).fork()).ldelim();
    }
    for (const v of message.assertIpmLatencyBelowThreshold) {
      AssertIpmLatencyBelowThreshold.encode(v!, writer.uint32(1146).fork()).ldelim();
    }
    for (const v of message.assertConfigIpmLossBelowThreshold) {
      AssertConfigIpmLossBelowThreshold.encode(v!, writer.uint32(1154).fork()).ldelim();
    }
    for (const v of message.assertIpmLossBelowThreshold) {
      AssertIpmLossBelowThreshold.encode(v!, writer.uint32(1162).fork()).ldelim();
    }
    for (const v of message.spyglassDevice) {
      SpyglassDevice.encode(v!, writer.uint32(1170).fork()).ldelim();
    }
    for (const v of message.spyglassTcpListener) {
      SpyglassTcpListener.encode(v!, writer.uint32(1178).fork()).ldelim();
    }
    for (const v of message.spyglassTcpSession) {
      SpyglassTcpSession.encode(v!, writer.uint32(1186).fork()).ldelim();
    }
    for (const v of message.unknownVlan) {
      UnknownVlan.encode(v!, writer.uint32(1194).fork()).ldelim();
    }
    for (const v of message.unknownVlanPort) {
      UnknownVlanPort.encode(v!, writer.uint32(1202).fork()).ldelim();
    }
    for (const v of message.assertConfigExpectedPortBreakoutExists) {
      AssertConfigExpectedPortBreakoutExists.encode(v!, writer.uint32(1210).fork()).ldelim();
    }
    for (const v of message.assertExpectedPortBreakoutExists) {
      AssertExpectedPortBreakoutExists.encode(v!, writer.uint32(1218).fork()).ldelim();
    }
    for (const v of message.assertConfigSubInterfaceUp) {
      AssertConfigSubInterfaceUp.encode(v!, writer.uint32(1226).fork()).ldelim();
    }
    for (const v of message.assertSubInterfaceUp) {
      AssertSubInterfaceUp.encode(v!, writer.uint32(1234).fork()).ldelim();
    }
    for (const v of message.assertSubInterfaceDown) {
      AssertSubInterfaceDown.encode(v!, writer.uint32(1242).fork()).ldelim();
    }
    for (const v of message.assertConfigSubInterfaceDown) {
      AssertConfigSubInterfaceDown.encode(v!, writer.uint32(1250).fork()).ldelim();
    }
    for (const v of message.deviceClaim) {
      DeviceClaim.encode(v!, writer.uint32(1266).fork()).ldelim();
    }
    for (const v of message.aggregatedDeviceClaim) {
      AggregatedDeviceClaim.encode(v!, writer.uint32(1274).fork()).ldelim();
    }
    for (const v of message.assertPortChannelMemberUp) {
      AssertPortChannelMemberUp.encode(v!, writer.uint32(1282).fork()).ldelim();
    }
    for (const v of message.assertConfigPortChannelMemberUp) {
      AssertConfigPortChannelMemberUp.encode(v!, writer.uint32(1290).fork()).ldelim();
    }
    for (const v of message.esiInfo) {
      EsiInfo.encode(v!, writer.uint32(1298).fork()).ldelim();
    }
    for (const v of message.esiVtepInfo) {
      EsiVtepInfo.encode(v!, writer.uint32(1306).fork()).ldelim();
    }
    for (const v of message.assertPortVlanStpStateConsistent) {
      AssertPortVlanStpStateConsistent.encode(v!, writer.uint32(1314).fork()).ldelim();
    }
    for (const v of message.assertConfigPortVlanStpStateConsistent) {
      AssertConfigPortVlanStpStateConsistent.encode(v!, writer.uint32(1322).fork()).ldelim();
    }
    for (const v of message.assertPortPluggablePidSame) {
      AssertPortPluggablePidSame.encode(v!, writer.uint32(1330).fork()).ldelim();
    }
    for (const v of message.assertConfigPortPluggablePidSame) {
      AssertConfigPortPluggablePidSame.encode(v!, writer.uint32(1338).fork()).ldelim();
    }
    for (const v of message.assertConfigIpmJitterBelowThreshold) {
      AssertConfigIpmJitterBelowThreshold.encode(v!, writer.uint32(1346).fork()).ldelim();
    }
    for (const v of message.assertIpmJitterBelowThreshold) {
      AssertIpmJitterBelowThreshold.encode(v!, writer.uint32(1354).fork()).ldelim();
    }
    for (const v of message.manifestImageState) {
      ManifestImageState.encode(v!, writer.uint32(1362).fork()).ldelim();
    }
    for (const v of message.orgUuid) {
      OrgUuid.encode(v!, writer.uint32(1762).fork()).ldelim();
    }
    for (const v of message.fabricUuid) {
      FabricUuid.encode(v!, writer.uint32(1770).fork()).ldelim();
    }
    for (const v of message.orgServiceProto) {
      OrgServiceProto.encode(v!, writer.uint32(1778).fork()).ldelim();
    }
    for (const v of message.orgServiceSvc) {
      OrgServiceSvc.encode(v!, writer.uint32(1786).fork()).ldelim();
    }
    for (const v of message.orgServiceRoute) {
      OrgServiceRoute.encode(v!, writer.uint32(1794).fork()).ldelim();
    }
    for (const v of message.apiPath) {
      ApiPath.encode(v!, writer.uint32(1802).fork()).ldelim();
    }
    for (const v of message.apiPathMethod) {
      ApiPathMethod.encode(v!, writer.uint32(1810).fork()).ldelim();
    }
    for (const v of message.apiServiceMapping) {
      ApiServiceMapping.encode(v!, writer.uint32(1818).fork()).ldelim();
    }
    for (const v of message.orgToCellPairMapping) {
      OrgToCellPairMapping.encode(v!, writer.uint32(1826).fork()).ldelim();
    }
    for (const v of message.tenantUuid) {
      TenantUuid.encode(v!, writer.uint32(1834).fork()).ldelim();
    }
    for (const v of message.userEmail) {
      UserEmail.encode(v!, writer.uint32(1842).fork()).ldelim();
    }
    for (const v of message.managementConfig) {
      ManagementConfig.encode(v!, writer.uint32(1850).fork()).ldelim();
    }
    for (const v of message.serviceInstanceHealth) {
      ServiceInstanceHealth.encode(v!, writer.uint32(1858).fork()).ldelim();
    }
    for (const v of message.assignmentStatus) {
      AssignmentStatus.encode(v!, writer.uint32(1866).fork()).ldelim();
    }
    for (const v of message.kubernetesServiceInstance) {
      KubernetesServiceInstance.encode(v!, writer.uint32(1882).fork()).ldelim();
    }
    for (const v of message.bearerToken) {
      BearerToken.encode(v!, writer.uint32(1938).fork()).ldelim();
    }
    for (const v of message.apiKey) {
      ApiKey.encode(v!, writer.uint32(1946).fork()).ldelim();
    }
    for (const v of message.fabricMemoryUsage) {
      FabricMemoryUsage.encode(v!, writer.uint32(1954).fork()).ldelim();
    }
    for (const v of message.typeMemoryUsage) {
      TypeMemoryUsage.encode(v!, writer.uint32(1962).fork()).ldelim();
    }
    for (const v of message.manifestRunningImageStatus) {
      ManifestRunningImageStatus.encode(v!, writer.uint32(1970).fork()).ldelim();
    }
    for (const v of message.manifestPartitionFileCacheStatus) {
      ManifestPartitionFileCacheStatus.encode(v!, writer.uint32(1978).fork()).ldelim();
    }
    for (const v of message.stpPortState) {
      StpPortState.encode(v!, writer.uint32(1986).fork()).ldelim();
    }
    for (const v of message.stpVlanState) {
      StpVlanState.encode(v!, writer.uint32(1994).fork()).ldelim();
    }
    for (const v of message.stpVlanPortState) {
      StpVlanPortState.encode(v!, writer.uint32(2002).fork()).ldelim();
    }
    for (const v of message.assertDeviceEnvPsuUsageBelowThreshold) {
      AssertDeviceEnvPsuUsageBelowThreshold.encode(v!, writer.uint32(2010).fork()).ldelim();
    }
    for (const v of message.assertDeviceEnvFanUsageBelowThreshold) {
      AssertDeviceEnvFanUsageBelowThreshold.encode(v!, writer.uint32(2018).fork()).ldelim();
    }
    for (const v of message.assertDeviceEnvTempSensorUsageBelowThreshold) {
      AssertDeviceEnvTempSensorUsageBelowThreshold.encode(v!, writer.uint32(2026).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceEnvPsuUsageBelowThreshold) {
      AssertConfigDeviceEnvPsuUsageBelowThreshold.encode(v!, writer.uint32(2034).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceEnvFanUsageBelowThreshold) {
      AssertConfigDeviceEnvFanUsageBelowThreshold.encode(v!, writer.uint32(2042).fork()).ldelim();
    }
    for (const v of message.assertConfigDeviceEnvTempSensorUsageBelowThreshold) {
      AssertConfigDeviceEnvTempSensorUsageBelowThreshold.encode(v!, writer.uint32(2050).fork()).ldelim();
    }
    for (const v of message.unpackAndAssembleTask) {
      UnpackAndAssembleTask.encode(v!, writer.uint32(2066).fork()).ldelim();
    }
    for (const v of message.rackAndPowerOnTask) {
      RackAndPowerOnTask.encode(v!, writer.uint32(2074).fork()).ldelim();
    }
    for (const v of message.claimAndBindTask) {
      ClaimAndBindTask.encode(v!, writer.uint32(2082).fork()).ldelim();
    }
    for (const v of message.portSetupTask) {
      PortSetupTask.encode(v!, writer.uint32(2090).fork()).ldelim();
    }
    for (const v of message.fabricConnectionTask) {
      FabricConnectionTask.encode(v!, writer.uint32(2098).fork()).ldelim();
    }
    for (const v of message.negativeConnectionTask) {
      NegativeConnectionTask.encode(v!, writer.uint32(2106).fork()).ldelim();
    }
    for (const v of message.application) {
      Application.encode(v!, writer.uint32(2114).fork()).ldelim();
    }
    for (const v of message.resourceAssignment) {
      ResourceAssignment.encode(v!, writer.uint32(2122).fork()).ldelim();
    }
    for (const v of message.node) {
      Node.encode(v!, writer.uint32(2130).fork()).ldelim();
    }
    for (const v of message.nodeLineCard) {
      NodeLineCard.encode(v!, writer.uint32(2138).fork()).ldelim();
    }
    for (const v of message.nodePort) {
      NodePort.encode(v!, writer.uint32(2146).fork()).ldelim();
    }
    for (const v of message.nodeBreakout) {
      NodeBreakout.encode(v!, writer.uint32(2154).fork()).ldelim();
    }
    for (const v of message.tenantMetadata) {
      TenantMetadata.encode(v!, writer.uint32(2162).fork()).ldelim();
    }
    for (const v of message.assertPortNoUnknownVlanFound) {
      AssertPortNoUnknownVlanFound.encode(v!, writer.uint32(2170).fork()).ldelim();
    }
    for (const v of message.assertConfigPortNoUnknownVlanFound) {
      AssertConfigPortNoUnknownVlanFound.encode(v!, writer.uint32(2178).fork()).ldelim();
    }
    for (const v of message.bulkUpliftJobRequest) {
      BulkUpliftJobRequest.encode(v!, writer.uint32(2186).fork()).ldelim();
    }
    for (const v of message.bulkUpliftJobStatus) {
      BulkUpliftJobStatus.encode(v!, writer.uint32(2194).fork()).ldelim();
    }
    for (const v of message.bgpNeighborInfo) {
      BgpNeighborInfo.encode(v!, writer.uint32(2202).fork()).ldelim();
    }
    for (const v of message.refPoint) {
      RefPoint.encode(v!, writer.uint32(2210).fork()).ldelim();
    }
    for (const v of message.refPointStatus) {
      RefPointStatus.encode(v!, writer.uint32(2218).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataSeries {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataSeries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offset = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.instance = reader.uint32();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.fabric.push(Fabric.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.device.push(Device.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.fan.push(Fan.decode(reader, reader.uint32()));
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.psu.push(Psu.decode(reader, reader.uint32()));
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.tempSensor.push(TempSensor.decode(reader, reader.uint32()));
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.lineCard.push(LineCard.decode(reader, reader.uint32()));
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.port.push(Port.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.dacQsfp.push(DacQsfp.decode(reader, reader.uint32()));
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.fiberQsfp.push(FiberQsfp.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.breakout.push(Breakout.decode(reader, reader.uint32()));
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.portNeighbor.push(PortNeighbor.decode(reader, reader.uint32()));
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.vtep.push(Vtep.decode(reader, reader.uint32()));
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.vniPort.push(VniPort.decode(reader, reader.uint32()));
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.mac.push(Mac.decode(reader, reader.uint32()));
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.procTable.push(ProcTable.decode(reader, reader.uint32()));
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.portVlan.push(PortVlan.decode(reader, reader.uint32()));
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.vlanState.push(VlanState.decode(reader, reader.uint32()));
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.qsfpDomSensor.push(QsfpDomSensor.decode(reader, reader.uint32()));
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.portConfig.push(PortConfig.decode(reader, reader.uint32()));
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.nodeConfig.push(NodeConfig.decode(reader, reader.uint32()));
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.fabricConfig.push(FabricConfig.decode(reader, reader.uint32()));
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.fabricConfigEvent.push(FabricConfigEvent.decode(reader, reader.uint32()));
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.l2Fdb.push(L2Fdb.decode(reader, reader.uint32()));
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.portCounters.push(PortCounters.decode(reader, reader.uint32()));
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.remedyAction.push(RemedyAction.decode(reader, reader.uint32()));
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.qsfpInfo.push(QsfpInfo.decode(reader, reader.uint32()));
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.fabricDevice.push(FabricDevice.decode(reader, reader.uint32()));
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.assertConfigPortLinkUp.push(AssertConfigPortLinkUp.decode(reader, reader.uint32()));
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.assertPortLinkUp.push(AssertPortLinkUp.decode(reader, reader.uint32()));
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.assertConfigDeviceConnectedToCloud.push(
            AssertConfigDeviceConnectedToCloud.decode(reader, reader.uint32()),
          );
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.assertDeviceConnectedToCloud.push(AssertDeviceConnectedToCloud.decode(reader, reader.uint32()));
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.assertConfigPortExpectedNeighbor.push(
            AssertConfigPortExpectedNeighbor.decode(reader, reader.uint32()),
          );
          continue;
        case 61:
          if (tag !== 490) {
            break;
          }

          message.assertPortExpectedNeighbor.push(AssertPortExpectedNeighbor.decode(reader, reader.uint32()));
          continue;
        case 62:
          if (tag !== 498) {
            break;
          }

          message.assertConfigPortNotConnectedToFabric.push(
            AssertConfigPortNotConnectedToFabric.decode(reader, reader.uint32()),
          );
          continue;
        case 63:
          if (tag !== 506) {
            break;
          }

          message.assertPortNotConnectedToFabric.push(AssertPortNotConnectedToFabric.decode(reader, reader.uint32()));
          continue;
        case 64:
          if (tag !== 514) {
            break;
          }

          message.assertConfigDeviceConnectedToFabric.push(
            AssertConfigDeviceConnectedToFabric.decode(reader, reader.uint32()),
          );
          continue;
        case 65:
          if (tag !== 522) {
            break;
          }

          message.assertDeviceConnectedToFabric.push(AssertDeviceConnectedToFabric.decode(reader, reader.uint32()));
          continue;
        case 66:
          if (tag !== 530) {
            break;
          }

          message.assertConfigPortLinkDown.push(AssertConfigPortLinkDown.decode(reader, reader.uint32()));
          continue;
        case 67:
          if (tag !== 538) {
            break;
          }

          message.assertPortLinkDown.push(AssertPortLinkDown.decode(reader, reader.uint32()));
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.assertConfigPortConnectionSpeedMatch.push(
            AssertConfigPortConnectionSpeedMatch.decode(reader, reader.uint32()),
          );
          continue;
        case 69:
          if (tag !== 554) {
            break;
          }

          message.assertPortConnectionSpeedMatch.push(AssertPortConnectionSpeedMatch.decode(reader, reader.uint32()));
          continue;
        case 70:
          if (tag !== 562) {
            break;
          }

          message.assertConfigPortSpeedConsistent.push(AssertConfigPortSpeedConsistent.decode(reader, reader.uint32()));
          continue;
        case 71:
          if (tag !== 570) {
            break;
          }

          message.assertPortSpeedConsistent.push(AssertPortSpeedConsistent.decode(reader, reader.uint32()));
          continue;
        case 73:
          if (tag !== 586) {
            break;
          }

          message.underlayInfo.push(UnderlayInfo.decode(reader, reader.uint32()));
          continue;
        case 74:
          if (tag !== 594) {
            break;
          }

          message.proxyStats.push(ProxyStats.decode(reader, reader.uint32()));
          continue;
        case 75:
          if (tag !== 602) {
            break;
          }

          message.vlanCounters.push(VlanCounters.decode(reader, reader.uint32()));
          continue;
        case 76:
          if (tag !== 610) {
            break;
          }

          message.testPlaceHolder1Secret.push(TestPlaceHolder1Secret.decode(reader, reader.uint32()));
          continue;
        case 77:
          if (tag !== 618) {
            break;
          }

          message.assertConfigVlanHasTraffic.push(AssertConfigVlanHasTraffic.decode(reader, reader.uint32()));
          continue;
        case 78:
          if (tag !== 626) {
            break;
          }

          message.assertVlanHasTraffic.push(AssertVlanHasTraffic.decode(reader, reader.uint32()));
          continue;
        case 79:
          if (tag !== 634) {
            break;
          }

          message.remoteVtep.push(RemoteVtep.decode(reader, reader.uint32()));
          continue;
        case 80:
          if (tag !== 642) {
            break;
          }

          message.qsfpDomThreshold.push(QsfpDomThreshold.decode(reader, reader.uint32()));
          continue;
        case 82:
          if (tag !== 658) {
            break;
          }

          message.l3Table.push(L3Table.decode(reader, reader.uint32()));
          continue;
        case 83:
          if (tag !== 666) {
            break;
          }

          message.vlanVniMap.push(VlanVniMap.decode(reader, reader.uint32()));
          continue;
        case 84:
          if (tag !== 674) {
            break;
          }

          message.proxyConnectionState.push(ProxyConnectionState.decode(reader, reader.uint32()));
          continue;
        case 85:
          if (tag !== 682) {
            break;
          }

          message.proxyConnectionStats.push(ProxyConnectionStats.decode(reader, reader.uint32()));
          continue;
        case 86:
          if (tag !== 690) {
            break;
          }

          message.l2Mac.push(L2Mac.decode(reader, reader.uint32()));
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.bmc.push(Bmc.decode(reader, reader.uint32()));
          continue;
        case 88:
          if (tag !== 706) {
            break;
          }

          message.smartNicDpu.push(SmartNicDpu.decode(reader, reader.uint32()));
          continue;
        case 89:
          if (tag !== 714) {
            break;
          }

          message.serverVoltageSensor.push(ServerVoltageSensor.decode(reader, reader.uint32()));
          continue;
        case 90:
          if (tag !== 722) {
            break;
          }

          message.serverTemperatureSensor.push(ServerTemperatureSensor.decode(reader, reader.uint32()));
          continue;
        case 91:
          if (tag !== 730) {
            break;
          }

          message.ipRouteTable.push(IpRouteTable.decode(reader, reader.uint32()));
          continue;
        case 92:
          if (tag !== 738) {
            break;
          }

          message.vrf.push(Vrf.decode(reader, reader.uint32()));
          continue;
        case 93:
          if (tag !== 746) {
            break;
          }

          message.ipNextHop.push(IpNextHop.decode(reader, reader.uint32()));
          continue;
        case 94:
          if (tag !== 754) {
            break;
          }

          message.ipPrefix.push(IpPrefix.decode(reader, reader.uint32()));
          continue;
        case 95:
          if (tag !== 762) {
            break;
          }

          message.subInterface.push(SubInterface.decode(reader, reader.uint32()));
          continue;
        case 96:
          if (tag !== 770) {
            break;
          }

          message.assertConfigStaticDefaultRouteExists.push(
            AssertConfigStaticDefaultRouteExists.decode(reader, reader.uint32()),
          );
          continue;
        case 97:
          if (tag !== 778) {
            break;
          }

          message.assertStaticDefaultRouteExists.push(AssertStaticDefaultRouteExists.decode(reader, reader.uint32()));
          continue;
        case 98:
          if (tag !== 786) {
            break;
          }

          message.spyglassBrokerEntry.push(SpyglassBrokerEntry.decode(reader, reader.uint32()));
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.managementState.push(ManagementState.decode(reader, reader.uint32()));
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.assertConfigManagementPortConfigSame.push(
            AssertConfigManagementPortConfigSame.decode(reader, reader.uint32()),
          );
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.assertManagementPortConfigSame.push(AssertManagementPortConfigSame.decode(reader, reader.uint32()));
          continue;
        case 104:
          if (tag !== 834) {
            break;
          }

          message.fabricConfigNotification.push(FabricConfigNotification.decode(reader, reader.uint32()));
          continue;
        case 105:
          if (tag !== 842) {
            break;
          }

          message.spyglassGingerEntry.push(SpyglassGingerEntry.decode(reader, reader.uint32()));
          continue;
        case 106:
          if (tag !== 850) {
            break;
          }

          message.plannedConfig.push(PlannedConfig.decode(reader, reader.uint32()));
          continue;
        case 107:
          if (tag !== 858) {
            break;
          }

          message.routedInterfaceCounters.push(RoutedInterfaceCounters.decode(reader, reader.uint32()));
          continue;
        case 108:
          if (tag !== 866) {
            break;
          }

          message.ipmRawLatencyCounters.push(IpmRawLatencyCounters.decode(reader, reader.uint32()));
          continue;
        case 109:
          if (tag !== 874) {
            break;
          }

          message.ipmProcLatencyCounters.push(IpmProcLatencyCounters.decode(reader, reader.uint32()));
          continue;
        case 110:
          if (tag !== 882) {
            break;
          }

          message.ipmProcLossCounters.push(IpmProcLossCounters.decode(reader, reader.uint32()));
          continue;
        case 111:
          if (tag !== 890) {
            break;
          }

          message.ipmLivenessCounters.push(IpmLivenessCounters.decode(reader, reader.uint32()));
          continue;
        case 112:
          if (tag !== 898) {
            break;
          }

          message.ipmSessionId.push(IpmSessionId.decode(reader, reader.uint32()));
          continue;
        case 113:
          if (tag !== 906) {
            break;
          }

          message.ipmProcJitterCounters.push(IpmProcJitterCounters.decode(reader, reader.uint32()));
          continue;
        case 119:
          if (tag !== 954) {
            break;
          }

          message.systemStats.push(SystemStats.decode(reader, reader.uint32()));
          continue;
        case 120:
          if (tag !== 962) {
            break;
          }

          message.testRootOne.push(TestRootOne.decode(reader, reader.uint32()));
          continue;
        case 121:
          if (tag !== 970) {
            break;
          }

          message.testRootTwo.push(TestRootTwo.decode(reader, reader.uint32()));
          continue;
        case 122:
          if (tag !== 978) {
            break;
          }

          message.testMidOne.push(TestMidOne.decode(reader, reader.uint32()));
          continue;
        case 124:
          if (tag !== 994) {
            break;
          }

          message.testLeafOne.push(TestLeafOne.decode(reader, reader.uint32()));
          continue;
        case 126:
          if (tag !== 1010) {
            break;
          }

          message.testIpAddress.push(TestIpAddress.decode(reader, reader.uint32()));
          continue;
        case 127:
          if (tag !== 1018) {
            break;
          }

          message.testIpPrefix.push(TestIpPrefix.decode(reader, reader.uint32()));
          continue;
        case 128:
          if (tag !== 1026) {
            break;
          }

          message.testMacAddress.push(TestMacAddress.decode(reader, reader.uint32()));
          continue;
        case 129:
          if (tag !== 1034) {
            break;
          }

          message.assertConfigDeviceResourceUsageBelowThreshold.push(
            AssertConfigDeviceResourceUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 130:
          if (tag !== 1042) {
            break;
          }

          message.assertDeviceResourceUsageBelowThreshold.push(
            AssertDeviceResourceUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 131:
          if (tag !== 1050) {
            break;
          }

          message.fabricTransaction.push(FabricTransaction.decode(reader, reader.uint32()));
          continue;
        case 133:
          if (tag !== 1066) {
            break;
          }

          message.bgpNeighborSummary.push(BgpNeighborSummary.decode(reader, reader.uint32()));
          continue;
        case 134:
          if (tag !== 1074) {
            break;
          }

          message.bmcConfig.push(BmcConfig.decode(reader, reader.uint32()));
          continue;
        case 135:
          if (tag !== 1082) {
            break;
          }

          message.deviceConnection.push(DeviceConnection.decode(reader, reader.uint32()));
          continue;
        case 136:
          if (tag !== 1090) {
            break;
          }

          message.bgpNeighborFamily.push(BgpNeighborFamily.decode(reader, reader.uint32()));
          continue;
        case 137:
          if (tag !== 1098) {
            break;
          }

          message.assertConfigBgpPeerEstablished.push(AssertConfigBgpPeerEstablished.decode(reader, reader.uint32()));
          continue;
        case 138:
          if (tag !== 1106) {
            break;
          }

          message.assertBgpPeerEstablished.push(AssertBgpPeerEstablished.decode(reader, reader.uint32()));
          continue;
        case 139:
          if (tag !== 1114) {
            break;
          }

          message.spyglassSshSessionEntry.push(SpyglassSshSessionEntry.decode(reader, reader.uint32()));
          continue;
        case 140:
          if (tag !== 1122) {
            break;
          }

          message.portChannelMemberState.push(PortChannelMemberState.decode(reader, reader.uint32()));
          continue;
        case 141:
          if (tag !== 1130) {
            break;
          }

          message.portChannelState.push(PortChannelState.decode(reader, reader.uint32()));
          continue;
        case 142:
          if (tag !== 1138) {
            break;
          }

          message.assertConfigIpmLatencyBelowThreshold.push(
            AssertConfigIpmLatencyBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 143:
          if (tag !== 1146) {
            break;
          }

          message.assertIpmLatencyBelowThreshold.push(AssertIpmLatencyBelowThreshold.decode(reader, reader.uint32()));
          continue;
        case 144:
          if (tag !== 1154) {
            break;
          }

          message.assertConfigIpmLossBelowThreshold.push(
            AssertConfigIpmLossBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 145:
          if (tag !== 1162) {
            break;
          }

          message.assertIpmLossBelowThreshold.push(AssertIpmLossBelowThreshold.decode(reader, reader.uint32()));
          continue;
        case 146:
          if (tag !== 1170) {
            break;
          }

          message.spyglassDevice.push(SpyglassDevice.decode(reader, reader.uint32()));
          continue;
        case 147:
          if (tag !== 1178) {
            break;
          }

          message.spyglassTcpListener.push(SpyglassTcpListener.decode(reader, reader.uint32()));
          continue;
        case 148:
          if (tag !== 1186) {
            break;
          }

          message.spyglassTcpSession.push(SpyglassTcpSession.decode(reader, reader.uint32()));
          continue;
        case 149:
          if (tag !== 1194) {
            break;
          }

          message.unknownVlan.push(UnknownVlan.decode(reader, reader.uint32()));
          continue;
        case 150:
          if (tag !== 1202) {
            break;
          }

          message.unknownVlanPort.push(UnknownVlanPort.decode(reader, reader.uint32()));
          continue;
        case 151:
          if (tag !== 1210) {
            break;
          }

          message.assertConfigExpectedPortBreakoutExists.push(
            AssertConfigExpectedPortBreakoutExists.decode(reader, reader.uint32()),
          );
          continue;
        case 152:
          if (tag !== 1218) {
            break;
          }

          message.assertExpectedPortBreakoutExists.push(
            AssertExpectedPortBreakoutExists.decode(reader, reader.uint32()),
          );
          continue;
        case 153:
          if (tag !== 1226) {
            break;
          }

          message.assertConfigSubInterfaceUp.push(AssertConfigSubInterfaceUp.decode(reader, reader.uint32()));
          continue;
        case 154:
          if (tag !== 1234) {
            break;
          }

          message.assertSubInterfaceUp.push(AssertSubInterfaceUp.decode(reader, reader.uint32()));
          continue;
        case 155:
          if (tag !== 1242) {
            break;
          }

          message.assertSubInterfaceDown.push(AssertSubInterfaceDown.decode(reader, reader.uint32()));
          continue;
        case 156:
          if (tag !== 1250) {
            break;
          }

          message.assertConfigSubInterfaceDown.push(AssertConfigSubInterfaceDown.decode(reader, reader.uint32()));
          continue;
        case 158:
          if (tag !== 1266) {
            break;
          }

          message.deviceClaim.push(DeviceClaim.decode(reader, reader.uint32()));
          continue;
        case 159:
          if (tag !== 1274) {
            break;
          }

          message.aggregatedDeviceClaim.push(AggregatedDeviceClaim.decode(reader, reader.uint32()));
          continue;
        case 160:
          if (tag !== 1282) {
            break;
          }

          message.assertPortChannelMemberUp.push(AssertPortChannelMemberUp.decode(reader, reader.uint32()));
          continue;
        case 161:
          if (tag !== 1290) {
            break;
          }

          message.assertConfigPortChannelMemberUp.push(AssertConfigPortChannelMemberUp.decode(reader, reader.uint32()));
          continue;
        case 162:
          if (tag !== 1298) {
            break;
          }

          message.esiInfo.push(EsiInfo.decode(reader, reader.uint32()));
          continue;
        case 163:
          if (tag !== 1306) {
            break;
          }

          message.esiVtepInfo.push(EsiVtepInfo.decode(reader, reader.uint32()));
          continue;
        case 164:
          if (tag !== 1314) {
            break;
          }

          message.assertPortVlanStpStateConsistent.push(
            AssertPortVlanStpStateConsistent.decode(reader, reader.uint32()),
          );
          continue;
        case 165:
          if (tag !== 1322) {
            break;
          }

          message.assertConfigPortVlanStpStateConsistent.push(
            AssertConfigPortVlanStpStateConsistent.decode(reader, reader.uint32()),
          );
          continue;
        case 166:
          if (tag !== 1330) {
            break;
          }

          message.assertPortPluggablePidSame.push(AssertPortPluggablePidSame.decode(reader, reader.uint32()));
          continue;
        case 167:
          if (tag !== 1338) {
            break;
          }

          message.assertConfigPortPluggablePidSame.push(
            AssertConfigPortPluggablePidSame.decode(reader, reader.uint32()),
          );
          continue;
        case 168:
          if (tag !== 1346) {
            break;
          }

          message.assertConfigIpmJitterBelowThreshold.push(
            AssertConfigIpmJitterBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 169:
          if (tag !== 1354) {
            break;
          }

          message.assertIpmJitterBelowThreshold.push(AssertIpmJitterBelowThreshold.decode(reader, reader.uint32()));
          continue;
        case 170:
          if (tag !== 1362) {
            break;
          }

          message.manifestImageState.push(ManifestImageState.decode(reader, reader.uint32()));
          continue;
        case 220:
          if (tag !== 1762) {
            break;
          }

          message.orgUuid.push(OrgUuid.decode(reader, reader.uint32()));
          continue;
        case 221:
          if (tag !== 1770) {
            break;
          }

          message.fabricUuid.push(FabricUuid.decode(reader, reader.uint32()));
          continue;
        case 222:
          if (tag !== 1778) {
            break;
          }

          message.orgServiceProto.push(OrgServiceProto.decode(reader, reader.uint32()));
          continue;
        case 223:
          if (tag !== 1786) {
            break;
          }

          message.orgServiceSvc.push(OrgServiceSvc.decode(reader, reader.uint32()));
          continue;
        case 224:
          if (tag !== 1794) {
            break;
          }

          message.orgServiceRoute.push(OrgServiceRoute.decode(reader, reader.uint32()));
          continue;
        case 225:
          if (tag !== 1802) {
            break;
          }

          message.apiPath.push(ApiPath.decode(reader, reader.uint32()));
          continue;
        case 226:
          if (tag !== 1810) {
            break;
          }

          message.apiPathMethod.push(ApiPathMethod.decode(reader, reader.uint32()));
          continue;
        case 227:
          if (tag !== 1818) {
            break;
          }

          message.apiServiceMapping.push(ApiServiceMapping.decode(reader, reader.uint32()));
          continue;
        case 228:
          if (tag !== 1826) {
            break;
          }

          message.orgToCellPairMapping.push(OrgToCellPairMapping.decode(reader, reader.uint32()));
          continue;
        case 229:
          if (tag !== 1834) {
            break;
          }

          message.tenantUuid.push(TenantUuid.decode(reader, reader.uint32()));
          continue;
        case 230:
          if (tag !== 1842) {
            break;
          }

          message.userEmail.push(UserEmail.decode(reader, reader.uint32()));
          continue;
        case 231:
          if (tag !== 1850) {
            break;
          }

          message.managementConfig.push(ManagementConfig.decode(reader, reader.uint32()));
          continue;
        case 232:
          if (tag !== 1858) {
            break;
          }

          message.serviceInstanceHealth.push(ServiceInstanceHealth.decode(reader, reader.uint32()));
          continue;
        case 233:
          if (tag !== 1866) {
            break;
          }

          message.assignmentStatus.push(AssignmentStatus.decode(reader, reader.uint32()));
          continue;
        case 235:
          if (tag !== 1882) {
            break;
          }

          message.kubernetesServiceInstance.push(KubernetesServiceInstance.decode(reader, reader.uint32()));
          continue;
        case 242:
          if (tag !== 1938) {
            break;
          }

          message.bearerToken.push(BearerToken.decode(reader, reader.uint32()));
          continue;
        case 243:
          if (tag !== 1946) {
            break;
          }

          message.apiKey.push(ApiKey.decode(reader, reader.uint32()));
          continue;
        case 244:
          if (tag !== 1954) {
            break;
          }

          message.fabricMemoryUsage.push(FabricMemoryUsage.decode(reader, reader.uint32()));
          continue;
        case 245:
          if (tag !== 1962) {
            break;
          }

          message.typeMemoryUsage.push(TypeMemoryUsage.decode(reader, reader.uint32()));
          continue;
        case 246:
          if (tag !== 1970) {
            break;
          }

          message.manifestRunningImageStatus.push(ManifestRunningImageStatus.decode(reader, reader.uint32()));
          continue;
        case 247:
          if (tag !== 1978) {
            break;
          }

          message.manifestPartitionFileCacheStatus.push(
            ManifestPartitionFileCacheStatus.decode(reader, reader.uint32()),
          );
          continue;
        case 248:
          if (tag !== 1986) {
            break;
          }

          message.stpPortState.push(StpPortState.decode(reader, reader.uint32()));
          continue;
        case 249:
          if (tag !== 1994) {
            break;
          }

          message.stpVlanState.push(StpVlanState.decode(reader, reader.uint32()));
          continue;
        case 250:
          if (tag !== 2002) {
            break;
          }

          message.stpVlanPortState.push(StpVlanPortState.decode(reader, reader.uint32()));
          continue;
        case 251:
          if (tag !== 2010) {
            break;
          }

          message.assertDeviceEnvPsuUsageBelowThreshold.push(
            AssertDeviceEnvPsuUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 252:
          if (tag !== 2018) {
            break;
          }

          message.assertDeviceEnvFanUsageBelowThreshold.push(
            AssertDeviceEnvFanUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 253:
          if (tag !== 2026) {
            break;
          }

          message.assertDeviceEnvTempSensorUsageBelowThreshold.push(
            AssertDeviceEnvTempSensorUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 254:
          if (tag !== 2034) {
            break;
          }

          message.assertConfigDeviceEnvPsuUsageBelowThreshold.push(
            AssertConfigDeviceEnvPsuUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 255:
          if (tag !== 2042) {
            break;
          }

          message.assertConfigDeviceEnvFanUsageBelowThreshold.push(
            AssertConfigDeviceEnvFanUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 256:
          if (tag !== 2050) {
            break;
          }

          message.assertConfigDeviceEnvTempSensorUsageBelowThreshold.push(
            AssertConfigDeviceEnvTempSensorUsageBelowThreshold.decode(reader, reader.uint32()),
          );
          continue;
        case 258:
          if (tag !== 2066) {
            break;
          }

          message.unpackAndAssembleTask.push(UnpackAndAssembleTask.decode(reader, reader.uint32()));
          continue;
        case 259:
          if (tag !== 2074) {
            break;
          }

          message.rackAndPowerOnTask.push(RackAndPowerOnTask.decode(reader, reader.uint32()));
          continue;
        case 260:
          if (tag !== 2082) {
            break;
          }

          message.claimAndBindTask.push(ClaimAndBindTask.decode(reader, reader.uint32()));
          continue;
        case 261:
          if (tag !== 2090) {
            break;
          }

          message.portSetupTask.push(PortSetupTask.decode(reader, reader.uint32()));
          continue;
        case 262:
          if (tag !== 2098) {
            break;
          }

          message.fabricConnectionTask.push(FabricConnectionTask.decode(reader, reader.uint32()));
          continue;
        case 263:
          if (tag !== 2106) {
            break;
          }

          message.negativeConnectionTask.push(NegativeConnectionTask.decode(reader, reader.uint32()));
          continue;
        case 264:
          if (tag !== 2114) {
            break;
          }

          message.application.push(Application.decode(reader, reader.uint32()));
          continue;
        case 265:
          if (tag !== 2122) {
            break;
          }

          message.resourceAssignment.push(ResourceAssignment.decode(reader, reader.uint32()));
          continue;
        case 266:
          if (tag !== 2130) {
            break;
          }

          message.node.push(Node.decode(reader, reader.uint32()));
          continue;
        case 267:
          if (tag !== 2138) {
            break;
          }

          message.nodeLineCard.push(NodeLineCard.decode(reader, reader.uint32()));
          continue;
        case 268:
          if (tag !== 2146) {
            break;
          }

          message.nodePort.push(NodePort.decode(reader, reader.uint32()));
          continue;
        case 269:
          if (tag !== 2154) {
            break;
          }

          message.nodeBreakout.push(NodeBreakout.decode(reader, reader.uint32()));
          continue;
        case 270:
          if (tag !== 2162) {
            break;
          }

          message.tenantMetadata.push(TenantMetadata.decode(reader, reader.uint32()));
          continue;
        case 271:
          if (tag !== 2170) {
            break;
          }

          message.assertPortNoUnknownVlanFound.push(AssertPortNoUnknownVlanFound.decode(reader, reader.uint32()));
          continue;
        case 272:
          if (tag !== 2178) {
            break;
          }

          message.assertConfigPortNoUnknownVlanFound.push(
            AssertConfigPortNoUnknownVlanFound.decode(reader, reader.uint32()),
          );
          continue;
        case 273:
          if (tag !== 2186) {
            break;
          }

          message.bulkUpliftJobRequest.push(BulkUpliftJobRequest.decode(reader, reader.uint32()));
          continue;
        case 274:
          if (tag !== 2194) {
            break;
          }

          message.bulkUpliftJobStatus.push(BulkUpliftJobStatus.decode(reader, reader.uint32()));
          continue;
        case 275:
          if (tag !== 2202) {
            break;
          }

          message.bgpNeighborInfo.push(BgpNeighborInfo.decode(reader, reader.uint32()));
          continue;
        case 276:
          if (tag !== 2210) {
            break;
          }

          message.refPoint.push(RefPoint.decode(reader, reader.uint32()));
          continue;
        case 277:
          if (tag !== 2218) {
            break;
          }

          message.refPointStatus.push(RefPointStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataSeries {
    return {
      objectName: isSet(object.objectName) ? globalThis.String(object.objectName) : "",
      objectType: isSet(object.objectType) ? schemaTypeFromJSON(object.objectType) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
      instance: isSet(object.instance) ? globalThis.Number(object.instance) : 0,
      fabric: globalThis.Array.isArray(object?.fabric) ? object.fabric.map((e: any) => Fabric.fromJSON(e)) : [],
      device: globalThis.Array.isArray(object?.device) ? object.device.map((e: any) => Device.fromJSON(e)) : [],
      fan: globalThis.Array.isArray(object?.fan) ? object.fan.map((e: any) => Fan.fromJSON(e)) : [],
      psu: globalThis.Array.isArray(object?.psu) ? object.psu.map((e: any) => Psu.fromJSON(e)) : [],
      tempSensor: globalThis.Array.isArray(object?.tempSensor)
        ? object.tempSensor.map((e: any) => TempSensor.fromJSON(e))
        : [],
      lineCard: globalThis.Array.isArray(object?.lineCard) ? object.lineCard.map((e: any) => LineCard.fromJSON(e)) : [],
      port: globalThis.Array.isArray(object?.port) ? object.port.map((e: any) => Port.fromJSON(e)) : [],
      dacQsfp: globalThis.Array.isArray(object?.dacQsfp) ? object.dacQsfp.map((e: any) => DacQsfp.fromJSON(e)) : [],
      fiberQsfp: globalThis.Array.isArray(object?.fiberQsfp)
        ? object.fiberQsfp.map((e: any) => FiberQsfp.fromJSON(e))
        : [],
      breakout: globalThis.Array.isArray(object?.breakout) ? object.breakout.map((e: any) => Breakout.fromJSON(e)) : [],
      portNeighbor: globalThis.Array.isArray(object?.portNeighbor)
        ? object.portNeighbor.map((e: any) => PortNeighbor.fromJSON(e))
        : [],
      vtep: globalThis.Array.isArray(object?.vtep) ? object.vtep.map((e: any) => Vtep.fromJSON(e)) : [],
      vniPort: globalThis.Array.isArray(object?.vniPort) ? object.vniPort.map((e: any) => VniPort.fromJSON(e)) : [],
      mac: globalThis.Array.isArray(object?.mac) ? object.mac.map((e: any) => Mac.fromJSON(e)) : [],
      procTable: globalThis.Array.isArray(object?.procTable)
        ? object.procTable.map((e: any) => ProcTable.fromJSON(e))
        : [],
      portVlan: globalThis.Array.isArray(object?.portVlan) ? object.portVlan.map((e: any) => PortVlan.fromJSON(e)) : [],
      vlanState: globalThis.Array.isArray(object?.vlanState)
        ? object.vlanState.map((e: any) => VlanState.fromJSON(e))
        : [],
      qsfpDomSensor: globalThis.Array.isArray(object?.qsfpDomSensor)
        ? object.qsfpDomSensor.map((e: any) => QsfpDomSensor.fromJSON(e))
        : [],
      portConfig: globalThis.Array.isArray(object?.portConfig)
        ? object.portConfig.map((e: any) => PortConfig.fromJSON(e))
        : [],
      nodeConfig: globalThis.Array.isArray(object?.nodeConfig)
        ? object.nodeConfig.map((e: any) => NodeConfig.fromJSON(e))
        : [],
      fabricConfig: globalThis.Array.isArray(object?.fabricConfig)
        ? object.fabricConfig.map((e: any) => FabricConfig.fromJSON(e))
        : [],
      fabricConfigEvent: globalThis.Array.isArray(object?.fabricConfigEvent)
        ? object.fabricConfigEvent.map((e: any) => FabricConfigEvent.fromJSON(e))
        : [],
      l2Fdb: globalThis.Array.isArray(object?.l2Fdb) ? object.l2Fdb.map((e: any) => L2Fdb.fromJSON(e)) : [],
      portCounters: globalThis.Array.isArray(object?.portCounters)
        ? object.portCounters.map((e: any) => PortCounters.fromJSON(e))
        : [],
      remedyAction: globalThis.Array.isArray(object?.remedyAction)
        ? object.remedyAction.map((e: any) => RemedyAction.fromJSON(e))
        : [],
      qsfpInfo: globalThis.Array.isArray(object?.qsfpInfo) ? object.qsfpInfo.map((e: any) => QsfpInfo.fromJSON(e)) : [],
      fabricDevice: globalThis.Array.isArray(object?.fabricDevice)
        ? object.fabricDevice.map((e: any) => FabricDevice.fromJSON(e))
        : [],
      assertConfigPortLinkUp: globalThis.Array.isArray(object?.assertConfigPortLinkUp)
        ? object.assertConfigPortLinkUp.map((e: any) => AssertConfigPortLinkUp.fromJSON(e))
        : [],
      assertPortLinkUp: globalThis.Array.isArray(object?.assertPortLinkUp)
        ? object.assertPortLinkUp.map((e: any) => AssertPortLinkUp.fromJSON(e))
        : [],
      assertConfigDeviceConnectedToCloud: globalThis.Array.isArray(object?.assertConfigDeviceConnectedToCloud)
        ? object.assertConfigDeviceConnectedToCloud.map((e: any) => AssertConfigDeviceConnectedToCloud.fromJSON(e))
        : [],
      assertDeviceConnectedToCloud: globalThis.Array.isArray(object?.assertDeviceConnectedToCloud)
        ? object.assertDeviceConnectedToCloud.map((e: any) => AssertDeviceConnectedToCloud.fromJSON(e))
        : [],
      assertConfigPortExpectedNeighbor: globalThis.Array.isArray(object?.assertConfigPortExpectedNeighbor)
        ? object.assertConfigPortExpectedNeighbor.map((e: any) => AssertConfigPortExpectedNeighbor.fromJSON(e))
        : [],
      assertPortExpectedNeighbor: globalThis.Array.isArray(object?.assertPortExpectedNeighbor)
        ? object.assertPortExpectedNeighbor.map((e: any) => AssertPortExpectedNeighbor.fromJSON(e))
        : [],
      assertConfigPortNotConnectedToFabric: globalThis.Array.isArray(object?.assertConfigPortNotConnectedToFabric)
        ? object.assertConfigPortNotConnectedToFabric.map((e: any) => AssertConfigPortNotConnectedToFabric.fromJSON(e))
        : [],
      assertPortNotConnectedToFabric: globalThis.Array.isArray(object?.assertPortNotConnectedToFabric)
        ? object.assertPortNotConnectedToFabric.map((e: any) => AssertPortNotConnectedToFabric.fromJSON(e))
        : [],
      assertConfigDeviceConnectedToFabric: globalThis.Array.isArray(object?.assertConfigDeviceConnectedToFabric)
        ? object.assertConfigDeviceConnectedToFabric.map((e: any) => AssertConfigDeviceConnectedToFabric.fromJSON(e))
        : [],
      assertDeviceConnectedToFabric: globalThis.Array.isArray(object?.assertDeviceConnectedToFabric)
        ? object.assertDeviceConnectedToFabric.map((e: any) => AssertDeviceConnectedToFabric.fromJSON(e))
        : [],
      assertConfigPortLinkDown: globalThis.Array.isArray(object?.assertConfigPortLinkDown)
        ? object.assertConfigPortLinkDown.map((e: any) => AssertConfigPortLinkDown.fromJSON(e))
        : [],
      assertPortLinkDown: globalThis.Array.isArray(object?.assertPortLinkDown)
        ? object.assertPortLinkDown.map((e: any) => AssertPortLinkDown.fromJSON(e))
        : [],
      assertConfigPortConnectionSpeedMatch: globalThis.Array.isArray(object?.assertConfigPortConnectionSpeedMatch)
        ? object.assertConfigPortConnectionSpeedMatch.map((e: any) => AssertConfigPortConnectionSpeedMatch.fromJSON(e))
        : [],
      assertPortConnectionSpeedMatch: globalThis.Array.isArray(object?.assertPortConnectionSpeedMatch)
        ? object.assertPortConnectionSpeedMatch.map((e: any) => AssertPortConnectionSpeedMatch.fromJSON(e))
        : [],
      assertConfigPortSpeedConsistent: globalThis.Array.isArray(object?.assertConfigPortSpeedConsistent)
        ? object.assertConfigPortSpeedConsistent.map((e: any) => AssertConfigPortSpeedConsistent.fromJSON(e))
        : [],
      assertPortSpeedConsistent: globalThis.Array.isArray(object?.assertPortSpeedConsistent)
        ? object.assertPortSpeedConsistent.map((e: any) => AssertPortSpeedConsistent.fromJSON(e))
        : [],
      underlayInfo: globalThis.Array.isArray(object?.underlayInfo)
        ? object.underlayInfo.map((e: any) => UnderlayInfo.fromJSON(e))
        : [],
      proxyStats: globalThis.Array.isArray(object?.proxyStats)
        ? object.proxyStats.map((e: any) => ProxyStats.fromJSON(e))
        : [],
      vlanCounters: globalThis.Array.isArray(object?.vlanCounters)
        ? object.vlanCounters.map((e: any) => VlanCounters.fromJSON(e))
        : [],
      testPlaceHolder1Secret: globalThis.Array.isArray(object?.testPlaceHolder1Secret)
        ? object.testPlaceHolder1Secret.map((e: any) => TestPlaceHolder1Secret.fromJSON(e))
        : [],
      assertConfigVlanHasTraffic: globalThis.Array.isArray(object?.assertConfigVlanHasTraffic)
        ? object.assertConfigVlanHasTraffic.map((e: any) => AssertConfigVlanHasTraffic.fromJSON(e))
        : [],
      assertVlanHasTraffic: globalThis.Array.isArray(object?.assertVlanHasTraffic)
        ? object.assertVlanHasTraffic.map((e: any) => AssertVlanHasTraffic.fromJSON(e))
        : [],
      remoteVtep: globalThis.Array.isArray(object?.remoteVtep)
        ? object.remoteVtep.map((e: any) => RemoteVtep.fromJSON(e))
        : [],
      qsfpDomThreshold: globalThis.Array.isArray(object?.qsfpDomThreshold)
        ? object.qsfpDomThreshold.map((e: any) => QsfpDomThreshold.fromJSON(e))
        : [],
      l3Table: globalThis.Array.isArray(object?.l3Table)
        ? object.l3Table.map((e: any) => L3Table.fromJSON(e))
        : [],
      vlanVniMap: globalThis.Array.isArray(object?.vlanVniMap)
        ? object.vlanVniMap.map((e: any) => VlanVniMap.fromJSON(e))
        : [],
      proxyConnectionState: globalThis.Array.isArray(object?.proxyConnectionState)
        ? object.proxyConnectionState.map((e: any) => ProxyConnectionState.fromJSON(e))
        : [],
      proxyConnectionStats: globalThis.Array.isArray(object?.proxyConnectionStats)
        ? object.proxyConnectionStats.map((e: any) => ProxyConnectionStats.fromJSON(e))
        : [],
      l2Mac: globalThis.Array.isArray(object?.l2Mac)
        ? object.l2Mac.map((e: any) => L2Mac.fromJSON(e))
        : [],
      bmc: globalThis.Array.isArray(object?.bmc) ? object.bmc.map((e: any) => Bmc.fromJSON(e)) : [],
      smartNicDpu: globalThis.Array.isArray(object?.smartNicDpu)
        ? object.smartNicDpu.map((e: any) => SmartNicDpu.fromJSON(e))
        : [],
      serverVoltageSensor: globalThis.Array.isArray(object?.serverVoltageSensor)
        ? object.serverVoltageSensor.map((e: any) => ServerVoltageSensor.fromJSON(e))
        : [],
      serverTemperatureSensor: globalThis.Array.isArray(object?.serverTemperatureSensor)
        ? object.serverTemperatureSensor.map((e: any) => ServerTemperatureSensor.fromJSON(e))
        : [],
      ipRouteTable: globalThis.Array.isArray(object?.ipRouteTable)
        ? object.ipRouteTable.map((e: any) => IpRouteTable.fromJSON(e))
        : [],
      vrf: globalThis.Array.isArray(object?.vrf) ? object.vrf.map((e: any) => Vrf.fromJSON(e)) : [],
      ipNextHop: globalThis.Array.isArray(object?.ipNextHop)
        ? object.ipNextHop.map((e: any) => IpNextHop.fromJSON(e))
        : [],
      ipPrefix: globalThis.Array.isArray(object?.ipPrefix)
        ? object.ipPrefix.map((e: any) => IpPrefix.fromJSON(e))
        : [],
      subInterface: globalThis.Array.isArray(object?.subInterface)
        ? object.subInterface.map((e: any) => SubInterface.fromJSON(e))
        : [],
      assertConfigStaticDefaultRouteExists: globalThis.Array.isArray(object?.assertConfigStaticDefaultRouteExists)
        ? object.assertConfigStaticDefaultRouteExists.map((e: any) => AssertConfigStaticDefaultRouteExists.fromJSON(e))
        : [],
      assertStaticDefaultRouteExists: globalThis.Array.isArray(object?.assertStaticDefaultRouteExists)
        ? object.assertStaticDefaultRouteExists.map((e: any) => AssertStaticDefaultRouteExists.fromJSON(e))
        : [],
      spyglassBrokerEntry: globalThis.Array.isArray(object?.spyglassBrokerEntry)
        ? object.spyglassBrokerEntry.map((e: any) => SpyglassBrokerEntry.fromJSON(e))
        : [],
      managementState: globalThis.Array.isArray(object?.managementState)
        ? object.managementState.map((e: any) => ManagementState.fromJSON(e))
        : [],
      assertConfigManagementPortConfigSame: globalThis.Array.isArray(object?.assertConfigManagementPortConfigSame)
        ? object.assertConfigManagementPortConfigSame.map((e: any) => AssertConfigManagementPortConfigSame.fromJSON(e))
        : [],
      assertManagementPortConfigSame: globalThis.Array.isArray(object?.assertManagementPortConfigSame)
        ? object.assertManagementPortConfigSame.map((e: any) => AssertManagementPortConfigSame.fromJSON(e))
        : [],
      fabricConfigNotification: globalThis.Array.isArray(object?.fabricConfigNotification)
        ? object.fabricConfigNotification.map((e: any) => FabricConfigNotification.fromJSON(e))
        : [],
      spyglassGingerEntry: globalThis.Array.isArray(object?.spyglassGingerEntry)
        ? object.spyglassGingerEntry.map((e: any) => SpyglassGingerEntry.fromJSON(e))
        : [],
      plannedConfig: globalThis.Array.isArray(object?.plannedConfig)
        ? object.plannedConfig.map((e: any) => PlannedConfig.fromJSON(e))
        : [],
      routedInterfaceCounters: globalThis.Array.isArray(object?.routedInterfaceCounters)
        ? object.routedInterfaceCounters.map((e: any) => RoutedInterfaceCounters.fromJSON(e))
        : [],
      ipmRawLatencyCounters: globalThis.Array.isArray(object?.ipmRawLatencyCounters)
        ? object.ipmRawLatencyCounters.map((e: any) => IpmRawLatencyCounters.fromJSON(e))
        : [],
      ipmProcLatencyCounters: globalThis.Array.isArray(object?.ipmProcLatencyCounters)
        ? object.ipmProcLatencyCounters.map((e: any) => IpmProcLatencyCounters.fromJSON(e))
        : [],
      ipmProcLossCounters: globalThis.Array.isArray(object?.ipmProcLossCounters)
        ? object.ipmProcLossCounters.map((e: any) => IpmProcLossCounters.fromJSON(e))
        : [],
      ipmLivenessCounters: globalThis.Array.isArray(object?.ipmLivenessCounters)
        ? object.ipmLivenessCounters.map((e: any) => IpmLivenessCounters.fromJSON(e))
        : [],
      ipmSessionId: globalThis.Array.isArray(object?.ipmSessionId)
        ? object.ipmSessionId.map((e: any) => IpmSessionId.fromJSON(e))
        : [],
      ipmProcJitterCounters: globalThis.Array.isArray(object?.ipmProcJitterCounters)
        ? object.ipmProcJitterCounters.map((e: any) => IpmProcJitterCounters.fromJSON(e))
        : [],
      systemStats: globalThis.Array.isArray(object?.systemStats)
        ? object.systemStats.map((e: any) => SystemStats.fromJSON(e))
        : [],
      testRootOne: globalThis.Array.isArray(object?.testRootOne)
        ? object.testRootOne.map((e: any) => TestRootOne.fromJSON(e))
        : [],
      testRootTwo: globalThis.Array.isArray(object?.testRootTwo)
        ? object.testRootTwo.map((e: any) => TestRootTwo.fromJSON(e))
        : [],
      testMidOne: globalThis.Array.isArray(object?.testMidOne)
        ? object.testMidOne.map((e: any) => TestMidOne.fromJSON(e))
        : [],
      testLeafOne: globalThis.Array.isArray(object?.testLeafOne)
        ? object.testLeafOne.map((e: any) => TestLeafOne.fromJSON(e))
        : [],
      testIpAddress: globalThis.Array.isArray(object?.testIpAddress)
        ? object.testIpAddress.map((e: any) => TestIpAddress.fromJSON(e))
        : [],
      testIpPrefix: globalThis.Array.isArray(object?.testIpPrefix)
        ? object.testIpPrefix.map((e: any) => TestIpPrefix.fromJSON(e))
        : [],
      testMacAddress: globalThis.Array.isArray(object?.testMacAddress)
        ? object.testMacAddress.map((e: any) => TestMacAddress.fromJSON(e))
        : [],
      assertConfigDeviceResourceUsageBelowThreshold:
        globalThis.Array.isArray(object?.assertConfigDeviceResourceUsageBelowThreshold)
          ? object.assertConfigDeviceResourceUsageBelowThreshold.map((e: any) =>
            AssertConfigDeviceResourceUsageBelowThreshold.fromJSON(e)
          )
          : [],
      assertDeviceResourceUsageBelowThreshold: globalThis.Array.isArray(object?.assertDeviceResourceUsageBelowThreshold)
        ? object.assertDeviceResourceUsageBelowThreshold.map((e: any) =>
          AssertDeviceResourceUsageBelowThreshold.fromJSON(e)
        )
        : [],
      fabricTransaction: globalThis.Array.isArray(object?.fabricTransaction)
        ? object.fabricTransaction.map((e: any) => FabricTransaction.fromJSON(e))
        : [],
      bgpNeighborSummary: globalThis.Array.isArray(object?.bgpNeighborSummary)
        ? object.bgpNeighborSummary.map((e: any) => BgpNeighborSummary.fromJSON(e))
        : [],
      bmcConfig: globalThis.Array.isArray(object?.bmcConfig)
        ? object.bmcConfig.map((e: any) => BmcConfig.fromJSON(e))
        : [],
      deviceConnection: globalThis.Array.isArray(object?.deviceConnection)
        ? object.deviceConnection.map((e: any) => DeviceConnection.fromJSON(e))
        : [],
      bgpNeighborFamily: globalThis.Array.isArray(object?.bgpNeighborFamily)
        ? object.bgpNeighborFamily.map((e: any) => BgpNeighborFamily.fromJSON(e))
        : [],
      assertConfigBgpPeerEstablished: globalThis.Array.isArray(object?.assertConfigBgpPeerEstablished)
        ? object.assertConfigBgpPeerEstablished.map((e: any) => AssertConfigBgpPeerEstablished.fromJSON(e))
        : [],
      assertBgpPeerEstablished: globalThis.Array.isArray(object?.assertBgpPeerEstablished)
        ? object.assertBgpPeerEstablished.map((e: any) => AssertBgpPeerEstablished.fromJSON(e))
        : [],
      spyglassSshSessionEntry: globalThis.Array.isArray(object?.spyglassSshSessionEntry)
        ? object.spyglassSshSessionEntry.map((e: any) => SpyglassSshSessionEntry.fromJSON(e))
        : [],
      portChannelMemberState: globalThis.Array.isArray(object?.portChannelMemberState)
        ? object.portChannelMemberState.map((e: any) => PortChannelMemberState.fromJSON(e))
        : [],
      portChannelState: globalThis.Array.isArray(object?.portChannelState)
        ? object.portChannelState.map((e: any) => PortChannelState.fromJSON(e))
        : [],
      assertConfigIpmLatencyBelowThreshold: globalThis.Array.isArray(object?.assertConfigIpmLatencyBelowThreshold)
        ? object.assertConfigIpmLatencyBelowThreshold.map((e: any) => AssertConfigIpmLatencyBelowThreshold.fromJSON(e))
        : [],
      assertIpmLatencyBelowThreshold: globalThis.Array.isArray(object?.assertIpmLatencyBelowThreshold)
        ? object.assertIpmLatencyBelowThreshold.map((e: any) => AssertIpmLatencyBelowThreshold.fromJSON(e))
        : [],
      assertConfigIpmLossBelowThreshold: globalThis.Array.isArray(object?.assertConfigIpmLossBelowThreshold)
        ? object.assertConfigIpmLossBelowThreshold.map((e: any) => AssertConfigIpmLossBelowThreshold.fromJSON(e))
        : [],
      assertIpmLossBelowThreshold: globalThis.Array.isArray(object?.assertIpmLossBelowThreshold)
        ? object.assertIpmLossBelowThreshold.map((e: any) => AssertIpmLossBelowThreshold.fromJSON(e))
        : [],
      spyglassDevice: globalThis.Array.isArray(object?.spyglassDevice)
        ? object.spyglassDevice.map((e: any) => SpyglassDevice.fromJSON(e))
        : [],
      spyglassTcpListener: globalThis.Array.isArray(object?.spyglassTcpListener)
        ? object.spyglassTcpListener.map((e: any) => SpyglassTcpListener.fromJSON(e))
        : [],
      spyglassTcpSession: globalThis.Array.isArray(object?.spyglassTcpSession)
        ? object.spyglassTcpSession.map((e: any) => SpyglassTcpSession.fromJSON(e))
        : [],
      unknownVlan: globalThis.Array.isArray(object?.unknownVlan)
        ? object.unknownVlan.map((e: any) => UnknownVlan.fromJSON(e))
        : [],
      unknownVlanPort: globalThis.Array.isArray(object?.unknownVlanPort)
        ? object.unknownVlanPort.map((e: any) => UnknownVlanPort.fromJSON(e))
        : [],
      assertConfigExpectedPortBreakoutExists: globalThis.Array.isArray(object?.assertConfigExpectedPortBreakoutExists)
        ? object.assertConfigExpectedPortBreakoutExists.map((e: any) =>
          AssertConfigExpectedPortBreakoutExists.fromJSON(e)
        )
        : [],
      assertExpectedPortBreakoutExists: globalThis.Array.isArray(object?.assertExpectedPortBreakoutExists)
        ? object.assertExpectedPortBreakoutExists.map((e: any) => AssertExpectedPortBreakoutExists.fromJSON(e))
        : [],
      assertConfigSubInterfaceUp: globalThis.Array.isArray(object?.assertConfigSubInterfaceUp)
        ? object.assertConfigSubInterfaceUp.map((e: any) => AssertConfigSubInterfaceUp.fromJSON(e))
        : [],
      assertSubInterfaceUp: globalThis.Array.isArray(object?.assertSubInterfaceUp)
        ? object.assertSubInterfaceUp.map((e: any) => AssertSubInterfaceUp.fromJSON(e))
        : [],
      assertSubInterfaceDown: globalThis.Array.isArray(object?.assertSubInterfaceDown)
        ? object.assertSubInterfaceDown.map((e: any) => AssertSubInterfaceDown.fromJSON(e))
        : [],
      assertConfigSubInterfaceDown: globalThis.Array.isArray(object?.assertConfigSubInterfaceDown)
        ? object.assertConfigSubInterfaceDown.map((e: any) => AssertConfigSubInterfaceDown.fromJSON(e))
        : [],
      deviceClaim: globalThis.Array.isArray(object?.deviceClaim)
        ? object.deviceClaim.map((e: any) => DeviceClaim.fromJSON(e))
        : [],
      aggregatedDeviceClaim: globalThis.Array.isArray(object?.aggregatedDeviceClaim)
        ? object.aggregatedDeviceClaim.map((e: any) => AggregatedDeviceClaim.fromJSON(e))
        : [],
      assertPortChannelMemberUp: globalThis.Array.isArray(object?.assertPortChannelMemberUp)
        ? object.assertPortChannelMemberUp.map((e: any) => AssertPortChannelMemberUp.fromJSON(e))
        : [],
      assertConfigPortChannelMemberUp: globalThis.Array.isArray(object?.assertConfigPortChannelMemberUp)
        ? object.assertConfigPortChannelMemberUp.map((e: any) => AssertConfigPortChannelMemberUp.fromJSON(e))
        : [],
      esiInfo: globalThis.Array.isArray(object?.esiInfo) ? object.esiInfo.map((e: any) => EsiInfo.fromJSON(e)) : [],
      esiVtepInfo: globalThis.Array.isArray(object?.esiVtepInfo)
        ? object.esiVtepInfo.map((e: any) => EsiVtepInfo.fromJSON(e))
        : [],
      assertPortVlanStpStateConsistent: globalThis.Array.isArray(object?.assertPortVlanStpStateConsistent)
        ? object.assertPortVlanStpStateConsistent.map((e: any) => AssertPortVlanStpStateConsistent.fromJSON(e))
        : [],
      assertConfigPortVlanStpStateConsistent: globalThis.Array.isArray(object?.assertConfigPortVlanStpStateConsistent)
        ? object.assertConfigPortVlanStpStateConsistent.map((e: any) =>
          AssertConfigPortVlanStpStateConsistent.fromJSON(e)
        )
        : [],
      assertPortPluggablePidSame: globalThis.Array.isArray(object?.assertPortPluggablePidSame)
        ? object.assertPortPluggablePidSame.map((e: any) => AssertPortPluggablePidSame.fromJSON(e))
        : [],
      assertConfigPortPluggablePidSame: globalThis.Array.isArray(object?.assertConfigPortPluggablePidSame)
        ? object.assertConfigPortPluggablePidSame.map((e: any) => AssertConfigPortPluggablePidSame.fromJSON(e))
        : [],
      assertConfigIpmJitterBelowThreshold: globalThis.Array.isArray(object?.assertConfigIpmJitterBelowThreshold)
        ? object.assertConfigIpmJitterBelowThreshold.map((e: any) => AssertConfigIpmJitterBelowThreshold.fromJSON(e))
        : [],
      assertIpmJitterBelowThreshold: globalThis.Array.isArray(object?.assertIpmJitterBelowThreshold)
        ? object.assertIpmJitterBelowThreshold.map((e: any) => AssertIpmJitterBelowThreshold.fromJSON(e))
        : [],
      manifestImageState: globalThis.Array.isArray(object?.manifestImageState)
        ? object.manifestImageState.map((e: any) => ManifestImageState.fromJSON(e))
        : [],
      orgUuid: globalThis.Array.isArray(object?.orgUuid) ? object.orgUuid.map((e: any) => OrgUuid.fromJSON(e)) : [],
      fabricUuid: globalThis.Array.isArray(object?.fabricUuid)
        ? object.fabricUuid.map((e: any) => FabricUuid.fromJSON(e))
        : [],
      orgServiceProto: globalThis.Array.isArray(object?.orgServiceProto)
        ? object.orgServiceProto.map((e: any) => OrgServiceProto.fromJSON(e))
        : [],
      orgServiceSvc: globalThis.Array.isArray(object?.orgServiceSvc)
        ? object.orgServiceSvc.map((e: any) => OrgServiceSvc.fromJSON(e))
        : [],
      orgServiceRoute: globalThis.Array.isArray(object?.orgServiceRoute)
        ? object.orgServiceRoute.map((e: any) => OrgServiceRoute.fromJSON(e))
        : [],
      apiPath: globalThis.Array.isArray(object?.apiPath)
        ? object.apiPath.map((e: any) => ApiPath.fromJSON(e))
        : [],
      apiPathMethod: globalThis.Array.isArray(object?.apiPathMethod)
        ? object.apiPathMethod.map((e: any) => ApiPathMethod.fromJSON(e))
        : [],
      apiServiceMapping: globalThis.Array.isArray(object?.apiServiceMapping)
        ? object.apiServiceMapping.map((e: any) => ApiServiceMapping.fromJSON(e))
        : [],
      orgToCellPairMapping: globalThis.Array.isArray(object?.orgToCellPairMapping)
        ? object.orgToCellPairMapping.map((e: any) => OrgToCellPairMapping.fromJSON(e))
        : [],
      tenantUuid: globalThis.Array.isArray(object?.tenantUuid)
        ? object.tenantUuid.map((e: any) => TenantUuid.fromJSON(e))
        : [],
      userEmail: globalThis.Array.isArray(object?.userEmail)
        ? object.userEmail.map((e: any) => UserEmail.fromJSON(e))
        : [],
      managementConfig: globalThis.Array.isArray(object?.managementConfig)
        ? object.managementConfig.map((e: any) => ManagementConfig.fromJSON(e))
        : [],
      serviceInstanceHealth: globalThis.Array.isArray(object?.serviceInstanceHealth)
        ? object.serviceInstanceHealth.map((e: any) => ServiceInstanceHealth.fromJSON(e))
        : [],
      assignmentStatus: globalThis.Array.isArray(object?.assignmentStatus)
        ? object.assignmentStatus.map((e: any) => AssignmentStatus.fromJSON(e))
        : [],
      kubernetesServiceInstance: globalThis.Array.isArray(object?.kubernetesServiceInstance)
        ? object.kubernetesServiceInstance.map((e: any) => KubernetesServiceInstance.fromJSON(e))
        : [],
      bearerToken: globalThis.Array.isArray(object?.bearerToken)
        ? object.bearerToken.map((e: any) => BearerToken.fromJSON(e))
        : [],
      apiKey: globalThis.Array.isArray(object?.apiKey) ? object.apiKey.map((e: any) => ApiKey.fromJSON(e)) : [],
      fabricMemoryUsage: globalThis.Array.isArray(object?.fabricMemoryUsage)
        ? object.fabricMemoryUsage.map((e: any) => FabricMemoryUsage.fromJSON(e))
        : [],
      typeMemoryUsage: globalThis.Array.isArray(object?.typeMemoryUsage)
        ? object.typeMemoryUsage.map((e: any) => TypeMemoryUsage.fromJSON(e))
        : [],
      manifestRunningImageStatus: globalThis.Array.isArray(object?.manifestRunningImageStatus)
        ? object.manifestRunningImageStatus.map((e: any) => ManifestRunningImageStatus.fromJSON(e))
        : [],
      manifestPartitionFileCacheStatus: globalThis.Array.isArray(object?.manifestPartitionFileCacheStatus)
        ? object.manifestPartitionFileCacheStatus.map((e: any) => ManifestPartitionFileCacheStatus.fromJSON(e))
        : [],
      stpPortState: globalThis.Array.isArray(object?.stpPortState)
        ? object.stpPortState.map((e: any) => StpPortState.fromJSON(e))
        : [],
      stpVlanState: globalThis.Array.isArray(object?.stpVlanState)
        ? object.stpVlanState.map((e: any) => StpVlanState.fromJSON(e))
        : [],
      stpVlanPortState: globalThis.Array.isArray(object?.stpVlanPortState)
        ? object.stpVlanPortState.map((e: any) => StpVlanPortState.fromJSON(e))
        : [],
      assertDeviceEnvPsuUsageBelowThreshold: globalThis.Array.isArray(object?.assertDeviceEnvPsuUsageBelowThreshold)
        ? object.assertDeviceEnvPsuUsageBelowThreshold.map((e: any) =>
          AssertDeviceEnvPsuUsageBelowThreshold.fromJSON(e)
        )
        : [],
      assertDeviceEnvFanUsageBelowThreshold: globalThis.Array.isArray(object?.assertDeviceEnvFanUsageBelowThreshold)
        ? object.assertDeviceEnvFanUsageBelowThreshold.map((e: any) =>
          AssertDeviceEnvFanUsageBelowThreshold.fromJSON(e)
        )
        : [],
      assertDeviceEnvTempSensorUsageBelowThreshold:
        globalThis.Array.isArray(object?.assertDeviceEnvTempSensorUsageBelowThreshold)
          ? object.assertDeviceEnvTempSensorUsageBelowThreshold.map((e: any) =>
            AssertDeviceEnvTempSensorUsageBelowThreshold.fromJSON(e)
          )
          : [],
      assertConfigDeviceEnvPsuUsageBelowThreshold:
        globalThis.Array.isArray(object?.assertConfigDeviceEnvPsuUsageBelowThreshold)
          ? object.assertConfigDeviceEnvPsuUsageBelowThreshold.map((e: any) =>
            AssertConfigDeviceEnvPsuUsageBelowThreshold.fromJSON(e)
          )
          : [],
      assertConfigDeviceEnvFanUsageBelowThreshold:
        globalThis.Array.isArray(object?.assertConfigDeviceEnvFanUsageBelowThreshold)
          ? object.assertConfigDeviceEnvFanUsageBelowThreshold.map((e: any) =>
            AssertConfigDeviceEnvFanUsageBelowThreshold.fromJSON(e)
          )
          : [],
      assertConfigDeviceEnvTempSensorUsageBelowThreshold:
        globalThis.Array.isArray(object?.assertConfigDeviceEnvTempSensorUsageBelowThreshold)
          ? object.assertConfigDeviceEnvTempSensorUsageBelowThreshold.map((e: any) =>
            AssertConfigDeviceEnvTempSensorUsageBelowThreshold.fromJSON(e)
          )
          : [],
      unpackAndAssembleTask: globalThis.Array.isArray(object?.unpackAndAssembleTask)
        ? object.unpackAndAssembleTask.map((e: any) => UnpackAndAssembleTask.fromJSON(e))
        : [],
      rackAndPowerOnTask: globalThis.Array.isArray(object?.rackAndPowerOnTask)
        ? object.rackAndPowerOnTask.map((e: any) => RackAndPowerOnTask.fromJSON(e))
        : [],
      claimAndBindTask: globalThis.Array.isArray(object?.claimAndBindTask)
        ? object.claimAndBindTask.map((e: any) => ClaimAndBindTask.fromJSON(e))
        : [],
      portSetupTask: globalThis.Array.isArray(object?.portSetupTask)
        ? object.portSetupTask.map((e: any) => PortSetupTask.fromJSON(e))
        : [],
      fabricConnectionTask: globalThis.Array.isArray(object?.fabricConnectionTask)
        ? object.fabricConnectionTask.map((e: any) => FabricConnectionTask.fromJSON(e))
        : [],
      negativeConnectionTask: globalThis.Array.isArray(object?.negativeConnectionTask)
        ? object.negativeConnectionTask.map((e: any) => NegativeConnectionTask.fromJSON(e))
        : [],
      application: globalThis.Array.isArray(object?.application)
        ? object.application.map((e: any) => Application.fromJSON(e))
        : [],
      resourceAssignment: globalThis.Array.isArray(object?.resourceAssignment)
        ? object.resourceAssignment.map((e: any) => ResourceAssignment.fromJSON(e))
        : [],
      node: globalThis.Array.isArray(object?.node)
        ? object.node.map((e: any) => Node.fromJSON(e))
        : [],
      nodeLineCard: globalThis.Array.isArray(object?.nodeLineCard)
        ? object.nodeLineCard.map((e: any) => NodeLineCard.fromJSON(e))
        : [],
      nodePort: globalThis.Array.isArray(object?.nodePort)
        ? object.nodePort.map((e: any) => NodePort.fromJSON(e))
        : [],
      nodeBreakout: globalThis.Array.isArray(object?.nodeBreakout)
        ? object.nodeBreakout.map((e: any) => NodeBreakout.fromJSON(e))
        : [],
      tenantMetadata: globalThis.Array.isArray(object?.tenantMetadata)
        ? object.tenantMetadata.map((e: any) => TenantMetadata.fromJSON(e))
        : [],
      assertPortNoUnknownVlanFound: globalThis.Array.isArray(object?.assertPortNoUnknownVlanFound)
        ? object.assertPortNoUnknownVlanFound.map((e: any) => AssertPortNoUnknownVlanFound.fromJSON(e))
        : [],
      assertConfigPortNoUnknownVlanFound: globalThis.Array.isArray(object?.assertConfigPortNoUnknownVlanFound)
        ? object.assertConfigPortNoUnknownVlanFound.map((e: any) => AssertConfigPortNoUnknownVlanFound.fromJSON(e))
        : [],
      bulkUpliftJobRequest: globalThis.Array.isArray(object?.bulkUpliftJobRequest)
        ? object.bulkUpliftJobRequest.map((e: any) => BulkUpliftJobRequest.fromJSON(e))
        : [],
      bulkUpliftJobStatus: globalThis.Array.isArray(object?.bulkUpliftJobStatus)
        ? object.bulkUpliftJobStatus.map((e: any) => BulkUpliftJobStatus.fromJSON(e))
        : [],
      bgpNeighborInfo: globalThis.Array.isArray(object?.bgpNeighborInfo)
        ? object.bgpNeighborInfo.map((e: any) => BgpNeighborInfo.fromJSON(e))
        : [],
      refPoint: globalThis.Array.isArray(object?.refPoint)
        ? object.refPoint.map((e: any) => RefPoint.fromJSON(e))
        : [],
      refPointStatus: globalThis.Array.isArray(object?.refPointStatus)
        ? object.refPointStatus.map((e: any) => RefPointStatus.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DataSeries): unknown {
    const obj: any = {};
    if (message.objectName !== "") {
      obj.objectName = message.objectName;
    }
    if (message.objectType !== 0) {
      obj.objectType = schemaTypeToJSON(message.objectType);
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    if (message.instance !== 0) {
      obj.instance = Math.round(message.instance);
    }
    if (message.fabric?.length) {
      obj.fabric = message.fabric.map((e) => Fabric.toJSON(e));
    }
    if (message.device?.length) {
      obj.device = message.device.map((e) => Device.toJSON(e));
    }
    if (message.fan?.length) {
      obj.fan = message.fan.map((e) => Fan.toJSON(e));
    }
    if (message.psu?.length) {
      obj.psu = message.psu.map((e) => Psu.toJSON(e));
    }
    if (message.tempSensor?.length) {
      obj.tempSensor = message.tempSensor.map((e) => TempSensor.toJSON(e));
    }
    if (message.lineCard?.length) {
      obj.lineCard = message.lineCard.map((e) => LineCard.toJSON(e));
    }
    if (message.port?.length) {
      obj.port = message.port.map((e) => Port.toJSON(e));
    }
    if (message.dacQsfp?.length) {
      obj.dacQsfp = message.dacQsfp.map((e) => DacQsfp.toJSON(e));
    }
    if (message.fiberQsfp?.length) {
      obj.fiberQsfp = message.fiberQsfp.map((e) => FiberQsfp.toJSON(e));
    }
    if (message.breakout?.length) {
      obj.breakout = message.breakout.map((e) => Breakout.toJSON(e));
    }
    if (message.portNeighbor?.length) {
      obj.portNeighbor = message.portNeighbor.map((e) => PortNeighbor.toJSON(e));
    }
    if (message.vtep?.length) {
      obj.vtep = message.vtep.map((e) => Vtep.toJSON(e));
    }
    if (message.vniPort?.length) {
      obj.vniPort = message.vniPort.map((e) => VniPort.toJSON(e));
    }
    if (message.mac?.length) {
      obj.mac = message.mac.map((e) => Mac.toJSON(e));
    }
    if (message.procTable?.length) {
      obj.procTable = message.procTable.map((e) => ProcTable.toJSON(e));
    }
    if (message.portVlan?.length) {
      obj.portVlan = message.portVlan.map((e) => PortVlan.toJSON(e));
    }
    if (message.vlanState?.length) {
      obj.vlanState = message.vlanState.map((e) => VlanState.toJSON(e));
    }
    if (message.qsfpDomSensor?.length) {
      obj.qsfpDomSensor = message.qsfpDomSensor.map((e) => QsfpDomSensor.toJSON(e));
    }
    if (message.portConfig?.length) {
      obj.portConfig = message.portConfig.map((e) => PortConfig.toJSON(e));
    }
    if (message.nodeConfig?.length) {
      obj.nodeConfig = message.nodeConfig.map((e) => NodeConfig.toJSON(e));
    }
    if (message.fabricConfig?.length) {
      obj.fabricConfig = message.fabricConfig.map((e) => FabricConfig.toJSON(e));
    }
    if (message.fabricConfigEvent?.length) {
      obj.fabricConfigEvent = message.fabricConfigEvent.map((e) => FabricConfigEvent.toJSON(e));
    }
    if (message.l2Fdb?.length) {
      obj.l2Fdb = message.l2Fdb.map((e) => L2Fdb.toJSON(e));
    }
    if (message.portCounters?.length) {
      obj.portCounters = message.portCounters.map((e) => PortCounters.toJSON(e));
    }
    if (message.remedyAction?.length) {
      obj.remedyAction = message.remedyAction.map((e) => RemedyAction.toJSON(e));
    }
    if (message.qsfpInfo?.length) {
      obj.qsfpInfo = message.qsfpInfo.map((e) => QsfpInfo.toJSON(e));
    }
    if (message.fabricDevice?.length) {
      obj.fabricDevice = message.fabricDevice.map((e) => FabricDevice.toJSON(e));
    }
    if (message.assertConfigPortLinkUp?.length) {
      obj.assertConfigPortLinkUp = message.assertConfigPortLinkUp.map((e) => AssertConfigPortLinkUp.toJSON(e));
    }
    if (message.assertPortLinkUp?.length) {
      obj.assertPortLinkUp = message.assertPortLinkUp.map((e) => AssertPortLinkUp.toJSON(e));
    }
    if (message.assertConfigDeviceConnectedToCloud?.length) {
      obj.assertConfigDeviceConnectedToCloud = message.assertConfigDeviceConnectedToCloud.map((e) =>
        AssertConfigDeviceConnectedToCloud.toJSON(e)
      );
    }
    if (message.assertDeviceConnectedToCloud?.length) {
      obj.assertDeviceConnectedToCloud = message.assertDeviceConnectedToCloud.map((e) =>
        AssertDeviceConnectedToCloud.toJSON(e)
      );
    }
    if (message.assertConfigPortExpectedNeighbor?.length) {
      obj.assertConfigPortExpectedNeighbor = message.assertConfigPortExpectedNeighbor.map((e) =>
        AssertConfigPortExpectedNeighbor.toJSON(e)
      );
    }
    if (message.assertPortExpectedNeighbor?.length) {
      obj.assertPortExpectedNeighbor = message.assertPortExpectedNeighbor.map((e) =>
        AssertPortExpectedNeighbor.toJSON(e)
      );
    }
    if (message.assertConfigPortNotConnectedToFabric?.length) {
      obj.assertConfigPortNotConnectedToFabric = message.assertConfigPortNotConnectedToFabric.map((e) =>
        AssertConfigPortNotConnectedToFabric.toJSON(e)
      );
    }
    if (message.assertPortNotConnectedToFabric?.length) {
      obj.assertPortNotConnectedToFabric = message.assertPortNotConnectedToFabric.map((e) =>
        AssertPortNotConnectedToFabric.toJSON(e)
      );
    }
    if (message.assertConfigDeviceConnectedToFabric?.length) {
      obj.assertConfigDeviceConnectedToFabric = message.assertConfigDeviceConnectedToFabric.map((e) =>
        AssertConfigDeviceConnectedToFabric.toJSON(e)
      );
    }
    if (message.assertDeviceConnectedToFabric?.length) {
      obj.assertDeviceConnectedToFabric = message.assertDeviceConnectedToFabric.map((e) =>
        AssertDeviceConnectedToFabric.toJSON(e)
      );
    }
    if (message.assertConfigPortLinkDown?.length) {
      obj.assertConfigPortLinkDown = message.assertConfigPortLinkDown.map((e) => AssertConfigPortLinkDown.toJSON(e));
    }
    if (message.assertPortLinkDown?.length) {
      obj.assertPortLinkDown = message.assertPortLinkDown.map((e) => AssertPortLinkDown.toJSON(e));
    }
    if (message.assertConfigPortConnectionSpeedMatch?.length) {
      obj.assertConfigPortConnectionSpeedMatch = message.assertConfigPortConnectionSpeedMatch.map((e) =>
        AssertConfigPortConnectionSpeedMatch.toJSON(e)
      );
    }
    if (message.assertPortConnectionSpeedMatch?.length) {
      obj.assertPortConnectionSpeedMatch = message.assertPortConnectionSpeedMatch.map((e) =>
        AssertPortConnectionSpeedMatch.toJSON(e)
      );
    }
    if (message.assertConfigPortSpeedConsistent?.length) {
      obj.assertConfigPortSpeedConsistent = message.assertConfigPortSpeedConsistent.map((e) =>
        AssertConfigPortSpeedConsistent.toJSON(e)
      );
    }
    if (message.assertPortSpeedConsistent?.length) {
      obj.assertPortSpeedConsistent = message.assertPortSpeedConsistent.map((e) => AssertPortSpeedConsistent.toJSON(e));
    }
    if (message.underlayInfo?.length) {
      obj.underlayInfo = message.underlayInfo.map((e) => UnderlayInfo.toJSON(e));
    }
    if (message.proxyStats?.length) {
      obj.proxyStats = message.proxyStats.map((e) => ProxyStats.toJSON(e));
    }
    if (message.vlanCounters?.length) {
      obj.vlanCounters = message.vlanCounters.map((e) => VlanCounters.toJSON(e));
    }
    if (message.testPlaceHolder1Secret?.length) {
      obj.testPlaceHolder1Secret = message.testPlaceHolder1Secret.map((e) => TestPlaceHolder1Secret.toJSON(e));
    }
    if (message.assertConfigVlanHasTraffic?.length) {
      obj.assertConfigVlanHasTraffic = message.assertConfigVlanHasTraffic.map((e) =>
        AssertConfigVlanHasTraffic.toJSON(e)
      );
    }
    if (message.assertVlanHasTraffic?.length) {
      obj.assertVlanHasTraffic = message.assertVlanHasTraffic.map((e) => AssertVlanHasTraffic.toJSON(e));
    }
    if (message.remoteVtep?.length) {
      obj.remoteVtep = message.remoteVtep.map((e) => RemoteVtep.toJSON(e));
    }
    if (message.qsfpDomThreshold?.length) {
      obj.qsfpDomThreshold = message.qsfpDomThreshold.map((e) => QsfpDomThreshold.toJSON(e));
    }
    if (message.l3Table?.length) {
      obj.l3Table = message.l3Table.map((e) => L3Table.toJSON(e));
    }
    if (message.vlanVniMap?.length) {
      obj.vlanVniMap = message.vlanVniMap.map((e) => VlanVniMap.toJSON(e));
    }
    if (message.proxyConnectionState?.length) {
      obj.proxyConnectionState = message.proxyConnectionState.map((e) => ProxyConnectionState.toJSON(e));
    }
    if (message.proxyConnectionStats?.length) {
      obj.proxyConnectionStats = message.proxyConnectionStats.map((e) => ProxyConnectionStats.toJSON(e));
    }
    if (message.l2Mac?.length) {
      obj.l2Mac = message.l2Mac.map((e) => L2Mac.toJSON(e));
    }
    if (message.bmc?.length) {
      obj.bmc = message.bmc.map((e) => Bmc.toJSON(e));
    }
    if (message.smartNicDpu?.length) {
      obj.smartNicDpu = message.smartNicDpu.map((e) => SmartNicDpu.toJSON(e));
    }
    if (message.serverVoltageSensor?.length) {
      obj.serverVoltageSensor = message.serverVoltageSensor.map((e) => ServerVoltageSensor.toJSON(e));
    }
    if (message.serverTemperatureSensor?.length) {
      obj.serverTemperatureSensor = message.serverTemperatureSensor.map((e) => ServerTemperatureSensor.toJSON(e));
    }
    if (message.ipRouteTable?.length) {
      obj.ipRouteTable = message.ipRouteTable.map((e) => IpRouteTable.toJSON(e));
    }
    if (message.vrf?.length) {
      obj.vrf = message.vrf.map((e) => Vrf.toJSON(e));
    }
    if (message.ipNextHop?.length) {
      obj.ipNextHop = message.ipNextHop.map((e) => IpNextHop.toJSON(e));
    }
    if (message.ipPrefix?.length) {
      obj.ipPrefix = message.ipPrefix.map((e) => IpPrefix.toJSON(e));
    }
    if (message.subInterface?.length) {
      obj.subInterface = message.subInterface.map((e) => SubInterface.toJSON(e));
    }
    if (message.assertConfigStaticDefaultRouteExists?.length) {
      obj.assertConfigStaticDefaultRouteExists = message.assertConfigStaticDefaultRouteExists.map((e) =>
        AssertConfigStaticDefaultRouteExists.toJSON(e)
      );
    }
    if (message.assertStaticDefaultRouteExists?.length) {
      obj.assertStaticDefaultRouteExists = message.assertStaticDefaultRouteExists.map((e) =>
        AssertStaticDefaultRouteExists.toJSON(e)
      );
    }
    if (message.spyglassBrokerEntry?.length) {
      obj.spyglassBrokerEntry = message.spyglassBrokerEntry.map((e) => SpyglassBrokerEntry.toJSON(e));
    }
    if (message.managementState?.length) {
      obj.managementState = message.managementState.map((e) => ManagementState.toJSON(e));
    }
    if (message.assertConfigManagementPortConfigSame?.length) {
      obj.assertConfigManagementPortConfigSame = message.assertConfigManagementPortConfigSame.map((e) =>
        AssertConfigManagementPortConfigSame.toJSON(e)
      );
    }
    if (message.assertManagementPortConfigSame?.length) {
      obj.assertManagementPortConfigSame = message.assertManagementPortConfigSame.map((e) =>
        AssertManagementPortConfigSame.toJSON(e)
      );
    }
    if (message.fabricConfigNotification?.length) {
      obj.fabricConfigNotification = message.fabricConfigNotification.map((e) => FabricConfigNotification.toJSON(e));
    }
    if (message.spyglassGingerEntry?.length) {
      obj.spyglassGingerEntry = message.spyglassGingerEntry.map((e) => SpyglassGingerEntry.toJSON(e));
    }
    if (message.plannedConfig?.length) {
      obj.plannedConfig = message.plannedConfig.map((e) => PlannedConfig.toJSON(e));
    }
    if (message.routedInterfaceCounters?.length) {
      obj.routedInterfaceCounters = message.routedInterfaceCounters.map((e) => RoutedInterfaceCounters.toJSON(e));
    }
    if (message.ipmRawLatencyCounters?.length) {
      obj.ipmRawLatencyCounters = message.ipmRawLatencyCounters.map((e) => IpmRawLatencyCounters.toJSON(e));
    }
    if (message.ipmProcLatencyCounters?.length) {
      obj.ipmProcLatencyCounters = message.ipmProcLatencyCounters.map((e) => IpmProcLatencyCounters.toJSON(e));
    }
    if (message.ipmProcLossCounters?.length) {
      obj.ipmProcLossCounters = message.ipmProcLossCounters.map((e) => IpmProcLossCounters.toJSON(e));
    }
    if (message.ipmLivenessCounters?.length) {
      obj.ipmLivenessCounters = message.ipmLivenessCounters.map((e) => IpmLivenessCounters.toJSON(e));
    }
    if (message.ipmSessionId?.length) {
      obj.ipmSessionId = message.ipmSessionId.map((e) => IpmSessionId.toJSON(e));
    }
    if (message.ipmProcJitterCounters?.length) {
      obj.ipmProcJitterCounters = message.ipmProcJitterCounters.map((e) => IpmProcJitterCounters.toJSON(e));
    }
    if (message.systemStats?.length) {
      obj.systemStats = message.systemStats.map((e) => SystemStats.toJSON(e));
    }
    if (message.testRootOne?.length) {
      obj.testRootOne = message.testRootOne.map((e) => TestRootOne.toJSON(e));
    }
    if (message.testRootTwo?.length) {
      obj.testRootTwo = message.testRootTwo.map((e) => TestRootTwo.toJSON(e));
    }
    if (message.testMidOne?.length) {
      obj.testMidOne = message.testMidOne.map((e) => TestMidOne.toJSON(e));
    }
    if (message.testLeafOne?.length) {
      obj.testLeafOne = message.testLeafOne.map((e) => TestLeafOne.toJSON(e));
    }
    if (message.testIpAddress?.length) {
      obj.testIpAddress = message.testIpAddress.map((e) => TestIpAddress.toJSON(e));
    }
    if (message.testIpPrefix?.length) {
      obj.testIpPrefix = message.testIpPrefix.map((e) => TestIpPrefix.toJSON(e));
    }
    if (message.testMacAddress?.length) {
      obj.testMacAddress = message.testMacAddress.map((e) => TestMacAddress.toJSON(e));
    }
    if (message.assertConfigDeviceResourceUsageBelowThreshold?.length) {
      obj.assertConfigDeviceResourceUsageBelowThreshold = message.assertConfigDeviceResourceUsageBelowThreshold.map(
        (e) => AssertConfigDeviceResourceUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertDeviceResourceUsageBelowThreshold?.length) {
      obj.assertDeviceResourceUsageBelowThreshold = message.assertDeviceResourceUsageBelowThreshold.map((e) =>
        AssertDeviceResourceUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.fabricTransaction?.length) {
      obj.fabricTransaction = message.fabricTransaction.map((e) => FabricTransaction.toJSON(e));
    }
    if (message.bgpNeighborSummary?.length) {
      obj.bgpNeighborSummary = message.bgpNeighborSummary.map((e) => BgpNeighborSummary.toJSON(e));
    }
    if (message.bmcConfig?.length) {
      obj.bmcConfig = message.bmcConfig.map((e) => BmcConfig.toJSON(e));
    }
    if (message.deviceConnection?.length) {
      obj.deviceConnection = message.deviceConnection.map((e) => DeviceConnection.toJSON(e));
    }
    if (message.bgpNeighborFamily?.length) {
      obj.bgpNeighborFamily = message.bgpNeighborFamily.map((e) => BgpNeighborFamily.toJSON(e));
    }
    if (message.assertConfigBgpPeerEstablished?.length) {
      obj.assertConfigBgpPeerEstablished = message.assertConfigBgpPeerEstablished.map((e) =>
        AssertConfigBgpPeerEstablished.toJSON(e)
      );
    }
    if (message.assertBgpPeerEstablished?.length) {
      obj.assertBgpPeerEstablished = message.assertBgpPeerEstablished.map((e) => AssertBgpPeerEstablished.toJSON(e));
    }
    if (message.spyglassSshSessionEntry?.length) {
      obj.spyglassSshSessionEntry = message.spyglassSshSessionEntry.map((e) => SpyglassSshSessionEntry.toJSON(e));
    }
    if (message.portChannelMemberState?.length) {
      obj.portChannelMemberState = message.portChannelMemberState.map((e) => PortChannelMemberState.toJSON(e));
    }
    if (message.portChannelState?.length) {
      obj.portChannelState = message.portChannelState.map((e) => PortChannelState.toJSON(e));
    }
    if (message.assertConfigIpmLatencyBelowThreshold?.length) {
      obj.assertConfigIpmLatencyBelowThreshold = message.assertConfigIpmLatencyBelowThreshold.map((e) =>
        AssertConfigIpmLatencyBelowThreshold.toJSON(e)
      );
    }
    if (message.assertIpmLatencyBelowThreshold?.length) {
      obj.assertIpmLatencyBelowThreshold = message.assertIpmLatencyBelowThreshold.map((e) =>
        AssertIpmLatencyBelowThreshold.toJSON(e)
      );
    }
    if (message.assertConfigIpmLossBelowThreshold?.length) {
      obj.assertConfigIpmLossBelowThreshold = message.assertConfigIpmLossBelowThreshold.map((e) =>
        AssertConfigIpmLossBelowThreshold.toJSON(e)
      );
    }
    if (message.assertIpmLossBelowThreshold?.length) {
      obj.assertIpmLossBelowThreshold = message.assertIpmLossBelowThreshold.map((e) =>
        AssertIpmLossBelowThreshold.toJSON(e)
      );
    }
    if (message.spyglassDevice?.length) {
      obj.spyglassDevice = message.spyglassDevice.map((e) => SpyglassDevice.toJSON(e));
    }
    if (message.spyglassTcpListener?.length) {
      obj.spyglassTcpListener = message.spyglassTcpListener.map((e) => SpyglassTcpListener.toJSON(e));
    }
    if (message.spyglassTcpSession?.length) {
      obj.spyglassTcpSession = message.spyglassTcpSession.map((e) => SpyglassTcpSession.toJSON(e));
    }
    if (message.unknownVlan?.length) {
      obj.unknownVlan = message.unknownVlan.map((e) => UnknownVlan.toJSON(e));
    }
    if (message.unknownVlanPort?.length) {
      obj.unknownVlanPort = message.unknownVlanPort.map((e) => UnknownVlanPort.toJSON(e));
    }
    if (message.assertConfigExpectedPortBreakoutExists?.length) {
      obj.assertConfigExpectedPortBreakoutExists = message.assertConfigExpectedPortBreakoutExists.map((e) =>
        AssertConfigExpectedPortBreakoutExists.toJSON(e)
      );
    }
    if (message.assertExpectedPortBreakoutExists?.length) {
      obj.assertExpectedPortBreakoutExists = message.assertExpectedPortBreakoutExists.map((e) =>
        AssertExpectedPortBreakoutExists.toJSON(e)
      );
    }
    if (message.assertConfigSubInterfaceUp?.length) {
      obj.assertConfigSubInterfaceUp = message.assertConfigSubInterfaceUp.map((e) =>
        AssertConfigSubInterfaceUp.toJSON(e)
      );
    }
    if (message.assertSubInterfaceUp?.length) {
      obj.assertSubInterfaceUp = message.assertSubInterfaceUp.map((e) => AssertSubInterfaceUp.toJSON(e));
    }
    if (message.assertSubInterfaceDown?.length) {
      obj.assertSubInterfaceDown = message.assertSubInterfaceDown.map((e) => AssertSubInterfaceDown.toJSON(e));
    }
    if (message.assertConfigSubInterfaceDown?.length) {
      obj.assertConfigSubInterfaceDown = message.assertConfigSubInterfaceDown.map((e) =>
        AssertConfigSubInterfaceDown.toJSON(e)
      );
    }
    if (message.deviceClaim?.length) {
      obj.deviceClaim = message.deviceClaim.map((e) => DeviceClaim.toJSON(e));
    }
    if (message.aggregatedDeviceClaim?.length) {
      obj.aggregatedDeviceClaim = message.aggregatedDeviceClaim.map((e) => AggregatedDeviceClaim.toJSON(e));
    }
    if (message.assertPortChannelMemberUp?.length) {
      obj.assertPortChannelMemberUp = message.assertPortChannelMemberUp.map((e) => AssertPortChannelMemberUp.toJSON(e));
    }
    if (message.assertConfigPortChannelMemberUp?.length) {
      obj.assertConfigPortChannelMemberUp = message.assertConfigPortChannelMemberUp.map((e) =>
        AssertConfigPortChannelMemberUp.toJSON(e)
      );
    }
    if (message.esiInfo?.length) {
      obj.esiInfo = message.esiInfo.map((e) => EsiInfo.toJSON(e));
    }
    if (message.esiVtepInfo?.length) {
      obj.esiVtepInfo = message.esiVtepInfo.map((e) => EsiVtepInfo.toJSON(e));
    }
    if (message.assertPortVlanStpStateConsistent?.length) {
      obj.assertPortVlanStpStateConsistent = message.assertPortVlanStpStateConsistent.map((e) =>
        AssertPortVlanStpStateConsistent.toJSON(e)
      );
    }
    if (message.assertConfigPortVlanStpStateConsistent?.length) {
      obj.assertConfigPortVlanStpStateConsistent = message.assertConfigPortVlanStpStateConsistent.map((e) =>
        AssertConfigPortVlanStpStateConsistent.toJSON(e)
      );
    }
    if (message.assertPortPluggablePidSame?.length) {
      obj.assertPortPluggablePidSame = message.assertPortPluggablePidSame.map((e) =>
        AssertPortPluggablePidSame.toJSON(e)
      );
    }
    if (message.assertConfigPortPluggablePidSame?.length) {
      obj.assertConfigPortPluggablePidSame = message.assertConfigPortPluggablePidSame.map((e) =>
        AssertConfigPortPluggablePidSame.toJSON(e)
      );
    }
    if (message.assertConfigIpmJitterBelowThreshold?.length) {
      obj.assertConfigIpmJitterBelowThreshold = message.assertConfigIpmJitterBelowThreshold.map((e) =>
        AssertConfigIpmJitterBelowThreshold.toJSON(e)
      );
    }
    if (message.assertIpmJitterBelowThreshold?.length) {
      obj.assertIpmJitterBelowThreshold = message.assertIpmJitterBelowThreshold.map((e) =>
        AssertIpmJitterBelowThreshold.toJSON(e)
      );
    }
    if (message.manifestImageState?.length) {
      obj.manifestImageState = message.manifestImageState.map((e) => ManifestImageState.toJSON(e));
    }
    if (message.orgUuid?.length) {
      obj.orgUuid = message.orgUuid.map((e) => OrgUuid.toJSON(e));
    }
    if (message.fabricUuid?.length) {
      obj.fabricUuid = message.fabricUuid.map((e) => FabricUuid.toJSON(e));
    }
    if (message.orgServiceProto?.length) {
      obj.orgServiceProto = message.orgServiceProto.map((e) => OrgServiceProto.toJSON(e));
    }
    if (message.orgServiceSvc?.length) {
      obj.orgServiceSvc = message.orgServiceSvc.map((e) => OrgServiceSvc.toJSON(e));
    }
    if (message.orgServiceRoute?.length) {
      obj.orgServiceRoute = message.orgServiceRoute.map((e) => OrgServiceRoute.toJSON(e));
    }
    if (message.apiPath?.length) {
      obj.apiPath = message.apiPath.map((e) => ApiPath.toJSON(e));
    }
    if (message.apiPathMethod?.length) {
      obj.apiPathMethod = message.apiPathMethod.map((e) => ApiPathMethod.toJSON(e));
    }
    if (message.apiServiceMapping?.length) {
      obj.apiServiceMapping = message.apiServiceMapping.map((e) => ApiServiceMapping.toJSON(e));
    }
    if (message.orgToCellPairMapping?.length) {
      obj.orgToCellPairMapping = message.orgToCellPairMapping.map((e) => OrgToCellPairMapping.toJSON(e));
    }
    if (message.tenantUuid?.length) {
      obj.tenantUuid = message.tenantUuid.map((e) => TenantUuid.toJSON(e));
    }
    if (message.userEmail?.length) {
      obj.userEmail = message.userEmail.map((e) => UserEmail.toJSON(e));
    }
    if (message.managementConfig?.length) {
      obj.managementConfig = message.managementConfig.map((e) => ManagementConfig.toJSON(e));
    }
    if (message.serviceInstanceHealth?.length) {
      obj.serviceInstanceHealth = message.serviceInstanceHealth.map((e) => ServiceInstanceHealth.toJSON(e));
    }
    if (message.assignmentStatus?.length) {
      obj.assignmentStatus = message.assignmentStatus.map((e) => AssignmentStatus.toJSON(e));
    }
    if (message.kubernetesServiceInstance?.length) {
      obj.kubernetesServiceInstance = message.kubernetesServiceInstance.map((e) => KubernetesServiceInstance.toJSON(e));
    }
    if (message.bearerToken?.length) {
      obj.bearerToken = message.bearerToken.map((e) => BearerToken.toJSON(e));
    }
    if (message.apiKey?.length) {
      obj.apiKey = message.apiKey.map((e) => ApiKey.toJSON(e));
    }
    if (message.fabricMemoryUsage?.length) {
      obj.fabricMemoryUsage = message.fabricMemoryUsage.map((e) => FabricMemoryUsage.toJSON(e));
    }
    if (message.typeMemoryUsage?.length) {
      obj.typeMemoryUsage = message.typeMemoryUsage.map((e) => TypeMemoryUsage.toJSON(e));
    }
    if (message.manifestRunningImageStatus?.length) {
      obj.manifestRunningImageStatus = message.manifestRunningImageStatus.map((e) =>
        ManifestRunningImageStatus.toJSON(e)
      );
    }
    if (message.manifestPartitionFileCacheStatus?.length) {
      obj.manifestPartitionFileCacheStatus = message.manifestPartitionFileCacheStatus.map((e) =>
        ManifestPartitionFileCacheStatus.toJSON(e)
      );
    }
    if (message.stpPortState?.length) {
      obj.stpPortState = message.stpPortState.map((e) => StpPortState.toJSON(e));
    }
    if (message.stpVlanState?.length) {
      obj.stpVlanState = message.stpVlanState.map((e) => StpVlanState.toJSON(e));
    }
    if (message.stpVlanPortState?.length) {
      obj.stpVlanPortState = message.stpVlanPortState.map((e) => StpVlanPortState.toJSON(e));
    }
    if (message.assertDeviceEnvPsuUsageBelowThreshold?.length) {
      obj.assertDeviceEnvPsuUsageBelowThreshold = message.assertDeviceEnvPsuUsageBelowThreshold.map((e) =>
        AssertDeviceEnvPsuUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertDeviceEnvFanUsageBelowThreshold?.length) {
      obj.assertDeviceEnvFanUsageBelowThreshold = message.assertDeviceEnvFanUsageBelowThreshold.map((e) =>
        AssertDeviceEnvFanUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertDeviceEnvTempSensorUsageBelowThreshold?.length) {
      obj.assertDeviceEnvTempSensorUsageBelowThreshold = message.assertDeviceEnvTempSensorUsageBelowThreshold.map((e) =>
        AssertDeviceEnvTempSensorUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertConfigDeviceEnvPsuUsageBelowThreshold?.length) {
      obj.assertConfigDeviceEnvPsuUsageBelowThreshold = message.assertConfigDeviceEnvPsuUsageBelowThreshold.map((e) =>
        AssertConfigDeviceEnvPsuUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertConfigDeviceEnvFanUsageBelowThreshold?.length) {
      obj.assertConfigDeviceEnvFanUsageBelowThreshold = message.assertConfigDeviceEnvFanUsageBelowThreshold.map((e) =>
        AssertConfigDeviceEnvFanUsageBelowThreshold.toJSON(e)
      );
    }
    if (message.assertConfigDeviceEnvTempSensorUsageBelowThreshold?.length) {
      obj.assertConfigDeviceEnvTempSensorUsageBelowThreshold = message
        .assertConfigDeviceEnvTempSensorUsageBelowThreshold.map((e) =>
          AssertConfigDeviceEnvTempSensorUsageBelowThreshold.toJSON(e)
        );
    }
    if (message.unpackAndAssembleTask?.length) {
      obj.unpackAndAssembleTask = message.unpackAndAssembleTask.map((e) => UnpackAndAssembleTask.toJSON(e));
    }
    if (message.rackAndPowerOnTask?.length) {
      obj.rackAndPowerOnTask = message.rackAndPowerOnTask.map((e) => RackAndPowerOnTask.toJSON(e));
    }
    if (message.claimAndBindTask?.length) {
      obj.claimAndBindTask = message.claimAndBindTask.map((e) => ClaimAndBindTask.toJSON(e));
    }
    if (message.portSetupTask?.length) {
      obj.portSetupTask = message.portSetupTask.map((e) => PortSetupTask.toJSON(e));
    }
    if (message.fabricConnectionTask?.length) {
      obj.fabricConnectionTask = message.fabricConnectionTask.map((e) => FabricConnectionTask.toJSON(e));
    }
    if (message.negativeConnectionTask?.length) {
      obj.negativeConnectionTask = message.negativeConnectionTask.map((e) => NegativeConnectionTask.toJSON(e));
    }
    if (message.application?.length) {
      obj.application = message.application.map((e) => Application.toJSON(e));
    }
    if (message.resourceAssignment?.length) {
      obj.resourceAssignment = message.resourceAssignment.map((e) => ResourceAssignment.toJSON(e));
    }
    if (message.node?.length) {
      obj.node = message.node.map((e) => Node.toJSON(e));
    }
    if (message.nodeLineCard?.length) {
      obj.nodeLineCard = message.nodeLineCard.map((e) => NodeLineCard.toJSON(e));
    }
    if (message.nodePort?.length) {
      obj.nodePort = message.nodePort.map((e) => NodePort.toJSON(e));
    }
    if (message.nodeBreakout?.length) {
      obj.nodeBreakout = message.nodeBreakout.map((e) => NodeBreakout.toJSON(e));
    }
    if (message.tenantMetadata?.length) {
      obj.tenantMetadata = message.tenantMetadata.map((e) => TenantMetadata.toJSON(e));
    }
    if (message.assertPortNoUnknownVlanFound?.length) {
      obj.assertPortNoUnknownVlanFound = message.assertPortNoUnknownVlanFound.map((e) =>
        AssertPortNoUnknownVlanFound.toJSON(e)
      );
    }
    if (message.assertConfigPortNoUnknownVlanFound?.length) {
      obj.assertConfigPortNoUnknownVlanFound = message.assertConfigPortNoUnknownVlanFound.map((e) =>
        AssertConfigPortNoUnknownVlanFound.toJSON(e)
      );
    }
    if (message.bulkUpliftJobRequest?.length) {
      obj.bulkUpliftJobRequest = message.bulkUpliftJobRequest.map((e) => BulkUpliftJobRequest.toJSON(e));
    }
    if (message.bulkUpliftJobStatus?.length) {
      obj.bulkUpliftJobStatus = message.bulkUpliftJobStatus.map((e) => BulkUpliftJobStatus.toJSON(e));
    }
    if (message.bgpNeighborInfo?.length) {
      obj.bgpNeighborInfo = message.bgpNeighborInfo.map((e) => BgpNeighborInfo.toJSON(e));
    }
    if (message.refPoint?.length) {
      obj.refPoint = message.refPoint.map((e) => RefPoint.toJSON(e));
    }
    if (message.refPointStatus?.length) {
      obj.refPointStatus = message.refPointStatus.map((e) => RefPointStatus.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataSeries>, I>>(base?: I): DataSeries {
    return DataSeries.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataSeries>, I>>(object: I): DataSeries {
    const message = createBaseDataSeries();
    message.objectName = object.objectName ?? "";
    message.objectType = object.objectType ?? 0;
    message.offset = object.offset ?? 0;
    message.instance = object.instance ?? 0;
    message.fabric = object.fabric?.map((e) => Fabric.fromPartial(e)) || [];
    message.device = object.device?.map((e) => Device.fromPartial(e)) || [];
    message.fan = object.fan?.map((e) => Fan.fromPartial(e)) || [];
    message.psu = object.psu?.map((e) => Psu.fromPartial(e)) || [];
    message.tempSensor = object.tempSensor?.map((e) => TempSensor.fromPartial(e)) || [];
    message.lineCard = object.lineCard?.map((e) => LineCard.fromPartial(e)) || [];
    message.port = object.port?.map((e) => Port.fromPartial(e)) || [];
    message.dacQsfp = object.dacQsfp?.map((e) => DacQsfp.fromPartial(e)) || [];
    message.fiberQsfp = object.fiberQsfp?.map((e) => FiberQsfp.fromPartial(e)) || [];
    message.breakout = object.breakout?.map((e) => Breakout.fromPartial(e)) || [];
    message.portNeighbor = object.portNeighbor?.map((e) => PortNeighbor.fromPartial(e)) || [];
    message.vtep = object.vtep?.map((e) => Vtep.fromPartial(e)) || [];
    message.vniPort = object.vniPort?.map((e) => VniPort.fromPartial(e)) || [];
    message.mac = object.mac?.map((e) => Mac.fromPartial(e)) || [];
    message.procTable = object.procTable?.map((e) => ProcTable.fromPartial(e)) || [];
    message.portVlan = object.portVlan?.map((e) => PortVlan.fromPartial(e)) || [];
    message.vlanState = object.vlanState?.map((e) => VlanState.fromPartial(e)) || [];
    message.qsfpDomSensor = object.qsfpDomSensor?.map((e) => QsfpDomSensor.fromPartial(e)) || [];
    message.portConfig = object.portConfig?.map((e) => PortConfig.fromPartial(e)) || [];
    message.nodeConfig = object.nodeConfig?.map((e) => NodeConfig.fromPartial(e)) || [];
    message.fabricConfig = object.fabricConfig?.map((e) => FabricConfig.fromPartial(e)) || [];
    message.fabricConfigEvent = object.fabricConfigEvent?.map((e) => FabricConfigEvent.fromPartial(e)) || [];
    message.l2Fdb = object.l2Fdb?.map((e) => L2Fdb.fromPartial(e)) || [];
    message.portCounters = object.portCounters?.map((e) => PortCounters.fromPartial(e)) || [];
    message.remedyAction = object.remedyAction?.map((e) => RemedyAction.fromPartial(e)) || [];
    message.qsfpInfo = object.qsfpInfo?.map((e) => QsfpInfo.fromPartial(e)) || [];
    message.fabricDevice = object.fabricDevice?.map((e) => FabricDevice.fromPartial(e)) || [];
    message.assertConfigPortLinkUp = object.assertConfigPortLinkUp?.map((e) => AssertConfigPortLinkUp.fromPartial(e)) ||
      [];
    message.assertPortLinkUp = object.assertPortLinkUp?.map((e) => AssertPortLinkUp.fromPartial(e)) || [];
    message.assertConfigDeviceConnectedToCloud =
      object.assertConfigDeviceConnectedToCloud?.map((e) => AssertConfigDeviceConnectedToCloud.fromPartial(e)) || [];
    message.assertDeviceConnectedToCloud =
      object.assertDeviceConnectedToCloud?.map((e) => AssertDeviceConnectedToCloud.fromPartial(e)) || [];
    message.assertConfigPortExpectedNeighbor =
      object.assertConfigPortExpectedNeighbor?.map((e) => AssertConfigPortExpectedNeighbor.fromPartial(e)) || [];
    message.assertPortExpectedNeighbor =
      object.assertPortExpectedNeighbor?.map((e) => AssertPortExpectedNeighbor.fromPartial(e)) || [];
    message.assertConfigPortNotConnectedToFabric =
      object.assertConfigPortNotConnectedToFabric?.map((e) => AssertConfigPortNotConnectedToFabric.fromPartial(e)) ||
      [];
    message.assertPortNotConnectedToFabric =
      object.assertPortNotConnectedToFabric?.map((e) => AssertPortNotConnectedToFabric.fromPartial(e)) || [];
    message.assertConfigDeviceConnectedToFabric =
      object.assertConfigDeviceConnectedToFabric?.map((e) => AssertConfigDeviceConnectedToFabric.fromPartial(e)) || [];
    message.assertDeviceConnectedToFabric =
      object.assertDeviceConnectedToFabric?.map((e) => AssertDeviceConnectedToFabric.fromPartial(e)) || [];
    message.assertConfigPortLinkDown =
      object.assertConfigPortLinkDown?.map((e) => AssertConfigPortLinkDown.fromPartial(e)) || [];
    message.assertPortLinkDown = object.assertPortLinkDown?.map((e) => AssertPortLinkDown.fromPartial(e)) || [];
    message.assertConfigPortConnectionSpeedMatch =
      object.assertConfigPortConnectionSpeedMatch?.map((e) => AssertConfigPortConnectionSpeedMatch.fromPartial(e)) ||
      [];
    message.assertPortConnectionSpeedMatch =
      object.assertPortConnectionSpeedMatch?.map((e) => AssertPortConnectionSpeedMatch.fromPartial(e)) || [];
    message.assertConfigPortSpeedConsistent =
      object.assertConfigPortSpeedConsistent?.map((e) => AssertConfigPortSpeedConsistent.fromPartial(e)) || [];
    message.assertPortSpeedConsistent =
      object.assertPortSpeedConsistent?.map((e) => AssertPortSpeedConsistent.fromPartial(e)) || [];
    message.underlayInfo = object.underlayInfo?.map((e) => UnderlayInfo.fromPartial(e)) || [];
    message.proxyStats = object.proxyStats?.map((e) => ProxyStats.fromPartial(e)) || [];
    message.vlanCounters = object.vlanCounters?.map((e) => VlanCounters.fromPartial(e)) || [];
    message.testPlaceHolder1Secret = object.testPlaceHolder1Secret?.map((e) => TestPlaceHolder1Secret.fromPartial(e)) ||
      [];
    message.assertConfigVlanHasTraffic =
      object.assertConfigVlanHasTraffic?.map((e) => AssertConfigVlanHasTraffic.fromPartial(e)) || [];
    message.assertVlanHasTraffic = object.assertVlanHasTraffic?.map((e) => AssertVlanHasTraffic.fromPartial(e)) || [];
    message.remoteVtep = object.remoteVtep?.map((e) => RemoteVtep.fromPartial(e)) || [];
    message.qsfpDomThreshold = object.qsfpDomThreshold?.map((e) => QsfpDomThreshold.fromPartial(e)) || [];
    message.l3Table = object.l3Table?.map((e) => L3Table.fromPartial(e)) || [];
    message.vlanVniMap = object.vlanVniMap?.map((e) => VlanVniMap.fromPartial(e)) || [];
    message.proxyConnectionState = object.proxyConnectionState?.map((e) => ProxyConnectionState.fromPartial(e)) || [];
    message.proxyConnectionStats = object.proxyConnectionStats?.map((e) => ProxyConnectionStats.fromPartial(e)) || [];
    message.l2Mac = object.l2Mac?.map((e) => L2Mac.fromPartial(e)) || [];
    message.bmc = object.bmc?.map((e) => Bmc.fromPartial(e)) || [];
    message.smartNicDpu = object.smartNicDpu?.map((e) => SmartNicDpu.fromPartial(e)) || [];
    message.serverVoltageSensor = object.serverVoltageSensor?.map((e) => ServerVoltageSensor.fromPartial(e)) || [];
    message.serverTemperatureSensor =
      object.serverTemperatureSensor?.map((e) => ServerTemperatureSensor.fromPartial(e)) || [];
    message.ipRouteTable = object.ipRouteTable?.map((e) => IpRouteTable.fromPartial(e)) || [];
    message.vrf = object.vrf?.map((e) => Vrf.fromPartial(e)) || [];
    message.ipNextHop = object.ipNextHop?.map((e) => IpNextHop.fromPartial(e)) || [];
    message.ipPrefix = object.ipPrefix?.map((e) => IpPrefix.fromPartial(e)) || [];
    message.subInterface = object.subInterface?.map((e) => SubInterface.fromPartial(e)) || [];
    message.assertConfigStaticDefaultRouteExists =
      object.assertConfigStaticDefaultRouteExists?.map((e) => AssertConfigStaticDefaultRouteExists.fromPartial(e)) ||
      [];
    message.assertStaticDefaultRouteExists =
      object.assertStaticDefaultRouteExists?.map((e) => AssertStaticDefaultRouteExists.fromPartial(e)) || [];
    message.spyglassBrokerEntry = object.spyglassBrokerEntry?.map((e) => SpyglassBrokerEntry.fromPartial(e)) || [];
    message.managementState = object.managementState?.map((e) => ManagementState.fromPartial(e)) || [];
    message.assertConfigManagementPortConfigSame =
      object.assertConfigManagementPortConfigSame?.map((e) => AssertConfigManagementPortConfigSame.fromPartial(e)) ||
      [];
    message.assertManagementPortConfigSame =
      object.assertManagementPortConfigSame?.map((e) => AssertManagementPortConfigSame.fromPartial(e)) || [];
    message.fabricConfigNotification =
      object.fabricConfigNotification?.map((e) => FabricConfigNotification.fromPartial(e)) || [];
    message.spyglassGingerEntry = object.spyglassGingerEntry?.map((e) => SpyglassGingerEntry.fromPartial(e)) || [];
    message.plannedConfig = object.plannedConfig?.map((e) => PlannedConfig.fromPartial(e)) || [];
    message.routedInterfaceCounters =
      object.routedInterfaceCounters?.map((e) => RoutedInterfaceCounters.fromPartial(e)) || [];
    message.ipmRawLatencyCounters = object.ipmRawLatencyCounters?.map((e) => IpmRawLatencyCounters.fromPartial(e)) ||
      [];
    message.ipmProcLatencyCounters = object.ipmProcLatencyCounters?.map((e) => IpmProcLatencyCounters.fromPartial(e)) ||
      [];
    message.ipmProcLossCounters = object.ipmProcLossCounters?.map((e) => IpmProcLossCounters.fromPartial(e)) || [];
    message.ipmLivenessCounters = object.ipmLivenessCounters?.map((e) => IpmLivenessCounters.fromPartial(e)) || [];
    message.ipmSessionId = object.ipmSessionId?.map((e) => IpmSessionId.fromPartial(e)) || [];
    message.ipmProcJitterCounters = object.ipmProcJitterCounters?.map((e) => IpmProcJitterCounters.fromPartial(e)) ||
      [];
    message.systemStats = object.systemStats?.map((e) => SystemStats.fromPartial(e)) || [];
    message.testRootOne = object.testRootOne?.map((e) => TestRootOne.fromPartial(e)) || [];
    message.testRootTwo = object.testRootTwo?.map((e) => TestRootTwo.fromPartial(e)) || [];
    message.testMidOne = object.testMidOne?.map((e) => TestMidOne.fromPartial(e)) || [];
    message.testLeafOne = object.testLeafOne?.map((e) => TestLeafOne.fromPartial(e)) || [];
    message.testIpAddress = object.testIpAddress?.map((e) => TestIpAddress.fromPartial(e)) || [];
    message.testIpPrefix = object.testIpPrefix?.map((e) => TestIpPrefix.fromPartial(e)) || [];
    message.testMacAddress = object.testMacAddress?.map((e) => TestMacAddress.fromPartial(e)) || [];
    message.assertConfigDeviceResourceUsageBelowThreshold =
      object.assertConfigDeviceResourceUsageBelowThreshold?.map((e) =>
        AssertConfigDeviceResourceUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.assertDeviceResourceUsageBelowThreshold =
      object.assertDeviceResourceUsageBelowThreshold?.map((e) =>
        AssertDeviceResourceUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.fabricTransaction = object.fabricTransaction?.map((e) => FabricTransaction.fromPartial(e)) || [];
    message.bgpNeighborSummary = object.bgpNeighborSummary?.map((e) => BgpNeighborSummary.fromPartial(e)) || [];
    message.bmcConfig = object.bmcConfig?.map((e) => BmcConfig.fromPartial(e)) || [];
    message.deviceConnection = object.deviceConnection?.map((e) => DeviceConnection.fromPartial(e)) || [];
    message.bgpNeighborFamily = object.bgpNeighborFamily?.map((e) => BgpNeighborFamily.fromPartial(e)) || [];
    message.assertConfigBgpPeerEstablished =
      object.assertConfigBgpPeerEstablished?.map((e) => AssertConfigBgpPeerEstablished.fromPartial(e)) || [];
    message.assertBgpPeerEstablished =
      object.assertBgpPeerEstablished?.map((e) => AssertBgpPeerEstablished.fromPartial(e)) || [];
    message.spyglassSshSessionEntry =
      object.spyglassSshSessionEntry?.map((e) => SpyglassSshSessionEntry.fromPartial(e)) || [];
    message.portChannelMemberState = object.portChannelMemberState?.map((e) => PortChannelMemberState.fromPartial(e)) ||
      [];
    message.portChannelState = object.portChannelState?.map((e) => PortChannelState.fromPartial(e)) || [];
    message.assertConfigIpmLatencyBelowThreshold =
      object.assertConfigIpmLatencyBelowThreshold?.map((e) => AssertConfigIpmLatencyBelowThreshold.fromPartial(e)) ||
      [];
    message.assertIpmLatencyBelowThreshold =
      object.assertIpmLatencyBelowThreshold?.map((e) => AssertIpmLatencyBelowThreshold.fromPartial(e)) || [];
    message.assertConfigIpmLossBelowThreshold =
      object.assertConfigIpmLossBelowThreshold?.map((e) => AssertConfigIpmLossBelowThreshold.fromPartial(e)) || [];
    message.assertIpmLossBelowThreshold =
      object.assertIpmLossBelowThreshold?.map((e) => AssertIpmLossBelowThreshold.fromPartial(e)) || [];
    message.spyglassDevice = object.spyglassDevice?.map((e) => SpyglassDevice.fromPartial(e)) || [];
    message.spyglassTcpListener = object.spyglassTcpListener?.map((e) => SpyglassTcpListener.fromPartial(e)) || [];
    message.spyglassTcpSession = object.spyglassTcpSession?.map((e) => SpyglassTcpSession.fromPartial(e)) || [];
    message.unknownVlan = object.unknownVlan?.map((e) => UnknownVlan.fromPartial(e)) || [];
    message.unknownVlanPort = object.unknownVlanPort?.map((e) => UnknownVlanPort.fromPartial(e)) || [];
    message.assertConfigExpectedPortBreakoutExists =
      object.assertConfigExpectedPortBreakoutExists?.map((e) =>
        AssertConfigExpectedPortBreakoutExists.fromPartial(e)
      ) || [];
    message.assertExpectedPortBreakoutExists =
      object.assertExpectedPortBreakoutExists?.map((e) => AssertExpectedPortBreakoutExists.fromPartial(e)) || [];
    message.assertConfigSubInterfaceUp =
      object.assertConfigSubInterfaceUp?.map((e) => AssertConfigSubInterfaceUp.fromPartial(e)) || [];
    message.assertSubInterfaceUp = object.assertSubInterfaceUp?.map((e) => AssertSubInterfaceUp.fromPartial(e)) || [];
    message.assertSubInterfaceDown = object.assertSubInterfaceDown?.map((e) => AssertSubInterfaceDown.fromPartial(e)) ||
      [];
    message.assertConfigSubInterfaceDown =
      object.assertConfigSubInterfaceDown?.map((e) => AssertConfigSubInterfaceDown.fromPartial(e)) || [];
    message.deviceClaim = object.deviceClaim?.map((e) => DeviceClaim.fromPartial(e)) || [];
    message.aggregatedDeviceClaim = object.aggregatedDeviceClaim?.map((e) => AggregatedDeviceClaim.fromPartial(e)) ||
      [];
    message.assertPortChannelMemberUp =
      object.assertPortChannelMemberUp?.map((e) => AssertPortChannelMemberUp.fromPartial(e)) || [];
    message.assertConfigPortChannelMemberUp =
      object.assertConfigPortChannelMemberUp?.map((e) => AssertConfigPortChannelMemberUp.fromPartial(e)) || [];
    message.esiInfo = object.esiInfo?.map((e) => EsiInfo.fromPartial(e)) || [];
    message.esiVtepInfo = object.esiVtepInfo?.map((e) => EsiVtepInfo.fromPartial(e)) || [];
    message.assertPortVlanStpStateConsistent =
      object.assertPortVlanStpStateConsistent?.map((e) => AssertPortVlanStpStateConsistent.fromPartial(e)) || [];
    message.assertConfigPortVlanStpStateConsistent =
      object.assertConfigPortVlanStpStateConsistent?.map((e) =>
        AssertConfigPortVlanStpStateConsistent.fromPartial(e)
      ) || [];
    message.assertPortPluggablePidSame =
      object.assertPortPluggablePidSame?.map((e) => AssertPortPluggablePidSame.fromPartial(e)) || [];
    message.assertConfigPortPluggablePidSame =
      object.assertConfigPortPluggablePidSame?.map((e) => AssertConfigPortPluggablePidSame.fromPartial(e)) || [];
    message.assertConfigIpmJitterBelowThreshold =
      object.assertConfigIpmJitterBelowThreshold?.map((e) => AssertConfigIpmJitterBelowThreshold.fromPartial(e)) || [];
    message.assertIpmJitterBelowThreshold =
      object.assertIpmJitterBelowThreshold?.map((e) => AssertIpmJitterBelowThreshold.fromPartial(e)) || [];
    message.manifestImageState = object.manifestImageState?.map((e) => ManifestImageState.fromPartial(e)) || [];
    message.orgUuid = object.orgUuid?.map((e) => OrgUuid.fromPartial(e)) || [];
    message.fabricUuid = object.fabricUuid?.map((e) => FabricUuid.fromPartial(e)) || [];
    message.orgServiceProto = object.orgServiceProto?.map((e) => OrgServiceProto.fromPartial(e)) || [];
    message.orgServiceSvc = object.orgServiceSvc?.map((e) => OrgServiceSvc.fromPartial(e)) || [];
    message.orgServiceRoute = object.orgServiceRoute?.map((e) => OrgServiceRoute.fromPartial(e)) || [];
    message.apiPath = object.apiPath?.map((e) => ApiPath.fromPartial(e)) || [];
    message.apiPathMethod = object.apiPathMethod?.map((e) => ApiPathMethod.fromPartial(e)) || [];
    message.apiServiceMapping = object.apiServiceMapping?.map((e) => ApiServiceMapping.fromPartial(e)) || [];
    message.orgToCellPairMapping = object.orgToCellPairMapping?.map((e) => OrgToCellPairMapping.fromPartial(e)) || [];
    message.tenantUuid = object.tenantUuid?.map((e) => TenantUuid.fromPartial(e)) || [];
    message.userEmail = object.userEmail?.map((e) => UserEmail.fromPartial(e)) || [];
    message.managementConfig = object.managementConfig?.map((e) => ManagementConfig.fromPartial(e)) || [];
    message.serviceInstanceHealth = object.serviceInstanceHealth?.map((e) => ServiceInstanceHealth.fromPartial(e)) ||
      [];
    message.assignmentStatus = object.assignmentStatus?.map((e) => AssignmentStatus.fromPartial(e)) || [];
    message.kubernetesServiceInstance =
      object.kubernetesServiceInstance?.map((e) => KubernetesServiceInstance.fromPartial(e)) || [];
    message.bearerToken = object.bearerToken?.map((e) => BearerToken.fromPartial(e)) || [];
    message.apiKey = object.apiKey?.map((e) => ApiKey.fromPartial(e)) || [];
    message.fabricMemoryUsage = object.fabricMemoryUsage?.map((e) => FabricMemoryUsage.fromPartial(e)) || [];
    message.typeMemoryUsage = object.typeMemoryUsage?.map((e) => TypeMemoryUsage.fromPartial(e)) || [];
    message.manifestRunningImageStatus =
      object.manifestRunningImageStatus?.map((e) => ManifestRunningImageStatus.fromPartial(e)) || [];
    message.manifestPartitionFileCacheStatus =
      object.manifestPartitionFileCacheStatus?.map((e) => ManifestPartitionFileCacheStatus.fromPartial(e)) || [];
    message.stpPortState = object.stpPortState?.map((e) => StpPortState.fromPartial(e)) || [];
    message.stpVlanState = object.stpVlanState?.map((e) => StpVlanState.fromPartial(e)) || [];
    message.stpVlanPortState = object.stpVlanPortState?.map((e) => StpVlanPortState.fromPartial(e)) || [];
    message.assertDeviceEnvPsuUsageBelowThreshold =
      object.assertDeviceEnvPsuUsageBelowThreshold?.map((e) => AssertDeviceEnvPsuUsageBelowThreshold.fromPartial(e)) ||
      [];
    message.assertDeviceEnvFanUsageBelowThreshold =
      object.assertDeviceEnvFanUsageBelowThreshold?.map((e) => AssertDeviceEnvFanUsageBelowThreshold.fromPartial(e)) ||
      [];
    message.assertDeviceEnvTempSensorUsageBelowThreshold =
      object.assertDeviceEnvTempSensorUsageBelowThreshold?.map((e) =>
        AssertDeviceEnvTempSensorUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.assertConfigDeviceEnvPsuUsageBelowThreshold =
      object.assertConfigDeviceEnvPsuUsageBelowThreshold?.map((e) =>
        AssertConfigDeviceEnvPsuUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.assertConfigDeviceEnvFanUsageBelowThreshold =
      object.assertConfigDeviceEnvFanUsageBelowThreshold?.map((e) =>
        AssertConfigDeviceEnvFanUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.assertConfigDeviceEnvTempSensorUsageBelowThreshold =
      object.assertConfigDeviceEnvTempSensorUsageBelowThreshold?.map((e) =>
        AssertConfigDeviceEnvTempSensorUsageBelowThreshold.fromPartial(e)
      ) || [];
    message.unpackAndAssembleTask = object.unpackAndAssembleTask?.map((e) => UnpackAndAssembleTask.fromPartial(e)) ||
      [];
    message.rackAndPowerOnTask = object.rackAndPowerOnTask?.map((e) => RackAndPowerOnTask.fromPartial(e)) || [];
    message.claimAndBindTask = object.claimAndBindTask?.map((e) => ClaimAndBindTask.fromPartial(e)) || [];
    message.portSetupTask = object.portSetupTask?.map((e) => PortSetupTask.fromPartial(e)) || [];
    message.fabricConnectionTask = object.fabricConnectionTask?.map((e) => FabricConnectionTask.fromPartial(e)) || [];
    message.negativeConnectionTask = object.negativeConnectionTask?.map((e) => NegativeConnectionTask.fromPartial(e)) ||
      [];
    message.application = object.application?.map((e) => Application.fromPartial(e)) || [];
    message.resourceAssignment = object.resourceAssignment?.map((e) => ResourceAssignment.fromPartial(e)) || [];
    message.node = object.node?.map((e) => Node.fromPartial(e)) || [];
    message.nodeLineCard = object.nodeLineCard?.map((e) => NodeLineCard.fromPartial(e)) || [];
    message.nodePort = object.nodePort?.map((e) => NodePort.fromPartial(e)) || [];
    message.nodeBreakout = object.nodeBreakout?.map((e) => NodeBreakout.fromPartial(e)) || [];
    message.tenantMetadata = object.tenantMetadata?.map((e) => TenantMetadata.fromPartial(e)) || [];
    message.assertPortNoUnknownVlanFound =
      object.assertPortNoUnknownVlanFound?.map((e) => AssertPortNoUnknownVlanFound.fromPartial(e)) || [];
    message.assertConfigPortNoUnknownVlanFound =
      object.assertConfigPortNoUnknownVlanFound?.map((e) => AssertConfigPortNoUnknownVlanFound.fromPartial(e)) || [];
    message.bulkUpliftJobRequest = object.bulkUpliftJobRequest?.map((e) => BulkUpliftJobRequest.fromPartial(e)) || [];
    message.bulkUpliftJobStatus = object.bulkUpliftJobStatus?.map((e) => BulkUpliftJobStatus.fromPartial(e)) || [];
    message.bgpNeighborInfo = object.bgpNeighborInfo?.map((e) => BgpNeighborInfo.fromPartial(e)) || [];
    message.refPoint = object.refPoint?.map((e) => RefPoint.fromPartial(e)) || [];
    message.refPointStatus = object.refPointStatus?.map((e) => RefPointStatus.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
