// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/global.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "models";

/** AdminState defines an enumeration for object's administrative states. */
export enum AdminState {
  /** STATE_UNSPECIFIED - Invalid object state. */
  STATE_UNSPECIFIED = 0,
  /** ENABLED - Object is in enabled or active state. */
  ENABLED = 1,
  /** DISABLED - Object is in disabled or suspended state. */
  DISABLED = 2,
  /** DELETED - Object is in deleted state. */
  DELETED = 3,
  /** PENDING - Object is in pending state. */
  PENDING = 4,
  UNRECOGNIZED = -1,
}

export function adminStateFromJSON(object: any): AdminState {
  switch (object) {
    case 0:
    case "STATE_UNSPECIFIED":
      return AdminState.STATE_UNSPECIFIED;
    case 1:
    case "ENABLED":
      return AdminState.ENABLED;
    case 2:
    case "DISABLED":
      return AdminState.DISABLED;
    case 3:
    case "DELETED":
      return AdminState.DELETED;
    case 4:
    case "PENDING":
      return AdminState.PENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdminState.UNRECOGNIZED;
  }
}

export function adminStateToJSON(object: AdminState): string {
  switch (object) {
    case AdminState.STATE_UNSPECIFIED:
      return "STATE_UNSPECIFIED";
    case AdminState.ENABLED:
      return "ENABLED";
    case AdminState.DISABLED:
      return "DISABLED";
    case AdminState.DELETED:
      return "DELETED";
    case AdminState.PENDING:
      return "PENDING";
    case AdminState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** BoolType defines a nullable boolean type. */
export enum BoolType {
  /** BOOL_UNSPECIFIED - Unspecified or undefined boolean. */
  BOOL_UNSPECIFIED = 0,
  /** BOOL_FALSE - Boolean value of false. */
  BOOL_FALSE = 1,
  /** BOOL_TRUE - Boolean value of true. */
  BOOL_TRUE = 2,
  UNRECOGNIZED = -1,
}

export function boolTypeFromJSON(object: any): BoolType {
  switch (object) {
    case 0:
    case "BOOL_UNSPECIFIED":
      return BoolType.BOOL_UNSPECIFIED;
    case 1:
    case "BOOL_FALSE":
      return BoolType.BOOL_FALSE;
    case 2:
    case "BOOL_TRUE":
      return BoolType.BOOL_TRUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BoolType.UNRECOGNIZED;
  }
}

export function boolTypeToJSON(object: BoolType): string {
  switch (object) {
    case BoolType.BOOL_UNSPECIFIED:
      return "BOOL_UNSPECIFIED";
    case BoolType.BOOL_FALSE:
      return "BOOL_FALSE";
    case BoolType.BOOL_TRUE:
      return "BOOL_TRUE";
    case BoolType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** The possible outcomes when attempting to claim a device. */
export enum ClaimStatus {
  /** CLAIM_STATUS_UNSPECIFIED - Unspecified claim error (there was no error). */
  CLAIM_STATUS_UNSPECIFIED = 0,
  /** CLAIM_STATUS_SUCCESS - Successfully claimed device. */
  CLAIM_STATUS_SUCCESS = 1,
  /** CLAIM_STATUS_ALREADY_CLAIMED - Device has been claimed already. */
  CLAIM_STATUS_ALREADY_CLAIMED = 2,
  /** CLAIM_STATUS_INVALID_CODE - The claim code provided is invalid (non-existent, malformed, etc). */
  CLAIM_STATUS_INVALID_CODE = 3,
  /** CLAIM_STATUS_EXPIRED_CODE - Expired claim code. */
  CLAIM_STATUS_EXPIRED_CODE = 4,
  UNRECOGNIZED = -1,
}

export function claimStatusFromJSON(object: any): ClaimStatus {
  switch (object) {
    case 0:
    case "CLAIM_STATUS_UNSPECIFIED":
      return ClaimStatus.CLAIM_STATUS_UNSPECIFIED;
    case 1:
    case "CLAIM_STATUS_SUCCESS":
      return ClaimStatus.CLAIM_STATUS_SUCCESS;
    case 2:
    case "CLAIM_STATUS_ALREADY_CLAIMED":
      return ClaimStatus.CLAIM_STATUS_ALREADY_CLAIMED;
    case 3:
    case "CLAIM_STATUS_INVALID_CODE":
      return ClaimStatus.CLAIM_STATUS_INVALID_CODE;
    case 4:
    case "CLAIM_STATUS_EXPIRED_CODE":
      return ClaimStatus.CLAIM_STATUS_EXPIRED_CODE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClaimStatus.UNRECOGNIZED;
  }
}

export function claimStatusToJSON(object: ClaimStatus): string {
  switch (object) {
    case ClaimStatus.CLAIM_STATUS_UNSPECIFIED:
      return "CLAIM_STATUS_UNSPECIFIED";
    case ClaimStatus.CLAIM_STATUS_SUCCESS:
      return "CLAIM_STATUS_SUCCESS";
    case ClaimStatus.CLAIM_STATUS_ALREADY_CLAIMED:
      return "CLAIM_STATUS_ALREADY_CLAIMED";
    case ClaimStatus.CLAIM_STATUS_INVALID_CODE:
      return "CLAIM_STATUS_INVALID_CODE";
    case ClaimStatus.CLAIM_STATUS_EXPIRED_CODE:
      return "CLAIM_STATUS_EXPIRED_CODE";
    case ClaimStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Activity event criticality enumeration. */
export enum Criticality {
  /** CRITICALITY_UNSPECIFIED - Unspecified criticality. */
  CRITICALITY_UNSPECIFIED = 0,
  /** EMERGENCY - System is unusable. */
  EMERGENCY = 1,
  /** ALERT - Action must be taken immediately. */
  ALERT = 2,
  /** CRITICAL - Critical conditions. */
  CRITICAL = 3,
  /** ERROR - Error conditions. */
  ERROR = 4,
  /** WARNING - Warning conditions. */
  WARNING = 5,
  /** NOTICE - Normal, but significant, condition. */
  NOTICE = 6,
  /** INFO - Informational message. */
  INFO = 7,
  /** DEBUG - Debug level message. */
  DEBUG = 8,
  UNRECOGNIZED = -1,
}

export function criticalityFromJSON(object: any): Criticality {
  switch (object) {
    case 0:
    case "CRITICALITY_UNSPECIFIED":
      return Criticality.CRITICALITY_UNSPECIFIED;
    case 1:
    case "EMERGENCY":
      return Criticality.EMERGENCY;
    case 2:
    case "ALERT":
      return Criticality.ALERT;
    case 3:
    case "CRITICAL":
      return Criticality.CRITICAL;
    case 4:
    case "ERROR":
      return Criticality.ERROR;
    case 5:
    case "WARNING":
      return Criticality.WARNING;
    case 6:
    case "NOTICE":
      return Criticality.NOTICE;
    case 7:
    case "INFO":
      return Criticality.INFO;
    case 8:
    case "DEBUG":
      return Criticality.DEBUG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Criticality.UNRECOGNIZED;
  }
}

export function criticalityToJSON(object: Criticality): string {
  switch (object) {
    case Criticality.CRITICALITY_UNSPECIFIED:
      return "CRITICALITY_UNSPECIFIED";
    case Criticality.EMERGENCY:
      return "EMERGENCY";
    case Criticality.ALERT:
      return "ALERT";
    case Criticality.CRITICAL:
      return "CRITICAL";
    case Criticality.ERROR:
      return "ERROR";
    case Criticality.WARNING:
      return "WARNING";
    case Criticality.NOTICE:
      return "NOTICE";
    case Criticality.INFO:
      return "INFO";
    case Criticality.DEBUG:
      return "DEBUG";
    case Criticality.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Data type enumeration. */
export enum DataType {
  /** DATA_TYPE_UNSPECIFIED - Unspecified data type. */
  DATA_TYPE_UNSPECIFIED = 0,
  /** STRING - String type. */
  STRING = 1,
  /** INT32 - 32-bit signed integer. */
  INT32 = 2,
  /** UINT32 - 32-bit unsigned integer. */
  UINT32 = 3,
  /** INT64 - 64-bit signed integer. */
  INT64 = 4,
  /** UINT64 - 64-bit unsigned integer. */
  UINT64 = 5,
  /** BOOL - Boolean value. */
  BOOL = 6,
  /** TIME - Time value in ISO-8601 format. */
  TIME = 7,
  /** UUID - UUID value. */
  UUID = 8,
  /** DURATION - Duration value. */
  DURATION = 9,
  /** JSON - JSON data. */
  JSON = 10,
  UNRECOGNIZED = -1,
}

export function dataTypeFromJSON(object: any): DataType {
  switch (object) {
    case 0:
    case "DATA_TYPE_UNSPECIFIED":
      return DataType.DATA_TYPE_UNSPECIFIED;
    case 1:
    case "STRING":
      return DataType.STRING;
    case 2:
    case "INT32":
      return DataType.INT32;
    case 3:
    case "UINT32":
      return DataType.UINT32;
    case 4:
    case "INT64":
      return DataType.INT64;
    case 5:
    case "UINT64":
      return DataType.UINT64;
    case 6:
    case "BOOL":
      return DataType.BOOL;
    case 7:
    case "TIME":
      return DataType.TIME;
    case 8:
    case "UUID":
      return DataType.UUID;
    case 9:
    case "DURATION":
      return DataType.DURATION;
    case 10:
    case "JSON":
      return DataType.JSON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DataType.UNRECOGNIZED;
  }
}

export function dataTypeToJSON(object: DataType): string {
  switch (object) {
    case DataType.DATA_TYPE_UNSPECIFIED:
      return "DATA_TYPE_UNSPECIFIED";
    case DataType.STRING:
      return "STRING";
    case DataType.INT32:
      return "INT32";
    case DataType.UINT32:
      return "UINT32";
    case DataType.INT64:
      return "INT64";
    case DataType.UINT64:
      return "UINT64";
    case DataType.BOOL:
      return "BOOL";
    case DataType.TIME:
      return "TIME";
    case DataType.UUID:
      return "UUID";
    case DataType.DURATION:
      return "DURATION";
    case DataType.JSON:
      return "JSON";
    case DataType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Time duration enumeration. */
export enum Duration {
  /** DURATION_UNSPECIFIED - Unspecified duration. */
  DURATION_UNSPECIFIED = 0,
  /** SECOND - Seconds. */
  SECOND = 1,
  /** MINUTE - Minutes. */
  MINUTE = 3,
  /** HOUR - Hours. */
  HOUR = 4,
  /** DAY - Days. */
  DAY = 5,
  /** WEEK - Weeks. */
  WEEK = 6,
  /** MONTH - Months. */
  MONTH = 7,
  /** YEAR - Years. */
  YEAR = 8,
  UNRECOGNIZED = -1,
}

export function durationFromJSON(object: any): Duration {
  switch (object) {
    case 0:
    case "DURATION_UNSPECIFIED":
      return Duration.DURATION_UNSPECIFIED;
    case 1:
    case "SECOND":
      return Duration.SECOND;
    case 3:
    case "MINUTE":
      return Duration.MINUTE;
    case 4:
    case "HOUR":
      return Duration.HOUR;
    case 5:
    case "DAY":
      return Duration.DAY;
    case 6:
    case "WEEK":
      return Duration.WEEK;
    case 7:
    case "MONTH":
      return Duration.MONTH;
    case 8:
    case "YEAR":
      return Duration.YEAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Duration.UNRECOGNIZED;
  }
}

export function durationToJSON(object: Duration): string {
  switch (object) {
    case Duration.DURATION_UNSPECIFIED:
      return "DURATION_UNSPECIFIED";
    case Duration.SECOND:
      return "SECOND";
    case Duration.MINUTE:
      return "MINUTE";
    case Duration.HOUR:
      return "HOUR";
    case Duration.DAY:
      return "DAY";
    case Duration.WEEK:
      return "WEEK";
    case Duration.MONTH:
      return "MONTH";
    case Duration.YEAR:
      return "YEAR";
    case Duration.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** FabricType defines an enumeration of fabric types. */
export enum FabricType {
  /** FABRIC_TYPE_UNSPECIFIED - Unspecified fabric type. */
  FABRIC_TYPE_UNSPECIFIED = 0,
  /** INVENTORY - Fabric is derived from inventory switches. */
  INVENTORY = 1,
  /** BLUEPRINT - Fabric is a blueprint fabric with linked switches. */
  BLUEPRINT = 2,
  /** PARKINGLOT - Fabric is a parking lot fabric with unbound switches. */
  PARKINGLOT = 3,
  UNRECOGNIZED = -1,
}

export function fabricTypeFromJSON(object: any): FabricType {
  switch (object) {
    case 0:
    case "FABRIC_TYPE_UNSPECIFIED":
      return FabricType.FABRIC_TYPE_UNSPECIFIED;
    case 1:
    case "INVENTORY":
      return FabricType.INVENTORY;
    case 2:
    case "BLUEPRINT":
      return FabricType.BLUEPRINT;
    case 3:
    case "PARKINGLOT":
      return FabricType.PARKINGLOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricType.UNRECOGNIZED;
  }
}

export function fabricTypeToJSON(object: FabricType): string {
  switch (object) {
    case FabricType.FABRIC_TYPE_UNSPECIFIED:
      return "FABRIC_TYPE_UNSPECIFIED";
    case FabricType.INVENTORY:
      return "INVENTORY";
    case FabricType.BLUEPRINT:
      return "BLUEPRINT";
    case FabricType.PARKINGLOT:
      return "PARKINGLOT";
    case FabricType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Object type enumeration. By convention, ObjectType must have exact same
 * string name (in uppercase) as Go type name. For example, Go type Fabric
 * must have an object type of FABRIC.
 */
export enum ObjectType {
  /** OBJECT_UNSPECIFIED - Unspecified object type. */
  OBJECT_UNSPECIFIED = 0,
  /** USER - System user type. */
  USER = 1,
  /** TENANT - Tenant object type. */
  TENANT = 2,
  /** FABRIC - Fabric object type. */
  FABRIC = 3,
  /** NODE - Node object type. */
  NODE = 4,
  /** NETWORK_PORT - Network port type. */
  NETWORK_PORT = 5,
  /** PORT_CONNECTION - Network port connection. */
  PORT_CONNECTION = 6,
  /** VLAN - Vlan object type. */
  VLAN = 7,
  /** SVI - Vlan SVI object type. */
  SVI = 8,
  /** DHCP_RELAY - DHCP relay object type. */
  DHCP_RELAY = 9,
  /** VRF - Virtual routing and forwarding (VRF) type. */
  VRF = 10,
  /** STATIC_ROUTES - Static routes type. */
  STATIC_ROUTES = 11,
  /** PORT_CHANNEL - Port channel object type. */
  PORT_CHANNEL = 12,
  /** FABRIC_TRANSACTION - Fabric transaction type. */
  FABRIC_TRANSACTION = 13,
  /** ORGANIZATION - Organization type. */
  ORGANIZATION = 14,
  /** BEARER_TOKEN - Bearer token type. */
  BEARER_TOKEN = 15,
  /** API_KEY - API key type. */
  API_KEY = 16,
  /** REST_KEY - API public key type. */
  REST_KEY = 17,
  /** VNI - Logical network type. */
  VNI = 18,
  /** MANAGEMENT_PORT - Ethernet management port type. */
  MANAGEMENT_PORT = 19,
  /** LOOPBACK - Loopback device type. */
  LOOPBACK = 20,
  /** SUB_INTERFACE - SubInterface type. */
  SUB_INTERFACE = 21,
  /** PER_VLAN_STP - Per-Vlan spanning tree protocol type. */
  PER_VLAN_STP = 22,
  /** BGP_PEER - BGP peer config object type. */
  BGP_PEER = 23,
  /** PORT_BREAKOUT - Network port breakout config. */
  PORT_BREAKOUT = 24,
  /** BGP_POLICY - BGP peering policy config. */
  BGP_POLICY = 25,
  /** PREFIX_LIST - IP prefix list. */
  PREFIX_LIST = 26,
  /** DEVICE - Device object type. */
  DEVICE = 27,
  /** NOTIFICATION_ENDPOINT - Notification endpoint object type. */
  NOTIFICATION_ENDPOINT = 28,
  /** CERTIFICATES - Certificates object type. */
  CERTIFICATES = 29,
  UNRECOGNIZED = -1,
}

export function objectTypeFromJSON(object: any): ObjectType {
  switch (object) {
    case 0:
    case "OBJECT_UNSPECIFIED":
      return ObjectType.OBJECT_UNSPECIFIED;
    case 1:
    case "USER":
      return ObjectType.USER;
    case 2:
    case "TENANT":
      return ObjectType.TENANT;
    case 3:
    case "FABRIC":
      return ObjectType.FABRIC;
    case 4:
    case "NODE":
      return ObjectType.NODE;
    case 5:
    case "NETWORK_PORT":
      return ObjectType.NETWORK_PORT;
    case 6:
    case "PORT_CONNECTION":
      return ObjectType.PORT_CONNECTION;
    case 7:
    case "VLAN":
      return ObjectType.VLAN;
    case 8:
    case "SVI":
      return ObjectType.SVI;
    case 9:
    case "DHCP_RELAY":
      return ObjectType.DHCP_RELAY;
    case 10:
    case "VRF":
      return ObjectType.VRF;
    case 11:
    case "STATIC_ROUTES":
      return ObjectType.STATIC_ROUTES;
    case 12:
    case "PORT_CHANNEL":
      return ObjectType.PORT_CHANNEL;
    case 13:
    case "FABRIC_TRANSACTION":
      return ObjectType.FABRIC_TRANSACTION;
    case 14:
    case "ORGANIZATION":
      return ObjectType.ORGANIZATION;
    case 15:
    case "BEARER_TOKEN":
      return ObjectType.BEARER_TOKEN;
    case 16:
    case "API_KEY":
      return ObjectType.API_KEY;
    case 17:
    case "REST_KEY":
      return ObjectType.REST_KEY;
    case 18:
    case "VNI":
      return ObjectType.VNI;
    case 19:
    case "MANAGEMENT_PORT":
      return ObjectType.MANAGEMENT_PORT;
    case 20:
    case "LOOPBACK":
      return ObjectType.LOOPBACK;
    case 21:
    case "SUB_INTERFACE":
      return ObjectType.SUB_INTERFACE;
    case 22:
    case "PER_VLAN_STP":
      return ObjectType.PER_VLAN_STP;
    case 23:
    case "BGP_PEER":
      return ObjectType.BGP_PEER;
    case 24:
    case "PORT_BREAKOUT":
      return ObjectType.PORT_BREAKOUT;
    case 25:
    case "BGP_POLICY":
      return ObjectType.BGP_POLICY;
    case 26:
    case "PREFIX_LIST":
      return ObjectType.PREFIX_LIST;
    case 27:
    case "DEVICE":
      return ObjectType.DEVICE;
    case 28:
    case "NOTIFICATION_ENDPOINT":
      return ObjectType.NOTIFICATION_ENDPOINT;
    case 29:
    case "CERTIFICATES":
      return ObjectType.CERTIFICATES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ObjectType.UNRECOGNIZED;
  }
}

export function objectTypeToJSON(object: ObjectType): string {
  switch (object) {
    case ObjectType.OBJECT_UNSPECIFIED:
      return "OBJECT_UNSPECIFIED";
    case ObjectType.USER:
      return "USER";
    case ObjectType.TENANT:
      return "TENANT";
    case ObjectType.FABRIC:
      return "FABRIC";
    case ObjectType.NODE:
      return "NODE";
    case ObjectType.NETWORK_PORT:
      return "NETWORK_PORT";
    case ObjectType.PORT_CONNECTION:
      return "PORT_CONNECTION";
    case ObjectType.VLAN:
      return "VLAN";
    case ObjectType.SVI:
      return "SVI";
    case ObjectType.DHCP_RELAY:
      return "DHCP_RELAY";
    case ObjectType.VRF:
      return "VRF";
    case ObjectType.STATIC_ROUTES:
      return "STATIC_ROUTES";
    case ObjectType.PORT_CHANNEL:
      return "PORT_CHANNEL";
    case ObjectType.FABRIC_TRANSACTION:
      return "FABRIC_TRANSACTION";
    case ObjectType.ORGANIZATION:
      return "ORGANIZATION";
    case ObjectType.BEARER_TOKEN:
      return "BEARER_TOKEN";
    case ObjectType.API_KEY:
      return "API_KEY";
    case ObjectType.REST_KEY:
      return "REST_KEY";
    case ObjectType.VNI:
      return "VNI";
    case ObjectType.MANAGEMENT_PORT:
      return "MANAGEMENT_PORT";
    case ObjectType.LOOPBACK:
      return "LOOPBACK";
    case ObjectType.SUB_INTERFACE:
      return "SUB_INTERFACE";
    case ObjectType.PER_VLAN_STP:
      return "PER_VLAN_STP";
    case ObjectType.BGP_PEER:
      return "BGP_PEER";
    case ObjectType.PORT_BREAKOUT:
      return "PORT_BREAKOUT";
    case ObjectType.BGP_POLICY:
      return "BGP_POLICY";
    case ObjectType.PREFIX_LIST:
      return "PREFIX_LIST";
    case ObjectType.DEVICE:
      return "DEVICE";
    case ObjectType.NOTIFICATION_ENDPOINT:
      return "NOTIFICATION_ENDPOINT";
    case ObjectType.CERTIFICATES:
      return "CERTIFICATES";
    case ObjectType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OperState defines an enumeration for object's operational states. */
export enum OperState {
  /** OP_STATE_UNSPECIFIED - Invalid operational state. */
  OP_STATE_UNSPECIFIED = 0,
  /** UP - Object's operational state is active. */
  UP = 1,
  /** DOWN - Object's operational state is inactive. */
  DOWN = 2,
  UNRECOGNIZED = -1,
}

export function operStateFromJSON(object: any): OperState {
  switch (object) {
    case 0:
    case "OP_STATE_UNSPECIFIED":
      return OperState.OP_STATE_UNSPECIFIED;
    case 1:
    case "UP":
      return OperState.UP;
    case 2:
    case "DOWN":
      return OperState.DOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperState.UNRECOGNIZED;
  }
}

export function operStateToJSON(object: OperState): string {
  switch (object) {
    case OperState.OP_STATE_UNSPECIFIED:
      return "OP_STATE_UNSPECIFIED";
    case OperState.UP:
      return "UP";
    case OperState.DOWN:
      return "DOWN";
    case OperState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Operation type enumeration. */
export enum Operation {
  /** OPERATION_UNSPECIFIED - Unspecified operation type. */
  OPERATION_UNSPECIFIED = 0,
  /** CREATE - An object has been added. */
  CREATE = 1,
  /** UPDATE - An object has been updated. */
  UPDATE = 2,
  /** DELETE - An object has been deleted. */
  DELETE = 3,
  /** ENABLE - An object has been enabled. */
  ENABLE = 4,
  /** DISABLE - An object has been disabled. */
  DISABLE = 5,
  /** LOGIN - A user has logged in. */
  LOGIN = 6,
  /** LOGOUT - A user has logged out. */
  LOGOUT = 7,
  /** COMMIT - A transaction has been committed. */
  COMMIT = 8,
  /** REVERT - A transaction has been reverted. */
  REVERT = 9,
  /** BIND - A device has been bound to a blueprint node. */
  BIND = 10,
  /** UNBIND - A switch has been unbound from a blueprint node. */
  UNBIND = 11,
  /** COMMENT - User has added a comment. */
  COMMENT = 12,
  UNRECOGNIZED = -1,
}

export function operationFromJSON(object: any): Operation {
  switch (object) {
    case 0:
    case "OPERATION_UNSPECIFIED":
      return Operation.OPERATION_UNSPECIFIED;
    case 1:
    case "CREATE":
      return Operation.CREATE;
    case 2:
    case "UPDATE":
      return Operation.UPDATE;
    case 3:
    case "DELETE":
      return Operation.DELETE;
    case 4:
    case "ENABLE":
      return Operation.ENABLE;
    case 5:
    case "DISABLE":
      return Operation.DISABLE;
    case 6:
    case "LOGIN":
      return Operation.LOGIN;
    case 7:
    case "LOGOUT":
      return Operation.LOGOUT;
    case 8:
    case "COMMIT":
      return Operation.COMMIT;
    case 9:
    case "REVERT":
      return Operation.REVERT;
    case 10:
    case "BIND":
      return Operation.BIND;
    case 11:
    case "UNBIND":
      return Operation.UNBIND;
    case 12:
    case "COMMENT":
      return Operation.COMMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Operation.UNRECOGNIZED;
  }
}

export function operationToJSON(object: Operation): string {
  switch (object) {
    case Operation.OPERATION_UNSPECIFIED:
      return "OPERATION_UNSPECIFIED";
    case Operation.CREATE:
      return "CREATE";
    case Operation.UPDATE:
      return "UPDATE";
    case Operation.DELETE:
      return "DELETE";
    case Operation.ENABLE:
      return "ENABLE";
    case Operation.DISABLE:
      return "DISABLE";
    case Operation.LOGIN:
      return "LOGIN";
    case Operation.LOGOUT:
      return "LOGOUT";
    case Operation.COMMIT:
      return "COMMIT";
    case Operation.REVERT:
      return "REVERT";
    case Operation.BIND:
      return "BIND";
    case Operation.UNBIND:
      return "UNBIND";
    case Operation.COMMENT:
      return "COMMENT";
    case Operation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OrgKind lists various organization types. */
export enum OrgKind {
  /** ORG_KIND_UNSPECIFIED - Unspecified organization kind. */
  ORG_KIND_UNSPECIFIED = 0,
  /** REGULAR - Regular organization. */
  REGULAR = 1,
  /** PARKING_LOT - Parking lot organization. */
  PARKING_LOT = 2,
  UNRECOGNIZED = -1,
}

export function orgKindFromJSON(object: any): OrgKind {
  switch (object) {
    case 0:
    case "ORG_KIND_UNSPECIFIED":
      return OrgKind.ORG_KIND_UNSPECIFIED;
    case 1:
    case "REGULAR":
      return OrgKind.REGULAR;
    case 2:
    case "PARKING_LOT":
      return OrgKind.PARKING_LOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgKind.UNRECOGNIZED;
  }
}

export function orgKindToJSON(object: OrgKind): string {
  switch (object) {
    case OrgKind.ORG_KIND_UNSPECIFIED:
      return "ORG_KIND_UNSPECIFIED";
    case OrgKind.REGULAR:
      return "REGULAR";
    case OrgKind.PARKING_LOT:
      return "PARKING_LOT";
    case OrgKind.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PackageName defines the name of the image package */
export enum PackageName {
  /** PACKAGE_NAME_UNSPECIFIED - Unspecified package name */
  PACKAGE_NAME_UNSPECIFIED = 0,
  /** AGENTS - The agent package */
  AGENTS = 1,
  /** SWSS - The sonic image name */
  SWSS = 2,
  UNRECOGNIZED = -1,
}

export function packageNameFromJSON(object: any): PackageName {
  switch (object) {
    case 0:
    case "PACKAGE_NAME_UNSPECIFIED":
      return PackageName.PACKAGE_NAME_UNSPECIFIED;
    case 1:
    case "AGENTS":
      return PackageName.AGENTS;
    case 2:
    case "SWSS":
      return PackageName.SWSS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PackageName.UNRECOGNIZED;
  }
}

export function packageNameToJSON(object: PackageName): string {
  switch (object) {
    case PackageName.PACKAGE_NAME_UNSPECIFIED:
      return "PACKAGE_NAME_UNSPECIFIED";
    case PackageName.AGENTS:
      return "AGENTS";
    case PackageName.SWSS:
      return "SWSS";
    case PackageName.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PackageStage defines the device image package deployment status */
export enum PackageStatus {
  /** PACKAGE_STATUS_UNSPECIFIED - Unspecified image stage. */
  PACKAGE_STATUS_UNSPECIFIED = 0,
  /** PACKAGE_STATUS_CONFIGURED - Package configured. */
  PACKAGE_STATUS_CONFIGURED = 1,
  /** PACKAGE_STATUS_STAGING - Package is being staged on the device */
  PACKAGE_STATUS_STAGING = 2,
  /** PACKAGE_STATUS_STAGING_ABORTED - Package is aborted from staging */
  PACKAGE_STATUS_STAGING_ABORTED = 3,
  /** PACKAGE_STATUS_RUNNING - Package is currently running on the device */
  PACKAGE_STATUS_RUNNING = 4,
  /** PACKAGE_STATUS_STANDBY - Package is in standby on the device. */
  PACKAGE_STATUS_STANDBY = 5,
  /** PACKAGE_STATUS_RUNNING_ABORTED - Package is aborted from running on the device */
  PACKAGE_STATUS_RUNNING_ABORTED = 6,
  UNRECOGNIZED = -1,
}

export function packageStatusFromJSON(object: any): PackageStatus {
  switch (object) {
    case 0:
    case "PACKAGE_STATUS_UNSPECIFIED":
      return PackageStatus.PACKAGE_STATUS_UNSPECIFIED;
    case 1:
    case "PACKAGE_STATUS_CONFIGURED":
      return PackageStatus.PACKAGE_STATUS_CONFIGURED;
    case 2:
    case "PACKAGE_STATUS_STAGING":
      return PackageStatus.PACKAGE_STATUS_STAGING;
    case 3:
    case "PACKAGE_STATUS_STAGING_ABORTED":
      return PackageStatus.PACKAGE_STATUS_STAGING_ABORTED;
    case 4:
    case "PACKAGE_STATUS_RUNNING":
      return PackageStatus.PACKAGE_STATUS_RUNNING;
    case 5:
    case "PACKAGE_STATUS_STANDBY":
      return PackageStatus.PACKAGE_STATUS_STANDBY;
    case 6:
    case "PACKAGE_STATUS_RUNNING_ABORTED":
      return PackageStatus.PACKAGE_STATUS_RUNNING_ABORTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PackageStatus.UNRECOGNIZED;
  }
}

export function packageStatusToJSON(object: PackageStatus): string {
  switch (object) {
    case PackageStatus.PACKAGE_STATUS_UNSPECIFIED:
      return "PACKAGE_STATUS_UNSPECIFIED";
    case PackageStatus.PACKAGE_STATUS_CONFIGURED:
      return "PACKAGE_STATUS_CONFIGURED";
    case PackageStatus.PACKAGE_STATUS_STAGING:
      return "PACKAGE_STATUS_STAGING";
    case PackageStatus.PACKAGE_STATUS_STAGING_ABORTED:
      return "PACKAGE_STATUS_STAGING_ABORTED";
    case PackageStatus.PACKAGE_STATUS_RUNNING:
      return "PACKAGE_STATUS_RUNNING";
    case PackageStatus.PACKAGE_STATUS_STANDBY:
      return "PACKAGE_STATUS_STANDBY";
    case PackageStatus.PACKAGE_STATUS_RUNNING_ABORTED:
      return "PACKAGE_STATUS_RUNNING_ABORTED";
    case PackageStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** TaskType defines an enumeration of various workflow tasks. */
export enum TaskType {
  /** TASK_TYPE_UNSPECIFIED - Unspecified task type. */
  TASK_TYPE_UNSPECIFIED = 0,
  /** TASK_TESTING - Tasks used for testing and verifying workflows. */
  TASK_TESTING = 1,
  /** TASK_SONIC - Workflow tasks for SONiC. */
  TASK_SONIC = 2,
  UNRECOGNIZED = -1,
}

export function taskTypeFromJSON(object: any): TaskType {
  switch (object) {
    case 0:
    case "TASK_TYPE_UNSPECIFIED":
      return TaskType.TASK_TYPE_UNSPECIFIED;
    case 1:
    case "TASK_TESTING":
      return TaskType.TASK_TESTING;
    case 2:
    case "TASK_SONIC":
      return TaskType.TASK_SONIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaskType.UNRECOGNIZED;
  }
}

export function taskTypeToJSON(object: TaskType): string {
  switch (object) {
    case TaskType.TASK_TYPE_UNSPECIFIED:
      return "TASK_TYPE_UNSPECIFIED";
    case TaskType.TASK_TESTING:
      return "TASK_TESTING";
    case TaskType.TASK_SONIC:
      return "TASK_SONIC";
    case TaskType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Annotation defines a typed name/value pair. */
export interface Annotation {
  /** Name defines a user-defined name of annotation. */
  name: string;
  /** Value defines annotation value expressed as string. */
  value: string;
  /** DataType defines data-type of the value. */
  dataType: DataType;
}

/** Claim result defines the status of claim attempt on a specific device ID using a specific claim code. */
export interface ClaimResult {
  /** DeviceId defines MAC address of the device. */
  deviceId: string;
  /** ClaimCode defines the claim code of the device. */
  claimCode: string;
  /** ClaimStatus defines the claim status of the device */
  claimStatus: ClaimStatus;
}

function createBaseAnnotation(): Annotation {
  return { name: "", value: "", dataType: 0 };
}

export const Annotation = {
  encode(message: Annotation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.dataType !== 0) {
      writer.uint32(24).int32(message.dataType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Annotation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dataType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Annotation {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      dataType: isSet(object.dataType) ? dataTypeFromJSON(object.dataType) : 0,
    };
  },

  toJSON(message: Annotation): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.dataType !== 0) {
      obj.dataType = dataTypeToJSON(message.dataType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Annotation>, I>>(base?: I): Annotation {
    return Annotation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Annotation>, I>>(object: I): Annotation {
    const message = createBaseAnnotation();
    message.name = object.name ?? "";
    message.value = object.value ?? "";
    message.dataType = object.dataType ?? 0;
    return message;
  },
};

function createBaseClaimResult(): ClaimResult {
  return { deviceId: "", claimCode: "", claimStatus: 0 };
}

export const ClaimResult = {
  encode(message: ClaimResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.claimCode !== "") {
      writer.uint32(18).string(message.claimCode);
    }
    if (message.claimStatus !== 0) {
      writer.uint32(24).int32(message.claimStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClaimResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClaimResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.claimCode = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.claimStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClaimResult {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      claimCode: isSet(object.claimCode) ? globalThis.String(object.claimCode) : "",
      claimStatus: isSet(object.claimStatus) ? claimStatusFromJSON(object.claimStatus) : 0,
    };
  },

  toJSON(message: ClaimResult): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.claimCode !== "") {
      obj.claimCode = message.claimCode;
    }
    if (message.claimStatus !== 0) {
      obj.claimStatus = claimStatusToJSON(message.claimStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClaimResult>, I>>(base?: I): ClaimResult {
    return ClaimResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClaimResult>, I>>(object: I): ClaimResult {
    const message = createBaseClaimResult();
    message.deviceId = object.deviceId ?? "";
    message.claimCode = object.claimCode ?? "";
    message.claimStatus = object.claimStatus ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
