// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: druid/record_query_api.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { PaginationRequest, PaginationResponse } from "../common/grpc";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  AdminState,
  adminStateFromJSON,
  adminStateToJSON,
  OperState,
  operStateFromJSON,
  operStateToJSON,
} from "../models/global";
import {
  ChainOperator,
  chainOperatorFromJSON,
  chainOperatorToJSON,
  Granularity,
  granularityFromJSON,
  granularityToJSON,
  Operator,
  operatorFromJSON,
  operatorToJSON,
  RequestMetadata,
  SortOrder,
  sortOrderFromJSON,
  sortOrderToJSON,
} from "./timeseries_api_common";

export const protobufPackage = "druid";

/**
 * PortStateHistoryRecordProjectDimensionBy is the valid set of dimensions, aggregated dimensions and
 * metrics a client can select/project. Note: the list is not complete.
 */
export enum PortStateHistoryRecordProjectDimensionBy {
  /** PSP_UNSPECIFIED - PortStateHistoryRecord ProjectDimensionBy unspecified */
  PSP_UNSPECIFIED = 0,
  /** PSP_TIME - PortStateHistoryRecord ProjectDimensionBy time */
  PSP_TIME = 1,
  /** PSP_FABRIC - PortStateHistoryRecord ProjectDimensionBy fabric */
  PSP_FABRIC = 2,
  /** PSP_DEVICE - PortStateHistoryRecord ProjectDimensionBy device */
  PSP_DEVICE = 3,
  /** PSP_PORT_ID - PortStateHistoryRecord ProjectDimensionBy port_id */
  PSP_PORT_ID = 4,
  /** PSP_ADMIN_STATE - PortStateHistoryRecord ProjectDimensionBy admin_state */
  PSP_ADMIN_STATE = 5,
  /** PSP_OP_STATE - PortStateHistoryRecord ProjectDimensionBy op_state */
  PSP_OP_STATE = 6,
  /** PSP_IP_V4 - PortStateHistoryRecord ProjectDimensionBy ip_v4 */
  PSP_IP_V4 = 7,
  /** PSP_ORG_ID - PortStateHistoryRecord ProjectDimensionBy org_id */
  PSP_ORG_ID = 8,
  /** PSP_ROW_COUNT - PortStateHistoryRecord ProjectDimensionBy row_count */
  PSP_ROW_COUNT = 9,
  UNRECOGNIZED = -1,
}

export function portStateHistoryRecordProjectDimensionByFromJSON(
  object: any,
): PortStateHistoryRecordProjectDimensionBy {
  switch (object) {
    case 0:
    case "PSP_UNSPECIFIED":
      return PortStateHistoryRecordProjectDimensionBy.PSP_UNSPECIFIED;
    case 1:
    case "PSP_TIME":
      return PortStateHistoryRecordProjectDimensionBy.PSP_TIME;
    case 2:
    case "PSP_FABRIC":
      return PortStateHistoryRecordProjectDimensionBy.PSP_FABRIC;
    case 3:
    case "PSP_DEVICE":
      return PortStateHistoryRecordProjectDimensionBy.PSP_DEVICE;
    case 4:
    case "PSP_PORT_ID":
      return PortStateHistoryRecordProjectDimensionBy.PSP_PORT_ID;
    case 5:
    case "PSP_ADMIN_STATE":
      return PortStateHistoryRecordProjectDimensionBy.PSP_ADMIN_STATE;
    case 6:
    case "PSP_OP_STATE":
      return PortStateHistoryRecordProjectDimensionBy.PSP_OP_STATE;
    case 7:
    case "PSP_IP_V4":
      return PortStateHistoryRecordProjectDimensionBy.PSP_IP_V4;
    case 8:
    case "PSP_ORG_ID":
      return PortStateHistoryRecordProjectDimensionBy.PSP_ORG_ID;
    case 9:
    case "PSP_ROW_COUNT":
      return PortStateHistoryRecordProjectDimensionBy.PSP_ROW_COUNT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortStateHistoryRecordProjectDimensionBy.UNRECOGNIZED;
  }
}

export function portStateHistoryRecordProjectDimensionByToJSON(
  object: PortStateHistoryRecordProjectDimensionBy,
): string {
  switch (object) {
    case PortStateHistoryRecordProjectDimensionBy.PSP_UNSPECIFIED:
      return "PSP_UNSPECIFIED";
    case PortStateHistoryRecordProjectDimensionBy.PSP_TIME:
      return "PSP_TIME";
    case PortStateHistoryRecordProjectDimensionBy.PSP_FABRIC:
      return "PSP_FABRIC";
    case PortStateHistoryRecordProjectDimensionBy.PSP_DEVICE:
      return "PSP_DEVICE";
    case PortStateHistoryRecordProjectDimensionBy.PSP_PORT_ID:
      return "PSP_PORT_ID";
    case PortStateHistoryRecordProjectDimensionBy.PSP_ADMIN_STATE:
      return "PSP_ADMIN_STATE";
    case PortStateHistoryRecordProjectDimensionBy.PSP_OP_STATE:
      return "PSP_OP_STATE";
    case PortStateHistoryRecordProjectDimensionBy.PSP_IP_V4:
      return "PSP_IP_V4";
    case PortStateHistoryRecordProjectDimensionBy.PSP_ORG_ID:
      return "PSP_ORG_ID";
    case PortStateHistoryRecordProjectDimensionBy.PSP_ROW_COUNT:
      return "PSP_ROW_COUNT";
    case PortStateHistoryRecordProjectDimensionBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PortStateHistoryRecordGroupBy is the valid set of dimensions that can be grouped by. */
export enum PortStateHistoryRecordGroupBy {
  /** PSG_UNSPECIFIED - PortStateHistoryRecord GroupBy unspecified */
  PSG_UNSPECIFIED = 0,
  /** PSG_TIME - PortStateHistoryRecord GroupBy time */
  PSG_TIME = 1,
  /** PSG_FABRIC - PortStateHistoryRecord GroupBy fabric */
  PSG_FABRIC = 2,
  /** PSG_DEVICE - PortStateHistoryRecord GroupBy device */
  PSG_DEVICE = 3,
  /** PSG_PORT_ID - PortStateHistoryRecord GroupBy port_id */
  PSG_PORT_ID = 4,
  /** PSG_ADMIN_STATE - PortStateHistoryRecord GroupBy admin_state */
  PSG_ADMIN_STATE = 5,
  /** PSG_OP_STATE - PortStateHistoryRecord GroupBy op_state */
  PSG_OP_STATE = 6,
  /** PSG_IP_V4 - PortStateHistoryRecord GroupBy ip_v4 */
  PSG_IP_V4 = 7,
  /** PSG_ORG_ID - PortStateHistoryRecord GroupBy org_id */
  PSG_ORG_ID = 8,
  UNRECOGNIZED = -1,
}

export function portStateHistoryRecordGroupByFromJSON(object: any): PortStateHistoryRecordGroupBy {
  switch (object) {
    case 0:
    case "PSG_UNSPECIFIED":
      return PortStateHistoryRecordGroupBy.PSG_UNSPECIFIED;
    case 1:
    case "PSG_TIME":
      return PortStateHistoryRecordGroupBy.PSG_TIME;
    case 2:
    case "PSG_FABRIC":
      return PortStateHistoryRecordGroupBy.PSG_FABRIC;
    case 3:
    case "PSG_DEVICE":
      return PortStateHistoryRecordGroupBy.PSG_DEVICE;
    case 4:
    case "PSG_PORT_ID":
      return PortStateHistoryRecordGroupBy.PSG_PORT_ID;
    case 5:
    case "PSG_ADMIN_STATE":
      return PortStateHistoryRecordGroupBy.PSG_ADMIN_STATE;
    case 6:
    case "PSG_OP_STATE":
      return PortStateHistoryRecordGroupBy.PSG_OP_STATE;
    case 7:
    case "PSG_IP_V4":
      return PortStateHistoryRecordGroupBy.PSG_IP_V4;
    case 8:
    case "PSG_ORG_ID":
      return PortStateHistoryRecordGroupBy.PSG_ORG_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortStateHistoryRecordGroupBy.UNRECOGNIZED;
  }
}

export function portStateHistoryRecordGroupByToJSON(object: PortStateHistoryRecordGroupBy): string {
  switch (object) {
    case PortStateHistoryRecordGroupBy.PSG_UNSPECIFIED:
      return "PSG_UNSPECIFIED";
    case PortStateHistoryRecordGroupBy.PSG_TIME:
      return "PSG_TIME";
    case PortStateHistoryRecordGroupBy.PSG_FABRIC:
      return "PSG_FABRIC";
    case PortStateHistoryRecordGroupBy.PSG_DEVICE:
      return "PSG_DEVICE";
    case PortStateHistoryRecordGroupBy.PSG_PORT_ID:
      return "PSG_PORT_ID";
    case PortStateHistoryRecordGroupBy.PSG_ADMIN_STATE:
      return "PSG_ADMIN_STATE";
    case PortStateHistoryRecordGroupBy.PSG_OP_STATE:
      return "PSG_OP_STATE";
    case PortStateHistoryRecordGroupBy.PSG_IP_V4:
      return "PSG_IP_V4";
    case PortStateHistoryRecordGroupBy.PSG_ORG_ID:
      return "PSG_ORG_ID";
    case PortStateHistoryRecordGroupBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * PortCounterHistoryRecordProjectDimensionBy is the valid set of dimensions, aggregated dimensions and
 * metrics a client can select/project. Note: the list is not complete.
 */
export enum PortCounterHistoryRecordProjectDimensionBy {
  /** PCP_UNSPECIFIED - PortCounterHistoryRecord ProjectDimensionBy unspecified */
  PCP_UNSPECIFIED = 0,
  /** PCP_TIME - PortCounterHistoryRecord ProjectDimensionBy time */
  PCP_TIME = 1,
  /** PCP_FABRIC - PortCounterHistoryRecord ProjectDimensionBy fabric */
  PCP_FABRIC = 2,
  /** PCP_DEVICE - PortCounterHistoryRecord ProjectDimensionBy device */
  PCP_DEVICE = 3,
  /** PCP_PORT_ID - PortCounterHistoryRecord ProjectDimensionBy port_id */
  PCP_PORT_ID = 4,
  /** PCP_ORG_ID - PortCounterHistoryRecord ProjectDimensionBy org_id */
  PCP_ORG_ID = 5,
  /** PCP_IN_OCTETS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_octets_total */
  PCP_IN_OCTETS_TOTAL = 6,
  /** PCP_IN_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pkts_total */
  PCP_IN_PKTS_TOTAL = 7,
  /** PCP_IN_DISCARDS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_discards_total */
  PCP_IN_DISCARDS_TOTAL = 8,
  /** PCP_IN_ERRORS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_errors_total */
  PCP_IN_ERRORS_TOTAL = 9,
  /** PCP_OUT_OCTETS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_octets_total */
  PCP_OUT_OCTETS_TOTAL = 10,
  /** PCP_OUT_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pkts_total */
  PCP_OUT_PKTS_TOTAL = 11,
  /** PCP_OUT_DISCARDS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_discards_total */
  PCP_OUT_DISCARDS_TOTAL = 12,
  /** PCP_OUT_ERRORS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_errors_total */
  PCP_OUT_ERRORS_TOTAL = 13,
  /** PCP_IN_PFC0_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc0_pkts_total */
  PCP_IN_PFC0_PKTS_TOTAL = 14,
  /** PCP_OUT_PFC0_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc0_pkts_total */
  PCP_OUT_PFC0_PKTS_TOTAL = 15,
  /** PCP_IN_PFC1_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc1_pkts_total */
  PCP_IN_PFC1_PKTS_TOTAL = 16,
  /** PCP_OUT_PFC1_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc1_pkts_total */
  PCP_OUT_PFC1_PKTS_TOTAL = 17,
  /** PCP_IN_PFC2_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc2_pkts_total */
  PCP_IN_PFC2_PKTS_TOTAL = 18,
  /** PCP_OUT_PFC2_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc2_pkts_total */
  PCP_OUT_PFC2_PKTS_TOTAL = 19,
  /** PCP_IN_PFC3_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc3_pkts_total */
  PCP_IN_PFC3_PKTS_TOTAL = 20,
  /** PCP_OUT_PFC3_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc3_pkts_total */
  PCP_OUT_PFC3_PKTS_TOTAL = 21,
  /** PCP_IN_PFC4_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc4_pkts_total */
  PCP_IN_PFC4_PKTS_TOTAL = 22,
  /** PCP_OUT_PFC4_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc4_pkts_total */
  PCP_OUT_PFC4_PKTS_TOTAL = 23,
  /** PCP_IN_PFC5_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc5_pkts_total */
  PCP_IN_PFC5_PKTS_TOTAL = 24,
  /** PCP_OUT_PFC5_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc5_pkts_total */
  PCP_OUT_PFC5_PKTS_TOTAL = 25,
  /** PCP_IN_PFC6_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc6_pkts_total */
  PCP_IN_PFC6_PKTS_TOTAL = 26,
  /** PCP_OUT_PFC6_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc6_pkts_total */
  PCP_OUT_PFC6_PKTS_TOTAL = 27,
  /** PCP_IN_PFC7_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy in_pfc7_pkts_total */
  PCP_IN_PFC7_PKTS_TOTAL = 28,
  /** PCP_OUT_PFC7_PKTS_TOTAL - PortCounterHistoryRecord ProjectDimensionBy out_pfc7_pkts_total */
  PCP_OUT_PFC7_PKTS_TOTAL = 29,
  /** PCP_ROW_COUNT - PortCounterHistoryRecord ProjectDimensionBy row_count */
  PCP_ROW_COUNT = 30,
  /** PCP_RX_MINUTE_RATE - PortCounterHistoryRecord ProjectDimensionBy rx_minute_rate */
  PCP_RX_MINUTE_RATE = 31,
  /** PCP_TX_MINUTE_RATE - PortCounterHistoryRecord ProjectDimensionBy tx_minute_rate */
  PCP_TX_MINUTE_RATE = 32,
  UNRECOGNIZED = -1,
}

export function portCounterHistoryRecordProjectDimensionByFromJSON(
  object: any,
): PortCounterHistoryRecordProjectDimensionBy {
  switch (object) {
    case 0:
    case "PCP_UNSPECIFIED":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_UNSPECIFIED;
    case 1:
    case "PCP_TIME":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_TIME;
    case 2:
    case "PCP_FABRIC":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_FABRIC;
    case 3:
    case "PCP_DEVICE":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_DEVICE;
    case 4:
    case "PCP_PORT_ID":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_PORT_ID;
    case 5:
    case "PCP_ORG_ID":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_ORG_ID;
    case 6:
    case "PCP_IN_OCTETS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_OCTETS_TOTAL;
    case 7:
    case "PCP_IN_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PKTS_TOTAL;
    case 8:
    case "PCP_IN_DISCARDS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_DISCARDS_TOTAL;
    case 9:
    case "PCP_IN_ERRORS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_ERRORS_TOTAL;
    case 10:
    case "PCP_OUT_OCTETS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_OCTETS_TOTAL;
    case 11:
    case "PCP_OUT_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PKTS_TOTAL;
    case 12:
    case "PCP_OUT_DISCARDS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_DISCARDS_TOTAL;
    case 13:
    case "PCP_OUT_ERRORS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_ERRORS_TOTAL;
    case 14:
    case "PCP_IN_PFC0_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC0_PKTS_TOTAL;
    case 15:
    case "PCP_OUT_PFC0_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC0_PKTS_TOTAL;
    case 16:
    case "PCP_IN_PFC1_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC1_PKTS_TOTAL;
    case 17:
    case "PCP_OUT_PFC1_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC1_PKTS_TOTAL;
    case 18:
    case "PCP_IN_PFC2_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC2_PKTS_TOTAL;
    case 19:
    case "PCP_OUT_PFC2_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC2_PKTS_TOTAL;
    case 20:
    case "PCP_IN_PFC3_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC3_PKTS_TOTAL;
    case 21:
    case "PCP_OUT_PFC3_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC3_PKTS_TOTAL;
    case 22:
    case "PCP_IN_PFC4_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC4_PKTS_TOTAL;
    case 23:
    case "PCP_OUT_PFC4_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC4_PKTS_TOTAL;
    case 24:
    case "PCP_IN_PFC5_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC5_PKTS_TOTAL;
    case 25:
    case "PCP_OUT_PFC5_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC5_PKTS_TOTAL;
    case 26:
    case "PCP_IN_PFC6_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC6_PKTS_TOTAL;
    case 27:
    case "PCP_OUT_PFC6_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC6_PKTS_TOTAL;
    case 28:
    case "PCP_IN_PFC7_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC7_PKTS_TOTAL;
    case 29:
    case "PCP_OUT_PFC7_PKTS_TOTAL":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC7_PKTS_TOTAL;
    case 30:
    case "PCP_ROW_COUNT":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_ROW_COUNT;
    case 31:
    case "PCP_RX_MINUTE_RATE":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_RX_MINUTE_RATE;
    case 32:
    case "PCP_TX_MINUTE_RATE":
      return PortCounterHistoryRecordProjectDimensionBy.PCP_TX_MINUTE_RATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortCounterHistoryRecordProjectDimensionBy.UNRECOGNIZED;
  }
}

export function portCounterHistoryRecordProjectDimensionByToJSON(
  object: PortCounterHistoryRecordProjectDimensionBy,
): string {
  switch (object) {
    case PortCounterHistoryRecordProjectDimensionBy.PCP_UNSPECIFIED:
      return "PCP_UNSPECIFIED";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_TIME:
      return "PCP_TIME";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_FABRIC:
      return "PCP_FABRIC";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_DEVICE:
      return "PCP_DEVICE";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_PORT_ID:
      return "PCP_PORT_ID";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_ORG_ID:
      return "PCP_ORG_ID";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_OCTETS_TOTAL:
      return "PCP_IN_OCTETS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PKTS_TOTAL:
      return "PCP_IN_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_DISCARDS_TOTAL:
      return "PCP_IN_DISCARDS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_ERRORS_TOTAL:
      return "PCP_IN_ERRORS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_OCTETS_TOTAL:
      return "PCP_OUT_OCTETS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PKTS_TOTAL:
      return "PCP_OUT_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_DISCARDS_TOTAL:
      return "PCP_OUT_DISCARDS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_ERRORS_TOTAL:
      return "PCP_OUT_ERRORS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC0_PKTS_TOTAL:
      return "PCP_IN_PFC0_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC0_PKTS_TOTAL:
      return "PCP_OUT_PFC0_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC1_PKTS_TOTAL:
      return "PCP_IN_PFC1_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC1_PKTS_TOTAL:
      return "PCP_OUT_PFC1_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC2_PKTS_TOTAL:
      return "PCP_IN_PFC2_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC2_PKTS_TOTAL:
      return "PCP_OUT_PFC2_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC3_PKTS_TOTAL:
      return "PCP_IN_PFC3_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC3_PKTS_TOTAL:
      return "PCP_OUT_PFC3_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC4_PKTS_TOTAL:
      return "PCP_IN_PFC4_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC4_PKTS_TOTAL:
      return "PCP_OUT_PFC4_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC5_PKTS_TOTAL:
      return "PCP_IN_PFC5_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC5_PKTS_TOTAL:
      return "PCP_OUT_PFC5_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC6_PKTS_TOTAL:
      return "PCP_IN_PFC6_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC6_PKTS_TOTAL:
      return "PCP_OUT_PFC6_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_IN_PFC7_PKTS_TOTAL:
      return "PCP_IN_PFC7_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_OUT_PFC7_PKTS_TOTAL:
      return "PCP_OUT_PFC7_PKTS_TOTAL";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_ROW_COUNT:
      return "PCP_ROW_COUNT";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_RX_MINUTE_RATE:
      return "PCP_RX_MINUTE_RATE";
    case PortCounterHistoryRecordProjectDimensionBy.PCP_TX_MINUTE_RATE:
      return "PCP_TX_MINUTE_RATE";
    case PortCounterHistoryRecordProjectDimensionBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PortCounterHistoryRecordGroupBy is the valid set of dimensions that can be grouped by. */
export enum PortCounterHistoryRecordGroupBy {
  /** PCG_UNSPECIFIED - PortCounterHistoryRecord GroupBy unspecified */
  PCG_UNSPECIFIED = 0,
  /** PCG_TIME - PortCounterHistoryRecord GroupBy time */
  PCG_TIME = 1,
  /** PCG_FABRIC - PortCounterHistoryRecord GroupBy fabric */
  PCG_FABRIC = 2,
  /** PCG_DEVICE - PortCounterHistoryRecord GroupBy device */
  PCG_DEVICE = 3,
  /** PCG_PORT_ID - PortCounterHistoryRecord GroupBy port_id */
  PCG_PORT_ID = 4,
  /** PCG_ORG_ID - PortCounterHistoryRecord GroupBy org_id */
  PCG_ORG_ID = 5,
  UNRECOGNIZED = -1,
}

export function portCounterHistoryRecordGroupByFromJSON(object: any): PortCounterHistoryRecordGroupBy {
  switch (object) {
    case 0:
    case "PCG_UNSPECIFIED":
      return PortCounterHistoryRecordGroupBy.PCG_UNSPECIFIED;
    case 1:
    case "PCG_TIME":
      return PortCounterHistoryRecordGroupBy.PCG_TIME;
    case 2:
    case "PCG_FABRIC":
      return PortCounterHistoryRecordGroupBy.PCG_FABRIC;
    case 3:
    case "PCG_DEVICE":
      return PortCounterHistoryRecordGroupBy.PCG_DEVICE;
    case 4:
    case "PCG_PORT_ID":
      return PortCounterHistoryRecordGroupBy.PCG_PORT_ID;
    case 5:
    case "PCG_ORG_ID":
      return PortCounterHistoryRecordGroupBy.PCG_ORG_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortCounterHistoryRecordGroupBy.UNRECOGNIZED;
  }
}

export function portCounterHistoryRecordGroupByToJSON(object: PortCounterHistoryRecordGroupBy): string {
  switch (object) {
    case PortCounterHistoryRecordGroupBy.PCG_UNSPECIFIED:
      return "PCG_UNSPECIFIED";
    case PortCounterHistoryRecordGroupBy.PCG_TIME:
      return "PCG_TIME";
    case PortCounterHistoryRecordGroupBy.PCG_FABRIC:
      return "PCG_FABRIC";
    case PortCounterHistoryRecordGroupBy.PCG_DEVICE:
      return "PCG_DEVICE";
    case PortCounterHistoryRecordGroupBy.PCG_PORT_ID:
      return "PCG_PORT_ID";
    case PortCounterHistoryRecordGroupBy.PCG_ORG_ID:
      return "PCG_ORG_ID";
    case PortCounterHistoryRecordGroupBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * AssertHistoryRecordProjectDimensionBy is the valid set of dimensions, aggregated dimensions and
 * metrics a client can select/project. Note: the list is not complete.
 */
export enum AssertHistoryRecordProjectDimensionBy {
  /** AHP_UNSPECIFIED - AssertHistoryRecord ProjectDimensionBy unspecified */
  AHP_UNSPECIFIED = 0,
  /** AHP_TIME - AssertHistoryRecord ProjectDimensionBy time */
  AHP_TIME = 1,
  /** AHP_ASSERT_TYPE - AssertHistoryRecord ProjectDimensionBy assert_type */
  AHP_ASSERT_TYPE = 2,
  /** AHP_CATEGORY - AssertHistoryRecord ProjectDimensionBy category */
  AHP_CATEGORY = 3,
  /** AHP_ASSERT_STATE - AssertHistoryRecord ProjectDimensionBy assert_state */
  AHP_ASSERT_STATE = 4,
  /** AHP_DEVICE - AssertHistoryRecord ProjectDimensionBy device */
  AHP_DEVICE = 5,
  /** AHP_LINE_CARD - AssertHistoryRecord ProjectDimensionBy line_card */
  AHP_LINE_CARD = 6,
  /** AHP_BREAKOUT - AssertHistoryRecord ProjectDimensionBy breakout */
  AHP_BREAKOUT = 7,
  /** AHP_PORT_NAME - AssertHistoryRecord ProjectDimensionBy port_name */
  AHP_PORT_NAME = 8,
  /** AHP_MASKED - AssertHistoryRecord ProjectDimensionBy masked */
  AHP_MASKED = 9,
  /** AHP_ORG_ID - AssertHistoryRecord ProjectDimensionBy org_id */
  AHP_ORG_ID = 10,
  /** AHP_LATCHED_AT - AssertHistoryRecord ProjectDimensionBy latched_at */
  AHP_LATCHED_AT = 11,
  /** AHP_LATCH_TIME - AssertHistoryRecord ProjectDimensionBy latch_time */
  AHP_LATCH_TIME = 12,
  /** AHP_IMPLICIT - AssertHistoryRecord ProjectDimensionBy implicit */
  AHP_IMPLICIT = 13,
  /** AHP_REMOTE_PORT_NAME - AssertHistoryRecord ProjectDimensionBy remote_port_name */
  AHP_REMOTE_PORT_NAME = 14,
  /** AHP_REMOTE_DEVICE_ID - AssertHistoryRecord ProjectDimensionBy remote_device_id */
  AHP_REMOTE_DEVICE_ID = 15,
  /** AHP_NUM_NEIGHBORS - AssertHistoryRecord ProjectDimensionBy num_neighbors */
  AHP_NUM_NEIGHBORS = 16,
  /** AHP_LOCAL_PORT_SPEED - AssertHistoryRecord ProjectDimensionBy local_port_speed */
  AHP_LOCAL_PORT_SPEED = 17,
  /** AHP_LAST_TRAFFIC_BYTES - AssertHistoryRecord ProjectDimensionBy last_traffic_bytes */
  AHP_LAST_TRAFFIC_BYTES = 18,
  /** AHP_RECENT_TRAFFIC_BYTES - AssertHistoryRecord ProjectDimensionBy recent_traffic_bytes */
  AHP_RECENT_TRAFFIC_BYTES = 19,
  /** AHP_LAST_TRAFFIC_TIME - AssertHistoryRecord ProjectDimensionBy last_traffic_time */
  AHP_LAST_TRAFFIC_TIME = 20,
  /** AHP_VRF - AssertHistoryRecord ProjectDimensionBy vrf */
  AHP_VRF = 21,
  /** AHP_VLAN_STATE - AssertHistoryRecord ProjectDimensionBy vlan_state */
  AHP_VLAN_STATE = 22,
  /** AHP_MANAGEMENT_STATE - AssertHistoryRecord ProjectDimensionBy management_state */
  AHP_MANAGEMENT_STATE = 23,
  /** AHP_HIGH_CPU_UTIL - AssertHistoryRecord ProjectDimensionBy high_cpu_util */
  AHP_HIGH_CPU_UTIL = 24,
  /** AHP_HIGH_DISK_UTIL - AssertHistoryRecord ProjectDimensionBy high_disk_util */
  AHP_HIGH_DISK_UTIL = 25,
  /** AHP_HIGH_MEM_UTIL - AssertHistoryRecord ProjectDimensionBy high_mem_util */
  AHP_HIGH_MEM_UTIL = 26,
  /** AHP_PORT - AssertHistoryRecord ProjectDimensionBy port */
  AHP_PORT = 27,
  /** AHP_NODE_ID - AssertHistoryRecord ProjectDimensionBy node_id */
  AHP_NODE_ID = 28,
  /** AHP_REMOTE_NODE_ID - AssertHistoryRecord ProjectDimensionBy remote_node_id */
  AHP_REMOTE_NODE_ID = 29,
  /** AHP_FABRIC - AssertHistoryRecord ProjectDimensionBy fabric */
  AHP_FABRIC = 30,
  /** AHP_TXN_ID - AssertHistoryRecord ProjectDimensionBy txn_id */
  AHP_TXN_ID = 31,
  /** AHP_OBJECT_NAME - AssertHistoryRecord ProjectDimensionBy object_name */
  AHP_OBJECT_NAME = 32,
  /** AHP_OBJECT_TYPE - AssertHistoryRecord ProjectDimensionBy object_type */
  AHP_OBJECT_TYPE = 33,
  /** AHP_OBJECT_ID - AssertHistoryRecord ProjectDimensionBy object_id */
  AHP_OBJECT_ID = 34,
  /** AHP_PARENT_ID - AssertHistoryRecord ProjectDimensionBy parent_id */
  AHP_PARENT_ID = 35,
  /** AHP_CHILD_ID - AssertHistoryRecord ProjectDimensionBy child_id */
  AHP_CHILD_ID = 36,
  /** AHP_USERNAME - AssertHistoryRecord ProjectDimensionBy username */
  AHP_USERNAME = 37,
  /** AHP_OPERATION - AssertHistoryRecord ProjectDimensionBy operation */
  AHP_OPERATION = 38,
  /** AHP_ROW_COUNT - AssertHistoryRecord ProjectDimensionBy row_count */
  AHP_ROW_COUNT = 39,
  UNRECOGNIZED = -1,
}

export function assertHistoryRecordProjectDimensionByFromJSON(object: any): AssertHistoryRecordProjectDimensionBy {
  switch (object) {
    case 0:
    case "AHP_UNSPECIFIED":
      return AssertHistoryRecordProjectDimensionBy.AHP_UNSPECIFIED;
    case 1:
    case "AHP_TIME":
      return AssertHistoryRecordProjectDimensionBy.AHP_TIME;
    case 2:
    case "AHP_ASSERT_TYPE":
      return AssertHistoryRecordProjectDimensionBy.AHP_ASSERT_TYPE;
    case 3:
    case "AHP_CATEGORY":
      return AssertHistoryRecordProjectDimensionBy.AHP_CATEGORY;
    case 4:
    case "AHP_ASSERT_STATE":
      return AssertHistoryRecordProjectDimensionBy.AHP_ASSERT_STATE;
    case 5:
    case "AHP_DEVICE":
      return AssertHistoryRecordProjectDimensionBy.AHP_DEVICE;
    case 6:
    case "AHP_LINE_CARD":
      return AssertHistoryRecordProjectDimensionBy.AHP_LINE_CARD;
    case 7:
    case "AHP_BREAKOUT":
      return AssertHistoryRecordProjectDimensionBy.AHP_BREAKOUT;
    case 8:
    case "AHP_PORT_NAME":
      return AssertHistoryRecordProjectDimensionBy.AHP_PORT_NAME;
    case 9:
    case "AHP_MASKED":
      return AssertHistoryRecordProjectDimensionBy.AHP_MASKED;
    case 10:
    case "AHP_ORG_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_ORG_ID;
    case 11:
    case "AHP_LATCHED_AT":
      return AssertHistoryRecordProjectDimensionBy.AHP_LATCHED_AT;
    case 12:
    case "AHP_LATCH_TIME":
      return AssertHistoryRecordProjectDimensionBy.AHP_LATCH_TIME;
    case 13:
    case "AHP_IMPLICIT":
      return AssertHistoryRecordProjectDimensionBy.AHP_IMPLICIT;
    case 14:
    case "AHP_REMOTE_PORT_NAME":
      return AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_PORT_NAME;
    case 15:
    case "AHP_REMOTE_DEVICE_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_DEVICE_ID;
    case 16:
    case "AHP_NUM_NEIGHBORS":
      return AssertHistoryRecordProjectDimensionBy.AHP_NUM_NEIGHBORS;
    case 17:
    case "AHP_LOCAL_PORT_SPEED":
      return AssertHistoryRecordProjectDimensionBy.AHP_LOCAL_PORT_SPEED;
    case 18:
    case "AHP_LAST_TRAFFIC_BYTES":
      return AssertHistoryRecordProjectDimensionBy.AHP_LAST_TRAFFIC_BYTES;
    case 19:
    case "AHP_RECENT_TRAFFIC_BYTES":
      return AssertHistoryRecordProjectDimensionBy.AHP_RECENT_TRAFFIC_BYTES;
    case 20:
    case "AHP_LAST_TRAFFIC_TIME":
      return AssertHistoryRecordProjectDimensionBy.AHP_LAST_TRAFFIC_TIME;
    case 21:
    case "AHP_VRF":
      return AssertHistoryRecordProjectDimensionBy.AHP_VRF;
    case 22:
    case "AHP_VLAN_STATE":
      return AssertHistoryRecordProjectDimensionBy.AHP_VLAN_STATE;
    case 23:
    case "AHP_MANAGEMENT_STATE":
      return AssertHistoryRecordProjectDimensionBy.AHP_MANAGEMENT_STATE;
    case 24:
    case "AHP_HIGH_CPU_UTIL":
      return AssertHistoryRecordProjectDimensionBy.AHP_HIGH_CPU_UTIL;
    case 25:
    case "AHP_HIGH_DISK_UTIL":
      return AssertHistoryRecordProjectDimensionBy.AHP_HIGH_DISK_UTIL;
    case 26:
    case "AHP_HIGH_MEM_UTIL":
      return AssertHistoryRecordProjectDimensionBy.AHP_HIGH_MEM_UTIL;
    case 27:
    case "AHP_PORT":
      return AssertHistoryRecordProjectDimensionBy.AHP_PORT;
    case 28:
    case "AHP_NODE_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_NODE_ID;
    case 29:
    case "AHP_REMOTE_NODE_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_NODE_ID;
    case 30:
    case "AHP_FABRIC":
      return AssertHistoryRecordProjectDimensionBy.AHP_FABRIC;
    case 31:
    case "AHP_TXN_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_TXN_ID;
    case 32:
    case "AHP_OBJECT_NAME":
      return AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_NAME;
    case 33:
    case "AHP_OBJECT_TYPE":
      return AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_TYPE;
    case 34:
    case "AHP_OBJECT_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_ID;
    case 35:
    case "AHP_PARENT_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_PARENT_ID;
    case 36:
    case "AHP_CHILD_ID":
      return AssertHistoryRecordProjectDimensionBy.AHP_CHILD_ID;
    case 37:
    case "AHP_USERNAME":
      return AssertHistoryRecordProjectDimensionBy.AHP_USERNAME;
    case 38:
    case "AHP_OPERATION":
      return AssertHistoryRecordProjectDimensionBy.AHP_OPERATION;
    case 39:
    case "AHP_ROW_COUNT":
      return AssertHistoryRecordProjectDimensionBy.AHP_ROW_COUNT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertHistoryRecordProjectDimensionBy.UNRECOGNIZED;
  }
}

export function assertHistoryRecordProjectDimensionByToJSON(object: AssertHistoryRecordProjectDimensionBy): string {
  switch (object) {
    case AssertHistoryRecordProjectDimensionBy.AHP_UNSPECIFIED:
      return "AHP_UNSPECIFIED";
    case AssertHistoryRecordProjectDimensionBy.AHP_TIME:
      return "AHP_TIME";
    case AssertHistoryRecordProjectDimensionBy.AHP_ASSERT_TYPE:
      return "AHP_ASSERT_TYPE";
    case AssertHistoryRecordProjectDimensionBy.AHP_CATEGORY:
      return "AHP_CATEGORY";
    case AssertHistoryRecordProjectDimensionBy.AHP_ASSERT_STATE:
      return "AHP_ASSERT_STATE";
    case AssertHistoryRecordProjectDimensionBy.AHP_DEVICE:
      return "AHP_DEVICE";
    case AssertHistoryRecordProjectDimensionBy.AHP_LINE_CARD:
      return "AHP_LINE_CARD";
    case AssertHistoryRecordProjectDimensionBy.AHP_BREAKOUT:
      return "AHP_BREAKOUT";
    case AssertHistoryRecordProjectDimensionBy.AHP_PORT_NAME:
      return "AHP_PORT_NAME";
    case AssertHistoryRecordProjectDimensionBy.AHP_MASKED:
      return "AHP_MASKED";
    case AssertHistoryRecordProjectDimensionBy.AHP_ORG_ID:
      return "AHP_ORG_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_LATCHED_AT:
      return "AHP_LATCHED_AT";
    case AssertHistoryRecordProjectDimensionBy.AHP_LATCH_TIME:
      return "AHP_LATCH_TIME";
    case AssertHistoryRecordProjectDimensionBy.AHP_IMPLICIT:
      return "AHP_IMPLICIT";
    case AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_PORT_NAME:
      return "AHP_REMOTE_PORT_NAME";
    case AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_DEVICE_ID:
      return "AHP_REMOTE_DEVICE_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_NUM_NEIGHBORS:
      return "AHP_NUM_NEIGHBORS";
    case AssertHistoryRecordProjectDimensionBy.AHP_LOCAL_PORT_SPEED:
      return "AHP_LOCAL_PORT_SPEED";
    case AssertHistoryRecordProjectDimensionBy.AHP_LAST_TRAFFIC_BYTES:
      return "AHP_LAST_TRAFFIC_BYTES";
    case AssertHistoryRecordProjectDimensionBy.AHP_RECENT_TRAFFIC_BYTES:
      return "AHP_RECENT_TRAFFIC_BYTES";
    case AssertHistoryRecordProjectDimensionBy.AHP_LAST_TRAFFIC_TIME:
      return "AHP_LAST_TRAFFIC_TIME";
    case AssertHistoryRecordProjectDimensionBy.AHP_VRF:
      return "AHP_VRF";
    case AssertHistoryRecordProjectDimensionBy.AHP_VLAN_STATE:
      return "AHP_VLAN_STATE";
    case AssertHistoryRecordProjectDimensionBy.AHP_MANAGEMENT_STATE:
      return "AHP_MANAGEMENT_STATE";
    case AssertHistoryRecordProjectDimensionBy.AHP_HIGH_CPU_UTIL:
      return "AHP_HIGH_CPU_UTIL";
    case AssertHistoryRecordProjectDimensionBy.AHP_HIGH_DISK_UTIL:
      return "AHP_HIGH_DISK_UTIL";
    case AssertHistoryRecordProjectDimensionBy.AHP_HIGH_MEM_UTIL:
      return "AHP_HIGH_MEM_UTIL";
    case AssertHistoryRecordProjectDimensionBy.AHP_PORT:
      return "AHP_PORT";
    case AssertHistoryRecordProjectDimensionBy.AHP_NODE_ID:
      return "AHP_NODE_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_REMOTE_NODE_ID:
      return "AHP_REMOTE_NODE_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_FABRIC:
      return "AHP_FABRIC";
    case AssertHistoryRecordProjectDimensionBy.AHP_TXN_ID:
      return "AHP_TXN_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_NAME:
      return "AHP_OBJECT_NAME";
    case AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_TYPE:
      return "AHP_OBJECT_TYPE";
    case AssertHistoryRecordProjectDimensionBy.AHP_OBJECT_ID:
      return "AHP_OBJECT_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_PARENT_ID:
      return "AHP_PARENT_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_CHILD_ID:
      return "AHP_CHILD_ID";
    case AssertHistoryRecordProjectDimensionBy.AHP_USERNAME:
      return "AHP_USERNAME";
    case AssertHistoryRecordProjectDimensionBy.AHP_OPERATION:
      return "AHP_OPERATION";
    case AssertHistoryRecordProjectDimensionBy.AHP_ROW_COUNT:
      return "AHP_ROW_COUNT";
    case AssertHistoryRecordProjectDimensionBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** AssertHistoryRecordGroupBy is the valid set of dimensions that can be grouped by. */
export enum AssertHistoryRecordGroupBy {
  /** AHG_UNSPECIFIED - AssertHistoryRecord GroupBy unspecified */
  AHG_UNSPECIFIED = 0,
  /** AHG_TIME - AssertHistoryRecord GroupBy time */
  AHG_TIME = 1,
  /** AHG_ASSERT_TYPE - AssertHistoryRecord GroupBy assert_type */
  AHG_ASSERT_TYPE = 2,
  /** AHG_CATEGORY - AssertHistoryRecord GroupBy category */
  AHG_CATEGORY = 3,
  /** AHG_ASSERT_STATE - AssertHistoryRecord GroupBy assert_state */
  AHG_ASSERT_STATE = 4,
  /** AHG_DEVICE - AssertHistoryRecord GroupBy device */
  AHG_DEVICE = 5,
  /** AHG_LINE_CARD - AssertHistoryRecord GroupBy line_card */
  AHG_LINE_CARD = 6,
  /** AHG_BREAKOUT - AssertHistoryRecord GroupBy breakout */
  AHG_BREAKOUT = 7,
  /** AHG_PORT_NAME - AssertHistoryRecord GroupBy port_name */
  AHG_PORT_NAME = 8,
  /** AHG_MASKED - AssertHistoryRecord GroupBy masked */
  AHG_MASKED = 9,
  /** AHG_ORG_ID - AssertHistoryRecord GroupBy org_id */
  AHG_ORG_ID = 10,
  /** AHG_LATCHED_AT - AssertHistoryRecord GroupBy latched_at */
  AHG_LATCHED_AT = 11,
  /** AHG_LATCH_TIME - AssertHistoryRecord GroupBy latch_time */
  AHG_LATCH_TIME = 12,
  /** AHG_IMPLICIT - AssertHistoryRecord GroupBy implicit */
  AHG_IMPLICIT = 13,
  /** AHG_REMOTE_PORT_NAME - AssertHistoryRecord GroupBy remote_port_name */
  AHG_REMOTE_PORT_NAME = 14,
  /** AHG_REMOTE_DEVICE_ID - AssertHistoryRecord GroupBy remote_device_id */
  AHG_REMOTE_DEVICE_ID = 15,
  /** AHG_NUM_NEIGHBORS - AssertHistoryRecord GroupBy num_neighbors */
  AHG_NUM_NEIGHBORS = 16,
  /** AHG_LOCAL_PORT_SPEED - AssertHistoryRecord GroupBy local_port_speed */
  AHG_LOCAL_PORT_SPEED = 17,
  /** AHG_LAST_TRAFFIC_BYTES - AssertHistoryRecord GroupBy last_traffic_bytes */
  AHG_LAST_TRAFFIC_BYTES = 18,
  /** AHG_RECENT_TRAFFIC_BYTES - AssertHistoryRecord GroupBy recent_traffic_bytes */
  AHG_RECENT_TRAFFIC_BYTES = 19,
  /** AHG_LAST_TRAFFIC_TIME - AssertHistoryRecord GroupBy last_traffic_time */
  AHG_LAST_TRAFFIC_TIME = 20,
  /** AHG_VRF - AssertHistoryRecord GroupBy vrf */
  AHG_VRF = 21,
  /** AHG_VLAN_STATE - AssertHistoryRecord GroupBy vlan_state */
  AHG_VLAN_STATE = 22,
  /** AHG_MANAGEMENT_STATE - AssertHistoryRecord GroupBy management_state */
  AHG_MANAGEMENT_STATE = 23,
  /** AHG_HIGH_CPU_UTIL - AssertHistoryRecord GroupBy high_cpu_util */
  AHG_HIGH_CPU_UTIL = 24,
  /** AHG_HIGH_DISK_UTIL - AssertHistoryRecord GroupBy high_disk_util */
  AHG_HIGH_DISK_UTIL = 25,
  /** AHG_HIGH_MEM_UTIL - AssertHistoryRecord GroupBy high_mem_util */
  AHG_HIGH_MEM_UTIL = 26,
  /** AHG_PORT - AssertHistoryRecord GroupBy port */
  AHG_PORT = 27,
  /** AHG_NODE_ID - AssertHistoryRecord GroupBy node_id */
  AHG_NODE_ID = 28,
  /** AHG_REMOTE_NODE_ID - AssertHistoryRecord GroupBy remote_node_id */
  AHG_REMOTE_NODE_ID = 29,
  /** AHG_FABRIC - AssertHistoryRecord GroupBy fabric */
  AHG_FABRIC = 30,
  /** AHG_TXN_ID - AssertHistoryRecord GroupBy txn_id */
  AHG_TXN_ID = 31,
  /** AHG_OBJECT_NAME - AssertHistoryRecord GroupBy object_name */
  AHG_OBJECT_NAME = 32,
  /** AHG_OBJECT_TYPE - AssertHistoryRecord GroupBy object_type */
  AHG_OBJECT_TYPE = 33,
  /** AHG_OBJECT_ID - AssertHistoryRecord GroupBy object_id */
  AHG_OBJECT_ID = 34,
  /** AHG_PARENT_ID - AssertHistoryRecord GroupBy parent_id */
  AHG_PARENT_ID = 35,
  /** AHG_CHILD_ID - AssertHistoryRecord GroupBy child_id */
  AHG_CHILD_ID = 36,
  /** AHG_USERNAME - AssertHistoryRecord GroupBy username */
  AHG_USERNAME = 37,
  /** AHG_OPERATION - AssertHistoryRecord GroupBy operation */
  AHG_OPERATION = 38,
  UNRECOGNIZED = -1,
}

export function assertHistoryRecordGroupByFromJSON(object: any): AssertHistoryRecordGroupBy {
  switch (object) {
    case 0:
    case "AHG_UNSPECIFIED":
      return AssertHistoryRecordGroupBy.AHG_UNSPECIFIED;
    case 1:
    case "AHG_TIME":
      return AssertHistoryRecordGroupBy.AHG_TIME;
    case 2:
    case "AHG_ASSERT_TYPE":
      return AssertHistoryRecordGroupBy.AHG_ASSERT_TYPE;
    case 3:
    case "AHG_CATEGORY":
      return AssertHistoryRecordGroupBy.AHG_CATEGORY;
    case 4:
    case "AHG_ASSERT_STATE":
      return AssertHistoryRecordGroupBy.AHG_ASSERT_STATE;
    case 5:
    case "AHG_DEVICE":
      return AssertHistoryRecordGroupBy.AHG_DEVICE;
    case 6:
    case "AHG_LINE_CARD":
      return AssertHistoryRecordGroupBy.AHG_LINE_CARD;
    case 7:
    case "AHG_BREAKOUT":
      return AssertHistoryRecordGroupBy.AHG_BREAKOUT;
    case 8:
    case "AHG_PORT_NAME":
      return AssertHistoryRecordGroupBy.AHG_PORT_NAME;
    case 9:
    case "AHG_MASKED":
      return AssertHistoryRecordGroupBy.AHG_MASKED;
    case 10:
    case "AHG_ORG_ID":
      return AssertHistoryRecordGroupBy.AHG_ORG_ID;
    case 11:
    case "AHG_LATCHED_AT":
      return AssertHistoryRecordGroupBy.AHG_LATCHED_AT;
    case 12:
    case "AHG_LATCH_TIME":
      return AssertHistoryRecordGroupBy.AHG_LATCH_TIME;
    case 13:
    case "AHG_IMPLICIT":
      return AssertHistoryRecordGroupBy.AHG_IMPLICIT;
    case 14:
    case "AHG_REMOTE_PORT_NAME":
      return AssertHistoryRecordGroupBy.AHG_REMOTE_PORT_NAME;
    case 15:
    case "AHG_REMOTE_DEVICE_ID":
      return AssertHistoryRecordGroupBy.AHG_REMOTE_DEVICE_ID;
    case 16:
    case "AHG_NUM_NEIGHBORS":
      return AssertHistoryRecordGroupBy.AHG_NUM_NEIGHBORS;
    case 17:
    case "AHG_LOCAL_PORT_SPEED":
      return AssertHistoryRecordGroupBy.AHG_LOCAL_PORT_SPEED;
    case 18:
    case "AHG_LAST_TRAFFIC_BYTES":
      return AssertHistoryRecordGroupBy.AHG_LAST_TRAFFIC_BYTES;
    case 19:
    case "AHG_RECENT_TRAFFIC_BYTES":
      return AssertHistoryRecordGroupBy.AHG_RECENT_TRAFFIC_BYTES;
    case 20:
    case "AHG_LAST_TRAFFIC_TIME":
      return AssertHistoryRecordGroupBy.AHG_LAST_TRAFFIC_TIME;
    case 21:
    case "AHG_VRF":
      return AssertHistoryRecordGroupBy.AHG_VRF;
    case 22:
    case "AHG_VLAN_STATE":
      return AssertHistoryRecordGroupBy.AHG_VLAN_STATE;
    case 23:
    case "AHG_MANAGEMENT_STATE":
      return AssertHistoryRecordGroupBy.AHG_MANAGEMENT_STATE;
    case 24:
    case "AHG_HIGH_CPU_UTIL":
      return AssertHistoryRecordGroupBy.AHG_HIGH_CPU_UTIL;
    case 25:
    case "AHG_HIGH_DISK_UTIL":
      return AssertHistoryRecordGroupBy.AHG_HIGH_DISK_UTIL;
    case 26:
    case "AHG_HIGH_MEM_UTIL":
      return AssertHistoryRecordGroupBy.AHG_HIGH_MEM_UTIL;
    case 27:
    case "AHG_PORT":
      return AssertHistoryRecordGroupBy.AHG_PORT;
    case 28:
    case "AHG_NODE_ID":
      return AssertHistoryRecordGroupBy.AHG_NODE_ID;
    case 29:
    case "AHG_REMOTE_NODE_ID":
      return AssertHistoryRecordGroupBy.AHG_REMOTE_NODE_ID;
    case 30:
    case "AHG_FABRIC":
      return AssertHistoryRecordGroupBy.AHG_FABRIC;
    case 31:
    case "AHG_TXN_ID":
      return AssertHistoryRecordGroupBy.AHG_TXN_ID;
    case 32:
    case "AHG_OBJECT_NAME":
      return AssertHistoryRecordGroupBy.AHG_OBJECT_NAME;
    case 33:
    case "AHG_OBJECT_TYPE":
      return AssertHistoryRecordGroupBy.AHG_OBJECT_TYPE;
    case 34:
    case "AHG_OBJECT_ID":
      return AssertHistoryRecordGroupBy.AHG_OBJECT_ID;
    case 35:
    case "AHG_PARENT_ID":
      return AssertHistoryRecordGroupBy.AHG_PARENT_ID;
    case 36:
    case "AHG_CHILD_ID":
      return AssertHistoryRecordGroupBy.AHG_CHILD_ID;
    case 37:
    case "AHG_USERNAME":
      return AssertHistoryRecordGroupBy.AHG_USERNAME;
    case 38:
    case "AHG_OPERATION":
      return AssertHistoryRecordGroupBy.AHG_OPERATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertHistoryRecordGroupBy.UNRECOGNIZED;
  }
}

export function assertHistoryRecordGroupByToJSON(object: AssertHistoryRecordGroupBy): string {
  switch (object) {
    case AssertHistoryRecordGroupBy.AHG_UNSPECIFIED:
      return "AHG_UNSPECIFIED";
    case AssertHistoryRecordGroupBy.AHG_TIME:
      return "AHG_TIME";
    case AssertHistoryRecordGroupBy.AHG_ASSERT_TYPE:
      return "AHG_ASSERT_TYPE";
    case AssertHistoryRecordGroupBy.AHG_CATEGORY:
      return "AHG_CATEGORY";
    case AssertHistoryRecordGroupBy.AHG_ASSERT_STATE:
      return "AHG_ASSERT_STATE";
    case AssertHistoryRecordGroupBy.AHG_DEVICE:
      return "AHG_DEVICE";
    case AssertHistoryRecordGroupBy.AHG_LINE_CARD:
      return "AHG_LINE_CARD";
    case AssertHistoryRecordGroupBy.AHG_BREAKOUT:
      return "AHG_BREAKOUT";
    case AssertHistoryRecordGroupBy.AHG_PORT_NAME:
      return "AHG_PORT_NAME";
    case AssertHistoryRecordGroupBy.AHG_MASKED:
      return "AHG_MASKED";
    case AssertHistoryRecordGroupBy.AHG_ORG_ID:
      return "AHG_ORG_ID";
    case AssertHistoryRecordGroupBy.AHG_LATCHED_AT:
      return "AHG_LATCHED_AT";
    case AssertHistoryRecordGroupBy.AHG_LATCH_TIME:
      return "AHG_LATCH_TIME";
    case AssertHistoryRecordGroupBy.AHG_IMPLICIT:
      return "AHG_IMPLICIT";
    case AssertHistoryRecordGroupBy.AHG_REMOTE_PORT_NAME:
      return "AHG_REMOTE_PORT_NAME";
    case AssertHistoryRecordGroupBy.AHG_REMOTE_DEVICE_ID:
      return "AHG_REMOTE_DEVICE_ID";
    case AssertHistoryRecordGroupBy.AHG_NUM_NEIGHBORS:
      return "AHG_NUM_NEIGHBORS";
    case AssertHistoryRecordGroupBy.AHG_LOCAL_PORT_SPEED:
      return "AHG_LOCAL_PORT_SPEED";
    case AssertHistoryRecordGroupBy.AHG_LAST_TRAFFIC_BYTES:
      return "AHG_LAST_TRAFFIC_BYTES";
    case AssertHistoryRecordGroupBy.AHG_RECENT_TRAFFIC_BYTES:
      return "AHG_RECENT_TRAFFIC_BYTES";
    case AssertHistoryRecordGroupBy.AHG_LAST_TRAFFIC_TIME:
      return "AHG_LAST_TRAFFIC_TIME";
    case AssertHistoryRecordGroupBy.AHG_VRF:
      return "AHG_VRF";
    case AssertHistoryRecordGroupBy.AHG_VLAN_STATE:
      return "AHG_VLAN_STATE";
    case AssertHistoryRecordGroupBy.AHG_MANAGEMENT_STATE:
      return "AHG_MANAGEMENT_STATE";
    case AssertHistoryRecordGroupBy.AHG_HIGH_CPU_UTIL:
      return "AHG_HIGH_CPU_UTIL";
    case AssertHistoryRecordGroupBy.AHG_HIGH_DISK_UTIL:
      return "AHG_HIGH_DISK_UTIL";
    case AssertHistoryRecordGroupBy.AHG_HIGH_MEM_UTIL:
      return "AHG_HIGH_MEM_UTIL";
    case AssertHistoryRecordGroupBy.AHG_PORT:
      return "AHG_PORT";
    case AssertHistoryRecordGroupBy.AHG_NODE_ID:
      return "AHG_NODE_ID";
    case AssertHistoryRecordGroupBy.AHG_REMOTE_NODE_ID:
      return "AHG_REMOTE_NODE_ID";
    case AssertHistoryRecordGroupBy.AHG_FABRIC:
      return "AHG_FABRIC";
    case AssertHistoryRecordGroupBy.AHG_TXN_ID:
      return "AHG_TXN_ID";
    case AssertHistoryRecordGroupBy.AHG_OBJECT_NAME:
      return "AHG_OBJECT_NAME";
    case AssertHistoryRecordGroupBy.AHG_OBJECT_TYPE:
      return "AHG_OBJECT_TYPE";
    case AssertHistoryRecordGroupBy.AHG_OBJECT_ID:
      return "AHG_OBJECT_ID";
    case AssertHistoryRecordGroupBy.AHG_PARENT_ID:
      return "AHG_PARENT_ID";
    case AssertHistoryRecordGroupBy.AHG_CHILD_ID:
      return "AHG_CHILD_ID";
    case AssertHistoryRecordGroupBy.AHG_USERNAME:
      return "AHG_USERNAME";
    case AssertHistoryRecordGroupBy.AHG_OPERATION:
      return "AHG_OPERATION";
    case AssertHistoryRecordGroupBy.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PortStateHistoryRecord {
  /** Time at which the port stats were collected by agent. */
  time:
    | Date
    | undefined;
  /** Unique identifier of a fabric instance. */
  fabric: string;
  /** Unique identifier of a chassis within a fabric, eg. chassis/lc id. */
  device: string;
  /** Unique identifier of a port within a device */
  portId: string;
  /** port admin state */
  adminState: AdminState;
  /** port operation state */
  opState: OperState;
  /** port ipv4 address */
  ipV4: string;
  /** Org UUID that this peering session belongs to. */
  orgId: string;
  /** Standard count metric */
  rowCount: number;
}

/** The top-level request object that encapsulates the port_state_history_record query parameters. */
export interface PortStateHistoryRecordRequest {
  /** Time at which the port stats were collected by agent. */
  startTime:
    | Date
    | undefined;
  /** Time at which the port stats were collected by agent. */
  endTime:
    | Date
    | undefined;
  /** Organization UUID String */
  orgId: string;
  /** REQUIRED: dimensions that would like to be projected (analogous to a 'SELECT' clause in SQL). */
  projections: PortStateHistoryRecordProjectDimensionBy[];
  /** Grouping  (analogous to a 'GROUP BY' clause in SQL) */
  groupings: PortStateHistoryRecordGroupBy[];
  /** Order by. Specifies the column and sort order (analogous to a 'ORDER BY' clause in SQL. */
  orderings: PortStateHistoryRecordUpdateOrderBy[];
  /** The filter condition to apply on the projected columns (analogous to a 'WHERE' clause in SQL). */
  where:
    | PortStateHistoryRecordChainExpression
    | undefined;
  /** The filter condition to apply on the projected columns (analogous to a 'HAVING' clause in SQL). */
  having:
    | PortStateHistoryRecordChainExpression
    | undefined;
  /** The query granularity interval. Mutually exclusive with num_buckets. */
  granularity: Granularity;
  /** The metadata about the request. */
  metadata: RequestMetadata | undefined;
  numBuckets: number;
  /** The common pagination properties in the request message. */
  pagination:
    | PaginationRequest
    | undefined;
  /** The flag for not using a fudge factor when calculating the number of records to return. */
  noFudgeFactor: boolean;
}

/** The top-level response object that encapsulates the port_state_history_record query response data. */
export interface PortStateHistoryRecordResponse {
  /** A list of PortStateHistoryRecord update events. */
  portStateHistoryRecord: PortStateHistoryRecord[];
  /** The common pagination properties in the response message */
  pagination: PaginationResponse | undefined;
}

/** The message that defines the query column and sort order. */
export interface PortStateHistoryRecordUpdateOrderBy {
  /** The column to be ordered by.. */
  orderBy: PortStateHistoryRecordProjectDimensionBy;
  /** The sort order corresponding to the above column. */
  sortOrder: SortOrder;
}

/**
 * The message that defines the simple filter rule such as:
 * {PP_FABRIC_ID, EQUALS, '1540E619-5D70-428C-AF57-DD598156C505'}.
 */
export interface PortStateHistoryRecordSimpleExpression {
  /** Operand. */
  operand: PortStateHistoryRecordProjectDimensionBy;
  /** Operator separating the operand and the value. */
  operator: Operator;
  /** A context unaware value. */
  value: string;
}

/**
 * The message that defines a combination of one or more PortStateHistoryRecordSimpleExpression separated by an operand.
 * combined with logical operators.
 */
export interface PortStateHistoryRecordChainExpression {
  /** Operand. */
  expressions: PortStateHistoryRecordChainExpression[];
  /** One or more operators separating the expressions (valid if len(expressions) > 1). */
  operators: ChainOperator[];
  /** Operand. */
  simpleExpression: PortStateHistoryRecordSimpleExpression | undefined;
}

export interface PortCounterHistoryRecord {
  /** Time at which the port stats were collected by agent. */
  time:
    | Date
    | undefined;
  /** Unique identifier of a fabric instance. */
  fabric: string;
  /** Unique identifier of a chassis within a fabric, eg. chassis/lc id. */
  device: string;
  /** Unique identifier of a port within a device */
  portId: string;
  /** Org UUID that this peering session belongs to. */
  orgId: string;
  /** total input byte count. */
  inOctets: number;
  /** total input packet count. */
  inPkts: number;
  /** total input packets discarded. */
  inDiscards: number;
  /** total input error packets. */
  inErrors: number;
  /** total output byte count. */
  outOctets: number;
  /** total output packet count. */
  outPkts: number;
  /** total output packets discarded. */
  outDiscards: number;
  /** total output error packets. */
  outErrors: number;
  /** total in pfc0 packets. */
  inPfc0Pkts: number;
  /** total out pfc0 packets. */
  outPfc0Pkts: number;
  /** total in pfc1 packets. */
  inPfc1Pkts: number;
  /** total out pfc1 packets. */
  outPfc1Pkts: number;
  /** total in pfc2 packets. */
  inPfc2Pkts: number;
  /** total out pfc2 packets. */
  outPfc2Pkts: number;
  /** total in pfc3 packets. */
  inPfc3Pkts: number;
  /** total out pfc3 packets. */
  outPfc3Pkts: number;
  /** total in pfc4 packets. */
  inPfc4Pkts: number;
  /** total out pfc4 packets. */
  outPfc4Pkts: number;
  /** total in pfc5 packets. */
  inPfc5Pkts: number;
  /** total out pfc5 packets. */
  outPfc5Pkts: number;
  /** total in pfc6 packets. */
  inPfc6Pkts: number;
  /** total out pfc6 packets. */
  outPfc6Pkts: number;
  /** total in pfc7 packets. */
  inPfc7Pkts: number;
  /** total out pfc7 packets. */
  outPfc7Pkts: number;
  /** Standard count metric */
  rowCount: number;
  /** rx bit rate */
  rxMinuteRate: number;
  /** tx bit rate */
  txMinuteRate: number;
}

/** The top-level request object that encapsulates the port_counter_history_record query parameters. */
export interface PortCounterHistoryRecordRequest {
  /** Time at which the port stats were collected by agent. */
  startTime:
    | Date
    | undefined;
  /** Time at which the port stats were collected by agent. */
  endTime:
    | Date
    | undefined;
  /** Organization UUID String */
  orgId: string;
  /** REQUIRED: dimensions that would like to be projected (analogous to a 'SELECT' clause in SQL). */
  projections: PortCounterHistoryRecordProjectDimensionBy[];
  /** Grouping  (analogous to a 'GROUP BY' clause in SQL) */
  groupings: PortCounterHistoryRecordGroupBy[];
  /** Order by. Specifies the column and sort order (analogous to a 'ORDER BY' clause in SQL. */
  orderings: PortCounterHistoryRecordUpdateOrderBy[];
  /** The filter condition to apply on the projected columns (analogous to a 'WHERE' clause in SQL). */
  where:
    | PortCounterHistoryRecordChainExpression
    | undefined;
  /** The filter condition to apply on the projected columns (analogous to a 'HAVING' clause in SQL). */
  having:
    | PortCounterHistoryRecordChainExpression
    | undefined;
  /** The query granularity interval. Mutually exclusive with num_buckets. */
  granularity: Granularity;
  /** The metadata about the request. */
  metadata: RequestMetadata | undefined;
  numBuckets: number;
  /** The common pagination properties in the request message. */
  pagination:
    | PaginationRequest
    | undefined;
  /** The flag for not using a fudge factor when calculating the number of records to return. */
  noFudgeFactor: boolean;
}

/** The top-level response object that encapsulates the port_counter_history_record query response data. */
export interface PortCounterHistoryRecordResponse {
  /** A list of PortCounterHistoryRecord update events. */
  portCounterHistoryRecord: PortCounterHistoryRecord[];
  /** The common pagination properties in the response message */
  pagination: PaginationResponse | undefined;
}

/** The message that defines the query column and sort order. */
export interface PortCounterHistoryRecordUpdateOrderBy {
  /** The column to be ordered by.. */
  orderBy: PortCounterHistoryRecordProjectDimensionBy;
  /** The sort order corresponding to the above column. */
  sortOrder: SortOrder;
}

/**
 * The message that defines the simple filter rule such as:
 * {PP_FABRIC_ID, EQUALS, '1540E619-5D70-428C-AF57-DD598156C505'}.
 */
export interface PortCounterHistoryRecordSimpleExpression {
  /** Operand. */
  operand: PortCounterHistoryRecordProjectDimensionBy;
  /** Operator separating the operand and the value. */
  operator: Operator;
  /** A context unaware value. */
  value: string;
}

/**
 * The message that defines a combination of one or more PortCounterHistoryRecordSimpleExpression separated by an operand.
 * combined with logical operators.
 */
export interface PortCounterHistoryRecordChainExpression {
  /** Operand. */
  expressions: PortCounterHistoryRecordChainExpression[];
  /** One or more operators separating the expressions (valid if len(expressions) > 1). */
  operators: ChainOperator[];
  /** Operand. */
  simpleExpression: PortCounterHistoryRecordSimpleExpression | undefined;
}

export interface AssertHistoryRecord {
  /** Time at which the assertion were modified. */
  time:
    | Date
    | undefined;
  /** The assert type value as set in the schema-path. */
  assertType: string;
  /** The assertion category */
  category: string;
  /** The assertion state, new or resolved */
  assertState: string;
  /** device mac address string */
  device: string;
  /** line_card as in various assert schema-paths */
  lineCard: string;
  /** breakout as in various assert schema-paths */
  breakout: string;
  /** port name as set in the assert config data. */
  portName: string;
  /** The flag indicates if the assertion is masked. */
  masked: string;
  /** The Org UUID as set in the assert config data. */
  orgId: string;
  /** latched_at as in all types of assert config data. */
  latchedAt: number;
  /** The latch_time as in all types of assert config data */
  latchTime: number;
  /** implicit assert flag as set in assert config data */
  implicit: string;
  /** remote_port_name is the LldpRemPortDesc stored in AssertPortLinkDown and AssertPortExpectedNeighbor */
  remotePortName: string;
  /** remote device mac address */
  remoteDeviceId: string;
  /** num_neighbors as in AssertPortExpectedNeighbor */
  numNeighbors: string;
  /** local_port_speed as in AssertPortConnectionSpeedMatch */
  localPortSpeed: number;
  /** last_traffic_bytes as in AssertVlanHasTraffic */
  lastTrafficBytes: number;
  /** last_traffic_bytes as in AssertVlanHasTraffic */
  recentTrafficBytes: number;
  /** last_traffic_time as in AssertVlanHasTraffic */
  lastTrafficTime: number;
  /** vrf as set in AssertStaticDefaultRouteExists. */
  vrf: string;
  /** vlan_state as in SchemaPathAssertConfigVlanHasTraffic. */
  vlanState: string;
  /** The management state as set in the config data. */
  managementState: string;
  /** The high_cpu_util flag as set in AssertHighSwitchResourceUsage. */
  highCpuUtil: string;
  /** The high_disk_util as set in AssertHighSwitchResourceUsage. */
  highDiskUtil: string;
  /** The high_mem_util as set in AssertHighSwitchResourceUsage. */
  highMemUtil: string;
  /** Unique identifier of a port within a device */
  port: string;
  /** The node uuid associated with the device */
  nodeId: string;
  /** The node uuid associated with the remote device */
  remoteNodeId: string;
  /** The fabric uuid */
  fabric: string;
  /** The database transaction id */
  txnId: string;
  /** The user defined object name.. */
  objectName: string;
  /** The type of the object that triggered the event. */
  objectType: string;
  /** The object identifier. */
  objectId: string;
  /** The object identifier of parent object. */
  parentId: string;
  /** The object identifier of child object. */
  childId: string;
  /** The user who triggered the activity event. */
  username: string;
  /** The type of user operation. */
  operation: string;
  /** Standard count metric */
  rowCount: number;
}

/** The top-level request object that encapsulates the assert_history_record query parameters. */
export interface AssertHistoryRecordRequest {
  /** Time at which the assertion were modified. */
  startTime:
    | Date
    | undefined;
  /** Time at which the assertion were modified. */
  endTime:
    | Date
    | undefined;
  /** Organization UUID String */
  orgId: string;
  /** REQUIRED: dimensions that would like to be projected (analogous to a 'SELECT' clause in SQL). */
  projections: AssertHistoryRecordProjectDimensionBy[];
  /** Grouping  (analogous to a 'GROUP BY' clause in SQL) */
  groupings: AssertHistoryRecordGroupBy[];
  /** Order by. Specifies the column and sort order (analogous to a 'ORDER BY' clause in SQL. */
  orderings: AssertHistoryRecordUpdateOrderBy[];
  /** The filter condition to apply on the projected columns (analogous to a 'WHERE' clause in SQL). */
  where:
    | AssertHistoryRecordChainExpression
    | undefined;
  /** The filter condition to apply on the projected columns (analogous to a 'HAVING' clause in SQL). */
  having:
    | AssertHistoryRecordChainExpression
    | undefined;
  /** The query granularity interval. Mutually exclusive with num_buckets. */
  granularity: Granularity;
  /** The metadata about the request. */
  metadata: RequestMetadata | undefined;
  numBuckets: number;
  /** The common pagination properties in the request message. */
  pagination:
    | PaginationRequest
    | undefined;
  /** The flag for not using a fudge factor when calculating the number of records to return. */
  noFudgeFactor: boolean;
}

/** The top-level response object that encapsulates the assert_history_record query response data. */
export interface AssertHistoryRecordResponse {
  /** A list of AssertHistoryRecord update events. */
  assertHistoryRecord: AssertHistoryRecord[];
  /** The common pagination properties in the response message */
  pagination: PaginationResponse | undefined;
}

/** The message that defines the query column and sort order. */
export interface AssertHistoryRecordUpdateOrderBy {
  /** The column to be ordered by.. */
  orderBy: AssertHistoryRecordProjectDimensionBy;
  /** The sort order corresponding to the above column. */
  sortOrder: SortOrder;
}

/**
 * The message that defines the simple filter rule such as:
 * {PP_FABRIC_ID, EQUALS, '1540E619-5D70-428C-AF57-DD598156C505'}.
 */
export interface AssertHistoryRecordSimpleExpression {
  /** Operand. */
  operand: AssertHistoryRecordProjectDimensionBy;
  /** Operator separating the operand and the value. */
  operator: Operator;
  /** A context unaware value. */
  value: string;
}

/**
 * The message that defines a combination of one or more AssertHistoryRecordSimpleExpression separated by an operand.
 * combined with logical operators.
 */
export interface AssertHistoryRecordChainExpression {
  /** Operand. */
  expressions: AssertHistoryRecordChainExpression[];
  /** One or more operators separating the expressions (valid if len(expressions) > 1). */
  operators: ChainOperator[];
  /** Operand. */
  simpleExpression: AssertHistoryRecordSimpleExpression | undefined;
}

function createBasePortStateHistoryRecord(): PortStateHistoryRecord {
  return {
    time: undefined,
    fabric: "",
    device: "",
    portId: "",
    adminState: 0,
    opState: 0,
    ipV4: "",
    orgId: "",
    rowCount: 0,
  };
}

export const PortStateHistoryRecord = {
  encode(message: PortStateHistoryRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
    }
    if (message.fabric !== "") {
      writer.uint32(18).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.portId !== "") {
      writer.uint32(34).string(message.portId);
    }
    if (message.adminState !== 0) {
      writer.uint32(40).int32(message.adminState);
    }
    if (message.opState !== 0) {
      writer.uint32(48).int32(message.opState);
    }
    if (message.ipV4 !== "") {
      writer.uint32(58).string(message.ipV4);
    }
    if (message.orgId !== "") {
      writer.uint32(66).string(message.orgId);
    }
    if (message.rowCount !== 0) {
      writer.uint32(800).int64(message.rowCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.portId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.opState = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipV4 = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.rowCount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecord {
    return {
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      portId: isSet(object.port_id) ? globalThis.String(object.port_id) : "",
      adminState: isSet(object.admin_state) ? adminStateFromJSON(object.admin_state) : 0,
      opState: isSet(object.op_state) ? operStateFromJSON(object.op_state) : 0,
      ipV4: isSet(object.ip_v4) ? globalThis.String(object.ip_v4) : "",
      orgId: isSet(object.org_id) ? globalThis.String(object.org_id) : "",
      rowCount: isSet(object.row_count) ? globalThis.Number(object.row_count) : 0,
    };
  },

  toJSON(message: PortStateHistoryRecord): unknown {
    const obj: any = {};
    if (message.time !== undefined) {
      obj.time = message.time.toISOString();
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.portId !== "") {
      obj.port_id = message.portId;
    }
    if (message.adminState !== 0) {
      obj.admin_state = adminStateToJSON(message.adminState);
    }
    if (message.opState !== 0) {
      obj.op_state = operStateToJSON(message.opState);
    }
    if (message.ipV4 !== "") {
      obj.ip_v4 = message.ipV4;
    }
    if (message.orgId !== "") {
      obj.org_id = message.orgId;
    }
    if (message.rowCount !== 0) {
      obj.row_count = Math.round(message.rowCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecord>, I>>(base?: I): PortStateHistoryRecord {
    return PortStateHistoryRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecord>, I>>(object: I): PortStateHistoryRecord {
    const message = createBasePortStateHistoryRecord();
    message.time = object.time ?? undefined;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.portId = object.portId ?? "";
    message.adminState = object.adminState ?? 0;
    message.opState = object.opState ?? 0;
    message.ipV4 = object.ipV4 ?? "";
    message.orgId = object.orgId ?? "";
    message.rowCount = object.rowCount ?? 0;
    return message;
  },
};

function createBasePortStateHistoryRecordRequest(): PortStateHistoryRecordRequest {
  return {
    startTime: undefined,
    endTime: undefined,
    orgId: "",
    projections: [],
    groupings: [],
    orderings: [],
    where: undefined,
    having: undefined,
    granularity: 0,
    metadata: undefined,
    numBuckets: 0,
    pagination: undefined,
    noFudgeFactor: false,
  };
}

export const PortStateHistoryRecordRequest = {
  encode(message: PortStateHistoryRecordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    writer.uint32(34).fork();
    for (const v of message.projections) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.groupings) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.orderings) {
      PortStateHistoryRecordUpdateOrderBy.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.where !== undefined) {
      PortStateHistoryRecordChainExpression.encode(message.where, writer.uint32(58).fork()).ldelim();
    }
    if (message.having !== undefined) {
      PortStateHistoryRecordChainExpression.encode(message.having, writer.uint32(66).fork()).ldelim();
    }
    if (message.granularity !== 0) {
      writer.uint32(72).int32(message.granularity);
    }
    if (message.metadata !== undefined) {
      RequestMetadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    if (message.numBuckets !== 0) {
      writer.uint32(88).uint32(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(98).fork()).ldelim();
    }
    if (message.noFudgeFactor !== false) {
      writer.uint32(104).bool(message.noFudgeFactor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag === 32) {
            message.projections.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.projections.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.groupings.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.groupings.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orderings.push(PortStateHistoryRecordUpdateOrderBy.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.where = PortStateHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.having = PortStateHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = RequestMetadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.numBuckets = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.noFudgeFactor = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecordRequest {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      projections: globalThis.Array.isArray(object?.projections)
        ? object.projections.map((e: any) => portStateHistoryRecordProjectDimensionByFromJSON(e))
        : [],
      groupings: globalThis.Array.isArray(object?.groupings)
        ? object.groupings.map((e: any) => portStateHistoryRecordGroupByFromJSON(e))
        : [],
      orderings: globalThis.Array.isArray(object?.orderings)
        ? object.orderings.map((e: any) => PortStateHistoryRecordUpdateOrderBy.fromJSON(e))
        : [],
      where: isSet(object.where) ? PortStateHistoryRecordChainExpression.fromJSON(object.where) : undefined,
      having: isSet(object.having) ? PortStateHistoryRecordChainExpression.fromJSON(object.having) : undefined,
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      metadata: isSet(object.metadata) ? RequestMetadata.fromJSON(object.metadata) : undefined,
      numBuckets: isSet(object.numBuckets) ? globalThis.Number(object.numBuckets) : 0,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
      noFudgeFactor: isSet(object.noFudgeFactor) ? globalThis.Boolean(object.noFudgeFactor) : false,
    };
  },

  toJSON(message: PortStateHistoryRecordRequest): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.projections?.length) {
      obj.projections = message.projections.map((e) => portStateHistoryRecordProjectDimensionByToJSON(e));
    }
    if (message.groupings?.length) {
      obj.groupings = message.groupings.map((e) => portStateHistoryRecordGroupByToJSON(e));
    }
    if (message.orderings?.length) {
      obj.orderings = message.orderings.map((e) => PortStateHistoryRecordUpdateOrderBy.toJSON(e));
    }
    if (message.where !== undefined) {
      obj.where = PortStateHistoryRecordChainExpression.toJSON(message.where);
    }
    if (message.having !== undefined) {
      obj.having = PortStateHistoryRecordChainExpression.toJSON(message.having);
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.metadata !== undefined) {
      obj.metadata = RequestMetadata.toJSON(message.metadata);
    }
    if (message.numBuckets !== 0) {
      obj.numBuckets = Math.round(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    if (message.noFudgeFactor !== false) {
      obj.noFudgeFactor = message.noFudgeFactor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecordRequest>, I>>(base?: I): PortStateHistoryRecordRequest {
    return PortStateHistoryRecordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecordRequest>, I>>(
    object: I,
  ): PortStateHistoryRecordRequest {
    const message = createBasePortStateHistoryRecordRequest();
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.orgId = object.orgId ?? "";
    message.projections = object.projections?.map((e) => e) || [];
    message.groupings = object.groupings?.map((e) => e) || [];
    message.orderings = object.orderings?.map((e) => PortStateHistoryRecordUpdateOrderBy.fromPartial(e)) || [];
    message.where = (object.where !== undefined && object.where !== null)
      ? PortStateHistoryRecordChainExpression.fromPartial(object.where)
      : undefined;
    message.having = (object.having !== undefined && object.having !== null)
      ? PortStateHistoryRecordChainExpression.fromPartial(object.having)
      : undefined;
    message.granularity = object.granularity ?? 0;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? RequestMetadata.fromPartial(object.metadata)
      : undefined;
    message.numBuckets = object.numBuckets ?? 0;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    message.noFudgeFactor = object.noFudgeFactor ?? false;
    return message;
  },
};

function createBasePortStateHistoryRecordResponse(): PortStateHistoryRecordResponse {
  return { portStateHistoryRecord: [], pagination: undefined };
}

export const PortStateHistoryRecordResponse = {
  encode(message: PortStateHistoryRecordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.portStateHistoryRecord) {
      PortStateHistoryRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.portStateHistoryRecord.push(PortStateHistoryRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecordResponse {
    return {
      portStateHistoryRecord: globalThis.Array.isArray(object?.portStateHistoryRecord)
        ? object.portStateHistoryRecord.map((e: any) => PortStateHistoryRecord.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: PortStateHistoryRecordResponse): unknown {
    const obj: any = {};
    if (message.portStateHistoryRecord?.length) {
      obj.portStateHistoryRecord = message.portStateHistoryRecord.map((e) => PortStateHistoryRecord.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecordResponse>, I>>(base?: I): PortStateHistoryRecordResponse {
    return PortStateHistoryRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecordResponse>, I>>(
    object: I,
  ): PortStateHistoryRecordResponse {
    const message = createBasePortStateHistoryRecordResponse();
    message.portStateHistoryRecord = object.portStateHistoryRecord?.map((e) => PortStateHistoryRecord.fromPartial(e)) ||
      [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBasePortStateHistoryRecordUpdateOrderBy(): PortStateHistoryRecordUpdateOrderBy {
  return { orderBy: 0, sortOrder: 0 };
}

export const PortStateHistoryRecordUpdateOrderBy = {
  encode(message: PortStateHistoryRecordUpdateOrderBy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderBy !== 0) {
      writer.uint32(8).int32(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      writer.uint32(16).int32(message.sortOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecordUpdateOrderBy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecordUpdateOrderBy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sortOrder = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecordUpdateOrderBy {
    return {
      orderBy: isSet(object.orderBy) ? portStateHistoryRecordProjectDimensionByFromJSON(object.orderBy) : 0,
      sortOrder: isSet(object.sortOrder) ? sortOrderFromJSON(object.sortOrder) : 0,
    };
  },

  toJSON(message: PortStateHistoryRecordUpdateOrderBy): unknown {
    const obj: any = {};
    if (message.orderBy !== 0) {
      obj.orderBy = portStateHistoryRecordProjectDimensionByToJSON(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      obj.sortOrder = sortOrderToJSON(message.sortOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecordUpdateOrderBy>, I>>(
    base?: I,
  ): PortStateHistoryRecordUpdateOrderBy {
    return PortStateHistoryRecordUpdateOrderBy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecordUpdateOrderBy>, I>>(
    object: I,
  ): PortStateHistoryRecordUpdateOrderBy {
    const message = createBasePortStateHistoryRecordUpdateOrderBy();
    message.orderBy = object.orderBy ?? 0;
    message.sortOrder = object.sortOrder ?? 0;
    return message;
  },
};

function createBasePortStateHistoryRecordSimpleExpression(): PortStateHistoryRecordSimpleExpression {
  return { operand: 0, operator: 0, value: "" };
}

export const PortStateHistoryRecordSimpleExpression = {
  encode(message: PortStateHistoryRecordSimpleExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operand !== 0) {
      writer.uint32(8).int32(message.operand);
    }
    if (message.operator !== 0) {
      writer.uint32(16).int32(message.operator);
    }
    if (message.value !== "") {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecordSimpleExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecordSimpleExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.operand = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecordSimpleExpression {
    return {
      operand: isSet(object.operand) ? portStateHistoryRecordProjectDimensionByFromJSON(object.operand) : 0,
      operator: isSet(object.operator) ? operatorFromJSON(object.operator) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: PortStateHistoryRecordSimpleExpression): unknown {
    const obj: any = {};
    if (message.operand !== 0) {
      obj.operand = portStateHistoryRecordProjectDimensionByToJSON(message.operand);
    }
    if (message.operator !== 0) {
      obj.operator = operatorToJSON(message.operator);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecordSimpleExpression>, I>>(
    base?: I,
  ): PortStateHistoryRecordSimpleExpression {
    return PortStateHistoryRecordSimpleExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecordSimpleExpression>, I>>(
    object: I,
  ): PortStateHistoryRecordSimpleExpression {
    const message = createBasePortStateHistoryRecordSimpleExpression();
    message.operand = object.operand ?? 0;
    message.operator = object.operator ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePortStateHistoryRecordChainExpression(): PortStateHistoryRecordChainExpression {
  return { expressions: [], operators: [], simpleExpression: undefined };
}

export const PortStateHistoryRecordChainExpression = {
  encode(message: PortStateHistoryRecordChainExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.expressions) {
      PortStateHistoryRecordChainExpression.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.operators) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.simpleExpression !== undefined) {
      PortStateHistoryRecordSimpleExpression.encode(message.simpleExpression, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortStateHistoryRecordChainExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortStateHistoryRecordChainExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expressions.push(PortStateHistoryRecordChainExpression.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag === 16) {
            message.operators.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.operators.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.simpleExpression = PortStateHistoryRecordSimpleExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortStateHistoryRecordChainExpression {
    return {
      expressions: globalThis.Array.isArray(object?.expressions)
        ? object.expressions.map((e: any) => PortStateHistoryRecordChainExpression.fromJSON(e))
        : [],
      operators: globalThis.Array.isArray(object?.operators)
        ? object.operators.map((e: any) => chainOperatorFromJSON(e))
        : [],
      simpleExpression: isSet(object.simpleExpression)
        ? PortStateHistoryRecordSimpleExpression.fromJSON(object.simpleExpression)
        : undefined,
    };
  },

  toJSON(message: PortStateHistoryRecordChainExpression): unknown {
    const obj: any = {};
    if (message.expressions?.length) {
      obj.expressions = message.expressions.map((e) => PortStateHistoryRecordChainExpression.toJSON(e));
    }
    if (message.operators?.length) {
      obj.operators = message.operators.map((e) => chainOperatorToJSON(e));
    }
    if (message.simpleExpression !== undefined) {
      obj.simpleExpression = PortStateHistoryRecordSimpleExpression.toJSON(message.simpleExpression);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortStateHistoryRecordChainExpression>, I>>(
    base?: I,
  ): PortStateHistoryRecordChainExpression {
    return PortStateHistoryRecordChainExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortStateHistoryRecordChainExpression>, I>>(
    object: I,
  ): PortStateHistoryRecordChainExpression {
    const message = createBasePortStateHistoryRecordChainExpression();
    message.expressions = object.expressions?.map((e) => PortStateHistoryRecordChainExpression.fromPartial(e)) || [];
    message.operators = object.operators?.map((e) => e) || [];
    message.simpleExpression = (object.simpleExpression !== undefined && object.simpleExpression !== null)
      ? PortStateHistoryRecordSimpleExpression.fromPartial(object.simpleExpression)
      : undefined;
    return message;
  },
};

function createBasePortCounterHistoryRecord(): PortCounterHistoryRecord {
  return {
    time: undefined,
    fabric: "",
    device: "",
    portId: "",
    orgId: "",
    inOctets: 0,
    inPkts: 0,
    inDiscards: 0,
    inErrors: 0,
    outOctets: 0,
    outPkts: 0,
    outDiscards: 0,
    outErrors: 0,
    inPfc0Pkts: 0,
    outPfc0Pkts: 0,
    inPfc1Pkts: 0,
    outPfc1Pkts: 0,
    inPfc2Pkts: 0,
    outPfc2Pkts: 0,
    inPfc3Pkts: 0,
    outPfc3Pkts: 0,
    inPfc4Pkts: 0,
    outPfc4Pkts: 0,
    inPfc5Pkts: 0,
    outPfc5Pkts: 0,
    inPfc6Pkts: 0,
    outPfc6Pkts: 0,
    inPfc7Pkts: 0,
    outPfc7Pkts: 0,
    rowCount: 0,
    rxMinuteRate: 0,
    txMinuteRate: 0,
  };
}

export const PortCounterHistoryRecord = {
  encode(message: PortCounterHistoryRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
    }
    if (message.fabric !== "") {
      writer.uint32(18).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.portId !== "") {
      writer.uint32(34).string(message.portId);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.inOctets !== 0) {
      writer.uint32(80).int64(message.inOctets);
    }
    if (message.inPkts !== 0) {
      writer.uint32(88).int64(message.inPkts);
    }
    if (message.inDiscards !== 0) {
      writer.uint32(96).int64(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      writer.uint32(104).int64(message.inErrors);
    }
    if (message.outOctets !== 0) {
      writer.uint32(160).int64(message.outOctets);
    }
    if (message.outPkts !== 0) {
      writer.uint32(168).int64(message.outPkts);
    }
    if (message.outDiscards !== 0) {
      writer.uint32(176).int64(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      writer.uint32(184).int64(message.outErrors);
    }
    if (message.inPfc0Pkts !== 0) {
      writer.uint32(192).int64(message.inPfc0Pkts);
    }
    if (message.outPfc0Pkts !== 0) {
      writer.uint32(200).int64(message.outPfc0Pkts);
    }
    if (message.inPfc1Pkts !== 0) {
      writer.uint32(208).int64(message.inPfc1Pkts);
    }
    if (message.outPfc1Pkts !== 0) {
      writer.uint32(216).int64(message.outPfc1Pkts);
    }
    if (message.inPfc2Pkts !== 0) {
      writer.uint32(224).int64(message.inPfc2Pkts);
    }
    if (message.outPfc2Pkts !== 0) {
      writer.uint32(232).int64(message.outPfc2Pkts);
    }
    if (message.inPfc3Pkts !== 0) {
      writer.uint32(240).int64(message.inPfc3Pkts);
    }
    if (message.outPfc3Pkts !== 0) {
      writer.uint32(248).int64(message.outPfc3Pkts);
    }
    if (message.inPfc4Pkts !== 0) {
      writer.uint32(256).int64(message.inPfc4Pkts);
    }
    if (message.outPfc4Pkts !== 0) {
      writer.uint32(264).int64(message.outPfc4Pkts);
    }
    if (message.inPfc5Pkts !== 0) {
      writer.uint32(272).int64(message.inPfc5Pkts);
    }
    if (message.outPfc5Pkts !== 0) {
      writer.uint32(280).int64(message.outPfc5Pkts);
    }
    if (message.inPfc6Pkts !== 0) {
      writer.uint32(288).int64(message.inPfc6Pkts);
    }
    if (message.outPfc6Pkts !== 0) {
      writer.uint32(296).int64(message.outPfc6Pkts);
    }
    if (message.inPfc7Pkts !== 0) {
      writer.uint32(304).int64(message.inPfc7Pkts);
    }
    if (message.outPfc7Pkts !== 0) {
      writer.uint32(312).int64(message.outPfc7Pkts);
    }
    if (message.rowCount !== 0) {
      writer.uint32(800).int64(message.rowCount);
    }
    if (message.rxMinuteRate !== 0) {
      writer.uint32(808).int64(message.rxMinuteRate);
    }
    if (message.txMinuteRate !== 0) {
      writer.uint32(816).int64(message.txMinuteRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.portId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.inOctets = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.inPkts = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.inDiscards = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.inErrors = longToNumber(reader.int64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.outOctets = longToNumber(reader.int64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.outPkts = longToNumber(reader.int64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.outDiscards = longToNumber(reader.int64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.outErrors = longToNumber(reader.int64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.inPfc0Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.outPfc0Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.inPfc1Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.outPfc1Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.inPfc2Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.outPfc2Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.inPfc3Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.outPfc3Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.inPfc4Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.outPfc4Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.inPfc5Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.outPfc5Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.inPfc6Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.outPfc6Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.inPfc7Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.outPfc7Pkts = longToNumber(reader.int64() as Long);
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.rowCount = longToNumber(reader.int64() as Long);
          continue;
        case 101:
          if (tag !== 808) {
            break;
          }

          message.rxMinuteRate = longToNumber(reader.int64() as Long);
          continue;
        case 102:
          if (tag !== 816) {
            break;
          }

          message.txMinuteRate = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecord {
    return {
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      portId: isSet(object.port_id) ? globalThis.String(object.port_id) : "",
      orgId: isSet(object.org_id) ? globalThis.String(object.org_id) : "",
      inOctets: isSet(object.in_octets) ? globalThis.Number(object.in_octets) : 0,
      inPkts: isSet(object.in_pkts) ? globalThis.Number(object.in_pkts) : 0,
      inDiscards: isSet(object.in_discards) ? globalThis.Number(object.in_discards) : 0,
      inErrors: isSet(object.in_errors) ? globalThis.Number(object.in_errors) : 0,
      outOctets: isSet(object.out_octets) ? globalThis.Number(object.out_octets) : 0,
      outPkts: isSet(object.out_pkts) ? globalThis.Number(object.out_pkts) : 0,
      outDiscards: isSet(object.out_discards) ? globalThis.Number(object.out_discards) : 0,
      outErrors: isSet(object.out_errors) ? globalThis.Number(object.out_errors) : 0,
      inPfc0Pkts: isSet(object.in_pfc0_pkts) ? globalThis.Number(object.in_pfc0_pkts) : 0,
      outPfc0Pkts: isSet(object.out_pfc0_pkts) ? globalThis.Number(object.out_pfc0_pkts) : 0,
      inPfc1Pkts: isSet(object.in_pfc1_pkts) ? globalThis.Number(object.in_pfc1_pkts) : 0,
      outPfc1Pkts: isSet(object.out_pfc1_pkts) ? globalThis.Number(object.out_pfc1_pkts) : 0,
      inPfc2Pkts: isSet(object.in_pfc2_pkts) ? globalThis.Number(object.in_pfc2_pkts) : 0,
      outPfc2Pkts: isSet(object.out_pfc2_pkts) ? globalThis.Number(object.out_pfc2_pkts) : 0,
      inPfc3Pkts: isSet(object.in_pfc3_pkts) ? globalThis.Number(object.in_pfc3_pkts) : 0,
      outPfc3Pkts: isSet(object.out_pfc3_pkts) ? globalThis.Number(object.out_pfc3_pkts) : 0,
      inPfc4Pkts: isSet(object.in_pfc4_pkts) ? globalThis.Number(object.in_pfc4_pkts) : 0,
      outPfc4Pkts: isSet(object.out_pfc4_pkts) ? globalThis.Number(object.out_pfc4_pkts) : 0,
      inPfc5Pkts: isSet(object.in_pfc5_pkts) ? globalThis.Number(object.in_pfc5_pkts) : 0,
      outPfc5Pkts: isSet(object.out_pfc5_pkts) ? globalThis.Number(object.out_pfc5_pkts) : 0,
      inPfc6Pkts: isSet(object.in_pfc6_pkts) ? globalThis.Number(object.in_pfc6_pkts) : 0,
      outPfc6Pkts: isSet(object.out_pfc6_pkts) ? globalThis.Number(object.out_pfc6_pkts) : 0,
      inPfc7Pkts: isSet(object.in_pfc7_pkts) ? globalThis.Number(object.in_pfc7_pkts) : 0,
      outPfc7Pkts: isSet(object.out_pfc7_pkts) ? globalThis.Number(object.out_pfc7_pkts) : 0,
      rowCount: isSet(object.row_count) ? globalThis.Number(object.row_count) : 0,
      rxMinuteRate: isSet(object.rx_minute_rate) ? globalThis.Number(object.rx_minute_rate) : 0,
      txMinuteRate: isSet(object.tx_minute_rate) ? globalThis.Number(object.tx_minute_rate) : 0,
    };
  },

  toJSON(message: PortCounterHistoryRecord): unknown {
    const obj: any = {};
    if (message.time !== undefined) {
      obj.time = message.time.toISOString();
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.portId !== "") {
      obj.port_id = message.portId;
    }
    if (message.orgId !== "") {
      obj.org_id = message.orgId;
    }
    if (message.inOctets !== 0) {
      obj.in_octets = Math.round(message.inOctets);
    }
    if (message.inPkts !== 0) {
      obj.in_pkts = Math.round(message.inPkts);
    }
    if (message.inDiscards !== 0) {
      obj.in_discards = Math.round(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      obj.in_errors = Math.round(message.inErrors);
    }
    if (message.outOctets !== 0) {
      obj.out_octets = Math.round(message.outOctets);
    }
    if (message.outPkts !== 0) {
      obj.out_pkts = Math.round(message.outPkts);
    }
    if (message.outDiscards !== 0) {
      obj.out_discards = Math.round(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      obj.out_errors = Math.round(message.outErrors);
    }
    if (message.inPfc0Pkts !== 0) {
      obj.in_pfc0_pkts = Math.round(message.inPfc0Pkts);
    }
    if (message.outPfc0Pkts !== 0) {
      obj.out_pfc0_pkts = Math.round(message.outPfc0Pkts);
    }
    if (message.inPfc1Pkts !== 0) {
      obj.in_pfc1_pkts = Math.round(message.inPfc1Pkts);
    }
    if (message.outPfc1Pkts !== 0) {
      obj.out_pfc1_pkts = Math.round(message.outPfc1Pkts);
    }
    if (message.inPfc2Pkts !== 0) {
      obj.in_pfc2_pkts = Math.round(message.inPfc2Pkts);
    }
    if (message.outPfc2Pkts !== 0) {
      obj.out_pfc2_pkts = Math.round(message.outPfc2Pkts);
    }
    if (message.inPfc3Pkts !== 0) {
      obj.in_pfc3_pkts = Math.round(message.inPfc3Pkts);
    }
    if (message.outPfc3Pkts !== 0) {
      obj.out_pfc3_pkts = Math.round(message.outPfc3Pkts);
    }
    if (message.inPfc4Pkts !== 0) {
      obj.in_pfc4_pkts = Math.round(message.inPfc4Pkts);
    }
    if (message.outPfc4Pkts !== 0) {
      obj.out_pfc4_pkts = Math.round(message.outPfc4Pkts);
    }
    if (message.inPfc5Pkts !== 0) {
      obj.in_pfc5_pkts = Math.round(message.inPfc5Pkts);
    }
    if (message.outPfc5Pkts !== 0) {
      obj.out_pfc5_pkts = Math.round(message.outPfc5Pkts);
    }
    if (message.inPfc6Pkts !== 0) {
      obj.in_pfc6_pkts = Math.round(message.inPfc6Pkts);
    }
    if (message.outPfc6Pkts !== 0) {
      obj.out_pfc6_pkts = Math.round(message.outPfc6Pkts);
    }
    if (message.inPfc7Pkts !== 0) {
      obj.in_pfc7_pkts = Math.round(message.inPfc7Pkts);
    }
    if (message.outPfc7Pkts !== 0) {
      obj.out_pfc7_pkts = Math.round(message.outPfc7Pkts);
    }
    if (message.rowCount !== 0) {
      obj.row_count = Math.round(message.rowCount);
    }
    if (message.rxMinuteRate !== 0) {
      obj.rx_minute_rate = Math.round(message.rxMinuteRate);
    }
    if (message.txMinuteRate !== 0) {
      obj.tx_minute_rate = Math.round(message.txMinuteRate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecord>, I>>(base?: I): PortCounterHistoryRecord {
    return PortCounterHistoryRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecord>, I>>(object: I): PortCounterHistoryRecord {
    const message = createBasePortCounterHistoryRecord();
    message.time = object.time ?? undefined;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.portId = object.portId ?? "";
    message.orgId = object.orgId ?? "";
    message.inOctets = object.inOctets ?? 0;
    message.inPkts = object.inPkts ?? 0;
    message.inDiscards = object.inDiscards ?? 0;
    message.inErrors = object.inErrors ?? 0;
    message.outOctets = object.outOctets ?? 0;
    message.outPkts = object.outPkts ?? 0;
    message.outDiscards = object.outDiscards ?? 0;
    message.outErrors = object.outErrors ?? 0;
    message.inPfc0Pkts = object.inPfc0Pkts ?? 0;
    message.outPfc0Pkts = object.outPfc0Pkts ?? 0;
    message.inPfc1Pkts = object.inPfc1Pkts ?? 0;
    message.outPfc1Pkts = object.outPfc1Pkts ?? 0;
    message.inPfc2Pkts = object.inPfc2Pkts ?? 0;
    message.outPfc2Pkts = object.outPfc2Pkts ?? 0;
    message.inPfc3Pkts = object.inPfc3Pkts ?? 0;
    message.outPfc3Pkts = object.outPfc3Pkts ?? 0;
    message.inPfc4Pkts = object.inPfc4Pkts ?? 0;
    message.outPfc4Pkts = object.outPfc4Pkts ?? 0;
    message.inPfc5Pkts = object.inPfc5Pkts ?? 0;
    message.outPfc5Pkts = object.outPfc5Pkts ?? 0;
    message.inPfc6Pkts = object.inPfc6Pkts ?? 0;
    message.outPfc6Pkts = object.outPfc6Pkts ?? 0;
    message.inPfc7Pkts = object.inPfc7Pkts ?? 0;
    message.outPfc7Pkts = object.outPfc7Pkts ?? 0;
    message.rowCount = object.rowCount ?? 0;
    message.rxMinuteRate = object.rxMinuteRate ?? 0;
    message.txMinuteRate = object.txMinuteRate ?? 0;
    return message;
  },
};

function createBasePortCounterHistoryRecordRequest(): PortCounterHistoryRecordRequest {
  return {
    startTime: undefined,
    endTime: undefined,
    orgId: "",
    projections: [],
    groupings: [],
    orderings: [],
    where: undefined,
    having: undefined,
    granularity: 0,
    metadata: undefined,
    numBuckets: 0,
    pagination: undefined,
    noFudgeFactor: false,
  };
}

export const PortCounterHistoryRecordRequest = {
  encode(message: PortCounterHistoryRecordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    writer.uint32(34).fork();
    for (const v of message.projections) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.groupings) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.orderings) {
      PortCounterHistoryRecordUpdateOrderBy.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.where !== undefined) {
      PortCounterHistoryRecordChainExpression.encode(message.where, writer.uint32(58).fork()).ldelim();
    }
    if (message.having !== undefined) {
      PortCounterHistoryRecordChainExpression.encode(message.having, writer.uint32(66).fork()).ldelim();
    }
    if (message.granularity !== 0) {
      writer.uint32(72).int32(message.granularity);
    }
    if (message.metadata !== undefined) {
      RequestMetadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    if (message.numBuckets !== 0) {
      writer.uint32(88).uint32(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(98).fork()).ldelim();
    }
    if (message.noFudgeFactor !== false) {
      writer.uint32(104).bool(message.noFudgeFactor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag === 32) {
            message.projections.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.projections.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.groupings.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.groupings.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orderings.push(PortCounterHistoryRecordUpdateOrderBy.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.where = PortCounterHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.having = PortCounterHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = RequestMetadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.numBuckets = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.noFudgeFactor = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecordRequest {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      projections: globalThis.Array.isArray(object?.projections)
        ? object.projections.map((e: any) => portCounterHistoryRecordProjectDimensionByFromJSON(e))
        : [],
      groupings: globalThis.Array.isArray(object?.groupings)
        ? object.groupings.map((e: any) => portCounterHistoryRecordGroupByFromJSON(e))
        : [],
      orderings: globalThis.Array.isArray(object?.orderings)
        ? object.orderings.map((e: any) => PortCounterHistoryRecordUpdateOrderBy.fromJSON(e))
        : [],
      where: isSet(object.where) ? PortCounterHistoryRecordChainExpression.fromJSON(object.where) : undefined,
      having: isSet(object.having) ? PortCounterHistoryRecordChainExpression.fromJSON(object.having) : undefined,
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      metadata: isSet(object.metadata) ? RequestMetadata.fromJSON(object.metadata) : undefined,
      numBuckets: isSet(object.numBuckets) ? globalThis.Number(object.numBuckets) : 0,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
      noFudgeFactor: isSet(object.noFudgeFactor) ? globalThis.Boolean(object.noFudgeFactor) : false,
    };
  },

  toJSON(message: PortCounterHistoryRecordRequest): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.projections?.length) {
      obj.projections = message.projections.map((e) => portCounterHistoryRecordProjectDimensionByToJSON(e));
    }
    if (message.groupings?.length) {
      obj.groupings = message.groupings.map((e) => portCounterHistoryRecordGroupByToJSON(e));
    }
    if (message.orderings?.length) {
      obj.orderings = message.orderings.map((e) => PortCounterHistoryRecordUpdateOrderBy.toJSON(e));
    }
    if (message.where !== undefined) {
      obj.where = PortCounterHistoryRecordChainExpression.toJSON(message.where);
    }
    if (message.having !== undefined) {
      obj.having = PortCounterHistoryRecordChainExpression.toJSON(message.having);
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.metadata !== undefined) {
      obj.metadata = RequestMetadata.toJSON(message.metadata);
    }
    if (message.numBuckets !== 0) {
      obj.numBuckets = Math.round(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    if (message.noFudgeFactor !== false) {
      obj.noFudgeFactor = message.noFudgeFactor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecordRequest>, I>>(base?: I): PortCounterHistoryRecordRequest {
    return PortCounterHistoryRecordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecordRequest>, I>>(
    object: I,
  ): PortCounterHistoryRecordRequest {
    const message = createBasePortCounterHistoryRecordRequest();
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.orgId = object.orgId ?? "";
    message.projections = object.projections?.map((e) => e) || [];
    message.groupings = object.groupings?.map((e) => e) || [];
    message.orderings = object.orderings?.map((e) => PortCounterHistoryRecordUpdateOrderBy.fromPartial(e)) || [];
    message.where = (object.where !== undefined && object.where !== null)
      ? PortCounterHistoryRecordChainExpression.fromPartial(object.where)
      : undefined;
    message.having = (object.having !== undefined && object.having !== null)
      ? PortCounterHistoryRecordChainExpression.fromPartial(object.having)
      : undefined;
    message.granularity = object.granularity ?? 0;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? RequestMetadata.fromPartial(object.metadata)
      : undefined;
    message.numBuckets = object.numBuckets ?? 0;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    message.noFudgeFactor = object.noFudgeFactor ?? false;
    return message;
  },
};

function createBasePortCounterHistoryRecordResponse(): PortCounterHistoryRecordResponse {
  return { portCounterHistoryRecord: [], pagination: undefined };
}

export const PortCounterHistoryRecordResponse = {
  encode(message: PortCounterHistoryRecordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.portCounterHistoryRecord) {
      PortCounterHistoryRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.portCounterHistoryRecord.push(PortCounterHistoryRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecordResponse {
    return {
      portCounterHistoryRecord: globalThis.Array.isArray(object?.portCounterHistoryRecord)
        ? object.portCounterHistoryRecord.map((e: any) => PortCounterHistoryRecord.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: PortCounterHistoryRecordResponse): unknown {
    const obj: any = {};
    if (message.portCounterHistoryRecord?.length) {
      obj.portCounterHistoryRecord = message.portCounterHistoryRecord.map((e) => PortCounterHistoryRecord.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecordResponse>, I>>(
    base?: I,
  ): PortCounterHistoryRecordResponse {
    return PortCounterHistoryRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecordResponse>, I>>(
    object: I,
  ): PortCounterHistoryRecordResponse {
    const message = createBasePortCounterHistoryRecordResponse();
    message.portCounterHistoryRecord =
      object.portCounterHistoryRecord?.map((e) => PortCounterHistoryRecord.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBasePortCounterHistoryRecordUpdateOrderBy(): PortCounterHistoryRecordUpdateOrderBy {
  return { orderBy: 0, sortOrder: 0 };
}

export const PortCounterHistoryRecordUpdateOrderBy = {
  encode(message: PortCounterHistoryRecordUpdateOrderBy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderBy !== 0) {
      writer.uint32(8).int32(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      writer.uint32(16).int32(message.sortOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecordUpdateOrderBy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecordUpdateOrderBy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sortOrder = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecordUpdateOrderBy {
    return {
      orderBy: isSet(object.orderBy) ? portCounterHistoryRecordProjectDimensionByFromJSON(object.orderBy) : 0,
      sortOrder: isSet(object.sortOrder) ? sortOrderFromJSON(object.sortOrder) : 0,
    };
  },

  toJSON(message: PortCounterHistoryRecordUpdateOrderBy): unknown {
    const obj: any = {};
    if (message.orderBy !== 0) {
      obj.orderBy = portCounterHistoryRecordProjectDimensionByToJSON(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      obj.sortOrder = sortOrderToJSON(message.sortOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecordUpdateOrderBy>, I>>(
    base?: I,
  ): PortCounterHistoryRecordUpdateOrderBy {
    return PortCounterHistoryRecordUpdateOrderBy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecordUpdateOrderBy>, I>>(
    object: I,
  ): PortCounterHistoryRecordUpdateOrderBy {
    const message = createBasePortCounterHistoryRecordUpdateOrderBy();
    message.orderBy = object.orderBy ?? 0;
    message.sortOrder = object.sortOrder ?? 0;
    return message;
  },
};

function createBasePortCounterHistoryRecordSimpleExpression(): PortCounterHistoryRecordSimpleExpression {
  return { operand: 0, operator: 0, value: "" };
}

export const PortCounterHistoryRecordSimpleExpression = {
  encode(message: PortCounterHistoryRecordSimpleExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operand !== 0) {
      writer.uint32(8).int32(message.operand);
    }
    if (message.operator !== 0) {
      writer.uint32(16).int32(message.operator);
    }
    if (message.value !== "") {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecordSimpleExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecordSimpleExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.operand = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecordSimpleExpression {
    return {
      operand: isSet(object.operand) ? portCounterHistoryRecordProjectDimensionByFromJSON(object.operand) : 0,
      operator: isSet(object.operator) ? operatorFromJSON(object.operator) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: PortCounterHistoryRecordSimpleExpression): unknown {
    const obj: any = {};
    if (message.operand !== 0) {
      obj.operand = portCounterHistoryRecordProjectDimensionByToJSON(message.operand);
    }
    if (message.operator !== 0) {
      obj.operator = operatorToJSON(message.operator);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecordSimpleExpression>, I>>(
    base?: I,
  ): PortCounterHistoryRecordSimpleExpression {
    return PortCounterHistoryRecordSimpleExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecordSimpleExpression>, I>>(
    object: I,
  ): PortCounterHistoryRecordSimpleExpression {
    const message = createBasePortCounterHistoryRecordSimpleExpression();
    message.operand = object.operand ?? 0;
    message.operator = object.operator ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBasePortCounterHistoryRecordChainExpression(): PortCounterHistoryRecordChainExpression {
  return { expressions: [], operators: [], simpleExpression: undefined };
}

export const PortCounterHistoryRecordChainExpression = {
  encode(message: PortCounterHistoryRecordChainExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.expressions) {
      PortCounterHistoryRecordChainExpression.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.operators) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.simpleExpression !== undefined) {
      PortCounterHistoryRecordSimpleExpression.encode(message.simpleExpression, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounterHistoryRecordChainExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounterHistoryRecordChainExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expressions.push(PortCounterHistoryRecordChainExpression.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag === 16) {
            message.operators.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.operators.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.simpleExpression = PortCounterHistoryRecordSimpleExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounterHistoryRecordChainExpression {
    return {
      expressions: globalThis.Array.isArray(object?.expressions)
        ? object.expressions.map((e: any) => PortCounterHistoryRecordChainExpression.fromJSON(e))
        : [],
      operators: globalThis.Array.isArray(object?.operators)
        ? object.operators.map((e: any) => chainOperatorFromJSON(e))
        : [],
      simpleExpression: isSet(object.simpleExpression)
        ? PortCounterHistoryRecordSimpleExpression.fromJSON(object.simpleExpression)
        : undefined,
    };
  },

  toJSON(message: PortCounterHistoryRecordChainExpression): unknown {
    const obj: any = {};
    if (message.expressions?.length) {
      obj.expressions = message.expressions.map((e) => PortCounterHistoryRecordChainExpression.toJSON(e));
    }
    if (message.operators?.length) {
      obj.operators = message.operators.map((e) => chainOperatorToJSON(e));
    }
    if (message.simpleExpression !== undefined) {
      obj.simpleExpression = PortCounterHistoryRecordSimpleExpression.toJSON(message.simpleExpression);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounterHistoryRecordChainExpression>, I>>(
    base?: I,
  ): PortCounterHistoryRecordChainExpression {
    return PortCounterHistoryRecordChainExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounterHistoryRecordChainExpression>, I>>(
    object: I,
  ): PortCounterHistoryRecordChainExpression {
    const message = createBasePortCounterHistoryRecordChainExpression();
    message.expressions = object.expressions?.map((e) => PortCounterHistoryRecordChainExpression.fromPartial(e)) || [];
    message.operators = object.operators?.map((e) => e) || [];
    message.simpleExpression = (object.simpleExpression !== undefined && object.simpleExpression !== null)
      ? PortCounterHistoryRecordSimpleExpression.fromPartial(object.simpleExpression)
      : undefined;
    return message;
  },
};

function createBaseAssertHistoryRecord(): AssertHistoryRecord {
  return {
    time: undefined,
    assertType: "",
    category: "",
    assertState: "",
    device: "",
    lineCard: "",
    breakout: "",
    portName: "",
    masked: "",
    orgId: "",
    latchedAt: 0,
    latchTime: 0,
    implicit: "",
    remotePortName: "",
    remoteDeviceId: "",
    numNeighbors: "",
    localPortSpeed: 0,
    lastTrafficBytes: 0,
    recentTrafficBytes: 0,
    lastTrafficTime: 0,
    vrf: "",
    vlanState: "",
    managementState: "",
    highCpuUtil: "",
    highDiskUtil: "",
    highMemUtil: "",
    port: "",
    nodeId: "",
    remoteNodeId: "",
    fabric: "",
    txnId: "",
    objectName: "",
    objectType: "",
    objectId: "",
    parentId: "",
    childId: "",
    username: "",
    operation: "",
    rowCount: 0,
  };
}

export const AssertHistoryRecord = {
  encode(message: AssertHistoryRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
    }
    if (message.assertType !== "") {
      writer.uint32(18).string(message.assertType);
    }
    if (message.category !== "") {
      writer.uint32(26).string(message.category);
    }
    if (message.assertState !== "") {
      writer.uint32(34).string(message.assertState);
    }
    if (message.device !== "") {
      writer.uint32(42).string(message.device);
    }
    if (message.lineCard !== "") {
      writer.uint32(50).string(message.lineCard);
    }
    if (message.breakout !== "") {
      writer.uint32(58).string(message.breakout);
    }
    if (message.portName !== "") {
      writer.uint32(66).string(message.portName);
    }
    if (message.masked !== "") {
      writer.uint32(74).string(message.masked);
    }
    if (message.orgId !== "") {
      writer.uint32(82).string(message.orgId);
    }
    if (message.latchedAt !== 0) {
      writer.uint32(88).int64(message.latchedAt);
    }
    if (message.latchTime !== 0) {
      writer.uint32(96).int64(message.latchTime);
    }
    if (message.implicit !== "") {
      writer.uint32(106).string(message.implicit);
    }
    if (message.remotePortName !== "") {
      writer.uint32(114).string(message.remotePortName);
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(122).string(message.remoteDeviceId);
    }
    if (message.numNeighbors !== "") {
      writer.uint32(130).string(message.numNeighbors);
    }
    if (message.localPortSpeed !== 0) {
      writer.uint32(136).int64(message.localPortSpeed);
    }
    if (message.lastTrafficBytes !== 0) {
      writer.uint32(144).int64(message.lastTrafficBytes);
    }
    if (message.recentTrafficBytes !== 0) {
      writer.uint32(152).int64(message.recentTrafficBytes);
    }
    if (message.lastTrafficTime !== 0) {
      writer.uint32(160).int64(message.lastTrafficTime);
    }
    if (message.vrf !== "") {
      writer.uint32(170).string(message.vrf);
    }
    if (message.vlanState !== "") {
      writer.uint32(178).string(message.vlanState);
    }
    if (message.managementState !== "") {
      writer.uint32(186).string(message.managementState);
    }
    if (message.highCpuUtil !== "") {
      writer.uint32(194).string(message.highCpuUtil);
    }
    if (message.highDiskUtil !== "") {
      writer.uint32(202).string(message.highDiskUtil);
    }
    if (message.highMemUtil !== "") {
      writer.uint32(210).string(message.highMemUtil);
    }
    if (message.port !== "") {
      writer.uint32(218).string(message.port);
    }
    if (message.nodeId !== "") {
      writer.uint32(226).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(234).string(message.remoteNodeId);
    }
    if (message.fabric !== "") {
      writer.uint32(242).string(message.fabric);
    }
    if (message.txnId !== "") {
      writer.uint32(250).string(message.txnId);
    }
    if (message.objectName !== "") {
      writer.uint32(258).string(message.objectName);
    }
    if (message.objectType !== "") {
      writer.uint32(266).string(message.objectType);
    }
    if (message.objectId !== "") {
      writer.uint32(274).string(message.objectId);
    }
    if (message.parentId !== "") {
      writer.uint32(282).string(message.parentId);
    }
    if (message.childId !== "") {
      writer.uint32(290).string(message.childId);
    }
    if (message.username !== "") {
      writer.uint32(298).string(message.username);
    }
    if (message.operation !== "") {
      writer.uint32(306).string(message.operation);
    }
    if (message.rowCount !== 0) {
      writer.uint32(800).int64(message.rowCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assertType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.category = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.assertState = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.device = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lineCard = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.breakout = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.masked = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.latchedAt = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.implicit = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.numNeighbors = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.localPortSpeed = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.lastTrafficBytes = longToNumber(reader.int64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.recentTrafficBytes = longToNumber(reader.int64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.lastTrafficTime = longToNumber(reader.int64() as Long);
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.vlanState = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.managementState = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.highCpuUtil = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.highDiskUtil = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.highMemUtil = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.port = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.txnId = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.objectName = reader.string();
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.objectType = reader.string();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.childId = reader.string();
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.username = reader.string();
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.operation = reader.string();
          continue;
        case 100:
          if (tag !== 800) {
            break;
          }

          message.rowCount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecord {
    return {
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      assertType: isSet(object.assert_type) ? globalThis.String(object.assert_type) : "",
      category: isSet(object.category) ? globalThis.String(object.category) : "",
      assertState: isSet(object.assert_state) ? globalThis.String(object.assert_state) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.line_card) ? globalThis.String(object.line_card) : "",
      breakout: isSet(object.breakout) ? globalThis.String(object.breakout) : "",
      portName: isSet(object.port_name) ? globalThis.String(object.port_name) : "",
      masked: isSet(object.masked) ? globalThis.String(object.masked) : "",
      orgId: isSet(object.org_id) ? globalThis.String(object.org_id) : "",
      latchedAt: isSet(object.latched_at) ? globalThis.Number(object.latched_at) : 0,
      latchTime: isSet(object.latch_time) ? globalThis.Number(object.latch_time) : 0,
      implicit: isSet(object.implicit) ? globalThis.String(object.implicit) : "",
      remotePortName: isSet(object.remote_port_name) ? globalThis.String(object.remote_port_name) : "",
      remoteDeviceId: isSet(object.remote_device_id) ? globalThis.String(object.remote_device_id) : "",
      numNeighbors: isSet(object.num_neighbors) ? globalThis.String(object.num_neighbors) : "",
      localPortSpeed: isSet(object.local_port_speed) ? globalThis.Number(object.local_port_speed) : 0,
      lastTrafficBytes: isSet(object.last_traffic_bytes) ? globalThis.Number(object.last_traffic_bytes) : 0,
      recentTrafficBytes: isSet(object.recent_traffic_bytes) ? globalThis.Number(object.recent_traffic_bytes) : 0,
      lastTrafficTime: isSet(object.last_traffic_time) ? globalThis.Number(object.last_traffic_time) : 0,
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      vlanState: isSet(object.vlan_state) ? globalThis.String(object.vlan_state) : "",
      managementState: isSet(object.management_state) ? globalThis.String(object.management_state) : "",
      highCpuUtil: isSet(object.high_cpu_util) ? globalThis.String(object.high_cpu_util) : "",
      highDiskUtil: isSet(object.high_disk_util) ? globalThis.String(object.high_disk_util) : "",
      highMemUtil: isSet(object.high_mem_util) ? globalThis.String(object.high_mem_util) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      nodeId: isSet(object.node_id) ? globalThis.String(object.node_id) : "",
      remoteNodeId: isSet(object.remote_node_id) ? globalThis.String(object.remote_node_id) : "",
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      txnId: isSet(object.txn_id) ? globalThis.String(object.txn_id) : "",
      objectName: isSet(object.object_name) ? globalThis.String(object.object_name) : "",
      objectType: isSet(object.object_type) ? globalThis.String(object.object_type) : "",
      objectId: isSet(object.object_id) ? globalThis.String(object.object_id) : "",
      parentId: isSet(object.parent_id) ? globalThis.String(object.parent_id) : "",
      childId: isSet(object.child_id) ? globalThis.String(object.child_id) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      operation: isSet(object.operation) ? globalThis.String(object.operation) : "",
      rowCount: isSet(object.row_count) ? globalThis.Number(object.row_count) : 0,
    };
  },

  toJSON(message: AssertHistoryRecord): unknown {
    const obj: any = {};
    if (message.time !== undefined) {
      obj.time = message.time.toISOString();
    }
    if (message.assertType !== "") {
      obj.assert_type = message.assertType;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.assertState !== "") {
      obj.assert_state = message.assertState;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== "") {
      obj.line_card = message.lineCard;
    }
    if (message.breakout !== "") {
      obj.breakout = message.breakout;
    }
    if (message.portName !== "") {
      obj.port_name = message.portName;
    }
    if (message.masked !== "") {
      obj.masked = message.masked;
    }
    if (message.orgId !== "") {
      obj.org_id = message.orgId;
    }
    if (message.latchedAt !== 0) {
      obj.latched_at = Math.round(message.latchedAt);
    }
    if (message.latchTime !== 0) {
      obj.latch_time = Math.round(message.latchTime);
    }
    if (message.implicit !== "") {
      obj.implicit = message.implicit;
    }
    if (message.remotePortName !== "") {
      obj.remote_port_name = message.remotePortName;
    }
    if (message.remoteDeviceId !== "") {
      obj.remote_device_id = message.remoteDeviceId;
    }
    if (message.numNeighbors !== "") {
      obj.num_neighbors = message.numNeighbors;
    }
    if (message.localPortSpeed !== 0) {
      obj.local_port_speed = Math.round(message.localPortSpeed);
    }
    if (message.lastTrafficBytes !== 0) {
      obj.last_traffic_bytes = Math.round(message.lastTrafficBytes);
    }
    if (message.recentTrafficBytes !== 0) {
      obj.recent_traffic_bytes = Math.round(message.recentTrafficBytes);
    }
    if (message.lastTrafficTime !== 0) {
      obj.last_traffic_time = Math.round(message.lastTrafficTime);
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.vlanState !== "") {
      obj.vlan_state = message.vlanState;
    }
    if (message.managementState !== "") {
      obj.management_state = message.managementState;
    }
    if (message.highCpuUtil !== "") {
      obj.high_cpu_util = message.highCpuUtil;
    }
    if (message.highDiskUtil !== "") {
      obj.high_disk_util = message.highDiskUtil;
    }
    if (message.highMemUtil !== "") {
      obj.high_mem_util = message.highMemUtil;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.nodeId !== "") {
      obj.node_id = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remote_node_id = message.remoteNodeId;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.txnId !== "") {
      obj.txn_id = message.txnId;
    }
    if (message.objectName !== "") {
      obj.object_name = message.objectName;
    }
    if (message.objectType !== "") {
      obj.object_type = message.objectType;
    }
    if (message.objectId !== "") {
      obj.object_id = message.objectId;
    }
    if (message.parentId !== "") {
      obj.parent_id = message.parentId;
    }
    if (message.childId !== "") {
      obj.child_id = message.childId;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.operation !== "") {
      obj.operation = message.operation;
    }
    if (message.rowCount !== 0) {
      obj.row_count = Math.round(message.rowCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecord>, I>>(base?: I): AssertHistoryRecord {
    return AssertHistoryRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecord>, I>>(object: I): AssertHistoryRecord {
    const message = createBaseAssertHistoryRecord();
    message.time = object.time ?? undefined;
    message.assertType = object.assertType ?? "";
    message.category = object.category ?? "";
    message.assertState = object.assertState ?? "";
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? "";
    message.breakout = object.breakout ?? "";
    message.portName = object.portName ?? "";
    message.masked = object.masked ?? "";
    message.orgId = object.orgId ?? "";
    message.latchedAt = object.latchedAt ?? 0;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? "";
    message.remotePortName = object.remotePortName ?? "";
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.numNeighbors = object.numNeighbors ?? "";
    message.localPortSpeed = object.localPortSpeed ?? 0;
    message.lastTrafficBytes = object.lastTrafficBytes ?? 0;
    message.recentTrafficBytes = object.recentTrafficBytes ?? 0;
    message.lastTrafficTime = object.lastTrafficTime ?? 0;
    message.vrf = object.vrf ?? "";
    message.vlanState = object.vlanState ?? "";
    message.managementState = object.managementState ?? "";
    message.highCpuUtil = object.highCpuUtil ?? "";
    message.highDiskUtil = object.highDiskUtil ?? "";
    message.highMemUtil = object.highMemUtil ?? "";
    message.port = object.port ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    message.fabric = object.fabric ?? "";
    message.txnId = object.txnId ?? "";
    message.objectName = object.objectName ?? "";
    message.objectType = object.objectType ?? "";
    message.objectId = object.objectId ?? "";
    message.parentId = object.parentId ?? "";
    message.childId = object.childId ?? "";
    message.username = object.username ?? "";
    message.operation = object.operation ?? "";
    message.rowCount = object.rowCount ?? 0;
    return message;
  },
};

function createBaseAssertHistoryRecordRequest(): AssertHistoryRecordRequest {
  return {
    startTime: undefined,
    endTime: undefined,
    orgId: "",
    projections: [],
    groupings: [],
    orderings: [],
    where: undefined,
    having: undefined,
    granularity: 0,
    metadata: undefined,
    numBuckets: 0,
    pagination: undefined,
    noFudgeFactor: false,
  };
}

export const AssertHistoryRecordRequest = {
  encode(message: AssertHistoryRecordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    writer.uint32(34).fork();
    for (const v of message.projections) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.groupings) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.orderings) {
      AssertHistoryRecordUpdateOrderBy.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.where !== undefined) {
      AssertHistoryRecordChainExpression.encode(message.where, writer.uint32(58).fork()).ldelim();
    }
    if (message.having !== undefined) {
      AssertHistoryRecordChainExpression.encode(message.having, writer.uint32(66).fork()).ldelim();
    }
    if (message.granularity !== 0) {
      writer.uint32(72).int32(message.granularity);
    }
    if (message.metadata !== undefined) {
      RequestMetadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    if (message.numBuckets !== 0) {
      writer.uint32(88).uint32(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(98).fork()).ldelim();
    }
    if (message.noFudgeFactor !== false) {
      writer.uint32(104).bool(message.noFudgeFactor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag === 32) {
            message.projections.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.projections.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.groupings.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.groupings.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orderings.push(AssertHistoryRecordUpdateOrderBy.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.where = AssertHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.having = AssertHistoryRecordChainExpression.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = RequestMetadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.numBuckets = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.noFudgeFactor = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecordRequest {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      projections: globalThis.Array.isArray(object?.projections)
        ? object.projections.map((e: any) => assertHistoryRecordProjectDimensionByFromJSON(e))
        : [],
      groupings: globalThis.Array.isArray(object?.groupings)
        ? object.groupings.map((e: any) => assertHistoryRecordGroupByFromJSON(e))
        : [],
      orderings: globalThis.Array.isArray(object?.orderings)
        ? object.orderings.map((e: any) => AssertHistoryRecordUpdateOrderBy.fromJSON(e))
        : [],
      where: isSet(object.where) ? AssertHistoryRecordChainExpression.fromJSON(object.where) : undefined,
      having: isSet(object.having) ? AssertHistoryRecordChainExpression.fromJSON(object.having) : undefined,
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      metadata: isSet(object.metadata) ? RequestMetadata.fromJSON(object.metadata) : undefined,
      numBuckets: isSet(object.numBuckets) ? globalThis.Number(object.numBuckets) : 0,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
      noFudgeFactor: isSet(object.noFudgeFactor) ? globalThis.Boolean(object.noFudgeFactor) : false,
    };
  },

  toJSON(message: AssertHistoryRecordRequest): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.projections?.length) {
      obj.projections = message.projections.map((e) => assertHistoryRecordProjectDimensionByToJSON(e));
    }
    if (message.groupings?.length) {
      obj.groupings = message.groupings.map((e) => assertHistoryRecordGroupByToJSON(e));
    }
    if (message.orderings?.length) {
      obj.orderings = message.orderings.map((e) => AssertHistoryRecordUpdateOrderBy.toJSON(e));
    }
    if (message.where !== undefined) {
      obj.where = AssertHistoryRecordChainExpression.toJSON(message.where);
    }
    if (message.having !== undefined) {
      obj.having = AssertHistoryRecordChainExpression.toJSON(message.having);
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.metadata !== undefined) {
      obj.metadata = RequestMetadata.toJSON(message.metadata);
    }
    if (message.numBuckets !== 0) {
      obj.numBuckets = Math.round(message.numBuckets);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    if (message.noFudgeFactor !== false) {
      obj.noFudgeFactor = message.noFudgeFactor;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecordRequest>, I>>(base?: I): AssertHistoryRecordRequest {
    return AssertHistoryRecordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecordRequest>, I>>(object: I): AssertHistoryRecordRequest {
    const message = createBaseAssertHistoryRecordRequest();
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.orgId = object.orgId ?? "";
    message.projections = object.projections?.map((e) => e) || [];
    message.groupings = object.groupings?.map((e) => e) || [];
    message.orderings = object.orderings?.map((e) => AssertHistoryRecordUpdateOrderBy.fromPartial(e)) || [];
    message.where = (object.where !== undefined && object.where !== null)
      ? AssertHistoryRecordChainExpression.fromPartial(object.where)
      : undefined;
    message.having = (object.having !== undefined && object.having !== null)
      ? AssertHistoryRecordChainExpression.fromPartial(object.having)
      : undefined;
    message.granularity = object.granularity ?? 0;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? RequestMetadata.fromPartial(object.metadata)
      : undefined;
    message.numBuckets = object.numBuckets ?? 0;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    message.noFudgeFactor = object.noFudgeFactor ?? false;
    return message;
  },
};

function createBaseAssertHistoryRecordResponse(): AssertHistoryRecordResponse {
  return { assertHistoryRecord: [], pagination: undefined };
}

export const AssertHistoryRecordResponse = {
  encode(message: AssertHistoryRecordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assertHistoryRecord) {
      AssertHistoryRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertHistoryRecord.push(AssertHistoryRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecordResponse {
    return {
      assertHistoryRecord: globalThis.Array.isArray(object?.assertHistoryRecord)
        ? object.assertHistoryRecord.map((e: any) => AssertHistoryRecord.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: AssertHistoryRecordResponse): unknown {
    const obj: any = {};
    if (message.assertHistoryRecord?.length) {
      obj.assertHistoryRecord = message.assertHistoryRecord.map((e) => AssertHistoryRecord.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecordResponse>, I>>(base?: I): AssertHistoryRecordResponse {
    return AssertHistoryRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecordResponse>, I>>(object: I): AssertHistoryRecordResponse {
    const message = createBaseAssertHistoryRecordResponse();
    message.assertHistoryRecord = object.assertHistoryRecord?.map((e) => AssertHistoryRecord.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseAssertHistoryRecordUpdateOrderBy(): AssertHistoryRecordUpdateOrderBy {
  return { orderBy: 0, sortOrder: 0 };
}

export const AssertHistoryRecordUpdateOrderBy = {
  encode(message: AssertHistoryRecordUpdateOrderBy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderBy !== 0) {
      writer.uint32(8).int32(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      writer.uint32(16).int32(message.sortOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecordUpdateOrderBy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecordUpdateOrderBy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sortOrder = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecordUpdateOrderBy {
    return {
      orderBy: isSet(object.orderBy) ? assertHistoryRecordProjectDimensionByFromJSON(object.orderBy) : 0,
      sortOrder: isSet(object.sortOrder) ? sortOrderFromJSON(object.sortOrder) : 0,
    };
  },

  toJSON(message: AssertHistoryRecordUpdateOrderBy): unknown {
    const obj: any = {};
    if (message.orderBy !== 0) {
      obj.orderBy = assertHistoryRecordProjectDimensionByToJSON(message.orderBy);
    }
    if (message.sortOrder !== 0) {
      obj.sortOrder = sortOrderToJSON(message.sortOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecordUpdateOrderBy>, I>>(
    base?: I,
  ): AssertHistoryRecordUpdateOrderBy {
    return AssertHistoryRecordUpdateOrderBy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecordUpdateOrderBy>, I>>(
    object: I,
  ): AssertHistoryRecordUpdateOrderBy {
    const message = createBaseAssertHistoryRecordUpdateOrderBy();
    message.orderBy = object.orderBy ?? 0;
    message.sortOrder = object.sortOrder ?? 0;
    return message;
  },
};

function createBaseAssertHistoryRecordSimpleExpression(): AssertHistoryRecordSimpleExpression {
  return { operand: 0, operator: 0, value: "" };
}

export const AssertHistoryRecordSimpleExpression = {
  encode(message: AssertHistoryRecordSimpleExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operand !== 0) {
      writer.uint32(8).int32(message.operand);
    }
    if (message.operator !== 0) {
      writer.uint32(16).int32(message.operator);
    }
    if (message.value !== "") {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecordSimpleExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecordSimpleExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.operand = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecordSimpleExpression {
    return {
      operand: isSet(object.operand) ? assertHistoryRecordProjectDimensionByFromJSON(object.operand) : 0,
      operator: isSet(object.operator) ? operatorFromJSON(object.operator) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: AssertHistoryRecordSimpleExpression): unknown {
    const obj: any = {};
    if (message.operand !== 0) {
      obj.operand = assertHistoryRecordProjectDimensionByToJSON(message.operand);
    }
    if (message.operator !== 0) {
      obj.operator = operatorToJSON(message.operator);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecordSimpleExpression>, I>>(
    base?: I,
  ): AssertHistoryRecordSimpleExpression {
    return AssertHistoryRecordSimpleExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecordSimpleExpression>, I>>(
    object: I,
  ): AssertHistoryRecordSimpleExpression {
    const message = createBaseAssertHistoryRecordSimpleExpression();
    message.operand = object.operand ?? 0;
    message.operator = object.operator ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseAssertHistoryRecordChainExpression(): AssertHistoryRecordChainExpression {
  return { expressions: [], operators: [], simpleExpression: undefined };
}

export const AssertHistoryRecordChainExpression = {
  encode(message: AssertHistoryRecordChainExpression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.expressions) {
      AssertHistoryRecordChainExpression.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.operators) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.simpleExpression !== undefined) {
      AssertHistoryRecordSimpleExpression.encode(message.simpleExpression, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryRecordChainExpression {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryRecordChainExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expressions.push(AssertHistoryRecordChainExpression.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag === 16) {
            message.operators.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.operators.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.simpleExpression = AssertHistoryRecordSimpleExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryRecordChainExpression {
    return {
      expressions: globalThis.Array.isArray(object?.expressions)
        ? object.expressions.map((e: any) => AssertHistoryRecordChainExpression.fromJSON(e))
        : [],
      operators: globalThis.Array.isArray(object?.operators)
        ? object.operators.map((e: any) => chainOperatorFromJSON(e))
        : [],
      simpleExpression: isSet(object.simpleExpression)
        ? AssertHistoryRecordSimpleExpression.fromJSON(object.simpleExpression)
        : undefined,
    };
  },

  toJSON(message: AssertHistoryRecordChainExpression): unknown {
    const obj: any = {};
    if (message.expressions?.length) {
      obj.expressions = message.expressions.map((e) => AssertHistoryRecordChainExpression.toJSON(e));
    }
    if (message.operators?.length) {
      obj.operators = message.operators.map((e) => chainOperatorToJSON(e));
    }
    if (message.simpleExpression !== undefined) {
      obj.simpleExpression = AssertHistoryRecordSimpleExpression.toJSON(message.simpleExpression);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryRecordChainExpression>, I>>(
    base?: I,
  ): AssertHistoryRecordChainExpression {
    return AssertHistoryRecordChainExpression.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryRecordChainExpression>, I>>(
    object: I,
  ): AssertHistoryRecordChainExpression {
    const message = createBaseAssertHistoryRecordChainExpression();
    message.expressions = object.expressions?.map((e) => AssertHistoryRecordChainExpression.fromPartial(e)) || [];
    message.operators = object.operators?.map((e) => e) || [];
    message.simpleExpression = (object.simpleExpression !== undefined && object.simpleExpression !== null)
      ? AssertHistoryRecordSimpleExpression.fromPartial(object.simpleExpression)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
