import React, { useCallback, useContext, useState } from "react";
import { Heading } from "@magnetic/heading";
import { Button } from "@magnetic/button";
import { Text } from "@magnetic/text";
import { Input } from "@magnetic/input";
import { Link } from "@magnetic/link";
import { PageLoader } from "components/pageLoader";
import { CiscoLogo } from "src/common/ciscoLogo";
import { GlobalFooter } from "src/layouts/globalFooter";

import "./login.scss";
import { ApiError } from "./apiError";
import { Apis } from "src/utils/api";
import { Constants } from "src/utils/constants";
import { LoginContext } from "./loginContext";
import { useNavigate } from "react-router-dom";
import { Flex } from "@magnetic/flex";
import { ErrorNotification } from "components/errorNotification";

export const DEV_SIGN_UP = "https://int-id.cisco.com/signin/register";
export const PROD_SIGN_UP = "https://id.cisco.com/signin/register";

export const DEV_FORGOT_EMAIL = "https://int-id.cisco.com/ui/v1.0/forgot-email";
export const PROD_FORGOT_EMAIL = "https://id.cisco.com/ui/v1.0/forgot-email";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * Email validation function
 * @param value
 */
export function isEmail(value: FormDataEntryValue | null): boolean {
  if (value) {
    if (!(value instanceof File)) {
      return EMAIL_REGEX.test(value);
    }
  }
  return false;
}

export const Login = () => {
  const isDevMode = window.cnc.settings.get("dev");
  const { getUserSession } = useContext(LoginContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();

  /**
   * Moved to using form submit to handle
   * the case where the user hits enter instead
   * of clicking the "sign in" button. Using
   * e.preventDefault(); should keep this
   * from causing problems for QA automation
   */
  const handleLogin = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const data = new FormData(e.currentTarget);
      const email = data.get("email");
      if (email === "") {
        setError("Enter email address to continue");
        return;
      }
      if (!isEmail(email)) {
        setError("Enter a valid email address to continue");
        return;
      }

      if (window?.cnc?.settings?.get("authBypass") !== true) {
        const formElement = e.target as HTMLFormElement;
        formElement.action = "/ui/v1/auth/login/okta";
        formElement.method = "post";
        formElement.submit();
        return;
      }
      setLoading(true);
      const payload: string[] = [];
      for (const [key, val] of data.entries()) {
        payload.push(`${key}=${encodeURIComponent(val.toString())}`);
      }
      Apis.Auth.login(payload).then(
        (response) => {
          if (response.status === 401) {
            response?.text().then((err) => {
              setError(err);
              setLoading(false);
            });
          } else {
            /**
             * If login is successful the
             * next step is go get the user
             * session to continue.
             */
            getUserSession().then(() => {
              setLoading(false);
              if (window.location.pathname !== "/fabrics") {
                navigate("/fabrics");
              }
            });
          }
        },
        (err: ApiError) => {
          setError(err.getMessage());
          setLoading(false);
        }
      );
    },
    [navigate, getUserSession]
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="login-page">
      <div className="login">
        <CiscoLogo />
        <form onSubmit={handleLogin}>
          <Flex align="center">
            <Heading size="section" className="brand">
              Cisco {Constants.APP_NAME} Sign In
            </Heading>
          </Flex>
          <Text size="p3" className="text">
            {Constants.APP_NAME} uses cisco.com to authenticate users. Please
            sign in using your cisco.com credentials.
          </Text>
          <Input
            label="Email"
            name="email"
            type="email"
            size="md"
            required={true}
            fixedWidth={true}
            autoComplete="off"
          />
          {/**
           * The hidden field should only be displayed if we are in dev mode
           * this should be better than having any of the auth_bypass logic in
           * the visible javascript logic.
           */}
          {isDevMode ? (
            <input
              type="hidden"
              name="auth_bypass"
              value={Boolean(
                window?.cnc?.settings?.get("authBypass")
              ).toString()}
            />
          ) : null}
          <ErrorNotification msg={error} />
          <Button type="submit" size="md" name="signin">
            Continue to cisco.com
          </Button>
          <Text size="p2">
            Don&apos;t have an account?&nbsp;
            <Link
              href={isDevMode ? DEV_SIGN_UP : PROD_SIGN_UP}
              className="login-link"
            >
              Sign up now
            </Link>
          </Text>
          <Text size="p2">
            <Link
              href={isDevMode ? DEV_FORGOT_EMAIL : PROD_FORGOT_EMAIL}
              className="login-link"
            >
              Forgot email?
            </Link>
          </Text>
        </form>
        <GlobalFooter showCopyright={false} textSize="p3" />
      </div>
    </div>
  );
};
