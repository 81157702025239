import { FredClientImpl } from "src/gen/schema/fred/service";
import { GrpcDebugableWebImpl } from "src/utils/api/grpc/grpcDebugableWebImpl";
import {
  GetUserContextsRequest,
  GetUserContextsResponse
} from "src/gen/schema/fred/fapi";

const rpc = new GrpcDebugableWebImpl("", { debug: false });
const fredClient = new FredClientImpl(rpc);

export const Fred = {
  getUserContexts: (
    req: GetUserContextsRequest = GetUserContextsRequest.create()
  ): Promise<GetUserContextsResponse> => {
    return fredClient.GetUserContexts(req);
  }
};
