// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: reader/reader_api.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { PaginationResponse } from "../common/grpc";
import {
  AssertHistoryRecordRequest,
  AssertHistoryRecordResponse,
  PortCounterHistoryRecordRequest,
  PortCounterHistoryRecordResponse,
  PortStateHistoryRecordRequest,
  PortStateHistoryRecordResponse,
} from "../druid/record_query_api";
import { Granularity, granularityFromJSON, granularityToJSON } from "../druid/timeseries_api_common";
import { Timestamp } from "../google/protobuf/timestamp";
import { AssertHistoryApiRecord } from "./assert_record_api";

export const protobufPackage = "reader";

/**
 * PROPRIETARY AND CONFIDENTIAL. Cisco Systems, Inc. considers the contents of this
 * file to be highly confidential trade secret information.
 *
 * COPYRIGHT 2023-present Cisco Systems, Inc., All rights reserved.
 */

/** ReaderType indicates the telemetry reader type */
export enum QueryType {
  /** UNSPECIFIED - Type unspecified */
  UNSPECIFIED = 0,
  /** PORT_STATE - high-level query types */
  PORT_STATE = 1,
  PORT_COUNTER_BIT_RATE = 2,
  PORT_COUNTER_PFC_COUNTER = 3,
  ASSERT_COUNTER_REPORT = 4,
  /** HISTORY_RECORD_QUERY_BASE - low-level query types */
  HISTORY_RECORD_QUERY_BASE = 100,
  PORT_STATE_HISTORY_RECORD = 101,
  PORT_COUNTER_HISTORY_RECORD = 102,
  ASSERT_HISTORY_RECORD = 103,
  UNRECOGNIZED = -1,
}

export function queryTypeFromJSON(object: any): QueryType {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return QueryType.UNSPECIFIED;
    case 1:
    case "PORT_STATE":
      return QueryType.PORT_STATE;
    case 2:
    case "PORT_COUNTER_BIT_RATE":
      return QueryType.PORT_COUNTER_BIT_RATE;
    case 3:
    case "PORT_COUNTER_PFC_COUNTER":
      return QueryType.PORT_COUNTER_PFC_COUNTER;
    case 4:
    case "ASSERT_COUNTER_REPORT":
      return QueryType.ASSERT_COUNTER_REPORT;
    case 100:
    case "HISTORY_RECORD_QUERY_BASE":
      return QueryType.HISTORY_RECORD_QUERY_BASE;
    case 101:
    case "PORT_STATE_HISTORY_RECORD":
      return QueryType.PORT_STATE_HISTORY_RECORD;
    case 102:
    case "PORT_COUNTER_HISTORY_RECORD":
      return QueryType.PORT_COUNTER_HISTORY_RECORD;
    case 103:
    case "ASSERT_HISTORY_RECORD":
      return QueryType.ASSERT_HISTORY_RECORD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QueryType.UNRECOGNIZED;
  }
}

export function queryTypeToJSON(object: QueryType): string {
  switch (object) {
    case QueryType.UNSPECIFIED:
      return "UNSPECIFIED";
    case QueryType.PORT_STATE:
      return "PORT_STATE";
    case QueryType.PORT_COUNTER_BIT_RATE:
      return "PORT_COUNTER_BIT_RATE";
    case QueryType.PORT_COUNTER_PFC_COUNTER:
      return "PORT_COUNTER_PFC_COUNTER";
    case QueryType.ASSERT_COUNTER_REPORT:
      return "ASSERT_COUNTER_REPORT";
    case QueryType.HISTORY_RECORD_QUERY_BASE:
      return "HISTORY_RECORD_QUERY_BASE";
    case QueryType.PORT_STATE_HISTORY_RECORD:
      return "PORT_STATE_HISTORY_RECORD";
    case QueryType.PORT_COUNTER_HISTORY_RECORD:
      return "PORT_COUNTER_HISTORY_RECORD";
    case QueryType.ASSERT_HISTORY_RECORD:
      return "ASSERT_HISTORY_RECORD";
    case QueryType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TrafficDirection {
  TX_AND_RX = 0,
  TX_ONLY = 1,
  RX_ONLY = 2,
  UNRECOGNIZED = -1,
}

export function trafficDirectionFromJSON(object: any): TrafficDirection {
  switch (object) {
    case 0:
    case "TX_AND_RX":
      return TrafficDirection.TX_AND_RX;
    case 1:
    case "TX_ONLY":
      return TrafficDirection.TX_ONLY;
    case 2:
    case "RX_ONLY":
      return TrafficDirection.RX_ONLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TrafficDirection.UNRECOGNIZED;
  }
}

export function trafficDirectionToJSON(object: TrafficDirection): string {
  switch (object) {
    case TrafficDirection.TX_AND_RX:
      return "TX_AND_RX";
    case TrafficDirection.TX_ONLY:
      return "TX_ONLY";
    case TrafficDirection.RX_ONLY:
      return "RX_ONLY";
    case TrafficDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AssertCounterType {
  /** COUNTER_UNSPECIFIED - Type unspecified */
  COUNTER_UNSPECIFIED = 0,
  NEW_ONLY = 1,
  CLEARED_ONLY = 2,
  CONFIG_ACTIVITY = 3,
  UNRECOGNIZED = -1,
}

export function assertCounterTypeFromJSON(object: any): AssertCounterType {
  switch (object) {
    case 0:
    case "COUNTER_UNSPECIFIED":
      return AssertCounterType.COUNTER_UNSPECIFIED;
    case 1:
    case "NEW_ONLY":
      return AssertCounterType.NEW_ONLY;
    case 2:
    case "CLEARED_ONLY":
      return AssertCounterType.CLEARED_ONLY;
    case 3:
    case "CONFIG_ACTIVITY":
      return AssertCounterType.CONFIG_ACTIVITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertCounterType.UNRECOGNIZED;
  }
}

export function assertCounterTypeToJSON(object: AssertCounterType): string {
  switch (object) {
    case AssertCounterType.COUNTER_UNSPECIFIED:
      return "COUNTER_UNSPECIFIED";
    case AssertCounterType.NEW_ONLY:
      return "NEW_ONLY";
    case AssertCounterType.CLEARED_ONLY:
      return "CLEARED_ONLY";
    case AssertCounterType.CONFIG_ACTIVITY:
      return "CONFIG_ACTIVITY";
    case AssertCounterType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** HistoryRequest is the top-level request object that encapsulates specific query parameters. */
export interface HistoryRequest {
  /** Indicates the type of data point */
  type: QueryType;
  /** Time at which the port stats were collected by agent. */
  startTime:
    | Date
    | undefined;
  /** Time at which the port stats were collected by agent. */
  endTime:
    | Date
    | undefined;
  /** Query granularity interval. Mutually exclusive with num_buckets. */
  granularity: Granularity;
  /**
   * The maximum number of results to return in a single response. If not specified, the API will return
   * a fixed number of results for the request.  The API will not interpret the context of this limit (e.g., topN query).
   * For timeseries queries, the server will add a fudge factor to the requested limit so expect additional results back in the client.
   */
  limit: number;
  /**
   * Number of buckets. Mutually exclusive with granularity.
   * If supplied, time will be bucketized down to the closest minute resolution.
   * Note that the result might be a bit strange if the computed interval doesn't evenly divide into the requested window.
   */
  numBuckets: number;
  /** Do not include a fudge factor when calculating the number of records to return. */
  noFudgeFactor: boolean;
  /** Traffic direction for the bit rate metric type */
  direction: TrafficDirection;
  /** Filter by the fabric UUID or name */
  fabric: string;
  /** Filter by device MAC address or name */
  device: string;
  /** Unique identifier of a port within a device */
  portId: string;
  /** Filter by one or more assertion types. */
  assertType: string[];
  /** If set, response will be encoded in druid native proto */
  druidNative: boolean;
  counterType: AssertCounterType[];
  /** Filter by node_id */
  nodeId: string;
  /** port name at the corresponding port_id */
  portName: string;
  /** Filter by remote device id or mac address */
  remoteDevice: string;
  /** Filter by remote node id */
  remoteNodeId: string;
  /** low-level query requests */
  portStateReq: PortStateHistoryRecordRequest | undefined;
  portCounterReq: PortCounterHistoryRecordRequest | undefined;
  assertRecordReq: AssertHistoryRecordRequest | undefined;
}

/** AssertRestCounterReportRequest is the top-level query request to collect the assertion counters */
export interface AssertRestCounterReportRequest {
  /** The start time in datetime string format */
  startTime: string;
  /** The end time in datetime string format */
  endTime: string;
  /** The query granularity in time interval */
  granularity: Granularity;
  /** The maximum number (default to 500) of results to return in a single response. */
  limit: number;
  /** Filter by the fabric UUID or name */
  fabric: string;
  /** Filter by the device MAC address or name */
  device: string;
  /** Filter by the port id or name */
  port: string;
  /** Filter by one or more assertion types. */
  assertType: string[];
  /** Filter by one or more counter_type */
  counterType: AssertCounterType[];
}

/** AssertCounterReport holds an assertion counter record at particular time instance */
export interface AssertCounterReport {
  /** the device name or UUID */
  device: string;
  /** The port index. */
  portName: string;
  /** The assert type */
  type: string;
  /** The number of new false asserts per time granularity */
  newFalseAsserts: number;
  /** The number of cleared false asserts per time granularity */
  clearedFalseAsserts: number;
  /** The number of config activities during the time granularity */
  configActivities: number;
  /** The timestamp associated this report */
  timestamp: Date | undefined;
}

/** AssertCounterReportResponse holds the AssertCounter time series query result. */
export interface AssertCounterReportResponse {
  counterReport: AssertCounterReport[];
  pagination: PaginationResponse | undefined;
}

/** AssertRestRecordRequest is the top-level query request to collect the assertion records */
export interface AssertRestRecordRequest {
  /** The start time in datetime string format */
  startTime: string;
  /** The end time in datetime string format */
  endTime: string;
  /** The query granularity in time interval */
  granularity: Granularity;
  /** The maximum number (default to 500) of results to return in a single response. */
  limit: number;
  /** Filter by the fabric id */
  fabric: string;
  /** Filter by the device id */
  device: string;
  /** Filter by the port id or name */
  port: string;
  /** Filter by one or more assertion types. */
  assertType: string[];
  /** Filter by one or more counter_type */
  counterType: AssertCounterType[];
}

/** AssertHistoryRecordResponseSchemaProto holds the Assertion record time series query result in the message format defined in schema.proto. */
export interface AssertHistoryApiRecordResponse {
  assertions: AssertHistoryApiRecord[];
  /** The common pagination properties in the response message */
  pagination: PaginationResponse | undefined;
}

/** HistoryResponse is the top-level history query response message for all supported query types */
export interface HistoryResponse {
  queryType: QueryType;
  portStateResponse: PortStateHistoryRecordResponse | undefined;
  portCounterResponse: PortCounterHistoryRecordResponse | undefined;
  assertCounterReport: AssertCounterReportResponse | undefined;
  assertResponse: AssertHistoryRecordResponse | undefined;
  assertApiResponse: AssertHistoryApiRecordResponse | undefined;
}

function createBaseHistoryRequest(): HistoryRequest {
  return {
    type: 0,
    startTime: undefined,
    endTime: undefined,
    granularity: 0,
    limit: 0,
    numBuckets: 0,
    noFudgeFactor: false,
    direction: 0,
    fabric: "",
    device: "",
    portId: "",
    assertType: [],
    druidNative: false,
    counterType: [],
    nodeId: "",
    portName: "",
    remoteDevice: "",
    remoteNodeId: "",
    portStateReq: undefined,
    portCounterReq: undefined,
    assertRecordReq: undefined,
  };
}

export const HistoryRequest = {
  encode(message: HistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.granularity !== 0) {
      writer.uint32(32).int32(message.granularity);
    }
    if (message.limit !== 0) {
      writer.uint32(40).uint32(message.limit);
    }
    if (message.numBuckets !== 0) {
      writer.uint32(48).uint32(message.numBuckets);
    }
    if (message.noFudgeFactor !== false) {
      writer.uint32(56).bool(message.noFudgeFactor);
    }
    if (message.direction !== 0) {
      writer.uint32(64).int32(message.direction);
    }
    if (message.fabric !== "") {
      writer.uint32(74).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(82).string(message.device);
    }
    if (message.portId !== "") {
      writer.uint32(90).string(message.portId);
    }
    for (const v of message.assertType) {
      writer.uint32(98).string(v!);
    }
    if (message.druidNative !== false) {
      writer.uint32(104).bool(message.druidNative);
    }
    writer.uint32(114).fork();
    for (const v of message.counterType) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.nodeId !== "") {
      writer.uint32(122).string(message.nodeId);
    }
    if (message.portName !== "") {
      writer.uint32(130).string(message.portName);
    }
    if (message.remoteDevice !== "") {
      writer.uint32(138).string(message.remoteDevice);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(146).string(message.remoteNodeId);
    }
    if (message.portStateReq !== undefined) {
      PortStateHistoryRecordRequest.encode(message.portStateReq, writer.uint32(810).fork()).ldelim();
    }
    if (message.portCounterReq !== undefined) {
      PortCounterHistoryRecordRequest.encode(message.portCounterReq, writer.uint32(818).fork()).ldelim();
    }
    if (message.assertRecordReq !== undefined) {
      AssertHistoryRecordRequest.encode(message.assertRecordReq, writer.uint32(826).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.numBuckets = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.noFudgeFactor = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.device = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.portId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.assertType.push(reader.string());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.druidNative = reader.bool();
          continue;
        case 14:
          if (tag === 112) {
            message.counterType.push(reader.int32() as any);

            continue;
          }

          if (tag === 114) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.counterType.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.remoteDevice = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
        case 101:
          if (tag !== 810) {
            break;
          }

          message.portStateReq = PortStateHistoryRecordRequest.decode(reader, reader.uint32());
          continue;
        case 102:
          if (tag !== 818) {
            break;
          }

          message.portCounterReq = PortCounterHistoryRecordRequest.decode(reader, reader.uint32());
          continue;
        case 103:
          if (tag !== 826) {
            break;
          }

          message.assertRecordReq = AssertHistoryRecordRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoryRequest {
    return {
      type: isSet(object.type) ? queryTypeFromJSON(object.type) : 0,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      numBuckets: isSet(object.numBuckets) ? globalThis.Number(object.numBuckets) : 0,
      noFudgeFactor: isSet(object.noFudgeFactor) ? globalThis.Boolean(object.noFudgeFactor) : false,
      direction: isSet(object.direction) ? trafficDirectionFromJSON(object.direction) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      portId: isSet(object.portId) ? globalThis.String(object.portId) : "",
      assertType: globalThis.Array.isArray(object?.assertType)
        ? object.assertType.map((e: any) => globalThis.String(e))
        : [],
      druidNative: isSet(object.druidNative) ? globalThis.Boolean(object.druidNative) : false,
      counterType: globalThis.Array.isArray(object?.counterType)
        ? object.counterType.map((e: any) => assertCounterTypeFromJSON(e))
        : [],
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      remoteDevice: isSet(object.remoteDevice) ? globalThis.String(object.remoteDevice) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
      portStateReq: isSet(object.portStateReq)
        ? PortStateHistoryRecordRequest.fromJSON(object.portStateReq)
        : undefined,
      portCounterReq: isSet(object.portCounterReq)
        ? PortCounterHistoryRecordRequest.fromJSON(object.portCounterReq)
        : undefined,
      assertRecordReq: isSet(object.assertRecordReq)
        ? AssertHistoryRecordRequest.fromJSON(object.assertRecordReq)
        : undefined,
    };
  },

  toJSON(message: HistoryRequest): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = queryTypeToJSON(message.type);
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.numBuckets !== 0) {
      obj.numBuckets = Math.round(message.numBuckets);
    }
    if (message.noFudgeFactor !== false) {
      obj.noFudgeFactor = message.noFudgeFactor;
    }
    if (message.direction !== 0) {
      obj.direction = trafficDirectionToJSON(message.direction);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.portId !== "") {
      obj.portId = message.portId;
    }
    if (message.assertType?.length) {
      obj.assertType = message.assertType;
    }
    if (message.druidNative !== false) {
      obj.druidNative = message.druidNative;
    }
    if (message.counterType?.length) {
      obj.counterType = message.counterType.map((e) => assertCounterTypeToJSON(e));
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.remoteDevice !== "") {
      obj.remoteDevice = message.remoteDevice;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    if (message.portStateReq !== undefined) {
      obj.portStateReq = PortStateHistoryRecordRequest.toJSON(message.portStateReq);
    }
    if (message.portCounterReq !== undefined) {
      obj.portCounterReq = PortCounterHistoryRecordRequest.toJSON(message.portCounterReq);
    }
    if (message.assertRecordReq !== undefined) {
      obj.assertRecordReq = AssertHistoryRecordRequest.toJSON(message.assertRecordReq);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoryRequest>, I>>(base?: I): HistoryRequest {
    return HistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoryRequest>, I>>(object: I): HistoryRequest {
    const message = createBaseHistoryRequest();
    message.type = object.type ?? 0;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.granularity = object.granularity ?? 0;
    message.limit = object.limit ?? 0;
    message.numBuckets = object.numBuckets ?? 0;
    message.noFudgeFactor = object.noFudgeFactor ?? false;
    message.direction = object.direction ?? 0;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.portId = object.portId ?? "";
    message.assertType = object.assertType?.map((e) => e) || [];
    message.druidNative = object.druidNative ?? false;
    message.counterType = object.counterType?.map((e) => e) || [];
    message.nodeId = object.nodeId ?? "";
    message.portName = object.portName ?? "";
    message.remoteDevice = object.remoteDevice ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    message.portStateReq = (object.portStateReq !== undefined && object.portStateReq !== null)
      ? PortStateHistoryRecordRequest.fromPartial(object.portStateReq)
      : undefined;
    message.portCounterReq = (object.portCounterReq !== undefined && object.portCounterReq !== null)
      ? PortCounterHistoryRecordRequest.fromPartial(object.portCounterReq)
      : undefined;
    message.assertRecordReq = (object.assertRecordReq !== undefined && object.assertRecordReq !== null)
      ? AssertHistoryRecordRequest.fromPartial(object.assertRecordReq)
      : undefined;
    return message;
  },
};

function createBaseAssertRestCounterReportRequest(): AssertRestCounterReportRequest {
  return {
    startTime: "",
    endTime: "",
    granularity: 0,
    limit: 0,
    fabric: "",
    device: "",
    port: "",
    assertType: [],
    counterType: [],
  };
}

export const AssertRestCounterReportRequest = {
  encode(message: AssertRestCounterReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== "") {
      writer.uint32(18).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(26).string(message.endTime);
    }
    if (message.granularity !== 0) {
      writer.uint32(32).int32(message.granularity);
    }
    if (message.limit !== 0) {
      writer.uint32(40).uint32(message.limit);
    }
    if (message.fabric !== "") {
      writer.uint32(50).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(58).string(message.device);
    }
    if (message.port !== "") {
      writer.uint32(66).string(message.port);
    }
    for (const v of message.assertType) {
      writer.uint32(74).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.counterType) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertRestCounterReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertRestCounterReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endTime = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.device = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.port = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.assertType.push(reader.string());
          continue;
        case 10:
          if (tag === 80) {
            message.counterType.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.counterType.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertRestCounterReportRequest {
    return {
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      assertType: globalThis.Array.isArray(object?.assertType)
        ? object.assertType.map((e: any) => globalThis.String(e))
        : [],
      counterType: globalThis.Array.isArray(object?.counterType)
        ? object.counterType.map((e: any) => assertCounterTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: AssertRestCounterReportRequest): unknown {
    const obj: any = {};
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.assertType?.length) {
      obj.assertType = message.assertType;
    }
    if (message.counterType?.length) {
      obj.counterType = message.counterType.map((e) => assertCounterTypeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertRestCounterReportRequest>, I>>(base?: I): AssertRestCounterReportRequest {
    return AssertRestCounterReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertRestCounterReportRequest>, I>>(
    object: I,
  ): AssertRestCounterReportRequest {
    const message = createBaseAssertRestCounterReportRequest();
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    message.granularity = object.granularity ?? 0;
    message.limit = object.limit ?? 0;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.port = object.port ?? "";
    message.assertType = object.assertType?.map((e) => e) || [];
    message.counterType = object.counterType?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssertCounterReport(): AssertCounterReport {
  return {
    device: "",
    portName: "",
    type: "",
    newFalseAsserts: 0,
    clearedFalseAsserts: 0,
    configActivities: 0,
    timestamp: undefined,
  };
}

export const AssertCounterReport = {
  encode(message: AssertCounterReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.device !== "") {
      writer.uint32(10).string(message.device);
    }
    if (message.portName !== "") {
      writer.uint32(18).string(message.portName);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.newFalseAsserts !== 0) {
      writer.uint32(32).uint32(message.newFalseAsserts);
    }
    if (message.clearedFalseAsserts !== 0) {
      writer.uint32(40).uint32(message.clearedFalseAsserts);
    }
    if (message.configActivities !== 0) {
      writer.uint32(56).uint32(message.configActivities);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertCounterReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertCounterReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.device = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.newFalseAsserts = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.clearedFalseAsserts = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.configActivities = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertCounterReport {
    return {
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      newFalseAsserts: isSet(object.newFalseAsserts) ? globalThis.Number(object.newFalseAsserts) : 0,
      clearedFalseAsserts: isSet(object.clearedFalseAsserts) ? globalThis.Number(object.clearedFalseAsserts) : 0,
      configActivities: isSet(object.configActivities) ? globalThis.Number(object.configActivities) : 0,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
    };
  },

  toJSON(message: AssertCounterReport): unknown {
    const obj: any = {};
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.newFalseAsserts !== 0) {
      obj.newFalseAsserts = Math.round(message.newFalseAsserts);
    }
    if (message.clearedFalseAsserts !== 0) {
      obj.clearedFalseAsserts = Math.round(message.clearedFalseAsserts);
    }
    if (message.configActivities !== 0) {
      obj.configActivities = Math.round(message.configActivities);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertCounterReport>, I>>(base?: I): AssertCounterReport {
    return AssertCounterReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertCounterReport>, I>>(object: I): AssertCounterReport {
    const message = createBaseAssertCounterReport();
    message.device = object.device ?? "";
    message.portName = object.portName ?? "";
    message.type = object.type ?? "";
    message.newFalseAsserts = object.newFalseAsserts ?? 0;
    message.clearedFalseAsserts = object.clearedFalseAsserts ?? 0;
    message.configActivities = object.configActivities ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseAssertCounterReportResponse(): AssertCounterReportResponse {
  return { counterReport: [], pagination: undefined };
}

export const AssertCounterReportResponse = {
  encode(message: AssertCounterReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.counterReport) {
      AssertCounterReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertCounterReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertCounterReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.counterReport.push(AssertCounterReport.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertCounterReportResponse {
    return {
      counterReport: globalThis.Array.isArray(object?.counterReport)
        ? object.counterReport.map((e: any) => AssertCounterReport.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: AssertCounterReportResponse): unknown {
    const obj: any = {};
    if (message.counterReport?.length) {
      obj.counterReport = message.counterReport.map((e) => AssertCounterReport.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertCounterReportResponse>, I>>(base?: I): AssertCounterReportResponse {
    return AssertCounterReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertCounterReportResponse>, I>>(object: I): AssertCounterReportResponse {
    const message = createBaseAssertCounterReportResponse();
    message.counterReport = object.counterReport?.map((e) => AssertCounterReport.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseAssertRestRecordRequest(): AssertRestRecordRequest {
  return {
    startTime: "",
    endTime: "",
    granularity: 0,
    limit: 0,
    fabric: "",
    device: "",
    port: "",
    assertType: [],
    counterType: [],
  };
}

export const AssertRestRecordRequest = {
  encode(message: AssertRestRecordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== "") {
      writer.uint32(18).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(26).string(message.endTime);
    }
    if (message.granularity !== 0) {
      writer.uint32(32).int32(message.granularity);
    }
    if (message.limit !== 0) {
      writer.uint32(40).uint32(message.limit);
    }
    if (message.fabric !== "") {
      writer.uint32(50).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(58).string(message.device);
    }
    if (message.port !== "") {
      writer.uint32(66).string(message.port);
    }
    for (const v of message.assertType) {
      writer.uint32(74).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.counterType) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertRestRecordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertRestRecordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endTime = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.granularity = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.limit = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.device = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.port = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.assertType.push(reader.string());
          continue;
        case 10:
          if (tag === 80) {
            message.counterType.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.counterType.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertRestRecordRequest {
    return {
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
      granularity: isSet(object.granularity) ? granularityFromJSON(object.granularity) : 0,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      assertType: globalThis.Array.isArray(object?.assertType)
        ? object.assertType.map((e: any) => globalThis.String(e))
        : [],
      counterType: globalThis.Array.isArray(object?.counterType)
        ? object.counterType.map((e: any) => assertCounterTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: AssertRestRecordRequest): unknown {
    const obj: any = {};
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    if (message.granularity !== 0) {
      obj.granularity = granularityToJSON(message.granularity);
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.assertType?.length) {
      obj.assertType = message.assertType;
    }
    if (message.counterType?.length) {
      obj.counterType = message.counterType.map((e) => assertCounterTypeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertRestRecordRequest>, I>>(base?: I): AssertRestRecordRequest {
    return AssertRestRecordRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertRestRecordRequest>, I>>(object: I): AssertRestRecordRequest {
    const message = createBaseAssertRestRecordRequest();
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    message.granularity = object.granularity ?? 0;
    message.limit = object.limit ?? 0;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.port = object.port ?? "";
    message.assertType = object.assertType?.map((e) => e) || [];
    message.counterType = object.counterType?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssertHistoryApiRecordResponse(): AssertHistoryApiRecordResponse {
  return { assertions: [], pagination: undefined };
}

export const AssertHistoryApiRecordResponse = {
  encode(message: AssertHistoryApiRecordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assertions) {
      AssertHistoryApiRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryApiRecordResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryApiRecordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertions.push(AssertHistoryApiRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryApiRecordResponse {
    return {
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => AssertHistoryApiRecord.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: AssertHistoryApiRecordResponse): unknown {
    const obj: any = {};
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => AssertHistoryApiRecord.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryApiRecordResponse>, I>>(base?: I): AssertHistoryApiRecordResponse {
    return AssertHistoryApiRecordResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryApiRecordResponse>, I>>(
    object: I,
  ): AssertHistoryApiRecordResponse {
    const message = createBaseAssertHistoryApiRecordResponse();
    message.assertions = object.assertions?.map((e) => AssertHistoryApiRecord.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseHistoryResponse(): HistoryResponse {
  return {
    queryType: 0,
    portStateResponse: undefined,
    portCounterResponse: undefined,
    assertCounterReport: undefined,
    assertResponse: undefined,
    assertApiResponse: undefined,
  };
}

export const HistoryResponse = {
  encode(message: HistoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queryType !== 0) {
      writer.uint32(8).int32(message.queryType);
    }
    if (message.portStateResponse !== undefined) {
      PortStateHistoryRecordResponse.encode(message.portStateResponse, writer.uint32(18).fork()).ldelim();
    }
    if (message.portCounterResponse !== undefined) {
      PortCounterHistoryRecordResponse.encode(message.portCounterResponse, writer.uint32(26).fork()).ldelim();
    }
    if (message.assertCounterReport !== undefined) {
      AssertCounterReportResponse.encode(message.assertCounterReport, writer.uint32(34).fork()).ldelim();
    }
    if (message.assertResponse !== undefined) {
      AssertHistoryRecordResponse.encode(message.assertResponse, writer.uint32(42).fork()).ldelim();
    }
    if (message.assertApiResponse !== undefined) {
      AssertHistoryApiRecordResponse.encode(message.assertApiResponse, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.queryType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portStateResponse = PortStateHistoryRecordResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portCounterResponse = PortCounterHistoryRecordResponse.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.assertCounterReport = AssertCounterReportResponse.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.assertResponse = AssertHistoryRecordResponse.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.assertApiResponse = AssertHistoryApiRecordResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistoryResponse {
    return {
      queryType: isSet(object.queryType) ? queryTypeFromJSON(object.queryType) : 0,
      portStateResponse: isSet(object.portStateResponse)
        ? PortStateHistoryRecordResponse.fromJSON(object.portStateResponse)
        : undefined,
      portCounterResponse: isSet(object.portCounterResponse)
        ? PortCounterHistoryRecordResponse.fromJSON(object.portCounterResponse)
        : undefined,
      assertCounterReport: isSet(object.assertCounterReport)
        ? AssertCounterReportResponse.fromJSON(object.assertCounterReport)
        : undefined,
      assertResponse: isSet(object.assertResponse)
        ? AssertHistoryRecordResponse.fromJSON(object.assertResponse)
        : undefined,
      assertApiResponse: isSet(object.assertApiResponse)
        ? AssertHistoryApiRecordResponse.fromJSON(object.assertApiResponse)
        : undefined,
    };
  },

  toJSON(message: HistoryResponse): unknown {
    const obj: any = {};
    if (message.queryType !== 0) {
      obj.queryType = queryTypeToJSON(message.queryType);
    }
    if (message.portStateResponse !== undefined) {
      obj.portStateResponse = PortStateHistoryRecordResponse.toJSON(message.portStateResponse);
    }
    if (message.portCounterResponse !== undefined) {
      obj.portCounterResponse = PortCounterHistoryRecordResponse.toJSON(message.portCounterResponse);
    }
    if (message.assertCounterReport !== undefined) {
      obj.assertCounterReport = AssertCounterReportResponse.toJSON(message.assertCounterReport);
    }
    if (message.assertResponse !== undefined) {
      obj.assertResponse = AssertHistoryRecordResponse.toJSON(message.assertResponse);
    }
    if (message.assertApiResponse !== undefined) {
      obj.assertApiResponse = AssertHistoryApiRecordResponse.toJSON(message.assertApiResponse);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HistoryResponse>, I>>(base?: I): HistoryResponse {
    return HistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HistoryResponse>, I>>(object: I): HistoryResponse {
    const message = createBaseHistoryResponse();
    message.queryType = object.queryType ?? 0;
    message.portStateResponse = (object.portStateResponse !== undefined && object.portStateResponse !== null)
      ? PortStateHistoryRecordResponse.fromPartial(object.portStateResponse)
      : undefined;
    message.portCounterResponse = (object.portCounterResponse !== undefined && object.portCounterResponse !== null)
      ? PortCounterHistoryRecordResponse.fromPartial(object.portCounterResponse)
      : undefined;
    message.assertCounterReport = (object.assertCounterReport !== undefined && object.assertCounterReport !== null)
      ? AssertCounterReportResponse.fromPartial(object.assertCounterReport)
      : undefined;
    message.assertResponse = (object.assertResponse !== undefined && object.assertResponse !== null)
      ? AssertHistoryRecordResponse.fromPartial(object.assertResponse)
      : undefined;
    message.assertApiResponse = (object.assertApiResponse !== undefined && object.assertApiResponse !== null)
      ? AssertHistoryApiRecordResponse.fromPartial(object.assertApiResponse)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
