import React from "react";
import "./selectionInfo.scss";
import HFlex from "components/hFlex";

interface SelectionInfoProps {
  readonly selectedCount: number;
}

const SelectionInfo = ({
  selectedCount
}: SelectionInfoProps): React.ReactNode => {
  return (
    <div id="selection-info">
      <HFlex gap="sm">
        <div className="count">{selectedCount}</div>
        <div className="info-text">
          {selectedCount === 1 ? "item" : "items"} selected
        </div>
      </HFlex>
    </div>
  );
};

export { SelectionInfo };
