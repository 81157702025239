// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: assertions/assertion_types.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  AssertBgpPeerEstablished,
  AssertDeviceConnectedToCloud,
  AssertDeviceConnectedToFabric,
  AssertDeviceEnvFanUsageBelowThreshold,
  AssertDeviceEnvPsuUsageBelowThreshold,
  AssertDeviceEnvTempSensorUsageBelowThreshold,
  AssertDeviceResourceUsageBelowThreshold,
  AssertExpectedPortBreakoutExists,
  AssertIpmJitterBelowThreshold,
  AssertIpmLatencyBelowThreshold,
  AssertIpmLossBelowThreshold,
  AssertManagementPortConfigSame,
  AssertPortChannelMemberUp,
  AssertPortConnectionSpeedMatch,
  AssertPortExpectedNeighbor,
  AssertPortLinkDown,
  AssertPortLinkUp,
  AssertPortNotConnectedToFabric,
  AssertPortNoUnknownVlanFound,
  AssertPortPluggablePidSame,
  AssertPortSpeedConsistent,
  AssertPortVlanStpStateConsistent,
  AssertStaticDefaultRouteExists,
  AssertSubInterfaceDown,
  AssertSubInterfaceUp,
  AssertVlanHasTraffic,
} from "../schema/schema";

export const protobufPackage = "assertions";

export enum LatchState {
  LATCH_STATE_UNSPECIFIED = 0,
  LATCHED = 1,
  UNLATCHED = 2,
  UNRECOGNIZED = -1,
}

export function latchStateFromJSON(object: any): LatchState {
  switch (object) {
    case 0:
    case "LATCH_STATE_UNSPECIFIED":
      return LatchState.LATCH_STATE_UNSPECIFIED;
    case 1:
    case "LATCHED":
      return LatchState.LATCHED;
    case 2:
    case "UNLATCHED":
      return LatchState.UNLATCHED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LatchState.UNRECOGNIZED;
  }
}

export function latchStateToJSON(object: LatchState): string {
  switch (object) {
    case LatchState.LATCH_STATE_UNSPECIFIED:
      return "LATCH_STATE_UNSPECIFIED";
    case LatchState.LATCHED:
      return "LATCHED";
    case LatchState.UNLATCHED:
      return "UNLATCHED";
    case LatchState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AssertTransitionStates {
  /** ASSERT_STATE_NO_OP - No transition */
  ASSERT_STATE_NO_OP = 0,
  /** ASSERT_STATE_UNSPECIFIED - From any other state to the unspecified state */
  ASSERT_STATE_UNSPECIFIED = 1,
  /** ASSERT_STATE_UNKNOWN - From any other state to the unknown state */
  ASSERT_STATE_UNKNOWN = 2,
  /** ASSERT_STATE_TRUE - From the unspecified or unknown state to the true state */
  ASSERT_STATE_TRUE = 3,
  /** ASSERT_STATE_FALSE - From any other state to the false state */
  ASSERT_STATE_FALSE = 4,
  /** ASSERT_STATE_CLEARED - From the false state to the true state */
  ASSERT_STATE_CLEARED = 5,
  /** CONFIG_ADDED - Reserved for the CONFIG_ADDED_TO_DB AssertType */
  CONFIG_ADDED = 6,
  UNRECOGNIZED = -1,
}

export function assertTransitionStatesFromJSON(object: any): AssertTransitionStates {
  switch (object) {
    case 0:
    case "ASSERT_STATE_NO_OP":
      return AssertTransitionStates.ASSERT_STATE_NO_OP;
    case 1:
    case "ASSERT_STATE_UNSPECIFIED":
      return AssertTransitionStates.ASSERT_STATE_UNSPECIFIED;
    case 2:
    case "ASSERT_STATE_UNKNOWN":
      return AssertTransitionStates.ASSERT_STATE_UNKNOWN;
    case 3:
    case "ASSERT_STATE_TRUE":
      return AssertTransitionStates.ASSERT_STATE_TRUE;
    case 4:
    case "ASSERT_STATE_FALSE":
      return AssertTransitionStates.ASSERT_STATE_FALSE;
    case 5:
    case "ASSERT_STATE_CLEARED":
      return AssertTransitionStates.ASSERT_STATE_CLEARED;
    case 6:
    case "CONFIG_ADDED":
      return AssertTransitionStates.CONFIG_ADDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertTransitionStates.UNRECOGNIZED;
  }
}

export function assertTransitionStatesToJSON(object: AssertTransitionStates): string {
  switch (object) {
    case AssertTransitionStates.ASSERT_STATE_NO_OP:
      return "ASSERT_STATE_NO_OP";
    case AssertTransitionStates.ASSERT_STATE_UNSPECIFIED:
      return "ASSERT_STATE_UNSPECIFIED";
    case AssertTransitionStates.ASSERT_STATE_UNKNOWN:
      return "ASSERT_STATE_UNKNOWN";
    case AssertTransitionStates.ASSERT_STATE_TRUE:
      return "ASSERT_STATE_TRUE";
    case AssertTransitionStates.ASSERT_STATE_FALSE:
      return "ASSERT_STATE_FALSE";
    case AssertTransitionStates.ASSERT_STATE_CLEARED:
      return "ASSERT_STATE_CLEARED";
    case AssertTransitionStates.CONFIG_ADDED:
      return "CONFIG_ADDED";
    case AssertTransitionStates.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AssertType {
  ASSERT_TYPE_UNSPECIFIED = 0,
  CONFIG_ADDED_TO_DB = 1,
  PORT_LINK_UP = 37,
  DEVICE_CONNECTED_TO_CLOUD = 39,
  PORT_EXPECTED_NEIGHBOR = 41,
  PORT_NOT_CONNECTED_TO_FABRIC = 43,
  DEVICE_CONNECTED_TO_FABRIC = 45,
  PORT_LINK_DOWN = 47,
  PORT_CONNECTION_SPEED_MATCH = 49,
  PORT_SPEED_CONSISTENT = 51,
  VLAN_HAS_TRAFFIC = 58,
  STATIC_DEFAULT_ROUTE_EXISTS = 77,
  MANAGEMENT_PORT_CONFIG_SAME = 83,
  DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD = 110,
  BGP_PEER_ESTABLISHED = 118,
  IPM_LATENCY_BELOW_THRESHOLD = 123,
  IPM_LOSS_BELOW_THRESHOLD = 125,
  EXPECTED_PORT_BREAKOUT_EXISTS = 132,
  SUB_INTERFACE_UP = 134,
  SUB_INTERFACE_DOWN = 135,
  PORT_CHANNEL_MEMBER_UP = 140,
  PORT_VLAN_STP_STATE_CONSISTENT = 144,
  PORT_PLUGGABLE_PID_SAME = 146,
  IPM_JITTER_BELOW_THRESHOLD = 149,
  DEVICE_ENV_PSU_USAGE_BELOW_THRESHOLD = 231,
  DEVICE_ENV_FAN_USAGE_BELOW_THRESHOLD = 232,
  DEVICE_ENV_TEMP_SENSOR_USAGE_BELOW_THRESHOLD = 233,
  PORT_NO_UNKNOWN_VLAN_FOUND = 251,
  UNRECOGNIZED = -1,
}

export function assertTypeFromJSON(object: any): AssertType {
  switch (object) {
    case 0:
    case "ASSERT_TYPE_UNSPECIFIED":
      return AssertType.ASSERT_TYPE_UNSPECIFIED;
    case 1:
    case "CONFIG_ADDED_TO_DB":
      return AssertType.CONFIG_ADDED_TO_DB;
    case 37:
    case "PORT_LINK_UP":
      return AssertType.PORT_LINK_UP;
    case 39:
    case "DEVICE_CONNECTED_TO_CLOUD":
      return AssertType.DEVICE_CONNECTED_TO_CLOUD;
    case 41:
    case "PORT_EXPECTED_NEIGHBOR":
      return AssertType.PORT_EXPECTED_NEIGHBOR;
    case 43:
    case "PORT_NOT_CONNECTED_TO_FABRIC":
      return AssertType.PORT_NOT_CONNECTED_TO_FABRIC;
    case 45:
    case "DEVICE_CONNECTED_TO_FABRIC":
      return AssertType.DEVICE_CONNECTED_TO_FABRIC;
    case 47:
    case "PORT_LINK_DOWN":
      return AssertType.PORT_LINK_DOWN;
    case 49:
    case "PORT_CONNECTION_SPEED_MATCH":
      return AssertType.PORT_CONNECTION_SPEED_MATCH;
    case 51:
    case "PORT_SPEED_CONSISTENT":
      return AssertType.PORT_SPEED_CONSISTENT;
    case 58:
    case "VLAN_HAS_TRAFFIC":
      return AssertType.VLAN_HAS_TRAFFIC;
    case 77:
    case "STATIC_DEFAULT_ROUTE_EXISTS":
      return AssertType.STATIC_DEFAULT_ROUTE_EXISTS;
    case 83:
    case "MANAGEMENT_PORT_CONFIG_SAME":
      return AssertType.MANAGEMENT_PORT_CONFIG_SAME;
    case 110:
    case "DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD":
      return AssertType.DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD;
    case 118:
    case "BGP_PEER_ESTABLISHED":
      return AssertType.BGP_PEER_ESTABLISHED;
    case 123:
    case "IPM_LATENCY_BELOW_THRESHOLD":
      return AssertType.IPM_LATENCY_BELOW_THRESHOLD;
    case 125:
    case "IPM_LOSS_BELOW_THRESHOLD":
      return AssertType.IPM_LOSS_BELOW_THRESHOLD;
    case 132:
    case "EXPECTED_PORT_BREAKOUT_EXISTS":
      return AssertType.EXPECTED_PORT_BREAKOUT_EXISTS;
    case 134:
    case "SUB_INTERFACE_UP":
      return AssertType.SUB_INTERFACE_UP;
    case 135:
    case "SUB_INTERFACE_DOWN":
      return AssertType.SUB_INTERFACE_DOWN;
    case 140:
    case "PORT_CHANNEL_MEMBER_UP":
      return AssertType.PORT_CHANNEL_MEMBER_UP;
    case 144:
    case "PORT_VLAN_STP_STATE_CONSISTENT":
      return AssertType.PORT_VLAN_STP_STATE_CONSISTENT;
    case 146:
    case "PORT_PLUGGABLE_PID_SAME":
      return AssertType.PORT_PLUGGABLE_PID_SAME;
    case 149:
    case "IPM_JITTER_BELOW_THRESHOLD":
      return AssertType.IPM_JITTER_BELOW_THRESHOLD;
    case 231:
    case "DEVICE_ENV_PSU_USAGE_BELOW_THRESHOLD":
      return AssertType.DEVICE_ENV_PSU_USAGE_BELOW_THRESHOLD;
    case 232:
    case "DEVICE_ENV_FAN_USAGE_BELOW_THRESHOLD":
      return AssertType.DEVICE_ENV_FAN_USAGE_BELOW_THRESHOLD;
    case 233:
    case "DEVICE_ENV_TEMP_SENSOR_USAGE_BELOW_THRESHOLD":
      return AssertType.DEVICE_ENV_TEMP_SENSOR_USAGE_BELOW_THRESHOLD;
    case 251:
    case "PORT_NO_UNKNOWN_VLAN_FOUND":
      return AssertType.PORT_NO_UNKNOWN_VLAN_FOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertType.UNRECOGNIZED;
  }
}

export function assertTypeToJSON(object: AssertType): string {
  switch (object) {
    case AssertType.ASSERT_TYPE_UNSPECIFIED:
      return "ASSERT_TYPE_UNSPECIFIED";
    case AssertType.CONFIG_ADDED_TO_DB:
      return "CONFIG_ADDED_TO_DB";
    case AssertType.PORT_LINK_UP:
      return "PORT_LINK_UP";
    case AssertType.DEVICE_CONNECTED_TO_CLOUD:
      return "DEVICE_CONNECTED_TO_CLOUD";
    case AssertType.PORT_EXPECTED_NEIGHBOR:
      return "PORT_EXPECTED_NEIGHBOR";
    case AssertType.PORT_NOT_CONNECTED_TO_FABRIC:
      return "PORT_NOT_CONNECTED_TO_FABRIC";
    case AssertType.DEVICE_CONNECTED_TO_FABRIC:
      return "DEVICE_CONNECTED_TO_FABRIC";
    case AssertType.PORT_LINK_DOWN:
      return "PORT_LINK_DOWN";
    case AssertType.PORT_CONNECTION_SPEED_MATCH:
      return "PORT_CONNECTION_SPEED_MATCH";
    case AssertType.PORT_SPEED_CONSISTENT:
      return "PORT_SPEED_CONSISTENT";
    case AssertType.VLAN_HAS_TRAFFIC:
      return "VLAN_HAS_TRAFFIC";
    case AssertType.STATIC_DEFAULT_ROUTE_EXISTS:
      return "STATIC_DEFAULT_ROUTE_EXISTS";
    case AssertType.MANAGEMENT_PORT_CONFIG_SAME:
      return "MANAGEMENT_PORT_CONFIG_SAME";
    case AssertType.DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD:
      return "DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD";
    case AssertType.BGP_PEER_ESTABLISHED:
      return "BGP_PEER_ESTABLISHED";
    case AssertType.IPM_LATENCY_BELOW_THRESHOLD:
      return "IPM_LATENCY_BELOW_THRESHOLD";
    case AssertType.IPM_LOSS_BELOW_THRESHOLD:
      return "IPM_LOSS_BELOW_THRESHOLD";
    case AssertType.EXPECTED_PORT_BREAKOUT_EXISTS:
      return "EXPECTED_PORT_BREAKOUT_EXISTS";
    case AssertType.SUB_INTERFACE_UP:
      return "SUB_INTERFACE_UP";
    case AssertType.SUB_INTERFACE_DOWN:
      return "SUB_INTERFACE_DOWN";
    case AssertType.PORT_CHANNEL_MEMBER_UP:
      return "PORT_CHANNEL_MEMBER_UP";
    case AssertType.PORT_VLAN_STP_STATE_CONSISTENT:
      return "PORT_VLAN_STP_STATE_CONSISTENT";
    case AssertType.PORT_PLUGGABLE_PID_SAME:
      return "PORT_PLUGGABLE_PID_SAME";
    case AssertType.IPM_JITTER_BELOW_THRESHOLD:
      return "IPM_JITTER_BELOW_THRESHOLD";
    case AssertType.DEVICE_ENV_PSU_USAGE_BELOW_THRESHOLD:
      return "DEVICE_ENV_PSU_USAGE_BELOW_THRESHOLD";
    case AssertType.DEVICE_ENV_FAN_USAGE_BELOW_THRESHOLD:
      return "DEVICE_ENV_FAN_USAGE_BELOW_THRESHOLD";
    case AssertType.DEVICE_ENV_TEMP_SENSOR_USAGE_BELOW_THRESHOLD:
      return "DEVICE_ENV_TEMP_SENSOR_USAGE_BELOW_THRESHOLD";
    case AssertType.PORT_NO_UNKNOWN_VLAN_FOUND:
      return "PORT_NO_UNKNOWN_VLAN_FOUND";
    case AssertType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Assertion {
  assertType: AssertType;
  state: LatchState;
  portLinkUp: AssertPortLinkUp | undefined;
  deviceConnectedToCloud: AssertDeviceConnectedToCloud | undefined;
  portExpectedNeighbor: AssertPortExpectedNeighbor | undefined;
  portNotConnectedToFabric: AssertPortNotConnectedToFabric | undefined;
  deviceConnectedToFabric: AssertDeviceConnectedToFabric | undefined;
  portLinkDown: AssertPortLinkDown | undefined;
  portConnectionSpeedMatch: AssertPortConnectionSpeedMatch | undefined;
  portSpeedConsistent: AssertPortSpeedConsistent | undefined;
  vlanHasTraffic: AssertVlanHasTraffic | undefined;
  staticDefaultRouteExists: AssertStaticDefaultRouteExists | undefined;
  managementPortConfigSame: AssertManagementPortConfigSame | undefined;
  deviceResourceUsageBelowThreshold: AssertDeviceResourceUsageBelowThreshold | undefined;
  bgpPeerEstablished: AssertBgpPeerEstablished | undefined;
  ipmLatencyBelowThreshold: AssertIpmLatencyBelowThreshold | undefined;
  ipmLossBelowThreshold: AssertIpmLossBelowThreshold | undefined;
  expectedPortBreakoutExists: AssertExpectedPortBreakoutExists | undefined;
  subInterfaceUp: AssertSubInterfaceUp | undefined;
  subInterfaceDown: AssertSubInterfaceDown | undefined;
  portChannelMemberUp: AssertPortChannelMemberUp | undefined;
  portVlanStpStateConsistent: AssertPortVlanStpStateConsistent | undefined;
  portPluggablePidSame: AssertPortPluggablePidSame | undefined;
  ipmJitterBelowThreshold: AssertIpmJitterBelowThreshold | undefined;
  deviceEnvPsuUsageBelowThreshold: AssertDeviceEnvPsuUsageBelowThreshold | undefined;
  deviceEnvFanUsageBelowThreshold: AssertDeviceEnvFanUsageBelowThreshold | undefined;
  deviceEnvTempSensorUsageBelowThreshold: AssertDeviceEnvTempSensorUsageBelowThreshold | undefined;
  portNoUnknownVlanFound: AssertPortNoUnknownVlanFound | undefined;
}

function createBaseAssertion(): Assertion {
  return {
    assertType: 0,
    state: 0,
    portLinkUp: undefined,
    deviceConnectedToCloud: undefined,
    portExpectedNeighbor: undefined,
    portNotConnectedToFabric: undefined,
    deviceConnectedToFabric: undefined,
    portLinkDown: undefined,
    portConnectionSpeedMatch: undefined,
    portSpeedConsistent: undefined,
    vlanHasTraffic: undefined,
    staticDefaultRouteExists: undefined,
    managementPortConfigSame: undefined,
    deviceResourceUsageBelowThreshold: undefined,
    bgpPeerEstablished: undefined,
    ipmLatencyBelowThreshold: undefined,
    ipmLossBelowThreshold: undefined,
    expectedPortBreakoutExists: undefined,
    subInterfaceUp: undefined,
    subInterfaceDown: undefined,
    portChannelMemberUp: undefined,
    portVlanStpStateConsistent: undefined,
    portPluggablePidSame: undefined,
    ipmJitterBelowThreshold: undefined,
    deviceEnvPsuUsageBelowThreshold: undefined,
    deviceEnvFanUsageBelowThreshold: undefined,
    deviceEnvTempSensorUsageBelowThreshold: undefined,
    portNoUnknownVlanFound: undefined,
  };
}

export const Assertion = {
  encode(message: Assertion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assertType !== 0) {
      writer.uint32(8).int32(message.assertType);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.portLinkUp !== undefined) {
      AssertPortLinkUp.encode(message.portLinkUp, writer.uint32(378).fork()).ldelim();
    }
    if (message.deviceConnectedToCloud !== undefined) {
      AssertDeviceConnectedToCloud.encode(message.deviceConnectedToCloud, writer.uint32(394).fork()).ldelim();
    }
    if (message.portExpectedNeighbor !== undefined) {
      AssertPortExpectedNeighbor.encode(message.portExpectedNeighbor, writer.uint32(410).fork()).ldelim();
    }
    if (message.portNotConnectedToFabric !== undefined) {
      AssertPortNotConnectedToFabric.encode(message.portNotConnectedToFabric, writer.uint32(426).fork()).ldelim();
    }
    if (message.deviceConnectedToFabric !== undefined) {
      AssertDeviceConnectedToFabric.encode(message.deviceConnectedToFabric, writer.uint32(442).fork()).ldelim();
    }
    if (message.portLinkDown !== undefined) {
      AssertPortLinkDown.encode(message.portLinkDown, writer.uint32(458).fork()).ldelim();
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      AssertPortConnectionSpeedMatch.encode(message.portConnectionSpeedMatch, writer.uint32(474).fork()).ldelim();
    }
    if (message.portSpeedConsistent !== undefined) {
      AssertPortSpeedConsistent.encode(message.portSpeedConsistent, writer.uint32(490).fork()).ldelim();
    }
    if (message.vlanHasTraffic !== undefined) {
      AssertVlanHasTraffic.encode(message.vlanHasTraffic, writer.uint32(546).fork()).ldelim();
    }
    if (message.staticDefaultRouteExists !== undefined) {
      AssertStaticDefaultRouteExists.encode(message.staticDefaultRouteExists, writer.uint32(698).fork()).ldelim();
    }
    if (message.managementPortConfigSame !== undefined) {
      AssertManagementPortConfigSame.encode(message.managementPortConfigSame, writer.uint32(746).fork()).ldelim();
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      AssertDeviceResourceUsageBelowThreshold.encode(
        message.deviceResourceUsageBelowThreshold,
        writer.uint32(962).fork(),
      ).ldelim();
    }
    if (message.bgpPeerEstablished !== undefined) {
      AssertBgpPeerEstablished.encode(message.bgpPeerEstablished, writer.uint32(1026).fork()).ldelim();
    }
    if (message.ipmLatencyBelowThreshold !== undefined) {
      AssertIpmLatencyBelowThreshold.encode(message.ipmLatencyBelowThreshold, writer.uint32(1066).fork()).ldelim();
    }
    if (message.ipmLossBelowThreshold !== undefined) {
      AssertIpmLossBelowThreshold.encode(message.ipmLossBelowThreshold, writer.uint32(1082).fork()).ldelim();
    }
    if (message.expectedPortBreakoutExists !== undefined) {
      AssertExpectedPortBreakoutExists.encode(message.expectedPortBreakoutExists, writer.uint32(1138).fork()).ldelim();
    }
    if (message.subInterfaceUp !== undefined) {
      AssertSubInterfaceUp.encode(message.subInterfaceUp, writer.uint32(1154).fork()).ldelim();
    }
    if (message.subInterfaceDown !== undefined) {
      AssertSubInterfaceDown.encode(message.subInterfaceDown, writer.uint32(1162).fork()).ldelim();
    }
    if (message.portChannelMemberUp !== undefined) {
      AssertPortChannelMemberUp.encode(message.portChannelMemberUp, writer.uint32(1202).fork()).ldelim();
    }
    if (message.portVlanStpStateConsistent !== undefined) {
      AssertPortVlanStpStateConsistent.encode(message.portVlanStpStateConsistent, writer.uint32(1234).fork()).ldelim();
    }
    if (message.portPluggablePidSame !== undefined) {
      AssertPortPluggablePidSame.encode(message.portPluggablePidSame, writer.uint32(1250).fork()).ldelim();
    }
    if (message.ipmJitterBelowThreshold !== undefined) {
      AssertIpmJitterBelowThreshold.encode(message.ipmJitterBelowThreshold, writer.uint32(1274).fork()).ldelim();
    }
    if (message.deviceEnvPsuUsageBelowThreshold !== undefined) {
      AssertDeviceEnvPsuUsageBelowThreshold.encode(message.deviceEnvPsuUsageBelowThreshold, writer.uint32(1930).fork())
        .ldelim();
    }
    if (message.deviceEnvFanUsageBelowThreshold !== undefined) {
      AssertDeviceEnvFanUsageBelowThreshold.encode(message.deviceEnvFanUsageBelowThreshold, writer.uint32(1938).fork())
        .ldelim();
    }
    if (message.deviceEnvTempSensorUsageBelowThreshold !== undefined) {
      AssertDeviceEnvTempSensorUsageBelowThreshold.encode(
        message.deviceEnvTempSensorUsageBelowThreshold,
        writer.uint32(1946).fork(),
      ).ldelim();
    }
    if (message.portNoUnknownVlanFound !== undefined) {
      AssertPortNoUnknownVlanFound.encode(message.portNoUnknownVlanFound, writer.uint32(2090).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assertion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.assertType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.portLinkUp = AssertPortLinkUp.decode(reader, reader.uint32());
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.deviceConnectedToCloud = AssertDeviceConnectedToCloud.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.portExpectedNeighbor = AssertPortExpectedNeighbor.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.portNotConnectedToFabric = AssertPortNotConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.deviceConnectedToFabric = AssertDeviceConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.portLinkDown = AssertPortLinkDown.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.decode(reader, reader.uint32());
          continue;
        case 61:
          if (tag !== 490) {
            break;
          }

          message.portSpeedConsistent = AssertPortSpeedConsistent.decode(reader, reader.uint32());
          continue;
        case 68:
          if (tag !== 546) {
            break;
          }

          message.vlanHasTraffic = AssertVlanHasTraffic.decode(reader, reader.uint32());
          continue;
        case 87:
          if (tag !== 698) {
            break;
          }

          message.staticDefaultRouteExists = AssertStaticDefaultRouteExists.decode(reader, reader.uint32());
          continue;
        case 93:
          if (tag !== 746) {
            break;
          }

          message.managementPortConfigSame = AssertManagementPortConfigSame.decode(reader, reader.uint32());
          continue;
        case 120:
          if (tag !== 962) {
            break;
          }

          message.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 128:
          if (tag !== 1026) {
            break;
          }

          message.bgpPeerEstablished = AssertBgpPeerEstablished.decode(reader, reader.uint32());
          continue;
        case 133:
          if (tag !== 1066) {
            break;
          }

          message.ipmLatencyBelowThreshold = AssertIpmLatencyBelowThreshold.decode(reader, reader.uint32());
          continue;
        case 135:
          if (tag !== 1082) {
            break;
          }

          message.ipmLossBelowThreshold = AssertIpmLossBelowThreshold.decode(reader, reader.uint32());
          continue;
        case 142:
          if (tag !== 1138) {
            break;
          }

          message.expectedPortBreakoutExists = AssertExpectedPortBreakoutExists.decode(reader, reader.uint32());
          continue;
        case 144:
          if (tag !== 1154) {
            break;
          }

          message.subInterfaceUp = AssertSubInterfaceUp.decode(reader, reader.uint32());
          continue;
        case 145:
          if (tag !== 1162) {
            break;
          }

          message.subInterfaceDown = AssertSubInterfaceDown.decode(reader, reader.uint32());
          continue;
        case 150:
          if (tag !== 1202) {
            break;
          }

          message.portChannelMemberUp = AssertPortChannelMemberUp.decode(reader, reader.uint32());
          continue;
        case 154:
          if (tag !== 1234) {
            break;
          }

          message.portVlanStpStateConsistent = AssertPortVlanStpStateConsistent.decode(reader, reader.uint32());
          continue;
        case 156:
          if (tag !== 1250) {
            break;
          }

          message.portPluggablePidSame = AssertPortPluggablePidSame.decode(reader, reader.uint32());
          continue;
        case 159:
          if (tag !== 1274) {
            break;
          }

          message.ipmJitterBelowThreshold = AssertIpmJitterBelowThreshold.decode(reader, reader.uint32());
          continue;
        case 241:
          if (tag !== 1930) {
            break;
          }

          message.deviceEnvPsuUsageBelowThreshold = AssertDeviceEnvPsuUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 242:
          if (tag !== 1938) {
            break;
          }

          message.deviceEnvFanUsageBelowThreshold = AssertDeviceEnvFanUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 243:
          if (tag !== 1946) {
            break;
          }

          message.deviceEnvTempSensorUsageBelowThreshold = AssertDeviceEnvTempSensorUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 261:
          if (tag !== 2090) {
            break;
          }

          message.portNoUnknownVlanFound = AssertPortNoUnknownVlanFound.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Assertion {
    return {
      assertType: isSet(object.assertType) ? assertTypeFromJSON(object.assertType) : 0,
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
      portLinkUp: isSet(object.portLinkUp) ? AssertPortLinkUp.fromJSON(object.portLinkUp) : undefined,
      deviceConnectedToCloud: isSet(object.deviceConnectedToCloud)
        ? AssertDeviceConnectedToCloud.fromJSON(object.deviceConnectedToCloud)
        : undefined,
      portExpectedNeighbor: isSet(object.portExpectedNeighbor)
        ? AssertPortExpectedNeighbor.fromJSON(object.portExpectedNeighbor)
        : undefined,
      portNotConnectedToFabric: isSet(object.portNotConnectedToFabric)
        ? AssertPortNotConnectedToFabric.fromJSON(object.portNotConnectedToFabric)
        : undefined,
      deviceConnectedToFabric: isSet(object.deviceConnectedToFabric)
        ? AssertDeviceConnectedToFabric.fromJSON(object.deviceConnectedToFabric)
        : undefined,
      portLinkDown: isSet(object.portLinkDown) ? AssertPortLinkDown.fromJSON(object.portLinkDown) : undefined,
      portConnectionSpeedMatch: isSet(object.portConnectionSpeedMatch)
        ? AssertPortConnectionSpeedMatch.fromJSON(object.portConnectionSpeedMatch)
        : undefined,
      portSpeedConsistent: isSet(object.portSpeedConsistent)
        ? AssertPortSpeedConsistent.fromJSON(object.portSpeedConsistent)
        : undefined,
      vlanHasTraffic: isSet(object.vlanHasTraffic) ? AssertVlanHasTraffic.fromJSON(object.vlanHasTraffic) : undefined,
      staticDefaultRouteExists: isSet(object.staticDefaultRouteExists)
        ? AssertStaticDefaultRouteExists.fromJSON(object.staticDefaultRouteExists)
        : undefined,
      managementPortConfigSame: isSet(object.managementPortConfigSame)
        ? AssertManagementPortConfigSame.fromJSON(object.managementPortConfigSame)
        : undefined,
      deviceResourceUsageBelowThreshold: isSet(object.deviceResourceUsageBelowThreshold)
        ? AssertDeviceResourceUsageBelowThreshold.fromJSON(object.deviceResourceUsageBelowThreshold)
        : undefined,
      bgpPeerEstablished: isSet(object.bgpPeerEstablished)
        ? AssertBgpPeerEstablished.fromJSON(object.bgpPeerEstablished)
        : undefined,
      ipmLatencyBelowThreshold: isSet(object.ipmLatencyBelowThreshold)
        ? AssertIpmLatencyBelowThreshold.fromJSON(object.ipmLatencyBelowThreshold)
        : undefined,
      ipmLossBelowThreshold: isSet(object.ipmLossBelowThreshold)
        ? AssertIpmLossBelowThreshold.fromJSON(object.ipmLossBelowThreshold)
        : undefined,
      expectedPortBreakoutExists: isSet(object.expectedPortBreakoutExists)
        ? AssertExpectedPortBreakoutExists.fromJSON(object.expectedPortBreakoutExists)
        : undefined,
      subInterfaceUp: isSet(object.subInterfaceUp) ? AssertSubInterfaceUp.fromJSON(object.subInterfaceUp) : undefined,
      subInterfaceDown: isSet(object.subInterfaceDown)
        ? AssertSubInterfaceDown.fromJSON(object.subInterfaceDown)
        : undefined,
      portChannelMemberUp: isSet(object.portChannelMemberUp)
        ? AssertPortChannelMemberUp.fromJSON(object.portChannelMemberUp)
        : undefined,
      portVlanStpStateConsistent: isSet(object.portVlanStpStateConsistent)
        ? AssertPortVlanStpStateConsistent.fromJSON(object.portVlanStpStateConsistent)
        : undefined,
      portPluggablePidSame: isSet(object.portPluggablePidSame)
        ? AssertPortPluggablePidSame.fromJSON(object.portPluggablePidSame)
        : undefined,
      ipmJitterBelowThreshold: isSet(object.ipmJitterBelowThreshold)
        ? AssertIpmJitterBelowThreshold.fromJSON(object.ipmJitterBelowThreshold)
        : undefined,
      deviceEnvPsuUsageBelowThreshold: isSet(object.deviceEnvPsuUsageBelowThreshold)
        ? AssertDeviceEnvPsuUsageBelowThreshold.fromJSON(object.deviceEnvPsuUsageBelowThreshold)
        : undefined,
      deviceEnvFanUsageBelowThreshold: isSet(object.deviceEnvFanUsageBelowThreshold)
        ? AssertDeviceEnvFanUsageBelowThreshold.fromJSON(object.deviceEnvFanUsageBelowThreshold)
        : undefined,
      deviceEnvTempSensorUsageBelowThreshold: isSet(object.deviceEnvTempSensorUsageBelowThreshold)
        ? AssertDeviceEnvTempSensorUsageBelowThreshold.fromJSON(object.deviceEnvTempSensorUsageBelowThreshold)
        : undefined,
      portNoUnknownVlanFound: isSet(object.portNoUnknownVlanFound)
        ? AssertPortNoUnknownVlanFound.fromJSON(object.portNoUnknownVlanFound)
        : undefined,
    };
  },

  toJSON(message: Assertion): unknown {
    const obj: any = {};
    if (message.assertType !== 0) {
      obj.assertType = assertTypeToJSON(message.assertType);
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    if (message.portLinkUp !== undefined) {
      obj.portLinkUp = AssertPortLinkUp.toJSON(message.portLinkUp);
    }
    if (message.deviceConnectedToCloud !== undefined) {
      obj.deviceConnectedToCloud = AssertDeviceConnectedToCloud.toJSON(message.deviceConnectedToCloud);
    }
    if (message.portExpectedNeighbor !== undefined) {
      obj.portExpectedNeighbor = AssertPortExpectedNeighbor.toJSON(message.portExpectedNeighbor);
    }
    if (message.portNotConnectedToFabric !== undefined) {
      obj.portNotConnectedToFabric = AssertPortNotConnectedToFabric.toJSON(message.portNotConnectedToFabric);
    }
    if (message.deviceConnectedToFabric !== undefined) {
      obj.deviceConnectedToFabric = AssertDeviceConnectedToFabric.toJSON(message.deviceConnectedToFabric);
    }
    if (message.portLinkDown !== undefined) {
      obj.portLinkDown = AssertPortLinkDown.toJSON(message.portLinkDown);
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      obj.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.toJSON(message.portConnectionSpeedMatch);
    }
    if (message.portSpeedConsistent !== undefined) {
      obj.portSpeedConsistent = AssertPortSpeedConsistent.toJSON(message.portSpeedConsistent);
    }
    if (message.vlanHasTraffic !== undefined) {
      obj.vlanHasTraffic = AssertVlanHasTraffic.toJSON(message.vlanHasTraffic);
    }
    if (message.staticDefaultRouteExists !== undefined) {
      obj.staticDefaultRouteExists = AssertStaticDefaultRouteExists.toJSON(message.staticDefaultRouteExists);
    }
    if (message.managementPortConfigSame !== undefined) {
      obj.managementPortConfigSame = AssertManagementPortConfigSame.toJSON(message.managementPortConfigSame);
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      obj.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.toJSON(
        message.deviceResourceUsageBelowThreshold,
      );
    }
    if (message.bgpPeerEstablished !== undefined) {
      obj.bgpPeerEstablished = AssertBgpPeerEstablished.toJSON(message.bgpPeerEstablished);
    }
    if (message.ipmLatencyBelowThreshold !== undefined) {
      obj.ipmLatencyBelowThreshold = AssertIpmLatencyBelowThreshold.toJSON(message.ipmLatencyBelowThreshold);
    }
    if (message.ipmLossBelowThreshold !== undefined) {
      obj.ipmLossBelowThreshold = AssertIpmLossBelowThreshold.toJSON(message.ipmLossBelowThreshold);
    }
    if (message.expectedPortBreakoutExists !== undefined) {
      obj.expectedPortBreakoutExists = AssertExpectedPortBreakoutExists.toJSON(message.expectedPortBreakoutExists);
    }
    if (message.subInterfaceUp !== undefined) {
      obj.subInterfaceUp = AssertSubInterfaceUp.toJSON(message.subInterfaceUp);
    }
    if (message.subInterfaceDown !== undefined) {
      obj.subInterfaceDown = AssertSubInterfaceDown.toJSON(message.subInterfaceDown);
    }
    if (message.portChannelMemberUp !== undefined) {
      obj.portChannelMemberUp = AssertPortChannelMemberUp.toJSON(message.portChannelMemberUp);
    }
    if (message.portVlanStpStateConsistent !== undefined) {
      obj.portVlanStpStateConsistent = AssertPortVlanStpStateConsistent.toJSON(message.portVlanStpStateConsistent);
    }
    if (message.portPluggablePidSame !== undefined) {
      obj.portPluggablePidSame = AssertPortPluggablePidSame.toJSON(message.portPluggablePidSame);
    }
    if (message.ipmJitterBelowThreshold !== undefined) {
      obj.ipmJitterBelowThreshold = AssertIpmJitterBelowThreshold.toJSON(message.ipmJitterBelowThreshold);
    }
    if (message.deviceEnvPsuUsageBelowThreshold !== undefined) {
      obj.deviceEnvPsuUsageBelowThreshold = AssertDeviceEnvPsuUsageBelowThreshold.toJSON(
        message.deviceEnvPsuUsageBelowThreshold,
      );
    }
    if (message.deviceEnvFanUsageBelowThreshold !== undefined) {
      obj.deviceEnvFanUsageBelowThreshold = AssertDeviceEnvFanUsageBelowThreshold.toJSON(
        message.deviceEnvFanUsageBelowThreshold,
      );
    }
    if (message.deviceEnvTempSensorUsageBelowThreshold !== undefined) {
      obj.deviceEnvTempSensorUsageBelowThreshold = AssertDeviceEnvTempSensorUsageBelowThreshold.toJSON(
        message.deviceEnvTempSensorUsageBelowThreshold,
      );
    }
    if (message.portNoUnknownVlanFound !== undefined) {
      obj.portNoUnknownVlanFound = AssertPortNoUnknownVlanFound.toJSON(message.portNoUnknownVlanFound);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Assertion>, I>>(base?: I): Assertion {
    return Assertion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Assertion>, I>>(object: I): Assertion {
    const message = createBaseAssertion();
    message.assertType = object.assertType ?? 0;
    message.state = object.state ?? 0;
    message.portLinkUp = (object.portLinkUp !== undefined && object.portLinkUp !== null)
      ? AssertPortLinkUp.fromPartial(object.portLinkUp)
      : undefined;
    message.deviceConnectedToCloud =
      (object.deviceConnectedToCloud !== undefined && object.deviceConnectedToCloud !== null)
        ? AssertDeviceConnectedToCloud.fromPartial(object.deviceConnectedToCloud)
        : undefined;
    message.portExpectedNeighbor = (object.portExpectedNeighbor !== undefined && object.portExpectedNeighbor !== null)
      ? AssertPortExpectedNeighbor.fromPartial(object.portExpectedNeighbor)
      : undefined;
    message.portNotConnectedToFabric =
      (object.portNotConnectedToFabric !== undefined && object.portNotConnectedToFabric !== null)
        ? AssertPortNotConnectedToFabric.fromPartial(object.portNotConnectedToFabric)
        : undefined;
    message.deviceConnectedToFabric =
      (object.deviceConnectedToFabric !== undefined && object.deviceConnectedToFabric !== null)
        ? AssertDeviceConnectedToFabric.fromPartial(object.deviceConnectedToFabric)
        : undefined;
    message.portLinkDown = (object.portLinkDown !== undefined && object.portLinkDown !== null)
      ? AssertPortLinkDown.fromPartial(object.portLinkDown)
      : undefined;
    message.portConnectionSpeedMatch =
      (object.portConnectionSpeedMatch !== undefined && object.portConnectionSpeedMatch !== null)
        ? AssertPortConnectionSpeedMatch.fromPartial(object.portConnectionSpeedMatch)
        : undefined;
    message.portSpeedConsistent = (object.portSpeedConsistent !== undefined && object.portSpeedConsistent !== null)
      ? AssertPortSpeedConsistent.fromPartial(object.portSpeedConsistent)
      : undefined;
    message.vlanHasTraffic = (object.vlanHasTraffic !== undefined && object.vlanHasTraffic !== null)
      ? AssertVlanHasTraffic.fromPartial(object.vlanHasTraffic)
      : undefined;
    message.staticDefaultRouteExists =
      (object.staticDefaultRouteExists !== undefined && object.staticDefaultRouteExists !== null)
        ? AssertStaticDefaultRouteExists.fromPartial(object.staticDefaultRouteExists)
        : undefined;
    message.managementPortConfigSame =
      (object.managementPortConfigSame !== undefined && object.managementPortConfigSame !== null)
        ? AssertManagementPortConfigSame.fromPartial(object.managementPortConfigSame)
        : undefined;
    message.deviceResourceUsageBelowThreshold =
      (object.deviceResourceUsageBelowThreshold !== undefined && object.deviceResourceUsageBelowThreshold !== null)
        ? AssertDeviceResourceUsageBelowThreshold.fromPartial(object.deviceResourceUsageBelowThreshold)
        : undefined;
    message.bgpPeerEstablished = (object.bgpPeerEstablished !== undefined && object.bgpPeerEstablished !== null)
      ? AssertBgpPeerEstablished.fromPartial(object.bgpPeerEstablished)
      : undefined;
    message.ipmLatencyBelowThreshold =
      (object.ipmLatencyBelowThreshold !== undefined && object.ipmLatencyBelowThreshold !== null)
        ? AssertIpmLatencyBelowThreshold.fromPartial(object.ipmLatencyBelowThreshold)
        : undefined;
    message.ipmLossBelowThreshold =
      (object.ipmLossBelowThreshold !== undefined && object.ipmLossBelowThreshold !== null)
        ? AssertIpmLossBelowThreshold.fromPartial(object.ipmLossBelowThreshold)
        : undefined;
    message.expectedPortBreakoutExists =
      (object.expectedPortBreakoutExists !== undefined && object.expectedPortBreakoutExists !== null)
        ? AssertExpectedPortBreakoutExists.fromPartial(object.expectedPortBreakoutExists)
        : undefined;
    message.subInterfaceUp = (object.subInterfaceUp !== undefined && object.subInterfaceUp !== null)
      ? AssertSubInterfaceUp.fromPartial(object.subInterfaceUp)
      : undefined;
    message.subInterfaceDown = (object.subInterfaceDown !== undefined && object.subInterfaceDown !== null)
      ? AssertSubInterfaceDown.fromPartial(object.subInterfaceDown)
      : undefined;
    message.portChannelMemberUp = (object.portChannelMemberUp !== undefined && object.portChannelMemberUp !== null)
      ? AssertPortChannelMemberUp.fromPartial(object.portChannelMemberUp)
      : undefined;
    message.portVlanStpStateConsistent =
      (object.portVlanStpStateConsistent !== undefined && object.portVlanStpStateConsistent !== null)
        ? AssertPortVlanStpStateConsistent.fromPartial(object.portVlanStpStateConsistent)
        : undefined;
    message.portPluggablePidSame = (object.portPluggablePidSame !== undefined && object.portPluggablePidSame !== null)
      ? AssertPortPluggablePidSame.fromPartial(object.portPluggablePidSame)
      : undefined;
    message.ipmJitterBelowThreshold =
      (object.ipmJitterBelowThreshold !== undefined && object.ipmJitterBelowThreshold !== null)
        ? AssertIpmJitterBelowThreshold.fromPartial(object.ipmJitterBelowThreshold)
        : undefined;
    message.deviceEnvPsuUsageBelowThreshold =
      (object.deviceEnvPsuUsageBelowThreshold !== undefined && object.deviceEnvPsuUsageBelowThreshold !== null)
        ? AssertDeviceEnvPsuUsageBelowThreshold.fromPartial(object.deviceEnvPsuUsageBelowThreshold)
        : undefined;
    message.deviceEnvFanUsageBelowThreshold =
      (object.deviceEnvFanUsageBelowThreshold !== undefined && object.deviceEnvFanUsageBelowThreshold !== null)
        ? AssertDeviceEnvFanUsageBelowThreshold.fromPartial(object.deviceEnvFanUsageBelowThreshold)
        : undefined;
    message.deviceEnvTempSensorUsageBelowThreshold =
      (object.deviceEnvTempSensorUsageBelowThreshold !== undefined &&
          object.deviceEnvTempSensorUsageBelowThreshold !== null)
        ? AssertDeviceEnvTempSensorUsageBelowThreshold.fromPartial(object.deviceEnvTempSensorUsageBelowThreshold)
        : undefined;
    message.portNoUnknownVlanFound =
      (object.portNoUnknownVlanFound !== undefined && object.portNoUnknownVlanFound !== null)
        ? AssertPortNoUnknownVlanFound.fromPartial(object.portNoUnknownVlanFound)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
