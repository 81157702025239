import React, { useContext } from "react";
import { Button } from "@magnetic/button";
import { Heading } from "@magnetic/heading";
import { CiscoLogo } from "../common/ciscoLogo";

import "./welcomeScreen.scss";
import VFlex from "../components/vFlex";
import HFlex from "../components/hFlex";
import { LoginContext } from "./loginContext";
import { Constants } from "../utils/constants";

export const ContactSales = () => {
  const { logout } = useContext(LoginContext);

  return (
    <VFlex className="welcome" gap="sm">
      <VFlex className="welcome" align="center" gap="lg">
        <CiscoLogo />
        <Heading size="h1">Not yet a {Constants.APP_NAME} member</Heading>
        <p>
          You are authenticated correctly but you are not an active member of{" "}
          {Constants.APP_NAME}.
        </p>
      </VFlex>
      <HFlex align="stretch" justify="flex-start">
        <Button name="logout" kind="tertiary" onClick={logout}>
          &larr; Back to login
        </Button>
      </HFlex>
    </VFlex>
  );
};
