import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Input } from "@magnetic/input";
import "./mockShellInput.scss";
import {
  CliCommandCompletion,
  CommandAction,
  ResultFormat
} from "src/gen/schema/bilge/api";
import { DataProviderContext } from "src/core/provider/dataProvider";
import { useLocation } from "react-router-dom";
import {
  getCommandCompletionData,
  getInitialCommand,
  moveCursorBackwardByWord,
  moveCursorForwardByWord
} from "./mockShellUtils";
import { CommandExecution } from "./manageMockShellModal";
import { ApiError } from "src/core/apiError";

type MockShellInputProps = {
  readonly commandExecution: (cmdObj: CommandExecution) => void;
  readonly completeCommand: (cmdObj: CommandExecution) => void;
  readonly commandCompleteSuggestions: CliCommandCompletion[];
  readonly onCommandCompleteSuggestionsChange: (
    value: CliCommandCompletion[]
  ) => void;
  readonly onClearError: (value: ApiError | undefined) => void;
  readonly loading: boolean;
};
/**
 * MockShellInput is intended to mostly mimic bash shell prompt
 * The main keystrokes (input) requirement as per TORT-2094:
 * 1. " " (space) -> Autocomplete. There can be 3 situations to handle
 *     a) Single command to autocomplete (unambiguous) where hitting of space will autocomplete with the command for user
 *     b) Multiple commands to autocomplete(ambiguous) where we show a dropdown with available commands that user can select from to autocomplete
 *     c) Actual need of entering space between commands(here backend does not return any commands to autocomplete as the command typed by user would be already complete)
 * 2. tab -> Expands. It outputs the expanded form of the current command or provides the next commands
 * 3. ? -> Expounds. Same as tab but also provides the helpstring explaining each command
 * 4. enter -> Executes. Executes the commad until where exactly the enter key is pressed
 * The other important keys strokes that mimics the bash shell that are implemented are:
 * 5. Ctrl+P and  Up Arrow: Moves the cursor to the previous command in history.
 * 6. Ctrl+N and Down Arrow: Moves the cursor to the next command in history.
 * 7. M+f: Move forward one word.
 * 8. M+b: Move backward one word.
 * 9. Ctrl+a: Move to the beginning of the line.
 * 10. Ctrl+e: Move to the end of the line.
 * 11. Ctrl+k: Kill (cut) text from the cursor to the end of the line.
 * 12. Ctrl+y: Yank (paste) the most recently killed text.
 */
export const MockShellInput = (props: MockShellInputProps) => {
  const {
    completeCommand,
    commandExecution,
    commandCompleteSuggestions,
    onCommandCompleteSuggestionsChange,
    onClearError
  } = props;

  const { fabric } = useContext(DataProviderContext);
  const location = useLocation();
  const [command, setCommand] = useState(
    getInitialCommand(location.pathname, fabric)
  );
  const [commandHistory, setCommandHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [killRing, setKillRing] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const navigateHistory = useCallback(
    (direction: number) => {
      const newIndex = historyIndex + direction;
      if (newIndex < -1 || newIndex >= commandHistory.length) return;
      if (newIndex === -1) {
        setCommand("");
      } else {
        setCommand(commandHistory?.[newIndex] ?? "");
      }
      setHistoryIndex(newIndex);
    },
    [historyIndex, commandHistory]
  );

  const killText = useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const start = input.selectionStart || 0;
      const end = command.length;
      setKillRing(command.substring(start, end));
      setCommand(command.substring(0, start));
    }
  }, [command]);

  const yankText = useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const start = input.selectionStart || 0;
      // Insert the text from the kill ring at the current cursor position
      const newValue =
        command.substring(0, start) + killRing + command.substring(start);
      setCommand(newValue);
    }
  }, [command, killRing]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCommand(e.target.value);
      onCommandCompleteSuggestionsChange([]);
    },
    [onCommandCompleteSuggestionsChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      onClearError(undefined);
      switch (e.key) {
        // space allows for autocompletion of command,
        case " ":
          completeCommand({
            cmd: command,
            cmdAction: CommandAction.COMPLETE,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "Tab":
          // tab provides all the commands that can be expanded from the given command
          e.preventDefault();
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXPAND,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "?":
          // similar to tab but with help string to explain the command
          e.preventDefault();
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXPOUND,
            resultFormat: ResultFormat.JSON,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "Enter":
          // executes the command
          e.preventDefault();
          setHistoryIndex((prevHistoryIndex) => prevHistoryIndex + 1);
          setCommandHistory([...commandHistory, command]);
          commandExecution({
            cmd: command,
            cmdAction: CommandAction.EXECUTE,
            resultFormat: ResultFormat.CSV,
            cmdPos: inputRef.current?.selectionStart || 0
          });
          break;
        case "ArrowUp":
          e.preventDefault();
          navigateHistory(-1);
          break;
        case "ArrowDown":
          e.preventDefault();
          navigateHistory(1);
          break;
        default:
          break;
      }
      if (e.metaKey) {
        switch (e.key) {
          case "f":
            e.preventDefault();
            moveCursorForwardByWord(inputRef, command);
            break;
          case "b":
            e.preventDefault();
            moveCursorBackwardByWord(inputRef, command);
            break;
          default:
            break;
        }
      }
      if (e.ctrlKey) {
        switch (e.key) {
          case "k":
            e.preventDefault();
            killText();
            break;
          case "y":
            e.preventDefault();
            yankText();
            break;
          case "p":
            e.preventDefault();
            navigateHistory(-1);
            break;
          case "n":
            e.preventDefault();
            navigateHistory(1);
            break;
          default:
            break;
        }
      }
    },
    [
      command,
      commandExecution,
      commandHistory,
      completeCommand,
      navigateHistory,
      killText,
      yankText,
      onClearError
    ]
  );
  useEffect(() => {
    // Autocomplete the input command, when there is only 1 auto complete suggestion (unambiguous)
    if (commandCompleteSuggestions.length === 1) {
      const { newCursorPos = command.length, newValue = command } =
        getCommandCompletionData(inputRef, command, commandCompleteSuggestions);
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          newCursorPos;
        inputRef.current.focus();
      }
      setCommand(newValue);
      onCommandCompleteSuggestionsChange([]);
    }
  }, [commandCompleteSuggestions, command, onCommandCompleteSuggestionsChange]);

  // If there are multiple options for autocomplete, user needs to select one from the options
  const insertSuggestion = useCallback(
    (suggestion: string) => {
      if (inputRef.current) {
        const { newCursorPos = command.length, newValue = command } =
          getCommandCompletionData(inputRef, command, undefined, suggestion);
        inputRef.current.selectionStart = inputRef.current.selectionEnd =
          newCursorPos;
        inputRef.current.focus();
        setCommand(newValue);
        onCommandCompleteSuggestionsChange([]);
      }
    },
    [command, onCommandCompleteSuggestionsChange]
  );

  return (
    <div className="cnc-manage-mock-shell-input-conatiner">
      <Input
        ref={inputRef}
        value={command}
        onChange={handleChange}
        label=""
        onKeyDown={handleKeyDown}
        className="mock-shell-input"
        prefix={`${fabric?.name}#`}
      />
      {commandCompleteSuggestions.length > 1 && (
        <ul className="suggestions-list">
          {commandCompleteSuggestions.map((suggestion) => (
            <li className="suggestion-item" key={suggestion.keyword}>
              <button onClick={() => insertSuggestion(suggestion.keyword)}>
                {suggestion.keyword}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
