// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/tenancy.proto

/* eslint-disable */

export const protobufPackage = "models";

/** CredentialsType defines an enumeration for various authentication credentials. */
export enum CredentialsType {
  /** CREDENTIALS_UNSPECIFIED - Unknown credential type */
  CREDENTIALS_UNSPECIFIED = 0,
  /** PASSWORD - Username/Password based credential */
  PASSWORD = 1,
  /** PUBLIC_KEY - SSH private/public key authentication */
  PUBLIC_KEY = 2,
  UNRECOGNIZED = -1,
}

export function credentialsTypeFromJSON(object: any): CredentialsType {
  switch (object) {
    case 0:
    case "CREDENTIALS_UNSPECIFIED":
      return CredentialsType.CREDENTIALS_UNSPECIFIED;
    case 1:
    case "PASSWORD":
      return CredentialsType.PASSWORD;
    case 2:
    case "PUBLIC_KEY":
      return CredentialsType.PUBLIC_KEY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CredentialsType.UNRECOGNIZED;
  }
}

export function credentialsTypeToJSON(object: CredentialsType): string {
  switch (object) {
    case CredentialsType.CREDENTIALS_UNSPECIFIED:
      return "CREDENTIALS_UNSPECIFIED";
    case CredentialsType.PASSWORD:
      return "PASSWORD";
    case CredentialsType.PUBLIC_KEY:
      return "PUBLIC_KEY";
    case CredentialsType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * EndpointType is an enumeration of various endpoint types used by the
 * Crosswork notification endpoints.
 */
export enum EndpointType {
  /** ENDPOINT_UNSPECIFIED - Invalid notification type. */
  ENDPOINT_UNSPECIFIED = 0,
  /** EMAIL - Email type. */
  EMAIL = 1,
  /** SMS - SMS type. */
  SMS = 2,
  /** S3 - AWS S3 type. */
  S3 = 3,
  /** SLACK - Slack type. */
  SLACK = 4,
  /** WEBEX - Cisco Webex type. */
  WEBEX = 5,
  /** TEST - Invalid test type. */
  TEST = 6,
  /** TEAMS - Microsoft teams. */
  TEAMS = 7,
  /** PAGER_DUTY - PagerDuty type. */
  PAGER_DUTY = 8,
  /** GOOGLE_STORAGE - Google Storage type. */
  GOOGLE_STORAGE = 9,
  UNRECOGNIZED = -1,
}

export function endpointTypeFromJSON(object: any): EndpointType {
  switch (object) {
    case 0:
    case "ENDPOINT_UNSPECIFIED":
      return EndpointType.ENDPOINT_UNSPECIFIED;
    case 1:
    case "EMAIL":
      return EndpointType.EMAIL;
    case 2:
    case "SMS":
      return EndpointType.SMS;
    case 3:
    case "S3":
      return EndpointType.S3;
    case 4:
    case "SLACK":
      return EndpointType.SLACK;
    case 5:
    case "WEBEX":
      return EndpointType.WEBEX;
    case 6:
    case "TEST":
      return EndpointType.TEST;
    case 7:
    case "TEAMS":
      return EndpointType.TEAMS;
    case 8:
    case "PAGER_DUTY":
      return EndpointType.PAGER_DUTY;
    case 9:
    case "GOOGLE_STORAGE":
      return EndpointType.GOOGLE_STORAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EndpointType.UNRECOGNIZED;
  }
}

export function endpointTypeToJSON(object: EndpointType): string {
  switch (object) {
    case EndpointType.ENDPOINT_UNSPECIFIED:
      return "ENDPOINT_UNSPECIFIED";
    case EndpointType.EMAIL:
      return "EMAIL";
    case EndpointType.SMS:
      return "SMS";
    case EndpointType.S3:
      return "S3";
    case EndpointType.SLACK:
      return "SLACK";
    case EndpointType.WEBEX:
      return "WEBEX";
    case EndpointType.TEST:
      return "TEST";
    case EndpointType.TEAMS:
      return "TEAMS";
    case EndpointType.PAGER_DUTY:
      return "PAGER_DUTY";
    case EndpointType.GOOGLE_STORAGE:
      return "GOOGLE_STORAGE";
    case EndpointType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OrgType defines an enumeration for various tenancies. */
export enum OrgType {
  ORG_TYPE_UNSPECIFIED = 0,
  SERVICE_PROVIDER = 1,
  ENTERPRISE = 2,
  SMALL_MEDIUM_BUSINESS = 3,
  CLOUD_CONSUMER = 4,
  UNRECOGNIZED = -1,
}

export function orgTypeFromJSON(object: any): OrgType {
  switch (object) {
    case 0:
    case "ORG_TYPE_UNSPECIFIED":
      return OrgType.ORG_TYPE_UNSPECIFIED;
    case 1:
    case "SERVICE_PROVIDER":
      return OrgType.SERVICE_PROVIDER;
    case 2:
    case "ENTERPRISE":
      return OrgType.ENTERPRISE;
    case 3:
    case "SMALL_MEDIUM_BUSINESS":
      return OrgType.SMALL_MEDIUM_BUSINESS;
    case 4:
    case "CLOUD_CONSUMER":
      return OrgType.CLOUD_CONSUMER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrgType.UNRECOGNIZED;
  }
}

export function orgTypeToJSON(object: OrgType): string {
  switch (object) {
    case OrgType.ORG_TYPE_UNSPECIFIED:
      return "ORG_TYPE_UNSPECIFIED";
    case OrgType.SERVICE_PROVIDER:
      return "SERVICE_PROVIDER";
    case OrgType.ENTERPRISE:
      return "ENTERPRISE";
    case OrgType.SMALL_MEDIUM_BUSINESS:
      return "SMALL_MEDIUM_BUSINESS";
    case OrgType.CLOUD_CONSUMER:
      return "CLOUD_CONSUMER";
    case OrgType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
