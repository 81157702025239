import { CliCommandCompletion } from "src/gen/schema/bilge/api";
import { Fabric, Node } from "src/gen/schema/models/models";

const getDevice = (path: string, fabric: Fabric | undefined) => {
  const [, nodeId] = new RegExp(/\/node\/([^/]+)/).exec(path) ?? [];
  return fabric?.nodes.find((node: Node) => {
    return node.id === nodeId;
  });
};

const getPort = (path: string, fabric: Fabric | undefined) => {
  const [, portId] = new RegExp(/\/port\/([^/]+)/).exec(path) ?? [];
  const device = getDevice(path, fabric);
  return device?.ports.find((port) => {
    return port.id === portId;
  });
};

export const getInitialCommand = (
  path: string,
  fabric: Fabric | undefined
): string => {
  const device = getDevice(path, fabric);
  const port = getPort(path, fabric);
  let _command = "";
  if (device) {
    _command += `show device ${device.deviceId}`;
    if (port) {
      _command += ` ports ${port.index}`;
    }
  }
  return _command;
};

export const moveCursorForwardByWord = (
  inputRef: React.RefObject<HTMLInputElement>,
  command: string
) => {
  if (inputRef.current) {
    const currCursor = inputRef.current.selectionStart || 0;
    const commandFromCursorPos = command.slice(currCursor);
    const commandList = commandFromCursorPos.split(" ");
    const newCursorPos = currCursor + (commandList?.[0]?.length ?? 0) + 1;
    inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
  }
};

export const moveCursorBackwardByWord = (
  inputRef: React.RefObject<HTMLInputElement>,
  command: string
) => {
  if (inputRef.current) {
    const input = inputRef.current;
    const start = input.selectionStart || 0;
    const previousSpace = command.lastIndexOf(" ", start - 2);
    const previousWordStart = previousSpace === -1 ? 0 : previousSpace + 1;
    input.setSelectionRange(previousWordStart, previousWordStart);
  }
};

export const getCommandCompletionData = (
  inputRef: React.RefObject<HTMLInputElement>,
  command: string,
  commandCompleteSuggestions: CliCommandCompletion[] | undefined,
  suggestion?: string
) => {
  const cursorPos = inputRef.current?.selectionStart || 0;
  const words = command.slice(0, cursorPos - 1).split(" ");
  words.pop(); // Remove the current incomplete word
  const suggestedKeyword = suggestion
    ? suggestion
    : commandCompleteSuggestions
    ? commandCompleteSuggestions?.[0]?.keyword
    : "";
  let newValue, newCursorPos;
  if (suggestedKeyword) {
    newValue = `${words.join(" ")} ${suggestedKeyword} ${command.slice(
      cursorPos
    )}`.trim();
    newCursorPos =
      newValue.indexOf(suggestedKeyword) + suggestedKeyword.length + 1;
  }
  return { newValue, newCursorPos };
};

export const getMockShellHelp = () => {
  return {
    space: "Autocompletes if any incomplete commands",
    tab: "Shows options for next command",
    "?": "Shows options for next command with help string",
    return: "Executes command until the cursor"
  };
};

export const getMockShellCtrlKeysHelp = () => {
  return {
    a: "Move to beginning of line",
    e: "Move to end of line",
    k: "Kill (cut) text from cursor to end of line",
    y: "Yank (paste) most recently killed text",
    p: "Move to previous command in history",
    n: "Move to next command in history"
  };
};

export const getMockShellArrowKeysHelp = () => {
  return {
    "↑": "Move to previous command in history",
    "↓": "Move to next command in history"
  };
};

export const getMockShellMetaKeysHelp = () => {
  return {
    f: "Move forward 1 word",
    b: "Move backward 1 word"
  };
};
