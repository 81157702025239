import { Button } from "@magnetic/button";
import { Card } from "@magnetic/card";

import { Modal } from "@magnetic/modal";
import HFlex from "src/components/hFlex";
import VFlex from "src/components/vFlex";
import { MockShellInput } from "./mockShellInput";
import { MockShellOutput } from "./mockShellOutput";
import "./manageMockShellModal.scss";
import { useCallback, useContext, useState } from "react";
import {
  CliParam,
  CliCommandRequest,
  CommandAction,
  ResultFormat,
  CliCommandResponse,
  CliCommandCompletion
} from "src/gen/schema/bilge/api";
import { Apis } from "src/utils/api";
import { PageLoader } from "src/components/pageLoader";
import { ApiError } from "src/core/apiError";
import { DataProviderContext } from "src/core/provider/dataProvider";
import { ErrorNotification } from "src/components/errorNotification";
import { MockShellHelp } from "./mockShellHelp";

interface MockshellProps {
  onClose(): void;
}

export interface CommandExecution {
  cmd: string;
  resultFormat: ResultFormat;
  cmdPos: number;
  cmdAction: CommandAction;
}

export const ManageMockShellModal = (props: MockshellProps) => {
  const { onClose } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const [cmdResult, setCmdResult] = useState<CliCommandResponse | undefined>();
  const [suggestions, setSuggestions] = useState<CliCommandCompletion[]>([]);
  const { fabric } = useContext(DataProviderContext);

  const commandExecution = useCallback(
    ({ cmd, cmdAction, resultFormat, cmdPos }: CommandExecution) => {
      if (fabric) {
        const payload: CliCommandRequest = {
          command: cmd,
          action: cmdAction,
          fabricId: fabric.id,
          resultFormat: resultFormat,
          cursorPos: cmdPos,
          cmdParams: [{ cliParam: CliParam.CLI_PARAM_UNSPECIFIED, value: "" }]
        };
        setLoading(true);
        Apis.Bilge.cliCommands(payload)
          .then((response) => {
            setCmdResult(response);
          })
          .catch((err) => {
            setError(err);
            // Clear the previous command
            setCmdResult(undefined);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [fabric]
  );

  const completeCommand = useCallback(
    ({ cmd, resultFormat, cmdPos }: CommandExecution) => {
      if (fabric) {
        const payload: CliCommandRequest = {
          command: cmd,
          action: CommandAction.COMPLETE,
          fabricId: fabric.id,
          resultFormat: resultFormat,
          cursorPos: cmdPos,
          cmdParams: [{ cliParam: CliParam.CLI_PARAM_UNSPECIFIED, value: "" }]
        };
        setLoading(true);
        Apis.Bilge.cliCommands(payload)
          .then((response) => {
            setSuggestions(response.commandCompletions);
          })
          .catch((err) => {
            setError(err);
            setCmdResult(undefined);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [fabric]
  );
  const clearError = useCallback(() => setError(undefined), []);
  return (
    <Modal isOpen={true} size={"xl"} className="cnc-manage-mock-shell">
      {loading && <PageLoader />}
      <HFlex justify="flex-end">
        <MockShellHelp />
        <Button
          name="route-table-cancel"
          kind="tertiary"
          size="md"
          onClick={onClose}
        >
          Close
        </Button>
      </HFlex>
      <Modal.Divider size="xs" />
      <Card className="cnc-manage-mock-shell-card">
        <Card.Well
          direction="vertical"
          gap="md"
          className="cnc-manage-mock-shell-container"
        >
          <VFlex>
            <MockShellInput
              completeCommand={completeCommand}
              commandExecution={commandExecution}
              commandCompleteSuggestions={suggestions}
              onCommandCompleteSuggestionsChange={setSuggestions}
              onClearError={setError}
              loading={loading}
            />
            {error && <ErrorNotification error={error} onClose={clearError} />}
            <MockShellOutput cmdResult={cmdResult} loading={loading} />
          </VFlex>
        </Card.Well>
      </Card>
    </Modal>
  );
};
