// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/rest_reference_points.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "sextant";

export enum JobState {
  STATE_UNSPECIFIED = 0,
  QUEUED = 1,
  NOT_REQUESTED = 2,
  PROCESSING = 3,
  READY = 4,
  FAILED = 5,
  UNRECOGNIZED = -1,
}

export function jobStateFromJSON(object: any): JobState {
  switch (object) {
    case 0:
    case "STATE_UNSPECIFIED":
      return JobState.STATE_UNSPECIFIED;
    case 1:
    case "QUEUED":
      return JobState.QUEUED;
    case 2:
    case "NOT_REQUESTED":
      return JobState.NOT_REQUESTED;
    case 3:
    case "PROCESSING":
      return JobState.PROCESSING;
    case 4:
    case "READY":
      return JobState.READY;
    case 5:
    case "FAILED":
      return JobState.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobState.UNRECOGNIZED;
  }
}

export function jobStateToJSON(object: JobState): string {
  switch (object) {
    case JobState.STATE_UNSPECIFIED:
      return "STATE_UNSPECIFIED";
    case JobState.QUEUED:
      return "QUEUED";
    case JobState.NOT_REQUESTED:
      return "NOT_REQUESTED";
    case JobState.PROCESSING:
      return "PROCESSING";
    case JobState.READY:
      return "READY";
    case JobState.FAILED:
      return "FAILED";
    case JobState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GeneralRefPointRequest {
  /** The fabric id */
  fabricId: string;
}

export interface SpecificRefPointRequest {
  /** The fabric id */
  fabricId: string;
  /** The reference point name */
  refPointName: string;
}

export interface CreateRefPointRequest {
  /** The fabric id */
  fabricId: string;
  /** An optional list of devices to include in the reference point data capture */
  deviceId: string[];
}

export interface RefPoint {
  /** The reference point name */
  refPointName: string;
  /** The state of the reference point creation job */
  jobState: JobState;
  /** The timestamp for when the reference point was initially created */
  created:
    | Date
    | undefined;
  /** The fabric id */
  fabricId: string;
  /** Optional list of devices included in the reference point as specified at creation time */
  deviceIds: string[];
  /** The state of the reference point diff creation */
  diffJobState: JobState;
}

export interface RefPointList {
  /** A repeated list of reference point */
  refPoints: RefPoint[];
}

export interface FileStreamResponse {
  /** incrementing part number */
  part: number;
  /** chunk of the binary diff file */
  chunk: Uint8Array;
}

function createBaseGeneralRefPointRequest(): GeneralRefPointRequest {
  return { fabricId: "" };
}

export const GeneralRefPointRequest = {
  encode(message: GeneralRefPointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneralRefPointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneralRefPointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneralRefPointRequest {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: GeneralRefPointRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneralRefPointRequest>, I>>(base?: I): GeneralRefPointRequest {
    return GeneralRefPointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneralRefPointRequest>, I>>(object: I): GeneralRefPointRequest {
    const message = createBaseGeneralRefPointRequest();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseSpecificRefPointRequest(): SpecificRefPointRequest {
  return { fabricId: "", refPointName: "" };
}

export const SpecificRefPointRequest = {
  encode(message: SpecificRefPointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.refPointName !== "") {
      writer.uint32(18).string(message.refPointName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpecificRefPointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpecificRefPointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refPointName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpecificRefPointRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      refPointName: isSet(object.refPointName) ? globalThis.String(object.refPointName) : "",
    };
  },

  toJSON(message: SpecificRefPointRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.refPointName !== "") {
      obj.refPointName = message.refPointName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpecificRefPointRequest>, I>>(base?: I): SpecificRefPointRequest {
    return SpecificRefPointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpecificRefPointRequest>, I>>(object: I): SpecificRefPointRequest {
    const message = createBaseSpecificRefPointRequest();
    message.fabricId = object.fabricId ?? "";
    message.refPointName = object.refPointName ?? "";
    return message;
  },
};

function createBaseCreateRefPointRequest(): CreateRefPointRequest {
  return { fabricId: "", deviceId: [] };
}

export const CreateRefPointRequest = {
  encode(message: CreateRefPointRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.deviceId) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRefPointRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRefPointRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRefPointRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: globalThis.Array.isArray(object?.deviceId) ? object.deviceId.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: CreateRefPointRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId?.length) {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateRefPointRequest>, I>>(base?: I): CreateRefPointRequest {
    return CreateRefPointRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRefPointRequest>, I>>(object: I): CreateRefPointRequest {
    const message = createBaseCreateRefPointRequest();
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId?.map((e) => e) || [];
    return message;
  },
};

function createBaseRefPoint(): RefPoint {
  return { refPointName: "", jobState: 0, created: undefined, fabricId: "", deviceIds: [], diffJobState: 0 };
}

export const RefPoint = {
  encode(message: RefPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.refPointName !== "") {
      writer.uint32(10).string(message.refPointName);
    }
    if (message.jobState !== 0) {
      writer.uint32(16).int32(message.jobState);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    for (const v of message.deviceIds) {
      writer.uint32(42).string(v!);
    }
    if (message.diffJobState !== 0) {
      writer.uint32(48).int32(message.diffJobState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refPointName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deviceIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.diffJobState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPoint {
    return {
      refPointName: isSet(object.refPointName) ? globalThis.String(object.refPointName) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceIds: globalThis.Array.isArray(object?.deviceIds)
        ? object.deviceIds.map((e: any) => globalThis.String(e))
        : [],
      diffJobState: isSet(object.diffJobState) ? jobStateFromJSON(object.diffJobState) : 0,
    };
  },

  toJSON(message: RefPoint): unknown {
    const obj: any = {};
    if (message.refPointName !== "") {
      obj.refPointName = message.refPointName;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.created !== undefined) {
      obj.created = message.created.toISOString();
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceIds?.length) {
      obj.deviceIds = message.deviceIds;
    }
    if (message.diffJobState !== 0) {
      obj.diffJobState = jobStateToJSON(message.diffJobState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPoint>, I>>(base?: I): RefPoint {
    return RefPoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPoint>, I>>(object: I): RefPoint {
    const message = createBaseRefPoint();
    message.refPointName = object.refPointName ?? "";
    message.jobState = object.jobState ?? 0;
    message.created = object.created ?? undefined;
    message.fabricId = object.fabricId ?? "";
    message.deviceIds = object.deviceIds?.map((e) => e) || [];
    message.diffJobState = object.diffJobState ?? 0;
    return message;
  },
};

function createBaseRefPointList(): RefPointList {
  return { refPoints: [] };
}

export const RefPointList = {
  encode(message: RefPointList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.refPoints) {
      RefPoint.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefPointList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefPointList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.refPoints.push(RefPoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefPointList {
    return {
      refPoints: globalThis.Array.isArray(object?.refPoints)
        ? object.refPoints.map((e: any) => RefPoint.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RefPointList): unknown {
    const obj: any = {};
    if (message.refPoints?.length) {
      obj.refPoints = message.refPoints.map((e) => RefPoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefPointList>, I>>(base?: I): RefPointList {
    return RefPointList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefPointList>, I>>(object: I): RefPointList {
    const message = createBaseRefPointList();
    message.refPoints = object.refPoints?.map((e) => RefPoint.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFileStreamResponse(): FileStreamResponse {
  return { part: 0, chunk: new Uint8Array(0) };
}

export const FileStreamResponse = {
  encode(message: FileStreamResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.part !== 0) {
      writer.uint32(8).int32(message.part);
    }
    if (message.chunk.length !== 0) {
      writer.uint32(18).bytes(message.chunk);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileStreamResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileStreamResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.part = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.chunk = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileStreamResponse {
    return {
      part: isSet(object.part) ? globalThis.Number(object.part) : 0,
      chunk: isSet(object.chunk) ? bytesFromBase64(object.chunk) : new Uint8Array(0),
    };
  },

  toJSON(message: FileStreamResponse): unknown {
    const obj: any = {};
    if (message.part !== 0) {
      obj.part = Math.round(message.part);
    }
    if (message.chunk.length !== 0) {
      obj.chunk = base64FromBytes(message.chunk);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileStreamResponse>, I>>(base?: I): FileStreamResponse {
    return FileStreamResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileStreamResponse>, I>>(object: I): FileStreamResponse {
    const message = createBaseFileStreamResponse();
    message.part = object.part ?? 0;
    message.chunk = object.chunk ?? new Uint8Array(0);
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
